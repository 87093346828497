<template>
  <div ref="teste">
    <div v-show="calculator">
      <div class="content-input-simulation">
        <h1 class="value-range-simulation">{{ valueAgainsOfferFmt }}</h1>
        <input
          type="range"
          ref="input-range"
          v-bind:min="minAgainstOffer"
          v-bind:max="maxAgainstOffer"
          v-bind:step="calculator.range"
          :value="valueAgainstOffer"
          @input="handleChangeInput"
          class="input-range"
        />
      </div>
      <section class="content-range-simulation">
        <p class="value-range">{{ calculator.minimumValueFmt }}</p>
        <p class="value-range max-value-range">{{ calculator.maximumValueFmt }}</p>
      </section>

      <ContainerCouterOffer
        v-bind:arrayOffers="lastArrayAgainsOffer"
        v-bind:offerValue="offerValue"
        v-if="arrayOffers.length <= 0"
      />
      <ContainerCouterOffer
        v-bind:arrayOffers="arrayOffers"
        v-bind:offerValue="offerValue"
        v-if="arrayOffers.length > 0"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import ContainerCouterOffer from './ContainerCounterOffer.vue'
import { MUTATION_CHANGE_AGAINST_INPUT_OFFER } from '../../../store/mutations.type'
import { BUSCAR_CONTRA_OFERTA } from '../../../store/actions.type'

export default {
  data() {
    return {
      arrayOffers: [],
      calculator: '',
      offerValue: {},
    }
  },
  components: { ContainerCouterOffer },
  watch: {
    counterOfferData: {
      handler() {
        if (this.counterOfferData) {
          this.calculator = this.counterOfferData.response.calculator
          this[MUTATION_CHANGE_AGAINST_INPUT_OFFER](Number(this.valueAgainstOffer))
          this.arrayOption.filter((option) => {
            if (option.value == this.valueAgainstOffer) {
              this.arrayOffers = option.offers.map((offer, index) => ({
                ...offer,
                isActive: index === this.arrayOffers.length - 1 ? true : false,
              }))
            }
          })
          this.$nextTick(() => {
            this.changeInputProgress(this.$refs['input-range'])
          })
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      counterOfferData: 'counterOfferData',
      arrayOption: 'arrayOption',
      minAgainstOffer: 'minAgainstOffer',
      maxAgainstOffer: 'maxAgainstOffer',
      valueAgainstOffer: 'valueAgainstOffer',
      valueAgainsOfferFmt: 'valueAgainsOfferFmt',
      lastArrayAgainsOffer: 'lastArrayAgainsOffer',
    }),
  },
  mounted() {
    this.$store.dispatch(BUSCAR_CONTRA_OFERTA)
  },
  methods: {
    ...mapMutations([MUTATION_CHANGE_AGAINST_INPUT_OFFER]),
    handleChangeInput(event) {
      this.offerValue = event.target.value
      this[MUTATION_CHANGE_AGAINST_INPUT_OFFER](Number(this.offerValue))
      this.arrayOption.filter((option) => {
        if (option.value == this.valueAgainstOffer) {
          this.arrayOffers = option.offers.map((offer, index) => ({
            ...offer,
            isActive: index === this.arrayOffers.length - 1 ? true : false,
          }))
        }
      })
      this.changeInputProgress(event.target)
    },
    changeInputProgress(element) {
      element.style.setProperty('--value', element.value == 0 ? this.maxAgainstOffer : element.value)
      element.style.setProperty('--min', element.min === '' ? this.minAgainstOffer : element.min)
      element.style.setProperty('--max', element.max === '' ? this.maxAgainstOffer : element.max)
    },
  },
}
</script>

<style scoped>
.first-text-simulation {
  color: #019df4;
}

.container-simulation {
  margin-top: 1.5rem;
  width: 100%;
}
.content-input-simulation {
  margin-top: 1.5rem;
  width: 100%;
}

.input-range {
  width: 84%;
}

.text-range-simulation {
  color: #b4b4b4;
  font-size: 12px;
}

.filter-value {
  display: none;
}

.content-simulation {
  width: 96%;
}

.text-content-simulation {
  color: #b4b4b4;
  font-size: 12px;
}

.value-range-simulation {
  color: #0b2739;
  font-size: 1rem;
  text-align: start;
  margin-left: 8%;
}

.max-value-range {
  position: relative;
  left: 15px;
}

.value-range {
  color: #86888c;
  font-size: 14px;
}

.content-range-simulation {
  display: flex;
  width: 87%;
  justify-content: space-between;
  padding-left: 8%;
}

@media (max-width: 450px) {
  .container-simulation,
  .content-input-simulation {
    margin-top: 1rem;
  }

  .value-range-simulation {
    font-size: 18px;
  }

  .value-range {
    color: #86888c;
    font-size: 11px;
  }
}
</style>
