<template>
  <b-row class="pt-5 pb-4">
    <b-col>
      <b-img
        v-if="invertImage"
        src="@/assets/images/money-white.png"
        fluid
        alt="Movistar money logo"
        class="movistar-image"
      />
      <b-img v-else src="@/assets/images/money.png" fluid alt="Movistar money logo" class="movistar-image" />
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'MovistarMoneyLogo',
  props: {
    invertImage: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
