<template>
  <div class="container-sm-credits">
    <CreditsPaymentsCard :propostaMambu="propostaMambu" :planoPagamento="planoPagamento" />
  </div>
</template>

<script>
import CreditsPaymentsCard from './CreditsPaymentsCard'
import { mapGetters } from 'vuex'

export default {
  name: 'CreditsPayments',
  components: { CreditsPaymentsCard },
  computed: {
    ...mapGetters({
      propostaMambu: 'selfManageApplicationMambu',
      planoPagamento: 'selfManageApplicationPaymentPlan',
    }),
  },
}
</script>
