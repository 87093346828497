<template>
  <b-card>
    <div class="py-2 mx-4">
      <p class="text-center mx-4 font-14 text-grey-2">Tu opinión es importante para nosotros</p>
      <p class="text-center font-16 text-pine-green">¿Tu experiencia en la solicitud de crédito fue satisfactoria?</p>

      <b-form-rating v-model="form.stars" variant="primary" class="mb-4" size="lg">
        <template slot="icon-empty">
          <b-img src="@/assets/icons/star-grey.svg" alt="Cancel icon" />
        </template>
        <template slot="icon-full">
          <b-img src="@/assets/icons/star-blue.svg" alt="Cancel icon" />
        </template>
      </b-form-rating>

      <p v-if="form.stars > 3" class="text-center font-16 text-pine-green">¿Qué es lo que más te gustó?</p>
      <p v-else class="text-center font-16 text-pine-green">¿Cómo podemos mejorar tu experiencia?</p>

      <b-form-textarea
        id="textarea"
        class="input-mensagem"
        v-model="form.complement"
        placeholder="Por favor escribe aquí tus comentarios"
        rows="3"
        :maxlength="max"
      ></b-form-textarea>
      <div class="mt-4">
        <MVButton
          class="btn-enviar"
          label="Enviar"
          :disabled="disableEnviar()"
          @click="sendForm"
          variant="movistar-green"
        />
      </div>
    </div>
  </b-card>
</template>

<script>
import MVButton from '@/components/movistar/MVButton'
import { DESTROY_TOKEN, SEND_SURVEY } from '../../store/actions.type'
import store from '../../store'
import { mapGetters } from 'vuex'
import { gtmDataLayerTrackEvent } from '../../helpers/gtmHelper'
import { FETCH_URL_CALLBACK, TOGGLE_OVERLAY } from '../../store/actions.type'

export default {
  name: 'ModalFinishOpinion',
  components: { MVButton },
  props: { newUrlCallback: String },
  data() {
    return {
      max: 200,
      formSend: false,
      form: {
        action: 'survey',
        stars: 5,
        complement: '',
        isPartial: true,
      },
    }
  },
  methods: {
    sendForm() {
      this.formSend = true
      gtmDataLayerTrackEvent(
        window.dataLayer,
        'trackEvent',
        'movistar money',
        'click::thank you page::paso 6',
        `${this.form.stars} de estrellas seleccionadas por el usuario`,
        this.CryptoJS.SHA1(this.currentApplication.mobile).toString()
      )
      store.dispatch(SEND_SURVEY, this.form).then((resp) => {
        if (resp) {
          this.$bvModal.hide('modal-finish')
          if (this.currentApplication.origin === 'Libre') {
            this.$bvModal.show('modal-finish-libre')
          } else {
            store.dispatch(TOGGLE_OVERLAY, true)
            setTimeout(() => {
              if (this.newUrlCallback) store.dispatch(FETCH_URL_CALLBACK, this.newUrlCallback)
              store.dispatch(DESTROY_TOKEN)
              location.href = this.urlCallback
            }, 2000)
          }
        }
      })
    },
    closeModal(id) {
      this.$bvModal.hide(id)
    },
    disableEnviar() {
      if (!this.form.stars || this.formSend) return true
      else return false
    },
  },
  computed: {
    ...mapGetters({
      urlCallback: 'urlCallback',
      currentApplication: 'currentApplication',
    }),
  },
}
</script>

<style lang="scss" scoped>
.card {
  border-radius: 10px;
  border: none;

  .input-mensagem {
    background-color: var(--movistar-grey-3);
    border-radius: 10px;
  }

  .btn-enviar {
    width: 100%;
  }
}
</style>
