<template>
  <div class="tela-reprovacao">
    <MovistarMoneyLogo />
    <div class="conteudo">
      <div class="container-img">
        <div class="col-md-12">
          <b-img
            :src="responseError.imagem || require(`@/assets/images/img-error-assistido.png`)"
            fluid
            alt="Cover logo"
          />
        </div>
      </div>
      <div class="content-errors">
        <section>
          <h1 class="title-error" v-if="responseError.messages.length > 1">
            Aquí puede encontrar algunos de los pasos necesarios para completar el préstamo.
          </h1>
        </section>
        <div v-for="(error, index) in responseError.messages" :key="index">
          <div v-if="error.html" class="col-md-12 no-padding pb-2" v-html="sanitizeMessage(error.html)" />
          <div v-else>
            <div class="col-md-12 no-padding pb-2">
              <h1
                class="onboard-error-title-array"
                :class="{ alignTitle: responseError.messages.length > 1 }"
                v-text="error.titulo"
              />
            </div>
            <div class="col-md-12 no-padding" v-if="error.mensagem">
              <p
                class="onboard-error-subtitle-array"
                :class="{ alignSubTitle: responseError.messages.length > 1 }"
                v-text="error.mensagem"
              />
            </div>
            <div class="col-md-12 no-padding" v-if="error.indicador">
              <p class="onboard-error-subtitle-array" :class="{ alignSubTitle: responseError.messages.length > 1 }">
                Escalamiento: {{ error.indicador }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ErrorPagesButton class="botao-finalizar" />
  </div>
</template>
<script>
import { DESTROY_TOKEN, TOGGLE_OVERLAY } from '@/store/actions.type'
import DOMPurify from 'dompurify'

import ErrorPagesButton from '@/components/buttons/ErrorPagesButton.vue'
import MovistarMoneyLogo from '@/components/MovistarMoneyLogo.vue'

export default {
  data: function () {
    return {
      responseError: {
        messages: [],
      },
    }
  },
  name: 'MensagemReprovacao',
  components: {
    ErrorPagesButton,
    MovistarMoneyLogo,
  },
  mounted() {
    this.responseError = this.$route.params.data.response

    if (this.responseError.messages.length === 0)
      this.responseError.messages = [
        {
          titulo: 'Ahora no podemos procesar tu solicitud',
          mensagem: '¡Regresa pronto, tendremos más ofertas de crédito para ti!',
        },
      ]
    this.$store.dispatch(DESTROY_TOKEN)
    this.$store.dispatch(TOGGLE_OVERLAY, false)
  },
  methods: {
    sanitizeMessage(message) {
      return DOMPurify.sanitize(message)
    },
  },
}
</script>
<style lang="scss" scoped>
.tela-reprovacao {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  .conteudo {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .container-img {
      margin-bottom: 2rem;
    }
  }

  ::v-deep .btn-continue {
    margin: 5rem auto;
    max-width: 30rem;
    @media (max-width: 768px) {
      margin-bottom: 2rem;
    }
  }
}

.onboard-error-title-array {
  text-align: center;
  font-weight: bold;
  font-size: 1.3rem;
  @media (max-width: 768px) {
    font-size: 1.1rem;
  }
}

.content-errors {
  text-align: start;
}

.onboard-error-subtitle-array {
  text-align: center;
  margin-left: 0.5rem;
  font-size: 1.2rem;
  @media (max-width: 768px) {
    font-size: 1rem;
  }
}
.title-error {
  color: #019df4;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  @media (max-width: 768px) {
    font-size: 1.3rem;
  }
}

@media (max-width: 1273px) {
  .arrayResponse {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: center;
    margin-top: 0rem;
  }
  .onboard-error-title-array,
  .onboard-error-subtitle-array {
    text-align: center;
  }
  .container-img {
    margin: 0 auto;
  }
}
.alignTitle,
.alignSubTitle {
  text-align: start;
  margin-left: 3rem;
}
.alignSubTitle {
  margin-left: 5rem;
}
</style>
