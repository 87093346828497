<template>
  <div class="container-fluid mb-5">
    <b-container>
      <StripesTopImage />

      <MovistarMoneyLogo />

      <Stepper :step="step" />

      <b-row class="text-center" align-h="center">
        <b-col cols="12" md="6" class="no-padding">
          <p class="onboard-contract-title">¡Genial, ya casi está!</p>
          <div class="mt-2">
            <CardTermsAndConditions @terms="updateTerms" />
          </div>
          <div>
            <BoxBankData :edit="true" :diaPagamento="currentApplication.paymentCycle" />
          </div>

          <div class="mt-5">
            <ButtonContinue text="Finalizar" :disable="!contractTerms" @click="createContract" origin="Libre" />
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import StripesTopImage from '../components/StripesTopImage'
import MovistarMoneyLogo from '../components/MovistarMoneyLogo'
import Stepper from '../components/Stepper'
import ButtonContinue from '../components/buttons/ButtonContinue'
import CardTermsAndConditions from '../components/CardTermsAndConditions'
import store from '../store'
import { CRIAR_CONTRATO_LIBRE, FETCH_CONTRACT, SAVE_CONTRACT_TERMS, TOGGLE_OVERLAY } from '../store/actions.type'
import { mapGetters } from 'vuex'
import BoxBankData from '../components/DadosBancarios/BoxBankData.vue'
import { Persist } from '../helpers/persist.service'
import { gtmDataLayerTrackEvent } from '../helpers/gtmHelper'

export default {
  name: 'ContratoLibre',
  components: { BoxBankData, CardTermsAndConditions, ButtonContinue, Stepper, MovistarMoneyLogo, StripesTopImage },
  data() {
    return {
      step: 5,
      nextPage: 'ResumoLibre',
      form: {
        action: 'createContractLibre',
        isPartial: false,
      },
    }
  },
  mounted() {
    Persist.clearContractTerms()
    store.dispatch(FETCH_CONTRACT)
  },
  methods: {
    createContract() {
      if (!this.contractTerms) return
      store.dispatch(TOGGLE_OVERLAY, true)
      store.dispatch(CRIAR_CONTRATO_LIBRE, this.form)
      gtmDataLayerTrackEvent(
        window.dataLayer,
        'trackEvent',
        'movistar money',
        'click::verificación de contrato::paso 5',
        'finalizar compra',
        this.CryptoJS.SHA1(this.currentApplication.mobile).toString()
      )
    },
    updateTerms() {
      store.dispatch(SAVE_CONTRACT_TERMS)
    },
  },
  computed: {
    ...mapGetters({
      applicationOffer: 'applicationOffer',
      currentApplication: 'currentApplication',
      contractTerms: 'contractTerms',
    }),
  },
}
</script>
