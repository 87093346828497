<template>
  <b-modal
    id="modal-evidente"
    cancel-title=""
    cancel-variant="hidden"
    size="xl"
    hide-header
    hide-footer
    ok-title=""
    ok-variant="hidden"
    centered
    header-border-variant="white"
    footer-border-variant="white"
    no-close-on-esc
    no-close-on-backdrop
  >
    <div class="mt-3 mb-5">
      <p class="text-center title-onboard">{{ title }}!</p>

      <p class="text-center subtitle-onboard no-margin">{{ subtitle }}</p>

      <form @submit.prevent="validate">
        <b-row align-h="center" class="mx-5 pt-4">
          <input
            id="date"
            name="date"
            type="date"
            v-model="date"
            :class="{ formInputError: $v.date.$error }"
            class="datepicker"
          />
          <p v-if="$v.date.$error" class="form-input-error no-margin">Esta fecha de expedición no es válida</p>
        </b-row>
        <div class="mt-5">
          <ButtonContinue :origin="this.currentApplication.origin" text="Validar" />
        </div>
      </form>
    </div>
  </b-modal>
</template>

<script>
import ButtonContinue from '../buttons/ButtonContinue'
import { required } from 'vuelidate/lib/validators'
import { gtmDataLayerTrackEvent } from '../../helpers/gtmHelper'
import { mapGetters } from 'vuex'

export default {
  name: 'ModalEvidente',
  components: { ButtonContinue },
  props: {
    title: String,
    subtitle: String,
    image: String,
    origin: {
      type: String,
      default: 'Finter',
    },
  },
  data() {
    return {
      date: '',
    }
  },
  validations: {
    date: { required },
  },
  methods: {
    validate() {
      this.$v.date.$touch()
      if (this.$v.date.$error) this.gtmInvalidDate()
      if (this.$v.date.$error) return
      gtmDataLayerTrackEvent(
        window.dataLayer,
        'trackEvent',
        'movistar money',
        'click::preguntas de seguridad::paso 3',
        'validar fecha de expedición del documento',
        this.CryptoJS.SHA1(this.currentApplication.mobile).toString()
      )
      this.$emit('next', this.date)
    },
    gtmInvalidDate() {
      window.dataLayer.trackEvent({
        event: 'trackEvent',
        category: 'movistar money',
        action: 'error',
        label: 'fecha de expedición inválidad',
      })
    },
  },
  computed: {
    ...mapGetters({
      currentApplication: 'currentApplication',
    }),
  },
}
</script>

<style scoped>
.datepicker {
  border: none;
  border-bottom: 1px solid var(--movistar-grey-3);
  color: var(--movistar-pine-green);
}

::-webkit-calendar-picker-indicator {
  background: url('../../assets/icons/calendar.svg') no-repeat;
  margin-left: 15px;
}
</style>
