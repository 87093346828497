<template>
  <b-card class="card-cupo" border-variant="light">
    <div class="d-flex justify-content-between">
      <h4 class="key text-left">Cupo</h4>
      <span class="value" v-text="responseData.quotaMaxFmt" />
    </div>
    <div class="divider mb-3"></div>
    <div class="d-flex justify-content-between">
      <h4 class="key">Categoría</h4>
      <span class="value" v-text="responseData.scoreClassifier" />
    </div>
  </b-card>
</template>

<script setup>
import { computed } from 'vue'
import store from '@/store'

const responseData = computed(() => store.getters.buscadorBasicData)
</script>

<style scoped lang="scss">
.card-cupo {
  max-width: 41.25rem;
  background-color: #f5f5f5;
  border-radius: 10px;

  .card-body {
    padding: 27px 37px;
    .key {
      font-size: 25px;
      margin-bottom: 0;
      font-weight: bold;
    }
    .value {
      font-size: 20px;
    }

    .divider {
      width: 100%;
      height: 0px;
      margin-top: 1rem;
      border-bottom: solid 0.5px #707070;
    }
  }
}
</style>
