<template>
  <div>
    <div class="header-simulation">
      <button
        class="btn-simulation-box-first"
        v-bind:class="{ modificarEstiloBtn: selecionarPrimeiroBotao }"
        @click="modificarEstiloBtn"
        :disabled="selecionarPrimeiroBotao"
      >
        Simular cuota
      </button>

      <button
        class="btn-simulation-box-second"
        v-bind:class="{ modificarEstiloBtn: selecionarSegundoBotao }"
        @click="modificarEstiloBtn"
        :disabled="selecionarSegundoBotao"
      >
        ¡Guía al cliente!
      </button>
    </div>
    <div class="container-assistido-info">
      <div v-if="selecionarPrimeiroBotao">
        <section>
          <h1 class="title-assistido-info">
            Aquí puedes ayudar al cliente a simular las cuotas de su crédito
            <span class="text-bold">da click en "Simular cuota"</span>
          </h1>
        </section>
        <section>
          <ul class="list-assistido-info">
            <li class="content-assistido-info">
              Esta simulación no generará ninguna consulta a fuentes de información o Buró, es solo una referencia
              aproximada.
            </li>

            <li class="content-assistido-info">
              Comunícale al cliente que el detalle que se muestra en el simulador es un aproximado, este puede variar
              acorde al perfil de riesgo del cliente mostrando el valor real más adelante.
            </li>
          </ul>
        </section>
        <BoxIntroducaoTelefoneAssistido />
      </div>
      <div v-if="selecionarSegundoBotao">
        <section>
          <h1 class="title-assistido-info">¡Para iniciar el crédito guía al cliente!</h1>
        </section>
        <section>
          <div class="list-number">
            <span class="number-in-list">1.</span>
            <p class="list-with-number">
              <span class="text-bold">Recuérdale al cliente</span> que iniciará una solicitud de crédito con Movistar
              Money y que para continuar debe aceptar los términos y condiciones y la política de tratamiento de datos
              que serán enviados a su correo al continuar este paso. Indícale que en resumen estará aceptando:
            </p>
          </div>
          <ul class="list-assistido-info-guia">
            <li class="content-assistido-info-guia">El uso de sus datos para la gestión de este crédito</li>
            <li class="content-assistido-info-guia">
              Sus datos personales serán tratados de acuerdo con la ley 1581 de 2012
            </li>
            <li class="content-assistido-info-guia">
              El crédito es financiado por Movistar Money y el equipo es vendido por Movistar Colombia, entidad que
              recibirá el desembolso del crédito aprobado.
            </li>
            <li class="content-assistido-info-guia">
              El monto del crédito esta sujeto a la evaluación de riesgo y nunca superará el valor del equipo.
            </li>
          </ul>
        </section>
        <section>
          <div class="list-number">
            <span class="number-in-list">2.</span>
            <p><span class="text-bold">Pregúntale al cliente</span> claramente que responda “si” o “no”.</p>
          </div>
          <section>
            <ul class="list-assistido-info">
              <li class="content-assistido-info-guia">
                ¿Acepta los Términos y condiciones y el tratamiento de sus datos?
              </li>

              <li class="content-assistido-info-guia">¿Acepta recibir información comercial?</li>
            </ul>
          </section>
        </section>
        <section>
          <div class="list-number">
            <span class="number-in-list">3.</span>
            <p>
              <span class="text-bold">Si el cliente respondió si,</span>
              selecciona check de acuerdo con su respuesta y a continuación da clic en la parte derecha de la pantalla
              "Iniciar crédito"
            </p>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import BoxIntroducaoTelefoneAssistido from './BoxIntroducaoTelefoneAssistido.vue'
export default {
  components: { BoxIntroducaoTelefoneAssistido },
  data() {
    return {
      selecionarPrimeiroBotao: true,
      selecionarSegundoBotao: false,
    }
  },
  methods: {
    modificarEstiloBtn() {
      if (this.selecionarPrimeiroBotao && !this.selecionarSegundoBotao) {
        ;(this.selecionarPrimeiroBotao = false), (this.selecionarSegundoBotao = true)
      } else if (this.selecionarSegundoBotao && !this.selecionarPrimeiroBotao) {
        ;(this.selecionarSegundoBotao = false), (this.selecionarPrimeiroBotao = true)
      }
    },
  },
}
</script>

<style scoped>
.header-simulation {
  display: flex;
  width: 509px;
}

.btn-simulation-box-first,
.btn-simulation-box-second {
  border: none;
  height: 40px;
  font-size: 16px;
  color: #50535a;
}

.btn-simulation-box-first {
  border-radius: 10px 0 0 0;
  width: 126px;
}

.btn-simulation-box-second {
  border-radius: 0 10px 0 0;
  margin-left: 4px;
  width: 144px;
}

.container-assistido-info {
  width: 510px;
  min-height: 580px;
  max-height: 840px;
  border: solid 1px rgba(1, 157, 244, 0.6);
  border-radius: 0 10px 10px 10px;
  background-color: #f5f5f5;
  padding: 42px 45px 81.4px 27px;
}

.title-assistido-info {
  font-size: 21px;
}

.content-assistido-info {
  font-size: 16px;
  width: 413px;
  height: 100%;
  text-align: start;
}

.content-assistido-info:nth-child(2) {
  margin-top: 1.3rem;
}

.content-assistido-info::marker {
  color: #86888c;
  font-size: 1rem;
}

.content-assistido-info-guia {
  font-size: 16px;
  width: 420px;
  height: 100%;
  text-align: start;
  margin-bottom: 8px;
}

.content-assistido-info-guia::marker {
  color: #86888c;
  font-size: 1rem;
}

.modificarEstiloBtn {
  background: #019df4;
  color: #ffffff;
}

.list-number {
  display: flex;
  text-align: start;
}

.number-in-list {
  margin-right: 14px;
  font-weight: bold;
}

.list-with-number {
  width: 430px;
  height: 100px;
  font-size: 15px;
  text-align: start;
  margin-bottom: 1.5rem;
}

.text-bold {
  font-weight: bold;
}

.box-informacoes-assistido {
  margin: 5rem auto;
}
</style>
