<template>
  <div class="container">
    <b-img :src="require(`@/assets/icons/${image}`)" :alt="alt" class="phone-details-icon" />
    <p class="font-14 text-grey no-margin">{{ item }}</p>
  </div>
</template>

<script>
export default {
  name: 'ItemPhoneDetails',
  props: {
    image: String,
    item: String,
    alt: String,
  },
}
</script>

<style scoped>
.container {
  max-width: 80px;
  padding: 0;
  margin: 0;
  margin: 0 auto;
}

.phone-details-icon {
  height: 1.5em;
}
</style>
