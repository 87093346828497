<template>
  <b-modal
    id="modal-address"
    cancel-title=""
    cancel-variant="hidden"
    size="xl"
    hide-header
    hide-footer
    ok-title=""
    ok-variant="hidden"
    centered
    header-border-variant="white"
    footer-border-variant="white"
  >
    <div class="mx-3">
      <b-row align-h="end">
        <b-button variant="blank" @click="closeModal">
          <b-img src="@/assets/icons/cancel.svg" alt="Cancel icon" />
        </b-button>
      </b-row>
      <p class="text-center title-onboard mb-5">{{ title }}</p>
      <b-form @submit.prevent="save">
        <b-form-group>
          <div>
            <b-row align-h="center" class="mx-2">
              <b-input-group :class="{ formInputError: $v.form.departmentDaneCode.$error }">
                <b-input-group-prepend>
                  <b-img src="@/assets/icons/gps-dark.svg" alt="GPS icon" />
                </b-input-group-prepend>
                <b-form-select
                  class="v-mask pb-2 wrap-text"
                  v-model="form.departmentDaneCode"
                  :options="addressDepartments"
                  @change="getCities"
                >
                  <template #first>
                    <b-form-select-option :value="null" disabled>Departamento</b-form-select-option>
                  </template>
                </b-form-select>
              </b-input-group>
            </b-row>

            <b-row align-h="start" class="mx-2 mb-3">
              <span v-if="$v.form.departmentDaneCode.$error" class="form-input-error">
                Seleccione un departamento
              </span>
            </b-row>
          </div>

          <div>
            <b-row align-h="center" class="mx-2">
              <b-input-group :class="{ formInputError: $v.form.cityDaneCode.$error }">
                <b-input-group-prepend>
                  <b-img src="@/assets/icons/gps-dark.svg" alt="GPS icon" />
                </b-input-group-prepend>
                <b-form-select
                  class="v-mask pb-2 wrap-text"
                  v-model="form.cityDaneCode"
                  :options="addressCities"
                  @change="getNeighborhood"
                >
                  <template #first>
                    <b-form-select-option :value="null" disabled>Ciudad</b-form-select-option>
                  </template>
                </b-form-select>
              </b-input-group>
            </b-row>

            <b-row align-h="start" class="mx-2 mb-3">
              <span v-if="$v.form.cityDaneCode.$error" class="form-input-error"> Seleccione una Ciudad </span>
            </b-row>
          </div>

          <div class="mx-2 mb-3">
            <Autocomplete
              :neighborhood-list="addressNeighborhood"
              :error="$v.form.neighborhoodName.$error"
              :input="form.neighborhoodName"
              @neighborhoodSelect="saveNeighborhoodSelect"
              @neighborhoodTyped="saveNeighborhoodTyped"
            />
            <span v-if="$v.form.neighborhoodName.$error" class="form-input-error"> Seleccione un Barrio </span>
          </div>

          <div>
            <b-row align-h="center" class="mx-2 mb-3">
              <b-col class="px-0" cols="4">
                <b-input-group :class="{ formInputError: $v.address.streetType.$error }">
                  <b-form-select class="v-mask pb-2 wrap-text" v-model="address.streetType" :options="streetTypeList">
                    <template #first>
                      <b-form-select-option :value="streetTypeDefault">CALLE</b-form-select-option>
                    </template>
                  </b-form-select>
                </b-input-group>
                <p v-if="$v.address.streetType.$error" class="form-input-error no-margin">Seleccione una Carrera</p>
              </b-col>
              <b-col class="px-0 pl-3" cols="8">
                <b-input-group :class="{ formInputError: $v.address.inputAddress1.$error }">
                  <input
                    type="text"
                    class="v-mask pb-2 input-height"
                    placeholder="Escribe una dirección"
                    v-model="address.inputAddress1"
                  />
                </b-input-group>
                <p v-if="$v.address.inputAddress1.$error" class="form-input-error no-margin">Escriba su dirección</p>
              </b-col>
            </b-row>
          </div>

          <div>
            <b-row align-h="center" class="mx-2">
              <b-input-group>
                <b-input-group-prepend>
                  <b-img src="@/assets/icons/house.svg" alt="GPS icon" />
                </b-input-group-prepend>
                <input
                  type="text"
                  class="v-mask pb-2"
                  placeholder="Edificio, apartamento, etc."
                  v-model="address.inputAddressComplement"
                />
              </b-input-group>
            </b-row>
          </div>

          <div class="mt-5">
            <ButtonContinue text="Validar" :disable="false" :origin="this.currentApplication.origin" />
          </div>
        </b-form-group>
      </b-form>
    </div>
  </b-modal>
</template>

<script>
import ButtonContinue from '../buttons/ButtonContinue'
import { mapGetters, mapMutations } from 'vuex'
import store from '../../store'
import { FETCH_ADDRESS_CITIES, FETCH_ADDRESS_NEIGHBORHOOD, SEND_ADDRESS_FORM } from '../../store/actions.type'
import Autocomplete from '../Autocomplete'
import { required } from 'vuelidate/lib/validators'
import { gtmDataLayerTrackEvent } from '../../helpers/gtmHelper'

export default {
  name: 'ModalAddress',
  components: { Autocomplete, ButtonContinue },
  props: {
    title: String,
  },
  data() {
    return {
      streetTypeDefault: 'CL',
      address: {
        streetType: 'CL',
        inputAddress1: '',
        inputAddressComplement: '',
      },
      form: {
        departmentDaneCode: null,
        cityDaneCode: null,
        neighborhoodName: '',
        typedAddress: '',
      },
      streetTypeList: [
        { value: 'KR', text: 'CARRERA' },
        { value: 'AA', text: 'APARTADO AEREO' },
        { value: 'AUTOP', text: 'AUTOPISTA' },
        { value: 'AV', text: 'AVENIDA' },
        { value: 'AC', text: 'AVENIDA CALLE' },
        { value: 'AK', text: 'AVENIDA CARRERA' },
        { value: 'CT', text: 'CARRETERA' },
        { value: 'DG', text: 'DIAGONAL' },
        { value: 'KM', text: 'KILOMETRO' },
        { value: 'OT', text: 'OTRO' },
        { value: 'TV', text: 'TRANSVERSAL' },
      ],
    }
  },
  validations: {
    address: {
      streetType: { required },
      inputAddress1: { required },
    },
    form: {
      departmentDaneCode: { required },
      cityDaneCode: { required },
      neighborhoodName: { required },
    },
  },
  methods: {
    save() {
      this.$v.form.$touch()
      this.$v.address.$touch()
      if (this.$v.form.$error || this.$v.address.$error) return
      this.concatTypedAddress()
      gtmDataLayerTrackEvent(
        window.dataLayer,
        'trackEvent',
        'movistar money',
        'click::dirección de entrega::paso 4',
        'respuesta de validación dirección',
        this.CryptoJS.SHA1(this.currentApplication.mobile).toString()
      )
      store.dispatch(SEND_ADDRESS_FORM, this.form)
      this.$emit('form')
      this.closeModal()
    },
    closeModal() {
      this.$bvModal.hide('modal-address')
      this.resetForm()
    },

    resetForm() {
      this.form.departmentDaneCode = null
      this.form.cityDaneCode = null
      this.form.neighborhoodCode = ''
      this.form.neighborhoodName = ''
      this.form.typedAddress = ''
      this.address.streetType = 'CL'
      this.address.inputAddress1 = ''
      this.address.inputAddressComplement = ''
    },
    getCities() {
      if (this.form.departmentDaneCode != null) {
        store.dispatch(FETCH_ADDRESS_CITIES, this.form.departmentDaneCode)
        this.form.cityDaneCode = null
        this.form.neighborhoodName = ''
      }
    },
    getNeighborhood() {
      if (this.form.cityDaneCode != null || this.form.cityDaneCode !== '') {
        store.dispatch(FETCH_ADDRESS_NEIGHBORHOOD, this.form.cityDaneCode)
        this.form.neighborhoodName = ''
      }
    },
    saveNeighborhoodSelect(params) {
      this.form.neighborhoodCode = params.value
      this.form.neighborhoodName = params.text
    },
    saveNeighborhoodTyped(params) {
      this.form.neighborhoodName = params
      this.$delete(this.form, 'neighborhoodCode')
    },
    concatTypedAddress() {
      this.form.typedAddress = ''
      this.form.typedAddress = this.form.typedAddress.concat(
        this.address.streetType,
        ' ',
        this.address.inputAddress1,
        ' ',
        this.address.inputAddressComplement
      )
    },
  },
  computed: {
    ...mapGetters({
      addressDepartments: 'addressDepartments',
      addressCities: 'addressCities',
      addressNeighborhood: 'addressNeighborhood',
      currentApplication: 'currentApplication',
    }),
  },
}
</script>

<style scoped>
.custom-select {
  background: url('../../assets/icons/arrow-down-grey.svg') no-repeat right 0.75rem center !important;
}

.input-group {
  border-bottom: 1px solid #707070;
}

.input-group-prepend {
  width: 30px;
  display: block;
}

.wrap-text {
  text-overflow: ellipsis;
  padding-right: 27px;
}

.input-height {
  height: 38px;
}
</style>
