<template>
  <div class="container-fluid mb-5">
    <b-container>
      <StripesTopImage />

      <MovistarMoneyLogo />

      <Stepper :step="step" />

      <b-row class="text-center" align-h="center">
        <b-col cols="12" md="6" class="no-padding">
          <div class="card-wrapper">
            <div class="card-header">
              <span v-text="phoneDetails.deviceModel" />
              <div class="divider"></div>
              <b v-text="financedAmountFmt" />
            </div>
            <b-card class="mb-2" border-variant="white" no-body>
              <div class="b-card-container">
                <b-row align-h="center" align-v="center" class="mb-1">
                  <b-img src="@/assets/icons/money.svg" alt="Money icon" />
                  <b-card-text class="choose-quotas">{{ title }}</b-card-text>
                </b-row>
                <b-card-text class="offer-subtitle">{{ subtitle }}</b-card-text>
                <hr />
                <b-form-group class="py-2 offer-form-group" v-if="offerEnabledOptions && selected">
                  <b-form-radio-group
                    v-model="selected.value"
                    button-variant="white"
                    buttons
                    v-for="option in offerEnabledOptions"
                    :key="option.value"
                  >
                    <b-form-radio
                      v-if="selected.value === option.value"
                      :value="option.value"
                      :key="option.value"
                      :disabled="option.disabled"
                      @change="changeOffer(option)"
                      class="button custom-shadow selected-button"
                    >
                      {{ option.value }}
                    </b-form-radio>
                    <b-form-radio
                      v-else
                      :value="option.value"
                      :key="option.value"
                      :disabled="option.disabled"
                      @change="changeOffer(option)"
                      class="button custom-shadow"
                      :class="{ 'button-disabled': option.disabled }"
                    >
                      {{ option.value }}
                    </b-form-radio>
                  </b-form-radio-group>
                </b-form-group>
                <!-- <div v-if="guaranteePayment" class="fianca">
                  <h2>En este valor esta incluida</h2>
                  <b-row>
                    <b-col class="col-left">
                      <p>Fianza *</p>
                      <p class="model" v-text="phoneDetails.deviceModel" />
                    </b-col>
                    <b-col class="col-right">{{ realFinancedAmountFmt }} </b-col>
                  </b-row>
                </div> -->
                <hr />
                <div v-if="guaranteePayment">
                  <b-row no-gutters align-v="center" class="mb-3">
                    <b-col class="d-flex" cols="7">
                      <p class="continue-button-text my-0 mr-3">Pago único de fianza</p>
                      <b-button class="p-0" variant="blank" v-b-toggle.fianca-details>
                        <b-img src="@/assets/icons/arrow-down.svg"></b-img>
                      </b-button>
                    </b-col>
                    <b-col cols="5" class="text-right">
                      <b-img
                        class="mr-2 mb-1"
                        src="@/assets/icons/warning-blue.svg"
                        alt="Info fianca"
                        width="18"
                        v-b-tooltip.hover.topleft="{
                          title: `Valor total de la fianza`,
                        }"
                      />
                      <span class="offer-value-iva font-weight-bold mr-1" v-text="guaranteePaymentTotalFmt" />
                    </b-col>
                  </b-row>
                  <b-collapse id="fianca-details" class="mt-2">
                    <b-row>
                      <b-col class="dropdown-items text-left grey offer-subtitle">Fianza</b-col>
                      <b-col class="dropdown-items text-right grey offer-subtitle">
                        <span v-text="guaranteePaymentFmt" />
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col class="dropdown-items text-left grey offer-subtitle">IVA fianza</b-col>
                      <b-col class="dropdown-items text-right grey offer-subtitle">
                        <span v-text="calculatedGuaranteePaymentIvaFmt" />
                      </b-col>
                    </b-row>
                  </b-collapse>
                </div>
                <!-- <b-row v-if="guaranteePayment" no-gutters align-v="center" class="mb-3">
                  <b-col cols="5">
                    <p class="continue-button-text my-0">Valor del equipo</p>
                  </b-col>
                  <b-col cols="7" class="text-right">
                    <span class="offer-value-iva font-weight-bold mr-1" v-text="financedAmountFmt" />
                  </b-col>
                </b-row> -->
                <b-row v-else no-gutters align-v="center" class="mb-3">
                  <b-col cols="5">
                    <p class="continue-button-text my-0">Pago inicial equipo:</p>
                  </b-col>
                  <b-col cols="7" class="text-right">
                    <span class="offer-value-iva font-weight-bold mr-1" v-text="advancedPaymentFmt" />
                  </b-col>
                </b-row>

                <b-row no-gutters align-v="center">
                  <b-col cols="5">
                    <p class="continue-button-text my-0">Total cuota / mes</p>
                  </b-col>
                  <b-col cols="7" class="text-right">
                    <ToggleCollapse
                      id="payment-details"
                      class="text-right"
                      :value="offerTotalLoan"
                      :current-application="currentApplication"
                      :total-value="phoneDetails.price"
                      icon-up="arrow-up.svg"
                      icon-down="arrow-down.svg"
                      @click="VerDetalhes()"
                    />
                  </b-col>
                </b-row>
                <b-collapse id="payment-details" class="mt-2">
                  <b-row v-for="(value, key) in offerSelectedItem" :key="key">
                    <b-col class="dropdown-items text-left pr-0">
                      <div v-if="key === 'Total cuota'" class="total-cuota-margin">
                        <span class="grey offer-subtitle" v-text="key" />
                      </div>
                      <!-- <div v-else-if="key === 'Fianza'" class="mb-3">
                        <div v-if="guaranteePayment" class="d-flex justify-content-between">
                          <b-button
                            @click="collapseFianca = !collapseFianca"
                            variant="blank"
                            class="p-0 grey offer-subtitle"
                          >
                            {{ key }}
                            <b-img src="@/assets/icons/arrow-down.svg" alt="Arrow icon" />
                          </b-button>
                          <div>
                            <b-img
                              class="mr-2 mb-1"
                              src="@/assets/icons/warning-blue.svg"
                              alt="Info fianca"
                              width="18"
                              v-b-tooltip.hover.topleft="{
                                title: `Valor total de la fianza`,
                              }"
                            />
                            <span
                              class="grey offer-subtitle pr-3"
                              v-text="collapseFianca ? guaranteePaymentFmt : value"
                            />
                          </div>
                        </div>

                        <b-collapse id="fianza-details" v-model="collapseFianca" class="mt-2">
                          <div class="d-flex justify-content-between">
                            <span class="grey offer-subtitle">IVA Fianza</span>
                            <span class="grey offer-subtitle pr-3">{{ calculatedGuaranteePaymentIvaFmt }}</span>
                          </div>
                        </b-collapse>
                      </div> -->
                      <span
                        v-else
                        class="grey offer-subtitle"
                        :class="{ 'text-bold': key === 'Valor a financiar' }"
                        v-text="key"
                      />
                    </b-col>
                    <b-col class="dropdown-items text-right pl-0" cols="4">
                      <span
                        class="grey offer-subtitle"
                        :class="{ 'text-bold': key === 'Valor a financiar' }"
                        v-text="value"
                      />
                    </b-col>
                  </b-row>
                  <span class="grey offer-subtitle">*Este es un valor aproximado de la composición de la cuota</span>
                </b-collapse>
              </div>
            </b-card>
          </div>
          <div class="card-phone-details-wrapper">
            <CardPhoneDetails
              :phone-details="phoneDetails"
              :showPhoneFeatures="true"
              :current-application="currentApplication"
              :step="step"
            />
          </div>

          <div class="mt-5">
            <ButtonContinue text="Confirmar" :disable="flagDisableOffer" @click="goNextPage" />
          </div>
        </b-col>
      </b-row>
      <Modal
        id="modal-correo"
        title="Para continuar debes validar tu correo electrónico, ingresa a tu bandeja de entrada y valida el link que te enviamos"
        image="check-email.png"
      />

      <Modal
        id="modal-basic"
        title="¡Felicidades!"
        subtitle="Estamos a un paso de aprobar tu solicitud de préstamo"
        image="congratulations.svg"
        v-bind:function="pushCloseModal"
      />
    </b-container>
    <ModalValorAlterado
      id="modal-valor-antecipo-alterado"
      @continuar="$bvModal.hide('modal-valor-antecipo-alterado')"
    />
    <ModalFiancaRequerido />
    <!-- <ModalFianca /> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { gtmDataLayerCheckout, gtmDataLayerTrackEvent } from '@/helpers/gtmHelper'

import {
  DEFINE_OFFER,
  REFRESH_OFFER,
  UPDATE_SELECTED,
  FETCH_APPLICATION,
  FETCH_APPLICATION_REDIRECT,
} from '@/store/actions.type'

import StripesTopImage from '@/components/StripesTopImage.vue'
import MovistarMoneyLogo from '@/components/MovistarMoneyLogo.vue'
import Stepper from '@/components/Stepper.vue'
import CardPhoneDetails from '@/components/CardPhoneDetails.vue'
import Modal from '@/components/modal/Modal.vue'
import ModalValorAlterado from '@/components/modal/antecipo/ModalValorAlterado.vue'
import ButtonContinue from '@/components/buttons/ButtonContinue.vue'
import ToggleCollapse from '@/components/buttons/ToggleCollapse.vue'
import ModalFiancaRequerido from '@/components/fianca/modal/ModalFiancaRequerido.vue'

export default {
  name: 'OfferFinter',
  components: {
    ToggleCollapse,
    ButtonContinue,
    Modal,
    ModalValorAlterado,
    CardPhoneDetails,
    Stepper,
    MovistarMoneyLogo,
    StripesTopImage,
    ModalFiancaRequerido,
  },
  data() {
    return {
      step: 2,
      title: 'Elige tus cuotas',
      subtitle: 'El cobro de tu cuota se incluirá en tu factura Movistar',
      toggle: false,
      postOffer: {
        action: 'listOffer',
      },
      sendOffer: {
        action: 'offer',
        installments: 0,
      },
      collapseFianca: false,
    }
  },
  async mounted() {
    await this.$store.dispatch(REFRESH_OFFER, this.postOffer)
    if (this.guaranteePayment) {
      this.$bvModal.show('modal-fianca-requerido')
    }
    gtmDataLayerCheckout(
      window.dataLayer,
      'checkOutView',
      this.phoneDetails.deviceModel,
      this.phoneDetails.sku,
      this.phoneDetails.price,
      this.phoneDetails.deviceModel,
      'movistar money',
      this.phoneDetails.deviceColor,
      this.step
    )
  },
  watch: {
    changedAdvancedPayment() {
      if (this.changedAdvancedPayment) {
        this.$bvModal.show('modal-valor-antecipo-alterado')
      }
    },
  },
  methods: {
    pushCloseModal() {
      this.$router.push({ name: 'Identity' })
    },
    goNextPage() {
      if (this.flagDisableOffer) return
      this.$store.dispatch(FETCH_APPLICATION, '')
      if (this.currentApplication.emailStatus !== 'ACCEPT') {
        this.showModal('modal-correo')
        return null
      }
      this.updateOffer(this.selected.value)
      this.$store.dispatch(DEFINE_OFFER, this.sendOffer).then((resp) => {
        if (resp) {
          setTimeout(() => {
            this.$store.dispatch(FETCH_APPLICATION_REDIRECT)
          }, 5000)
          this.showModal('modal-basic')
        }
      })
      gtmDataLayerTrackEvent(
        window.dataLayer,
        'trackEvent',
        'movistar money',
        'click::selección cuotas::paso 2',
        'confirmar cuotas',
        this.CryptoJS.SHA1(this.currentApplication.mobile).toString()
      )
    },
    showModal(id) {
      this.$bvModal.show(id)
    },
    changeOffer(selected) {
      this.$store.dispatch(UPDATE_SELECTED, selected)
      gtmDataLayerTrackEvent(
        window.dataLayer,
        'trackEvent',
        'movistar money',
        'click::selección cuotas::paso 2',
        selected.value,
        this.CryptoJS.SHA1(this.currentApplication.mobile).toString()
      )
    },
    VerDetalhes() {
      gtmDataLayerTrackEvent(
        window.dataLayer,
        'trackEvent',
        'movistar money',
        'click::selección cuotas::paso 2',
        'ver detalle de crédito',
        this.CryptoJS.SHA1(this.currentApplication.mobile).toString()
      )
    },
    updateOffer(selected) {
      this.sendOffer.installments = selected
    },
  },
  computed: {
    ...mapGetters({
      phoneDetails: 'phoneDetails',
      offerEnabledOptions: 'offerEnabledOptions',
      selected: 'offerSelectedNumber',
      offerTotalLoan: 'offerTotalLoan',
      currentApplication: 'currentApplication',
      flagDisableOffer: 'flagDisableOffer',
      offerSelectedItem: 'offerSelectedItem',
      advancedPaymentFmt: 'advancedPaymentFmt',
      financedAmountFmt: 'financedAmountFmt',
      realFinancedAmountFmt: 'realFinancedAmountFmt',
      guaranteePayment: 'guaranteePayment',
      guaranteePaymentFmt: 'guaranteePaymentFmt',
      guaranteePaymentTotalFmt: 'guaranteePaymentTotalFmt',
      calculatedGuaranteePaymentIvaFmt: 'calculatedGuaranteePaymentIvaFmt',
      changedAdvancedPayment: 'changedAdvancedPayment',
    }),
  },
}
</script>

<style lang="scss" scoped>
.card-phone-details-wrapper {
  margin-top: 40px;
}

.b-card-container {
  padding: 10px;
}

.button {
  margin: 0 1px;
  width: 50px;
  height: 40px;
  color: var(--movistar-grey);
}

.selected-button {
  background-color: #019df4;
  color: #ffffff;
  font-weight: bold;
}

.offer-subtitle {
  color: #019df4;
}

.choose-quotas {
  font-weight: bold;
  margin-left: 10px;
}

.continue-button-text {
  font-weight: bold;
  text-align: left;
  color: var(--movistar-grey);
}

.grey {
  color: var(--movistar-grey);
}

.custom-shadow {
  box-shadow: 0 3px 6px #00000029;
}

.dropdown-items {
  font-size: 14px;
}

.total-cuota-margin {
  padding-bottom: 1em;
}

.button-disabled {
  background-color: #b6b9bf;
  color: #ffffff;
}

.fianca {
  background-color: #ec624b;
  color: white;
  border-radius: 0 0 10px 10px;
  padding: 10px 0;
  h2 {
    font-size: 1.25rem;
  }
  p {
    font-size: 0.8rem;
    margin: 0;
    &.model {
      font-size: 0.75rem;
      color: #f0f0f0;
    }
  }
  .col-left {
    padding-left: 2rem;
    text-align: left;
  }
  .col-right {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 2rem;
    border-left: 1px solid #d0d0d0;
  }
}

.text-bold {
  font-weight: bold;
}

.card-header {
  display: flex;
  justify-content: space-between;
  background-color: #019df4;
  border: 1px solid #019df4;
  color: white;
  border-radius: 1rem 1rem 0 0 !important;
  .divider {
    border-right: 2px solid #d6d6d6;
  }
}
</style>
