<template>
  <div class="container-solicitud">
    <h1 class="content-title-solicitud">
      Para poder aprobar la solicitud, es <br />
      necesario que:
    </h1>
    <section>
      <ul class="content-list-solicitud">
        <li>1. Leas el contrato.</li>
        <li>2. Firmes el contrato marcado el ícono</li>
      </ul>
    </section>
    <section class="content-contrato-solicitud">
      <img src="@/assets/icons/book-contrato.svg" alt="heade-image" class="img-money-offer" />
      <p class="get-acuerdo">He leído y estoy de acuerdo con el contrato</p>
    </section>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
.container-solicitud {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  padding: 14.2px 9px 13.3px 7px;
  width: 288px;
  height: 154px;
  margin: 0 auto;
}
.content-title-solicitud {
  font-size: 16px;
  color: #0b2739;
}
.content-list-solicitud {
  list-style: none;
  color: #50535a;
  font-size: 14px;

  padding-left: 1rem;
}

.content-list-solicitud li {
  text-align: start;
}

.content-contrato-solicitud {
  display: flex;
  justify-content: center;
  align-items: center;
}

.get-acuerdo {
  color: #019df4;
  font-size: 12px;
  position: relative;
  top: 7px;
  left: 7px;
}
</style>
