<template>
  <div class="container-sm-profile">
    <p class="sm-title mb-4">Mis Pagos</p>
    <div class="sm-cards" v-if="this.propostaAtual" style="margin: -16px">
      <div class="swiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(proposta, index) in propostasValidas" :key="index">
            <FastPaymentHeaderCard
              :propostaMambu="proposta"
              :planoPagamento="planoPagamento"
              :selecionado="index === indexSelecionado"
            />
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { FAST_PAYMENT_GET_APPLICATION_PAYMENT_PLAN } from '@/store/actions.type'
import { MUTATION_FAST_PAYMENT_APPLICATION_MAMBU_SELECIONADO } from '@/store/mutations.type'

import FastPaymentHeaderCard from '@/components/AcessoRapido/FastPaymentHeaderCard.vue'

import Swiper, { Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'

export default {
  name: 'FastPaymentHeader',
  components: {
    FastPaymentHeaderCard,
  },
  data() {
    return {
      indexSelecionado: 0,
      propostaAtual: {},
      listOfTodos: [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }],
      headerOpt: { isVisible: true, backgroundColor: 'green' },
      footerOpt: { isVisible: false },
    }
  },
  mounted() {
    new Swiper('.swiper', {
      modules: [Pagination],

      slidesPerView: 1.1,
      centeredSlides: true,
      spaceBetween: 5,

      on: {
        slideChange: (swiper) => {
          this.mudarProposta(swiper.realIndex)
        },
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    })
    this.mudarProposta(0)
  },
  methods: {
    mudarProposta(index) {
      this.indexSelecionado = index
      this.propostaAtual = this.propostasValidas[index]
      this.$store.commit(MUTATION_FAST_PAYMENT_APPLICATION_MAMBU_SELECIONADO, this.propostasValidas[index])
      this.$store.dispatch(FAST_PAYMENT_GET_APPLICATION_PAYMENT_PLAN, this.propostaAtual.keyMambu)
    },
    isActive(key) {
      if (this.propostaAtual.keyMambu == key) return true
      else return false
    },
  },
  computed: {
    ...mapGetters({
      propostasMambu: 'fastPaymentApplicationsMambu',
      planoPagamento: 'fastPaymentApplicationPaymentPlan',
    }),

    propostasValidas() {
      return this.propostasMambu.applications.filter((proposta) => !proposta.isMovistarBill)
    },
  },
}
</script>

<style scoped>
.swiper-wrapper {
  margin-bottom: 50px;
}
.swiper {
  max-width: 530px;
}

.swiper-pagination {
  text-align: right;
  padding-right: 1.5rem;
}

::v-deep .swiper-pagination-bullet {
  background: rgba(205, 205, 205) !important;
  width: 12px;
  height: 12px;
  border-radius: 100px;
  transition: all 300ms ease;
}

::v-deep .swiper-pagination-bullet-active {
  background: #ec624b !important;
  width: 24px;
  border-radius: 24px;
}
</style>
