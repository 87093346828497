import { render, staticRenderFns } from "./EvidenteValidarAssistido.vue?vue&type=template&id=21b11978&scoped=true&"
import script from "./EvidenteValidarAssistido.vue?vue&type=script&lang=js&"
export * from "./EvidenteValidarAssistido.vue?vue&type=script&lang=js&"
import style0 from "./EvidenteValidarAssistido.vue?vue&type=style&index=0&id=21b11978&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21b11978",
  null
  
)

export default component.exports