<template>
  <div class="conteudo">
    <MovistarMoneyLogo class="logo" />
    <b-row class="imagens">
      <b-col>
        <img src="@/assets/images/gracias.png" alt="" />
      </b-col>
      <b-col class="d-flex align-items-center">
        <img src="@/assets/images/gracias-title.png" alt="" />
      </b-col>
    </b-row>

    <h2 class="descricao">Si tienes alguna duda comunícate al whatsapp</h2>

    <div class="whatsapp d-flex justify-center">
      <img src="@/assets/icons/whatsapp-icon.svg" alt="whatsapp" />
      <span class="ml-1">+57 3152008280</span>
    </div>

    <ButtonContinue class="botao-finalizar" text="Finalizar" @click="finalizarReenvioLink" />
  </div>
</template>

<script>
import MovistarMoneyLogo from '@/components/MovistarMoneyLogo.vue'
import ButtonContinue from '@/components/buttons/ButtonContinue.vue'

export default {
  components: {
    MovistarMoneyLogo,
    ButtonContinue,
  },
  methods: {
    finalizarReenvioLink() {
      window.location.replace('https://www.movistarmoney.co/')
    },
  },
}
</script>

<style lang="scss" scoped>
.conteudo {
  background-image: url(@/assets/images/bg-anticiporesendlink.webp);
  background-size: 100% 100%;
  background-attachment: fixed;

  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  .logo {
    padding-top: 88px !important;
    padding-bottom: 54px !important;
  }

  .imagens {
    margin-top: 2rem;
  }

  .descricao {
    font-size: 26px;
    font-weight: 300;
    margin-top: 3rem;
  }

  .whatsapp {
    img {
      width: 26px;
    }
    font-size: 26px;
    color: #019df4;
  }

  .botao-finalizar {
    width: 400px;
    margin-top: 2rem;
  }
}
</style>
