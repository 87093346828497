<template>
  <div>
    <ButtonContinue text="Finalizar" :origin="origin()" @click="redirect" />
  </div>
</template>

<script>
import ButtonContinue from './ButtonContinue'
import { mapGetters } from 'vuex'

export default {
  name: 'ErrorPagesButton',
  components: { ButtonContinue },
  methods: {
    origin() {
      if (this.currentApplication) {
        return this.currentApplication.origin
      } else if (this.channelOrigin) {
        return this.channelOrigin
      }
      return null
    },
    redirect() {
      window.location.replace('https://www.movistarmoney.co/')
    },
  },
  computed: {
    ...mapGetters({
      url: 'urlCallback',
      channelOrigin: 'channelOrigin',
      currentApplication: 'currentApplication',
    }),
  },
}
</script>
