import api from '../plugins/api'
import apiAuth from '../autenticacao/api.auth'

import {
  CANCEL_PROPOSAL,
  MOBILE_DISPONIVEIS,
  REMOVER_MOBILE_DISPONIVEIS,
  SELF_MANAGE_LOGIN_OTP,
  SELF_MANAGE_LOGIN,
  SELF_MANAGE_NEW_FORGOTTEN_PASSWORD,
  SELF_MANAGE_RESET_PASSWORD,
  SELF_MANAGE_UPDATE_PASSWORD,
  TOGGLE_OVERLAY,
  SELF_MANAGE_LOGIN_PASSWORD,
  SELF_MANAGE_GET_BORROWER,
  SELF_MANAGE_UPDATE_CONTACT,
  SELF_MANAGE_UPDATE_EMAIL,
  SELF_MANAGE_GET_APPLICATIONS,
  SELF_MANAGE_GET_APPLICATIONS_LIBERADAS,
  SELF_MANAGE_GET_APPLICATION_PAYMENT_PLAN,
  SELF_MANAGE_GET_APPLICATION_MAMBU,
  SELF_MANAGE_GET_APPLICATION_CONTRACT,
  SELF_MANAGE_GET_PRODUCT_CERTIFICATE,
  SELF_MANAGE_GET_ACQUITTANCE_CERTIFICATE,
  SELF_MANAGE_GET_APPLICATION_EXTRACT,
  SELF_MANAGE_COMPROVANTE_EPAYCO_EMAIL,
  SELF_MANAGE_GET_CLAIMS,
  SELF_MANAGE_POST_UPDATE_CASE_STATUS,
  SELF_MANAGE_GET_APPLICATION_APOLICE_SEGURO,
  SELF_MANAGE_GET_APPLICATION_BALANCE,
  SELF_MANAGE_VALIDATE_LINK_FORGOTTEN_PASSWORD,
  SELF_MANAGE_UPDATE_CONTACT_PREFERENCES,
} from './actions.type'

import {
  SET_CANCEL_PROPOSAL,
  AUTH_ERROR,
  AUTH_REQUEST,
  AUTH_SUCCESS,
  MUTATE_BORROWER_PHONE,
  DEFINIR_MOBILE_DISPONIVEIS,
  SELF_MANAGE_ERROR,
  SELF_MANAGE_GET_BORROWER_RESP,
  SELF_MANAGE_RESET_PASSWORD_RESP,
  SELF_MANAGE_UPDATE_PASSWORD_ERROR,
  SELF_MANAGE_UPDATE_PASSWORD_RESP,
  SET_ONBOARD_LOGIN,
  SET_ONBOARD_LOGIN_FORM,
  SELF_MANAGE_GET_APPLICATIONS_RESP,
  SELF_MANAGE_GET_APPLICATIONS_LIBERADAS_RESP,
  SELF_MANAGE_GET_APPLICATION_PAYMENT_PLAN_RESP,
  SELF_MANAGE_GET_APPLICATION_MAMBU_RESP,
  SELF_MANAGE_GET_APPLICATIONS_RETRIEVE_CART,
  SELF_MANAGE_MUTATE_LAST_DUE_VALUE,
  SELF_MANAGE_GET_CLAIMS_RESP,
  SELF_MANAGE_POST_UPDATE_CASE_STATUS_RESP,
  SELF_MANAGE_MUTATE_BALANCE_VALUE,
  AUTH_SUCCESS_AUTO_GESTAO,
} from './mutations.type'

import router from '../router'
import store from './index'

const state = {
  mobileDisponiveis: {},
  cancelProposal: '',
  onboardLogin: '',
  onboardLoginForm: '',
  selfManageUpdatePasswordResp: '',
  selfManageUpdatePasswordError: '',
  selfManageResetPassword: '',
  selfManageError: '',
  borrowerPhone: '',
  selfManageBorrower: {},
  selfManageApplications: {},
  selfManageApplicationsRetrieveCart: {},
  selfManageApplicationsLiberadas: {},
  selfManageApplicationPaymentPlan: {},
  paymentPlanGlobalDataLastDueValue: '',
  selfManageApplicationMambu: {},
  selfManageClaims: {},
  selfManageUpdateCaseStatus: {},
  totalBalance: '',
}

const mutations = {
  [DEFINIR_MOBILE_DISPONIVEIS](state, resp) {
    state.mobileDisponiveis = resp
  },
  [SET_ONBOARD_LOGIN](state, form) {
    state.onboardLogin = form
  },
  [SELF_MANAGE_ERROR](state, error) {
    state.selfManageError = error
  },
  [SET_ONBOARD_LOGIN_FORM](state, form) {
    state.onboardLoginForm = form
  },
  [SELF_MANAGE_UPDATE_PASSWORD_RESP](state, resp) {
    state.selfManageUpdatePasswordResp = resp
  },
  [SELF_MANAGE_UPDATE_PASSWORD_ERROR](state, resp) {
    let errormsg = resp.message.replace('[senha:', '')
    errormsg = errormsg.replace(']', '')
    state.selfManageUpdatePasswordError = errormsg
  },
  [SELF_MANAGE_RESET_PASSWORD_RESP](state, resp) {
    state.selfManageResetPassword = resp.emailSentText
  },
  [SELF_MANAGE_GET_BORROWER_RESP](state, resp) {
    state.selfManageBorrower = resp.borrower
    state.borrowerPhone = resp.borrower.contactData[0].telephoneNumber
  },
  [MUTATE_BORROWER_PHONE](state, phone) {
    state.borrowerPhone = phone
  },
  [SELF_MANAGE_GET_APPLICATIONS_RESP](state, response) {
    state.selfManageApplications = response
  },
  [SELF_MANAGE_GET_CLAIMS_RESP](state, response) {
    state.selfManageClaims = response
  },
  [SELF_MANAGE_POST_UPDATE_CASE_STATUS_RESP](state, response) {
    state.selfManageUpdateCaseStatus = response
  },
  [SELF_MANAGE_GET_APPLICATIONS_RETRIEVE_CART](state, response) {
    const selected = response.find(
      (item) =>
        item.situation === 'EmAndamento' &&
        item.origin !== 'Assistido' &&
        item.activity !== 'Formalizacao' &&
        item.activity !== 'ConfirmacaoCompra' &&
        item.activity !== 'OrdemPagamento' &&
        item.activity !== 'EquipamentoEntregue' &&
        item.activity !== 'EquipamentoRecusado' &&
        item.activity !== 'BackOffice' &&
        item.activity !== 'ConfirmacaoAntecipado' &&
        item.activity !== 'ConfirmacaoFianca' &&
        item.activity !== 'ConfirmacaoDeposito'
    )
    if (selected) state.selfManageApplicationsRetrieveCart = selected
    else state.selfManageApplicationsRetrieveCart = null
  },
  [SELF_MANAGE_GET_APPLICATIONS_LIBERADAS_RESP](state, response) {
    state.selfManageApplicationsLiberadas = response.applications
  },
  [SELF_MANAGE_GET_APPLICATION_PAYMENT_PLAN_RESP](state, response) {
    state.selfManageApplicationPaymentPlan = response
    const lastDueValue = response.globalData.lastDueValueFmt.split(/(\s+)/)
    state.paymentPlanGlobalDataLastDueValue = lastDueValue[2]
  },
  [SELF_MANAGE_GET_APPLICATION_MAMBU_RESP](state, response) {
    state.selfManageApplicationMambu = response
  },
  [SELF_MANAGE_MUTATE_LAST_DUE_VALUE](state, value) {
    state.paymentPlanGlobalDataLastDueValue = value
  },
  [SET_CANCEL_PROPOSAL](state, response) {
    state.cancelProposal = response
  },
  [SELF_MANAGE_MUTATE_BALANCE_VALUE](state, value) {
    state.totalBalance = value
  },
}

const actions = {
  [MOBILE_DISPONIVEIS](context, form) {
    store.dispatch(TOGGLE_OVERLAY, true)
    context.commit(SELF_MANAGE_ERROR, '')
    context.commit(DEFINIR_MOBILE_DISPONIVEIS, {})
    context.commit(SET_ONBOARD_LOGIN_FORM, form)
    api
      .consultaMobiles(form.identityCardNumber)
      .then((response) => {
        store.dispatch(TOGGLE_OVERLAY, false)
        if (!response.data.loginOTP) {
          context.commit(SET_ONBOARD_LOGIN, response)
          router.push({ name: 'LoginPassword' })
        }
        context.commit(DEFINIR_MOBILE_DISPONIVEIS, response)
      })
      .catch((e) => {
        const parsedError = JSON.parse(e.message)
        context.commit(SELF_MANAGE_ERROR, parsedError.data.message)
        store.dispatch(TOGGLE_OVERLAY, false)
        throw new Error(e)
      })
  },
  [REMOVER_MOBILE_DISPONIVEIS](context) {
    context.commit(SELF_MANAGE_ERROR, '')
    context.commit(DEFINIR_MOBILE_DISPONIVEIS, {})
  },

  [SELF_MANAGE_LOGIN](context, form) {
    store.dispatch(TOGGLE_OVERLAY, true)
    context.commit(SELF_MANAGE_ERROR, '')
    context.commit(SET_ONBOARD_LOGIN_FORM, form)

    apiAuth
      .selfManageLogin(form)
      .then((response) => {
        context.commit(SET_ONBOARD_LOGIN, response)
        store.dispatch(TOGGLE_OVERLAY, false)
        if (response.data.loginOTP) {
          router.push({ name: 'LoginOTP' })
        } else {
          router.push({ name: 'LoginPassword' })
        }
      })
      .catch((e) => {
        context.commit(SELF_MANAGE_ERROR, e.message)
        store.dispatch(TOGGLE_OVERLAY, false)
        throw new Error(e)
      })
  },

  [SELF_MANAGE_LOGIN_OTP](context, userAuth) {
    context.commit(AUTH_REQUEST)
    store.dispatch(TOGGLE_OVERLAY, true)
    apiAuth
      .postOauthToken(userAuth)
      .then((response) => {
        context.commit(AUTH_SUCCESS_AUTO_GESTAO, response.data)
        store.dispatch(TOGGLE_OVERLAY, false)
        if (response.data.updatePassword) {
          router.push({ name: 'LoginCreatePassword' })
        }
      })
      .catch((e) => {
        const parsedError = JSON.parse(e.message)

        if (parsedError.data.message === 'Usuario no es válido.') {
          router.push({ name: 'Login' })
          store.dispatch(TOGGLE_OVERLAY, false)
          throw new Error(e.message)
        }

        const messageParsed = JSON.parse(parsedError.data.message)
        context.commit(AUTH_ERROR, messageParsed)
        switch (messageParsed.pinError) {
          case 'TTL_EXPIRED':
            store.dispatch(TOGGLE_OVERLAY, false)
            break
          case 'WRONG_PIN':
            store.dispatch(TOGGLE_OVERLAY, false)
            break
          case 'NO_MORE_PIN_ATTEMPTS':
            store.dispatch(TOGGLE_OVERLAY, false)
            break
          case 'Too many requests':
            router.push({ name: 'CantProcessRequest' })
            store.dispatch(TOGGLE_OVERLAY, false)
            break
        }
      })
  },

  [SELF_MANAGE_UPDATE_PASSWORD](context, userAuth) {
    store.dispatch(TOGGLE_OVERLAY, true)
    apiAuth
      .selfManageUpdatePassword(userAuth)
      .then((response) => {
        context.commit(SELF_MANAGE_UPDATE_PASSWORD_RESP, response.data)
        router.push({ name: 'LoginPassword' })
        store.dispatch(TOGGLE_OVERLAY, false)
      })
      .catch((e) => {
        const parsedError = JSON.parse(e.message)
        context.commit(SELF_MANAGE_UPDATE_PASSWORD_ERROR, parsedError)
        store.dispatch(TOGGLE_OVERLAY, false)
      })
  },

  [SELF_MANAGE_LOGIN_PASSWORD](context, userAuth) {
    if (context.getters.onboardLoginForm && context.getters.onboardLoginForm.identityCardNumber) {
      userAuth.username = context.getters.onboardLoginForm.identityCardNumber
    } else if (
      context.getters.selfManageUpdatePasswordResp &&
      context.getters.selfManageUpdatePasswordResp.identificationNumber
    ) {
      userAuth.username = context.getters.selfManageUpdatePasswordResp.identificationNumber
    } else {
      router.push({ name: 'Login' })
      return
    }

    context.commit(AUTH_REQUEST)
    store.dispatch(TOGGLE_OVERLAY, true)

    apiAuth
      .postOauthToken(userAuth)
      .then((response) => {
        context.commit(AUTH_SUCCESS_AUTO_GESTAO, response.data)
        store.dispatch(TOGGLE_OVERLAY, false)
        router.push({ name: 'Home' })
      })
      .catch((e) => {
        const parsedError = JSON.parse(e.message)
        context.commit(AUTH_ERROR, parsedError.data)
        store.dispatch(TOGGLE_OVERLAY, false)
        throw new Error(parsedError.message)
      })
  },

  [SELF_MANAGE_RESET_PASSWORD](context) {
    let body
    if (context.getters.onboardLoginForm) {
      body = { identityCardNumber: context.getters.onboardLoginForm.identityCardNumber }
    } else {
      router.push({ name: 'Login' })
      return
    }
    store.dispatch(TOGGLE_OVERLAY, true)
    apiAuth
      .selfManageResetPassword(body)
      .then((response) => {
        context.commit(SELF_MANAGE_RESET_PASSWORD_RESP, response.data)
        store.dispatch(TOGGLE_OVERLAY, false)
      })
      .catch((e) => {
        store.dispatch(TOGGLE_OVERLAY, false)
        throw new Error(e)
      })
  },

  [SELF_MANAGE_NEW_FORGOTTEN_PASSWORD](context, params) {
    store.dispatch(TOGGLE_OVERLAY, true)
    apiAuth
      .selfManageResetForgottenPassword(params.form, params.routeParams)
      .then((response) => {
        if (response.data.success) {
          context.commit(SELF_MANAGE_UPDATE_PASSWORD_RESP, response.data)
          router.push({ name: 'LoginPassword' })
        } else {
          router.push({ name: 'Login' })
        }
        store.dispatch(TOGGLE_OVERLAY, false)
      })
      .catch((e) => {
        const parsedError = JSON.parse(e.message)
        context.commit(SELF_MANAGE_UPDATE_PASSWORD_ERROR, parsedError)
        store.dispatch(TOGGLE_OVERLAY, false)
        throw new Error(e)
      })
  },
  [SELF_MANAGE_VALIDATE_LINK_FORGOTTEN_PASSWORD](context, params) {
    store.dispatch(TOGGLE_OVERLAY, true)
    return apiAuth
      .selfManageValidateLinkForgottenPassword(params.routeParams)
      .then((response) => {
        store.dispatch(TOGGLE_OVERLAY, false)
        return response.data
      })
      .catch((e) => {
        store.dispatch(TOGGLE_OVERLAY, false)
        throw new Error(e)
      })
  },

  [SELF_MANAGE_GET_BORROWER](context) {
    store.dispatch(TOGGLE_OVERLAY, true)
    api
      .selfManageResetBorrower()
      .then((response) => {
        context.commit(SELF_MANAGE_GET_BORROWER_RESP, response.data)
        store.dispatch(TOGGLE_OVERLAY, false)
      })
      .catch((e) => {
        store.dispatch(TOGGLE_OVERLAY, false)
      })
  },

  [SELF_MANAGE_UPDATE_CONTACT](context, body) {
    store.dispatch(TOGGLE_OVERLAY, true)
    api
      .selfManageUpdateContact(body)
      .then((response) => {
        store.dispatch(TOGGLE_OVERLAY, false)
      })
      .catch((e) => {
        store.dispatch(TOGGLE_OVERLAY, false)
      })
  },
  [SELF_MANAGE_UPDATE_EMAIL](context, body) {
    store.dispatch(TOGGLE_OVERLAY, true)
    api
      .selfManageChangeEmail(body)
      .then((response) => {
        // context.commit(SELF_MANAGE_UPDATE_EMAIL_RESP, response.data)
        store.dispatch(TOGGLE_OVERLAY, false)
      })
      .catch((e) => {
        store.dispatch(TOGGLE_OVERLAY, false)
      })
  },
  [SELF_MANAGE_COMPROVANTE_EPAYCO_EMAIL](context, body) {
    store.dispatch(TOGGLE_OVERLAY, true)
    api
      .selfManageApplicationSendEmailComprovanteEpayco(body)
      .then((response) => {
        store.dispatch(TOGGLE_OVERLAY, false)
      })
      .catch((e) => {
        store.dispatch(TOGGLE_OVERLAY, false)
      })
  },
  [SELF_MANAGE_UPDATE_CONTACT_PREFERENCES](context, body) {
    store.dispatch(TOGGLE_OVERLAY, true)
    api
      .selfManageUpdateContactPreferences(body)
      .then((response) => {
        store.dispatch(TOGGLE_OVERLAY, false)
      })
      .catch((e) => {
        store.dispatch(TOGGLE_OVERLAY, false)
      })
  },

  //-----------------------------------------------
  [SELF_MANAGE_GET_APPLICATIONS](context, dadosGet) {
    api
      .getSelfManageApplications()
      .then((response) => {
        context.commit(SELF_MANAGE_GET_APPLICATIONS_RESP, response.data)
        if (dadosGet.retrieveCard)
          context.commit(SELF_MANAGE_GET_APPLICATIONS_RETRIEVE_CART, response.data.applications)
      })
      .catch((e) => console.log(e))
  },

  [SELF_MANAGE_GET_CLAIMS](context) {
    api
      .getSelfManageClaims()
      .then((response) => {
        context.commit(SELF_MANAGE_GET_CLAIMS_RESP, response.data)
      })
      .catch((e) => console.log(e))
  },
  [SELF_MANAGE_POST_UPDATE_CASE_STATUS](context, caseUpdate) {
    api
      .postSelfManageUpdateCaseStatus(caseUpdate.caseId, caseUpdate.status)
      .then((response) => {
        context.commit(SELF_MANAGE_POST_UPDATE_CASE_STATUS_RESP, response.data)
      })
      .catch((e) => console.log(e))
  },
  [SELF_MANAGE_GET_APPLICATIONS_LIBERADAS](context) {
    api
      .getSelfManageApplicationsLiberadas()
      .then((response) => {
        context.commit(SELF_MANAGE_GET_APPLICATIONS_LIBERADAS_RESP, response.data)
      })
      .catch((e) => console.log(e))
  },

  [SELF_MANAGE_GET_APPLICATION_PAYMENT_PLAN](context, loanAccountId) {
    store.dispatch(TOGGLE_OVERLAY, true)
    api
      .getSelfManageApplicationPaymentPlan(loanAccountId)
      .then((response) => {
        context.commit(SELF_MANAGE_GET_APPLICATION_PAYMENT_PLAN_RESP, response.data)
        store.dispatch(TOGGLE_OVERLAY, false)
      })
      .catch((e) => {
        console.log(e)
        store.dispatch(TOGGLE_OVERLAY, false)
      })
  },

  [SELF_MANAGE_GET_APPLICATION_MAMBU](context, loanAccountId) {
    api
      .getSelfManageApplicationMambu(loanAccountId)
      .then((response) => {
        context.commit(SELF_MANAGE_GET_APPLICATION_MAMBU_RESP, response.data.applications[0])
      })
      .catch((e) => console.log(e))
  },

  [SELF_MANAGE_GET_APPLICATION_CONTRACT](context, loanAccountId) {
    api
      .getSelfManageApplicationConctact(loanAccountId)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', loanAccountId + '_Contrato_Credito.pdf')
        document.body.appendChild(link)
        link.click()
      })
      .catch((e) => console.log(e))
  },

  async [SELF_MANAGE_GET_PRODUCT_CERTIFICATE](context, loanAccountId) {
    await api
      .getSelfManageProductCertificate(loanAccountId)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', loanAccountId + '_Certificado_Producto.pdf')
        document.body.appendChild(link)
        link.click()
      })
      .catch((e) => {
        var decoder = new TextDecoder('utf-8')
        const resposta = JSON.parse(decoder.decode(e.response.data))
        return Promise.reject(resposta.message)
      })
  },

  async [SELF_MANAGE_GET_ACQUITTANCE_CERTIFICATE](context, loanAccountId) {
    await api
      .getSelfManageAcquittanceCertificate(loanAccountId)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', loanAccountId + '_Certificado_Paz_Salvo.pdf')
        document.body.appendChild(link)
        link.click()
      })
      .catch((e) => {
        var decoder = new TextDecoder('utf-8')
        const resposta = JSON.parse(decoder.decode(e.response.data))
        return Promise.reject(resposta.message)
      })
  },

  async [SELF_MANAGE_GET_APPLICATION_EXTRACT](context, loanAccountId) {
    await api
      .getSelfManageApplicationExtract(loanAccountId)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', loanAccountId + '_Extracto.pdf')
        document.body.appendChild(link)
        link.click()
      })
      .catch((error) => {
        var decoder = new TextDecoder('utf-8')
        const resposta = JSON.parse(decoder.decode(error.response.data))
        throw resposta.message
      })
  },

  [CANCEL_PROPOSAL](context, dadosCancelar) {
    const body = {
      idProposta: dadosCancelar.idProposta,
      action: 'cancel',
      status: 'CANCELLED',
      isPartial: false,
    }
    api
      .executeAction(dadosCancelar.idProposta, body)
      .then((response) => {
        context.commit(SET_CANCEL_PROPOSAL, response.data)
        store.dispatch(SELF_MANAGE_GET_APPLICATIONS, dadosCancelar.retrieveCard)
        if (dadosCancelar.redirect) router.push({ name: dadosCancelar.redirect })
      })
      .catch((e) => console.log(e))
  },
  [SELF_MANAGE_GET_APPLICATION_APOLICE_SEGURO](context, loanAccountId) {
    api
      .getSelfManageApplicationApoliceSeguro(loanAccountId)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'Certificado de adhesión – Póliza individua.pdf')
        document.body.appendChild(link)
        link.click()
      })
      .catch((e) => console.log(e))
  },
  [SELF_MANAGE_GET_APPLICATION_BALANCE](context, loanAccountId) {
    api
      .getApplicationBalance(loanAccountId)
      .then((response) => {
        context.commit(SELF_MANAGE_MUTATE_BALANCE_VALUE, response.data.totalBalance)
      })
      .catch((e) => console.log(e))
  },
}

const getters = {
  onboardLoginForm: (state) => state.onboardLoginForm,
  mobileDisponiveis: (state) => state.mobileDisponiveis,
  selfManageResetPassword: (state) => state.selfManageResetPassword,
  selfManageError: (state) => state.selfManageError,
  selfManageUpdatePasswordError: (state) => state.selfManageUpdatePasswordError,
  selfManageUpdatePasswordResp: (state) => state.selfManageUpdatePasswordResp,
  selfManageBorrower: (state) => state.selfManageBorrower,
  borrowerPhone: (state) => state.borrowerPhone,
  selfManageApplications: (state) => state.selfManageApplications,
  selfManageApplicationsLiberadas: (state) => state.selfManageApplicationsLiberadas,
  selfManageApplicationPaymentPlan: (state) => state.selfManageApplicationPaymentPlan,
  paymentPlanGlobalDataLastDueValue: (state) => state.paymentPlanGlobalDataLastDueValue,
  selfManageApplicationMambu: (state) => state.selfManageApplicationMambu,
  selfManageApplicationsRetrieveCart: (state) => state.selfManageApplicationsRetrieveCart,
  selfManageClaims: (state) => state.selfManageClaims,
  selfManageUpdateCaseStatus: (state) => state.selfManageUpdateCaseStatus,
  totalBalance: (state) => state.totalBalance,
}

export default {
  state,
  mutations,
  actions,
  getters,
}
