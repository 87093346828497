<template>
  <div class="container-request-value">
    <div class="card-form">
      <h1 class="title-card-request">Simula el cŕedito del equipo</h1>
      <div class="card-request-information">
        <div class="list-number">
          <span class="number-in-list">1.</span>
          <p class="list-with-number">
            Debes tener claro el cargo básico del cliente para identificar el precio del equipo acorde con la renta
            actual.
          </p>
        </div>

        <div class="list-number">
          <span class="number-in-list">2.</span>
          <p class="list-with-number">Escribe el valor del equipo para calcular la cuota.</p>
        </div>
      </div>

      <div class="card-request-value">
        <form>
          <div class="mb-3">
            <b-input-group>
              <b-input-group-prepend>
                <h1 class="cifrao-input">$</h1>
              </b-input-group-prepend>
              <input class="input-request-value" placeholder="Escribe el valor" v-model="valueRequest" />
            </b-input-group>
            <p v-html="mensagemErro" class="form-input-error"></p>
          </div>
          <button class="btn-request-value" @click.prevent="sendSimuladorAssistido">Simular cuota</button>
        </form>
      </div>
    </div>

    <CardRequest :offerAssisted="responseSimuladorAssistidoOferta" :requestedValue="valueRequest" />
  </div>
</template>

<script>
import CardRequest from '../components/CardRequest.vue'
import store from '../store'
import { ACTION_POST_REQUEST_VALUE } from '../store/actions.type'
import { mapGetters } from 'vuex'

export default {
  name: 'SimuladorAssistido',
  components: { CardRequest },
  data: () => {
    return {
      valueRequest: '',
      mensagemErro: '',
    }
  },
  methods: {
    sendSimuladorAssistido() {
      if (this.valueRequest > 0) {
        store.dispatch(ACTION_POST_REQUEST_VALUE, this.valueRequest)
      }
    },
  },
  watch: {
    responseSimuladorAssistido(response) {
      this.mensagemErro = ''
      if (response.status == 500) this.mensagemErro = 'Error de servidor interno'
      else if (response.status == 400) {
        let mensagemErroQuebraLinha = response.data.message
        this.mensagemErro = mensagemErroQuebraLinha.replace(/(?:\n)/g, '<br/>')
      } else if (response.status == 200 && !response.data.calculator)
        this.mensagemErro = 'No hay ofertas disponibles en mambu.'
    },
  },
  computed: {
    ...mapGetters({
      responseSimuladorAssistido: 'responseSimuladorAssistido',
      responseSimuladorAssistidoOferta: 'responseSimuladorAssistidoOferta',
    }),
  },
}
</script>

<style scoped>
.container-request-value {
  height: 100vh;
  width: 100%;
  background-image: url(~@/assets/images/background-assistido.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.title-card-request {
  color: #019df4;
  font-size: 44px;
  font-weight: 200;
  font-family: Telefonica;
  text-align: start;
  margin-bottom: 2rem;
}

.card-form {
  padding-top: 1rem;
  width: 500px;
  height: 450px;
}

.list-number {
  display: flex;
}

.list-with-number {
  width: 430px;
  font-size: 18px;
  text-align: start;
  margin-bottom: 1rem;
  color: #313235;
}

.number-in-list {
  margin-right: 14px;
  font-weight: bold;
}

.cifrao-input {
  color: #019df4;
  font-size: 35px;
}

input::-webkit-input-placeholder {
  color: #b4b4b4 !important;
  font-size: 18px;
  font-family: Telefonica;
}

.card-request-value {
  display: flex;
  width: 300px;
}

.input-request-value {
  border: none;
  padding: 5px 12px;
  width: 300px;
  min-width: 0;
  background: none;
}

.input-request-value:focus {
  box-shadow: 0 0 0 0;
  outline: 0;
}

.btn-request-value {
  width: 308px;
  height: 46px;
  margin: 49.5px 148.4px 129px 81px;
  padding: 13px 104px;
  border-radius: 40px;
  background-color: #019df4;
  text-aling: center;
  color: #ffff;
  border: none;
  font-weight: bold;
}

/* Para resolução 1380 x 768 */
@media (max-width: 1380px) {
  .container-request-value {
    background-image: url(~@/assets/images/background-assistido.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 100vh;
    width: 100%;
    overflow-y: scroll;
  }
}
.disabledButton {
  background: #d3d4d3;
  color: var(--movistar-grey);
  pointer-events: none;
  cursor: default;
}
</style>
