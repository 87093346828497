<template>
  <div class="container-profile">
    <MovistarMoneyLogo />
    <p class="titulo">¡Gracias {{ borrowerName }} por confiar en Movistar Money</p>
    <p class="sub-titulo mb-4 pb-2">Te llamaremos para confirmar tu desembolso</p>
    <ResumoLibreCard />
  </div>
</template>

<script>
import ResumoLibreCard from '../components/ResumoLibreCard.vue'
import MovistarMoneyLogo from '../components/MovistarMoneyLogo'
import { mapGetters } from 'vuex'
export default {
  name: 'ResumoLibre',
  components: { ResumoLibreCard, MovistarMoneyLogo },
  data() {
    return {
      borrowerName: '',
    }
  },
  methods: {
    setName() {
      if (this.currentApplication) {
        this.borrowerName = this.currentApplication.borrower.name.toLowerCase()
      }
    },
  },
  mounted() {
    this.setName()
  },
  computed: {
    ...mapGetters({
      currentApplication: 'currentApplication',
    }),
  },
}
</script>

<style scoped>
.container-profile {
  background-color: var(--movistar-pine-green);
  padding: 45px 16px;
  border-radius: 0 0 14px 14px;
  height: 100vh;
}

.titulo {
  font-family: Telefonica;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.6px;
  text-align: center;
  color: #fff;
}
.sub-titulo {
  font-family: Telefonica;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}
</style>
