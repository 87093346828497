<template>
  <div class="container-final-assistido">
    <img src="@/assets/icons/cloud-orange.svg" class="cloud-orange" />
    <p class="text-cloud">Número de orden {{ currentApplication.idOrderAmount }}</p>

    <div class="box-information-final">
      <div class="alldevices mb-4">
        <ul class="no-bullets">
          <li
            v-for="device in phoneDetailtUrl.deviceModel"
            id="devices"
            class="devices title-final-assistido"
            :key="device"
          >
            {{ device }}
          </li>
        </ul>
        <img @click="showDevices()" id="arrow" src="@/assets/icons/arrow-down.svg" class="arrow-down" />
      </div>

      <div class="d-flex">
        <p class="m-0"><b>Detalle de tu orden</b></p>
        <b-button class="ml-2 p-0" variant="blank" v-b-toggle.informacao-telefone>
          <b-img src="@/assets/icons/arrow-down.svg"></b-img>
        </b-button>
      </div>

      <b-collapse id="informacao-telefone">
        <div class="box-information-phone">
          <section class="box-information-details">
            <h1 class="number-installments">Valor equipo</h1>
            <p class="number-value">{{ currentApplication.requestedValueFmt }}</p>
          </section>

          <section class="box-information-details">
            <h1 class="number-installments">Número de cuotas</h1>
            <p class="number-value">{{ selectedOfferAssisted.installment }}</p>
          </section>

          <div class="box-information-divider" />

          <section class="box-information-details">
            <p class="number-installments">Total Cuota / mes</p>
            <p class="number-value" v-if="selectedOfferAssisted.taxRate > 0">
              {{ selectedOfferAssisted.totalFmt }} Con IVA
            </p>
            <p class="number-value" v-if="selectedOfferAssisted.taxRate <= 0">{{ selectedOfferAssisted.totalFmt }}</p>
          </section>

          <div class="box-information-divider" />
        </div>
      </b-collapse>

      <section class="box-information-details" v-if="currentApplication.advancedPayment">
        <h1 class="number-installments">Valor cuota inicial</h1>
        <p class="number-value">{{ currentApplication.advancedPaymentFmt }}</p>
      </section>

      <div v-if="currentApplication.statusGuaranteePayment === 'Pendente'" class="box-pendente-fianca">
        <b-img class="mr-2" src="@/assets/icons/warning-blue.svg" width="16" />
        Pendiente pago fianza
      </div>

      <section class="box-information-details" v-if="currentApplication.guaranteePayment">
        <h1 class="number-installments">Pago único fianza</h1>
        <p class="number-value">{{ currentApplication.guaranteePaymentFmt }}</p>
      </section>

      <div
        class="box-information-divider"
        v-if="currentApplication.advancedPayment || currentApplication.guaranteePayment"
      />

      <section class="box-details-final">
        <img src="@/assets/icons/book-assistido.svg" class="img-details-final" />
        <p class="text-details-final" v-if="!!currentApplication.advancedPayment">
          Enviamos el link de pago de tu cuota inicial del equipo a tu correo, si no recibiste el link puedes reenviar
          dando clic en el botón "Volver a enviar el link de pago"
        </p>
        <div v-else-if="currentApplication.guaranteePayment">
          <p>Enviamos el link de pago de la fianza al correo del cliente</p>
          <p>Si no recibió el correo puedes reenviarlo con el botón de "Volver a enviar"</p>
        </div>
        <p class="text-details-final" v-else>
          Recibirás la información de tu solicitud de crédito en tu correo electrónico
        </p>
      </section>
    </div>

    <MVButton
      v-if="currentApplication.advancedPayment"
      class="btn-reenviar-link"
      label="Volver a enviar"
      variant="movistar-green"
      outline
      v-b-modal.modal-recado-email-antecipo
    />
    <MVButton
      v-if="currentApplication.guaranteePayment"
      class="btn-reenviar-link"
      label="Volver a enviar"
      variant="movistar-green"
      outline
      v-b-modal.modal-recado-email-fianca
    />
    <MVButton class="btn-cerrar" label="Cerrar" variant="movistar-green" @click="finalizar()" />

    <!-- <div class="box-opniao">
      <FormularioResumoAssistido />
    </div> -->
  </div>
</template>

<script>
// import FormularioResumoAssistido from '@/components/assistido/ResumoAssistido/FormularioResumoAssistido.vue'
import MVButton from '@/components/movistar/MVButton.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    // FormularioResumoAssistido,
    MVButton,
  },
  data() {
    return {
      allDevices: false,
      informationAssisted: {},
      phone: {},
    }
  },
  methods: {
    showDevices() {
      document.getElementById('arrow').className = this.allDevices ? 'arrow-down' : 'arrow-up'
      document
        .querySelectorAll('[id=devices]')
        .forEach(
          (element) =>
            (element.className = this.allDevices
              ? 'devices title-final-assistido'
              : 'devices-open title-final-assistido')
        )
      this.allDevices = !this.allDevices
    },
    finalizar() {
      this.$router.push({ name: 'FinalAssistido' })
    },
  },
  computed: {
    ...mapGetters({
      currentApplication: 'currentApplication',
      phoneDetailtUrl: 'phoneDetailtUrl',
      selectedOfferAssisted: 'selectedOfferAssisted',
    }),
  },
}
</script>

<style lang="scss" scoped>
.box-opniao {
  width: 340px;
  height: 343px;
  border-radius: 10px;
  border: solid 1px #d3d4d3;
  margin-top: 2rem;
  padding: 24px 10px 10px 0px;
}

.container-final-assistido {
  position: relative;
  margin-top: 32px;
}

.text-cloud {
  position: absolute;
  top: -15px;
  right: 10px;
  font-size: 14px;
  color: #fff;
}

.cloud-orange {
  position: absolute;
  right: 1px;
  top: -20px;
}

.number-value {
  font-size: 16px;
  color: #50535a;
}

.box-information-final {
  width: 340px;
  padding: 27.6px 8.7px 0.3px 19.5px;
  border-radius: 10px;
  border: solid 1px #d3d4d3;
  background-color: #fff;
}

.title-final-assistido {
  font-size: 20px;
  color: #0b2739;
  font-weight: bold;
}

.box-details-final {
  display: flex;
  align-items: center;
  text-align: left;
  margin-top: 1.5rem;
  gap: 0.5rem;
}

.img-details-final {
  width: 68px;
  height: 48px;
}

.text-details-final {
  font-size: 14px;
}

.box-information-phone {
  display: flex;
  margin: 10px 0;
  flex-direction: column;
}

.box-information-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
}

.box-information-divider {
  width: 100%;
  border-top: 1px solid #d6d6d6;
  margin-bottom: 0.5rem;
}

.number-installments {
  text-align: start;
  font-size: 14px;
  color: #86888c;
  font-weight: 300;
}

.devices {
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.devices-open {
  width: 90%;
  overflow: auto;
  word-wrap: break-word;
  height: fit-content;
}

ul.no-bullets {
  list-style-type: none;
  text-align: left;
  font-size: 14px;
  margin: 0;
  padding: 0;
  width: 100%;
}

img.arrow-down {
  margin-top: 0px;
  width: 17px;
}

img.arrow-up {
  width: 17px;
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  margin-top: 0px;
}

.alldevices {
  display: flex;
  width: 75%;
  margin-left: 50px;
}

.box-pendente-fianca {
  display: inline-block;
  border: 1px solid #ec624b;
  padding: 2px 6px;
  margin: 1rem 3rem;
}

.btn {
  &-cerrar,
  &-reenviar-link {
    margin-top: 1rem;
    width: 100%;
  }
}
</style>
