<template>
  <div>
    <b-row align-h="center" align-v="center">
      <b-img class="login-otp-icon" src="@/assets/icons/lock.svg" alt="Lock icon" />
      <v-otp-input
        ref="otpInput"
        input-classes="otp-input-login"
        separator=""
        :num-inputs="4"
        :should-auto-focus="true"
        :is-input-num="true"
        @on-change="handleOnChange"
        @on-complete="handleOnComplete"
      />
    </b-row>
    <b-row class="mt-2" align-v="center">
      <b-col>
        <p v-if="authErrorPin === 'TTL_EXPIRED'" class="font-12 no-margin text-pink">Tu tiempo ha caducado.</p>
        <p v-if="authErrorPin === 'WRONG_PIN'" class="font-12 no-margin text-pink">Código Incorrecto.</p>
        <p v-if="authErrorPin === 'NO_MORE_PIN_ATTEMPTS'" class="font-12 no-margin text-pink">
          Has superado el límite de reintentos. Solicita un nuevo código.
        </p>
      </b-col>
      <b-col></b-col>
    </b-row>

    <OTPNewPinRow :countdown="countdown" :disable-new-pin="disableNewPin" @countdown="resetCountdown" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import OTPNewPinRow from './OTPNewPinRow'

export default {
  name: 'OTPInput',
  components: { OTPNewPinRow },
  props: {
    clearOtp: Number,
  },
  data() {
    return {
      disableNewPin: true,
      countdown: 80,
      disableButton: true,
    }
  },
  watch: {
    countdown: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.countdown--
          }, 1000)
        }
        if (value === 0) {
          this.disableNewPin = false
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
    clearOtp: function (newVal, oldVal) {
      this.$refs.otpInput.clearInput()
    },
  },
  methods: {
    handleOnComplete(value) {
      this.disableButton = false
      this.handleOnChange(value)
    },
    handleOnChange(value, disabled) {
      if (disabled) return
      if (value.length < 4) {
        this.disableButton = true
      }
      this.$emit('password', value)
    },
    resetCountdown(value) {
      this.disableNewPin = true
      this.countdown = value
    },
  },
  computed: {
    ...mapGetters({
      authErrorPin: 'authErrorPin',
    }),
  },
}
</script>
