import Api from '@/plugins/api'

import {
  EPAYCO_POST_RESPONSE,
  EPAYCO_MOBILE_POST_RESPONSE,
  EPAYCO_SALVAR_CHECKOUT_REQUISICAO,
  EPAYCO_POST_RESPONSE_ACESSO_RAPIDO,
  EPAYCO_MOBILE_POST_RESPONSE_ACESSO_RAPIDO,
  EPAYCO_SALVAR_CHECKOUT_REQUISICAO_ACESSO_RAPIDO,
  EPAYCO_POST_RESPONSE_ANTECIPADO,
  EPAYCO_MOBILE_POST_RESPONSE_ANTECIPADO,
  EPAYCO_SALVAR_CHECKOUT_REQUISICAO_PAGAMENTO_FIANCA,
  EPAYCO_POST_RESPONSE_FIANCA,
  EPAYCO_MOBILE_POST_RESPONSE_FIANCA,
} from '@/store/actions.type'

import { EPAYCO_POST_RESPONSE_SUCCESS, EPAYCO_ERROR_RESPONSE } from '@/store/mutations.type'

const state = {
  epaycoResponse: {},
  errorEpaycoResponse: {},
}

const mutations = {
  [EPAYCO_POST_RESPONSE_SUCCESS](state, response) {
    state.epaycoResponse = response
  },
  [EPAYCO_ERROR_RESPONSE](state, response) {
    state.errorEpaycoResponse = response
  },
}

const actions = {
  [EPAYCO_POST_RESPONSE](context, epaycoResponse) {
    Api.postEpaycoResponse(epaycoResponse)
      .then((response) => {
        context.commit(EPAYCO_POST_RESPONSE_SUCCESS, response.data)
      })
      .catch((e) => console.log(e))
  },
  [EPAYCO_MOBILE_POST_RESPONSE](context, epaycoResponse) {
    Api.postEpaycoMobileResponse(epaycoResponse)
      .then((response) => {
        context.commit(EPAYCO_POST_RESPONSE_SUCCESS, response.data)
      })
      .catch((e) => console.log(e))
  },
  [EPAYCO_SALVAR_CHECKOUT_REQUISICAO](context, epaycoResponse) {
    return Api.postEpaycoCheckoutRequisicao(epaycoResponse)
      .then((response) => {
        context.commit(EPAYCO_POST_RESPONSE_SUCCESS, response.data)
        return response
      })
      .catch((e) => {
        const error = JSON.parse(e.message)
        context.commit(EPAYCO_ERROR_RESPONSE, error)
      })
  },
  //ACESSO RAPIDO
  [EPAYCO_POST_RESPONSE_ACESSO_RAPIDO](context, epaycoResponse) {
    Api.postEpaycoResponseAcessoRapido(epaycoResponse)
      .then((response) => {
        context.commit(EPAYCO_POST_RESPONSE_SUCCESS, response.data)
      })
      .catch((e) => console.log(e))
  },
  [EPAYCO_MOBILE_POST_RESPONSE_ACESSO_RAPIDO](context, epaycoResponse) {
    Api.postEpaycoMobileResponseAcessoRapido(epaycoResponse)
      .then((response) => {
        context.commit(EPAYCO_POST_RESPONSE_SUCCESS, response.data)
      })
      .catch((e) => console.log(e))
  },
  [EPAYCO_SALVAR_CHECKOUT_REQUISICAO_ACESSO_RAPIDO](context, epaycoResponse) {
    return Api.postEpaycoCheckoutRequisicaoAcessoRapido(epaycoResponse)
      .then((response) => {
        context.commit(EPAYCO_POST_RESPONSE_SUCCESS, response.data)
        return response
      })
      .catch((e) => {
        const error = JSON.parse(e.message)
        context.commit(EPAYCO_ERROR_RESPONSE, error)
      })
  },

  // ANTECIPO
  [EPAYCO_POST_RESPONSE_ANTECIPADO](context, epaycoResponse) {
    Api.postEpaycoResponseAntecipado(epaycoResponse)
      .then((response) => {
        context.commit(EPAYCO_POST_RESPONSE_SUCCESS, response.data)
      })
      .catch((e) => console.log(e))
  },
  [EPAYCO_MOBILE_POST_RESPONSE_ANTECIPADO](context, epaycoResponse) {
    Api.postEpaycoMobileResponseAntecipado(epaycoResponse)
      .then((response) => {
        context.commit(EPAYCO_POST_RESPONSE_SUCCESS, response.data)
      })
      .catch((e) => console.log(e))
  },
  //FIANCA
  [EPAYCO_SALVAR_CHECKOUT_REQUISICAO_PAGAMENTO_FIANCA](context, fiancaResponse) {
    return Api.postFiancaCheckoutRequisicaoPagamento(fiancaResponse)
      .then((response) => {
        context.commit(EPAYCO_POST_RESPONSE_SUCCESS, response.data)
        return response
      })
      .catch((e) => {
        const error = JSON.parse(e.message)
        context.commit(EPAYCO_ERROR_RESPONSE, error)
      })
  },
  [EPAYCO_POST_RESPONSE_FIANCA](context, epaycoResponse) {
    Api.postEpaycoResponseFianca(epaycoResponse)
      .then((response) => {
        context.commit(EPAYCO_POST_RESPONSE_SUCCESS, response.data)
      })
      .catch((e) => console.log(e))
  },
  [EPAYCO_MOBILE_POST_RESPONSE_FIANCA](context, epaycoResponse) {
    Api.postEpaycoMobileResponseFianca(epaycoResponse)
      .then((response) => {
        context.commit(EPAYCO_POST_RESPONSE_SUCCESS, response.data)
      })
      .catch((e) => console.log(e))
  },
}

const getters = {
  epaycoResponse: (state) => state.epaycoResponse,
  errorEpaycoResponse: (state) => state.errorEpaycoResponse,
}

export default {
  state,
  mutations,
  actions,
  getters,
}
