<template>
  <b-navbar toggleable="sm" class="navbar-bg">
    <b-navbar-brand href="#" class="mx-auto">
      <b-img src="@/assets/images/money-white.png" fluid alt="Movistar money logo" class="movistar-image" />
    </b-navbar-brand>
  </b-navbar>
</template>

<script>
export default {
  name: 'SMNavbar',
}
</script>

<style lang="scss" scoped>
.navbar-bg {
  background-color: var(--movistar-blue);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
</style>
