<template>
  <div class="stepper">
    <b-col class="p-0">
      <div v-for="index in 4" :key="index" class="d-flex flex-column align-items-center">
        <div
          class="step"
          :class="{
            ['step-past']: step > index,
            ['step-active']: step === index,
          }"
          v-text="index"
        />
        <div v-if="index !== maxStep" class="line-vertical"></div>
      </div>

      <!-- <b-row v-if="step === 4" class="text-center" align-h="center">
        <div class="step step-past rotate-number">1</div>
        <hr class="line line-past" />
        <div class="step step-past rotate-number">2</div>
        <hr class="line line-past" />
        <div class="step step-past rotate-number">3</div>
        <hr class="line line-past" />
        <div class="step step-active rotate-number">4</div>
      </b-row>

      <b-row v-else-if="step === 3" class="text-center" align-h="center">
        <div class="step step-past rotate-number">1</div>
        <hr class="line line-past" />
        <div class="step step-past rotate-number">2</div>
        <hr class="line line-past" />
        <div class="step step-active rotate-number">3</div>
        <hr class="line" />
        <div class="step rotate-number">4</div>
      </b-row>

      <b-row v-else-if="step === 2" class="text-center" align-h="center">
        <div class="step step-past rotate-number">1</div>
        <hr class="line line-past" />
        <div class="step step-active rotate-number">2</div>
        <hr class="line" />
        <div class="step rotate-number">3</div>
        <hr class="line" />
        <div class="step rotate-number">4</div>
      </b-row>

      <b-row v-else class="text-center" align-h="center">
        <div class="step step-active rotate-number">1</div>
        <hr class="line" />
        <div class="step rotate-number">2</div>
        <hr class="line" />
        <div class="step rotate-number">3</div>
        <hr class="line" />
        <div class="step rotate-number">4</div>
      </b-row> -->
    </b-col>
  </div>
</template>

<script>
export default {
  name: 'Stepper',
  props: {
    step: Number,
    maxStep: {
      type: Number,
      default: 4,
    },
  },
}
</script>

<style lang="scss" scoped>
.step {
  line-height: 30px;
  border-radius: 50%;
  font-size: 18px;
  text-align: center;
  background: #ffffff;
  color: #d3d4d3;
  border-style: solid;
  border-width: 1px;
  border-color: #d3d4d3;
  @media (min-width: 410px) {
    width: 39px;
    height: 39px;
    line-height: 39px;
  }
}

.step-active {
  color: #ffffff;
  border: 1px solid #019df4;
  background: #019df4;
}

.step-past {
  color: #ffffff;
  border-color: #a3ddee;
  background: #a3ddee;
}

.line-vertical {
  height: 4rem;
  width: 1px;
  background-color: #d3d4d3;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
</style>
