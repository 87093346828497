<template>
  <div>
    <div class="floating-input" :class="{ error }">
      <label :for="id" v-text="label" />
      <input :id="id" ref="inputRef" class="currency-input" type="text" :maxLength="maxLength" />

      <slot v-if="icon" name="icon">
        <div class="icon"><img :src="icone" alt="" /></div>
      </slot>
    </div>
    <div v-if="error" class="error-message" v-text="errorMessage" />
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useCurrencyInput } from 'vue-currency-input'

const props = defineProps({
  id: {
    type: String,
    default: '',
  },
  label: {
    type: String,
    default: '',
  },
  value: {
    type: Number,
  },
  precision: {
    type: Number,
    default: 2,
  },
  error: {
    type: Boolean,
    default: false,
  },
  errorMessage: {
    type: String,
    default: 'Error de validacion',
  },
  icon: {
    type: String,
    default: '',
  },
  maxLength: {
    type: Number,
    default: null,
  },
})

const options = computed(() => ({
  currency: 'COL',
  currencyDisplay: 'hidden',
  precision: props.precision,
  autoDecimalDigits: true,
  hideGroupingSeparatorOnFocus: false,
}))

const { inputRef, setValue } = useCurrencyInput(options.value)
const icone = computed(() => {
  return require('@/assets/icons/' + props.icon)
})

// watch(props.value, (value) => {
//   setValue(value)
// })
</script>

<style scoped lang="scss">
.floating-input {
  border: solid 1px #86888c;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0.9rem;
  position: relative;
  &.error {
    border: solid 1px #ff0000;
  }
  &:focus-within {
    border: solid 1px #019df4;
  }
  label {
    margin: 0;
    text-align: left;
    font-size: 1.25rem;
    color: #019df4;
  }
  input {
    border: none;
    padding: 0;
    height: 100%;
    background-color: transparent;
    font-size: 1.25rem;
    &:focus {
      outline: none;
    }
  }

  .icon {
    position: absolute;
    right: 2rem;
    top: 1.5rem;
  }
}

.error-message {
  color: #ff0000;
  text-align: right;
}
</style>
