import { render, staticRenderFns } from "./DetalhesTelefoneAssistido.vue?vue&type=template&id=5144e2df&scoped=true&"
import script from "./DetalhesTelefoneAssistido.vue?vue&type=script&lang=js&"
export * from "./DetalhesTelefoneAssistido.vue?vue&type=script&lang=js&"
import style0 from "./DetalhesTelefoneAssistido.vue?vue&type=style&index=0&id=5144e2df&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5144e2df",
  null
  
)

export default component.exports