<template>
  <b-container fluid class="conteudo">
    <MovistarMoneyLogo />
    <b-img class="alert" src="@/assets/images/oh-no.svg" alt="Cover logo" />
    <h1>¡Lo sentimos!</h1>
    <p>No encontramos ningún link de pago vigente</p>
    <MVButton label="Finalizar" variant="movistar-green" @click="finalizar()" />
  </b-container>
</template>

<script setup>
import MovistarMoneyLogo from '@/components/MovistarMoneyLogo'
import MVButton from '@/components/movistar/MVButton.vue'

function finalizar() {
  window.location.replace('https://www.movistarmoney.co/')
}
</script>

<style lang="scss" scoped>
.conteudo {
  background-image: url(@/assets/images/bg-anticiporesendlink.webp);
  background-size: 100% 100%;
  background-attachment: fixed;
  min-height: 100vh;
}
img.alert {
  width: 400px;
  margin-top: 4rem;
  margin-bottom: 6rem;
}

h1 {
  font-size: 2rem;
  margin-bottom: 2rem;
}

p {
  margin: 0;
  font-size: 1.5rem;
}

.btn {
  margin-top: 2rem;
  width: 22rem;
}
</style>
