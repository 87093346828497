<template>
  <div class="container-fluid mb-5">
    <b-container>
      <StripesTopImage />

      <MovistarMoneyLogo />
      <div :id="this.sendBody.paymentCycle" v-if="msgCard" align="center">
        <CardMensagem :texto="textoMsg" v-bind:abrirApos="2" v-bind:fecharApos="8" />
      </div>

      <Stepper :step="step" />

      <b-row class="text-center" align-h="center">
        <b-col cols="12" md="6" class="no-padding">
          <p class="title-onboard-otp">Completa tú datos</p>

          <div class="mt-4">
            <CardCycle @valueCycle="updateCycle" @enableButton="cardCycleEnableButtonMethod" />
          </div>
          <div class="mt-4">
            <CardAddress
              origin="Libre"
              :address-form-callejero="addressFormCallejero"
              @enableButton="cardAddressEnableButtonMethod"
              @selectAddress="updateAddress"
            />
          </div>

          <div>
            <BoxResumoDadosBancarios :edit="true" :diaPagamento="this.sendBody.paymentCycle" />
          </div>

          <div class="mt-5">
            <ButtonContinue
              text="Continuar"
              :disable="!enableButton || completeDirection"
              @click="goNextPage"
              origin="Libre"
            />
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import StripesTopImage from '../components/StripesTopImage'
import MovistarMoneyLogo from '../components/MovistarMoneyLogo'
import Stepper from '../components/Stepper'
import ButtonContinue from '../components/buttons/ButtonContinue'
import CardAddress from '../components/CardAddress'
import { mapGetters } from 'vuex'
import BoxResumoDadosBancarios from '../components/DadosBancarios/BoxBankData.vue'
import store from '../store'
import { GET_ADDRESS_CLIENT, SAVE_CHOOSEN_ADDRESS } from '../store/actions.type'
import CardCycle from '../components/CardCycle.vue'
import { gtmDataLayerTrackEvent, gtmDataLayerCheckout } from '../helpers/gtmHelper'
import CardMensagem from '../components/CardMensagem'

export default {
  name: 'DirectionLibre',
  components: {
    CardMensagem,
    CardAddress,
    ButtonContinue,
    Stepper,
    MovistarMoneyLogo,
    StripesTopImage,
    CardCycle,
    BoxResumoDadosBancarios,
  },
  data() {
    return {
      msgCard: false,
      step: 4,
      nextPage: 'ContratoLibre',
      adressSelect: true,
      cycleSelect: false,
      sendBody: {
        action: 'confirmDeliveryAddress',
        confirmDeliveryAddress: 'WORK',
        paymentCycle: null,
      },
    }
  },
  mounted() {
    store.dispatch(GET_ADDRESS_CLIENT)
    gtmDataLayerCheckout(
      window.dataLayer,
      'checkOutView',
      this.currentApplication.requestedValueFmt,
      this.currentApplication.installmentsNumber,
      this.currentApplication.offer.feesDueFmt,
      'prestamo libre inversión',
      'movistar money',
      '0',
      this.step
    )
  },
  methods: {
    updateCycle(payload) {
      this.msgCard = false
      this.sendBody.paymentCycle = payload
      setTimeout(this.exibirMsg, 500)
    },
    exibirMsg() {
      this.msgCard = true
    },
    goNextPage() {
      if (!this.enableButton) return
      store.dispatch(SAVE_CHOOSEN_ADDRESS, { sendBody: this.sendBody, nextPage: this.nextPage })
      gtmDataLayerTrackEvent(
        window.dataLayer,
        'trackEvent',
        'movistar money',
        'click::click::completar datos::paso 5',
        'continuar',
        this.CryptoJS.SHA1(this.currentApplication.mobile).toString()
      )
    },
    cardAddressEnableButtonMethod(bool) {
      this.adressSelect = bool
    },
    cardCycleEnableButtonMethod(bool) {
      this.cycleSelect = bool
    },
    updateAddress(address) {
      this.sendBody.confirmDeliveryAddress = address
    },
  },
  computed: {
    ...mapGetters({
      addressFormCallejero: 'addressFormCallejero',
      currentApplication: 'currentApplication',
      completeDirection: 'completeDirection',
    }),
    textoMsg() {
      return (
        'A tu correo electrónico llegará el extracto <b>independiente</b> para pagar la cuota de tu crédito de libre inversión antes del ' +
        this.sendBody.paymentCycle +
        ' del mes, también lo podrás consultar y pagar en www.movistarmoney.co, opción Mi cuenta.'
      )
    },
    enableButton() {
      if (this.adressSelect === true && this.cycleSelect === true) {
        return true
      }
      return false
    },
  },
}
</script>
