<template>
  <div class="footer">
    <div class="footer-container-security">
      <img src="@/assets/icons/secure.svg" alt="Movistar lock secure" class="movistar-security-img" />
      <p class="sm-footer-security no-margin text-security-footer">
        Contamos con un protocolo seguro para proteger tus datos durante el proceso.
      </p>
    </div>
    <div class="container-about">
      <span class="sm-footer-title">Más de nosotros</span>
      <ul class="about-list">
        <li class="sm-footer-links">
          <a href="https://www.movistarmoney.co/politica_cobranza.html">Política de cobranzas</a>
        </li>
        <li class="sm-footer-links">
          <a href="https://www.movistarmoney.co/politica_tratamiento.html">Política de tratamiento de datos</a>
        </li>
        <li class="sm-footer-links">
          <a href="https://www.movistarmoney.co/poliza-seguro-de-vida.html">Clausulado - Póliza de seguro de vida</a>
        </li>
        <li class="sm-footer-links">
          <a href="https://www.movistarmoney.co/terminos_condiciones.html">Términos y Condiciones de Movistar Money</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterFianca',
}
</script>
<style scoped>
.footer {
  border-top: 1px solid #d3d4d3;
  display: flex;
  flex-direction: row-reverse;
  margin-top: 10px;
  padding-bottom: 10px;
  justify-content: space-around;
}

.movistar-security-img {
  width: 33.6px;
  height: 38.3px;
  margin-right: 10px;
}

.sm-footer-links {
  text-align: center;
  margin-top: 3px;
}

.container-about {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.about-container {
  text-align: start;
  list-style: none;
  margin-top: 5px;
}

.about-list {
  list-style: none;
  margin-top: 5px;
  margin-right: 2.5rem;
}

li {
  text-align: start;
  text-decoration: underline;
}
a {
  color: #707070;
}
.footer-container-security {
  display: flex;
  align-self: center;
  justify-content: center;
  margin-top: 10px;
  width: 400px;
}

.text-security-footer {
  font-size: 1rem;
  text-align: start;
}

@media (max-width: 790px) {
  .footer {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }
  .about-container {
    text-align: start;
    list-style: none;
    margin-top: 0px;
  }
  .footer-container-security {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .container-about {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
  }

  .movistar-security-img {
    margin-left: 30px;
  }

  .text-security-footer {
    font-size: 12px;
    width: 230px;
  }
  .about-container {
    position: relative;
    left: -40px;
  }
}
</style>
