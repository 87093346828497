<template>
  <div class="container-fluid mb-5">
    <Error
      title="Ahora no podemos procesar tu solicitud"
      subtitle="¡Regresa pronto, tendremos más ofertas de crédito para ti!"
      :html="htmlMessage"
      image="backpack-guy.png"
    />
    <ErrorPagesButton />
  </div>
</template>

<script>
import Error from '@/components/Error.vue'
import store from '@/store'
import { DESTROY_TOKEN, TOGGLE_OVERLAY } from '@/store/actions.type'
import ErrorPagesButton from '@/components/buttons/ErrorPagesButton'
import { gtmErrorTags } from '@/helpers/gtmHelper'
import { mapGetters } from 'vuex'

export default {
  name: 'CantProcessRequest',
  components: {
    ErrorPagesButton,
    Error,
  },
  data: () => {
    return {
      htmlMessage: null,
    }
  },
  mounted() {
    this.htmlMessage = this.$route.params.htmlMessage
    gtmErrorTags(
      window.dataLayer,
      'trackEvent',
      'movistar money',
      'error',
      'rechazo de préstamo',
      this.CryptoJS.SHA1(this.currentApplication.mobile).toString()
    )
    store.dispatch(DESTROY_TOKEN)
    store.dispatch(TOGGLE_OVERLAY, false)
  },
  computed: {
    ...mapGetters({
      currentApplication: 'currentApplication',
    }),
  },
}
</script>
