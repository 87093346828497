<template>
  <div class="container-evidente-validar">
    <div class="container-input-radio">
      <h1 class="title-validate">Vamos a validar tu identidad</h1>
      <div v-for="item in arrayQuestion" :key="item.orden" class="radio-buttons-container">
        <RadioButtonGroup :title="item.texto" :name="item.orden" :options="item.Respuesta" @change="updateOption" />
      </div>
      <div class="box-btn">
        <ButtonContinue text="Confirmar" @click="goNextPage" :disable="this.disableButton" />
      </div>
    </div>
    <div class="container-stepper">
      <StepperVertical :step="step" />
    </div>
  </div>
</template>

<script>
import StepperVertical from '../../../components/StepperVertical.vue'
import ButtonContinue from '../../../components/buttons/ButtonContinue.vue'
import { mapGetters } from 'vuex'
import RadioButtonGroup from '../../../components/buttons/RadioButtonGroup.vue'
import { ACTION_WORKFLOW_STATUS_ASSISTED } from '../../../store/actions.type'
import store from '../../../store'
import router from '../../../router'
import { gtmDataLayerTrackEvent } from '../../../helpers/gtmHelper'

export default {
  components: { StepperVertical, ButtonContinue, RadioButtonGroup },
  data() {
    return {
      arrayQuestion: [],
      disableButton: true,
      step: 2,
      respuesta: {},
      date: '',
      controleRespostas: [],
      form: {
        action: 'verifyAnswers',
        respuestas: {
          idCuestionario: '',
          regCuestionario: '',
          respuesta: '',
        },
      },
    }
  },
  mounted() {
    this.arrayQuestion = this.responseChangeStatusAssisted.response.Pregunta
    gtmDataLayerTrackEvent(
      window.dataLayer,
      'trackEvent',
      'movistar money',
      'click::preguntas de seguridad::paso 3',
      'validar respuestas de las preguntas de seguridad',
      this.CryptoJS.SHA1(this.currentApplication.mobile).toString()
    )
  },
  watch: {
    responseChangeStatusAssisted(newValue) {
      if (newValue) {
        return newValue
      }
    },
  },
  methods: {
    updateOption(param) {
      const order = param.order
      const newElement = {
        [order]: {
          idPregunta: order,
          idRespuesta: param.answer,
        },
      }
      this.respuesta = Object.assign(this.respuesta, newElement)
      this.controleRespostas[param.order] = true
      if (Object.keys(this.controleRespostas).length >= this.responseChangeStatusAssisted.response.Pregunta.length)
        this.disableButton = false
    },
    goNextPage() {
      this.disableButton = true
      this.handleForm()
      store.dispatch(ACTION_WORKFLOW_STATUS_ASSISTED, this.form).then((response) => {
        if (response.situation == 'reproved' || response.situation == 'infolaftFailure') {
          router.push({ name: 'CantProcessRequest' })
        } else {
          router.push({ name: 'ContratoAssistido' })
        }
      })
    },
    handleForm() {
      this.form.respuestas.respuesta = Object.values(this.respuesta)
      this.form.respuestas.idCuestionario = this.responseChangeStatusAssisted.response.id
      this.form.respuestas.regCuestionario = this.responseChangeStatusAssisted.response.registro
    },
  },
  computed: {
    ...mapGetters({
      responseChangeStatusAssisted: 'responseChangeStatusAssisted',
      offerEnabledOptions: 'offerEnabledOptions',
      selected: 'offerSelectedNumber',
      maisInformacoes: 'maisInformacoes',
      currentApplication: 'currentApplication',
      offerAssisted: 'offerAssisted',
    }),
  },
}
</script>

<style scoped>
.container-stepper {
  position: absolute;
  right: -10rem;
  top: 200px;
  margin-left: 100px;
}

.box-btn {
  margin-top: 3rem;
  padding-bottom: 1rem;
}

.title-validate {
  font-size: 32px;
  margin-bottom: 2rem;
}

.container-input-radio {
  display: flex;
  flex-direction: column;

  width: 509px;
  height: 582px;
  margin: 0 1.4px 20px 0;
}
.container-stepper-horizontal {
  position: absolute;
  right: 0px;
  top: 400px;
  margin-left: 100px;
}

.section-validate {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}

.title-input-box {
  text-align: start;
  height: 40px;
  font-size: 16px;
}

.container-evidente-validar {
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  width: 800px;
}

label {
  margin-bottom: 0px;
}

input[type='radio'] {
  width: 1.15em;
  height: 1.15em;
}

@media (max-width: 1590px) {
  .container-stepper {
    position: absolute;
    right: -11rem;
    top: 200px;
    margin-left: 100px;
  }
}
</style>
