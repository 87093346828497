<template>
  <div v-if="accountInstallments.ops">
    <div v-for="(value, index) in accountInstallments.ops[0].data.accountInstallments" :key="index">
      <CreditsPaymentInstallmentsCard :installmentValue="value" />
    </div>
  </div>
</template>

<script>
import CreditsPaymentInstallmentsCard from './CreditsPaymentInstallmentsCard'
import { mapGetters } from 'vuex'

export default {
  name: 'CreditsPaymentInstallments',
  components: { CreditsPaymentInstallmentsCard },
  computed: {
    ...mapGetters({
      accountInstallments: 'selfManageApplicationPaymentPlan',
    }),
  },
}
</script>
