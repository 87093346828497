<template>
  <div class="input-group">
    <div :class="['input', { invalid }]">
      <div class="input-prepend">
        <slot name="prepend" />
      </div>
      <input v-if="mask" :placeholder="placeholder" v-mask="mask" :value="value" v-on="listeners" />
      <input v-else :placeholder="placeholder" :value="value" v-on="listeners" />
    </div>
    <slot name="input-help">
      <div class="input-help" :class="{ ['align-right']: inputHelpAlign === 'right' }">
        <small class="invalid" v-for="(message, index) in computedMessages" :key="index" v-text="message" />
      </div>
    </slot>
  </div>
</template>
<script>
export default {
  props: {
    value: null,
    placeholder: {
      type: String,
      required: false,
    },
    mask: {
      required: false,
      default: false,
    },
    invalid: {
      type: Boolean,
      required: false,
      default: false,
    },
    messages: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    inputHelpAlign: {
      type: String,
      default: 'left',
    },
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: (event) => this.$emit('input', event.target.value),
      }
    },
    computedMessages() {
      const computedMessages = []
      for (const key in this.messages) {
        if (this.messages[key] === true) {
          computedMessages.push(key)
        }
      }
      return computedMessages
    },
  },
}
</script>
<style lang="scss" scoped>
.input-group {
  border-bottom: none;
  .invalid {
    color: #ff0000;
  }
  .input {
    padding: 0.5rem 0;
    width: 100%;
    border-bottom: 1px solid #b6b7b7;
    display: flex;
    &.invalid {
      border-bottom: 1px solid #ff0000;
    }
    &:focus-within {
      border-bottom: 1px solid #019df4;
    }
    .input-prepend {
      width: 30px;
      height: 30px;
    }

    input {
      border: none;
      background-color: rgba(0, 0, 0, 0);
      margin-left: 1rem;
      flex-grow: 1;
      &::placeholder {
        color: #000000;
      }
      &:focus {
        outline: none;
      }
    }
  }

  .input-help {
    width: 100%;
    text-align: left;
    &.align-right {
      text-align: right;
    }
    display: flex;
    flex-direction: column;
  }
}
</style>
