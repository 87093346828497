<template>
  <MVModal id="modal-enviar-email-outro-endereco">
    <template #header>
      <h2 class="titulo">Ingresa el correo al que quieres enviar el link de pago</h2>
    </template>
    <form class="card-form" @submit.prevent="validarForm">
      <div class="email-inputs">
        <InputGroup
          v-model.trim="form.email"
          :invalid="v$.email.$error"
          :messages="{
            'El correo es requerido': v$.email.$error && !v$.email.required,
            'El correo no es válido': v$.email.$error && !v$.email.email,
          }"
        >
          <template #prepend>
            <b-img src="@/assets/icons/mail-blue.svg" />
          </template>
        </InputGroup>

        <InputGroup
          v-model.trim="form.emailConfirmacao"
          :invalid="v$.emailConfirmacao.$error"
          :messages="{
            'El correo es requerido': v$.emailConfirmacao.$error && v$.emailConfirmacao.required.$invalid,
            'Los correos no coinciden': v$.emailConfirmacao.$error && v$.emailConfirmacao.email.$invalid,
          }"
        >
          <template #prepend>
            <b-img src="@/assets/icons/mail-blue.svg" />
          </template>
        </InputGroup>
        <small v-if="v$.emailConfirmacao"></small>
      </div>
      <div class="termos-condicoes">
        <InputCheckbox v-model="form.aceitoTermosCondicoes" />
        <p>
          Acepto los Términos y Condiciones y la Política de Datos Personales, declaro que los he leído en su totalidad
          y que estoy de acuerdo con su contenido.
        </p>
      </div>
      <div class="alerta">
        <p>
          Recuerda que el correo es un dato muy importante para estar en contacto con el cliente, asegúrate de que sea
          correcto
        </p>
      </div>
    </form>

    <template #footer>
      <MVButton
        label="Continuar"
        variant="movistar-green"
        size="sm"
        :disabled="!form.aceitoTermosCondicoes || v$.$error"
        @click="enviarEmailPagamento($bvModal)"
      />
    </template>
  </MVModal>
</template>
<script setup>
import { reactive, computed } from 'vue'
import { useRouter } from 'vue-router/composables'

import { TOGGLE_OVERLAY } from '@/store/actions.type'
import apiFianca from '@/services/fianca.service'
import { useVuelidate } from '@vuelidate/core'
import { required, maxLength, email, sameAs } from '@vuelidate/validators'

import InputGroup from '@/components/inputs/InputGroup.vue'
import InputCheckbox from '@/components/inputs/InputCheckbox.vue'
import MVModal from '@/components/movistar/MVModal.vue'
import MVButton from '@/components/movistar/MVButton.vue'
import store from '@/store'

const emailRef = computed(() => form.email)
const formRules = {
  email: { required, email, max: maxLength(100) },
  emailConfirmacao: { required, email, max: maxLength(100), sameAsEmail: sameAs(emailRef) },
  aceitoTermosCondicoes: { required },
}
const form = reactive({
  email: '',
  emailConfirmacao: '',
  aceitoTermosCondicoes: false,
})

const v$ = useVuelidate(formRules, form)

const applicationId = computed(() => store.getters.currentApplication.applicationId)

const router = useRouter()

function enviarEmailPagamento(modalContext) {
  v$.value.$touch()
  if (v$.value.$invalid) return
  modalContext.hide('modal-enviar-email-outro-endereco')
  store.dispatch(TOGGLE_OVERLAY, true)
  apiFianca
    .postReenviarEmailPagamento(applicationId.value, form.email)
    .then(() => {
      modalContext.show('modal-confirmar-recebimento-email-fianca')
    })
    .catch((error) => {
      router.push({ name: 'ManyAttemptsResendLink' })
    })
    .finally(() => {
      store.dispatch(TOGGLE_OVERLAY, false)
    })
}

// export default {
//   components: {
//     InputGroup,
//     InputCheckbox,
//     ButtonContinue,
//     IconeFechar,
//     IconeEmail,
//   },
//   props: {
//
//   },
//   data() {
//     return {
//       form: {
//         email: '',
//         emailConfirmacao: '',
//         aceitoTermosCondicoes: false,
//       },
//     }
//   },
//   validations: {
//     form: {
//       email: {
//         required,
//         email,
//       },
//       emailConfirmacao: {
//         required,
//         sameAsEmail: sameAs('email'),
//       },
//       aceitoTermosCondicoes: {
//         checked: (value) => value === true,
//       },
//     },
//   },
//   methods: {
//     validarForm() {
//     },
//     fecharModal() {
//       this.$bvModal.hide('modal-enviar-email-outro-endereco')
//       this.$emit('fechar')
//     },
//   },
// }
</script>
<style lang="scss" scoped>
::v-deep .modal-footer {
  justify-content: center !important;
}
::v-deep .modal-header {
  justify-content: flex-end !important;
  padding-bottom: 0px;
}
::v-deep .modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.card-form {
  padding: 0 1rem;
  .email-inputs {
    .input-group {
      margin-bottom: 1.5rem;
    }
    label {
      text-align: center;
      width: 100%;
      font-weight: bold;
    }
  }
  .termos-condicoes {
    display: flex;
    margin-bottom: 1.5rem;
    p {
      font-size: 14px;
      margin: 0;
      padding-left: 1rem;
      text-align: left;
      text-decoration: underline black;
    }
  }
  .alerta {
    padding: 0 1rem;
    margin-bottom: 2rem;
    p {
      border: 1px solid #019df4;
      border-radius: 10px;
      padding: 0.75rem;
      text-align: center;
      font-size: 16px;
    }
  }
  .btn-continue {
    width: 60%;
    margin: 0 auto 1.75rem auto;
  }
}

.titulo {
  margin-top: 1rem;
  width: 100%;
  padding: 0 2rem;
  font-size: 1.5rem;
}
.modal-footer .btn-sm {
  padding: 12px 4rem;
}
</style>
