<template>
  <div class="container-sm-profile">
    <p class="sm-title mb-4">Mis Pagos</p>
    <ApplyPaymentHeaderCard :propostaMambu="this.propostaMambu" :planoPagamento="this.planoPagamento" />
  </div>
</template>

<script>
import ApplyPaymentHeaderCard from './ApplyPaymentHeaderCard'
import { mapGetters } from 'vuex'

export default {
  name: 'ApplyPaymentHeader',
  components: { ApplyPaymentHeaderCard },
  computed: {
    ...mapGetters({
      propostaMambu: 'selfManageApplicationMambu',
      planoPagamento: 'selfManageApplicationPaymentPlan',
    }),
  },
}
</script>
