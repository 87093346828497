import { render, staticRenderFns } from "./PreValidacao.vue?vue&type=template&id=394c9876&scoped=true&"
import script from "./PreValidacao.vue?vue&type=script&setup=true&lang=js&"
export * from "./PreValidacao.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./PreValidacao.vue?vue&type=style&index=0&id=394c9876&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "394c9876",
  null
  
)

export default component.exports