<template>
  <b-form @submit.prevent="enviarFormulario()" class="radio-custom my-5">
    <p class="text-left">Selecciona el medio de pago</p>
    <b-row>
      <b-col class="text-left">
        <fieldset>
          <input type="radio" id="tarjeta-pse" value="Tarjeta-PSE" v-model="selected" />
          <label for="tarjeta-pse" class="radio-label">Tarjeta / PSE</label>
        </fieldset>
      </b-col>
      <b-col class="text-left">
        <fieldset>
          <input type="radio" id="tarjeta-efectivo" value="Efectivo" v-model="selected" />
          <label for="tarjeta-efectivo" class="radio-label">Efectivo (puntos fisicos)</label>
        </fieldset>
      </b-col>
    </b-row>
    <div class="d-flex justify-content-center mt-5">
      <ButtonContinueBlue text="Pagar" class="w-50" :desabilitar="desabilitar" />
    </div>
    <span class="texto-error">
      {{ errorEpaycoResponse?.data?.message }}
    </span>
  </b-form>
</template>
<script>
import ButtonContinueBlue from '@/components/buttons/ButtonContinueBlue.vue'
import { mapGetters } from 'vuex'
export default {
  components: { ButtonContinueBlue },
  data() {
    return {
      selected: 'Tarjeta-PSE',
      desabilitar: false,
    }
  },
  computed: {
    ...mapGetters({
      advancedPayment: 'advancedPayment',
      errorEpaycoResponse: 'errorEpaycoResponse',
    }),
  },
  methods: {
    enviarFormulario() {
      this.$emit('pagar', this.selected)
      this.desabilitarBotao()
    },
    desabilitarBotao() {
      this.desabilitar = true
      setTimeout(() => {
        this.desabilitar = false
      }, 60000)
    },
  },
}
</script>
<style lang="scss">
.total-pagar {
  font-size: 18px;
  color: #3b3a3a;
  .value {
    font-size: 20px;
    color: #0b2739;
    margin-left: 0.5rem;
  }
}
.texto-error {
  color: #ff0000;
}
</style>
