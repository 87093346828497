<template>
  <div class="container-fluid mb-5">
    <Error :title="postApplicationError" image="solicitud-en-vuelo.png" />
    <ErrorPagesButton />
  </div>
</template>

<script>
import Error from '../../components/Error'
import { mapGetters } from 'vuex'
import store from '../../store'
import { DESTROY_TOKEN, TOGGLE_OVERLAY } from '../../store/actions.type'
import ErrorPagesButton from '../../components/buttons/ErrorPagesButton'
import { gtmErrorTags } from '../../helpers/gtmHelper'

export default {
  name: 'LoanInProgress',
  components: {
    ErrorPagesButton,
    Error,
  },
  mounted() {
    gtmErrorTags(
      window.dataLayer,
      'trackEvent',
      'movistar money',
      'error',
      'solicitud en proceso ya existente',
      this.CryptoJS.SHA1(this.currentApplication.mobile).toString()
    )
    store.dispatch(DESTROY_TOKEN)
    store.dispatch(TOGGLE_OVERLAY, false)
  },
  computed: {
    ...mapGetters({
      currentApplication: 'currentApplication',
      postApplicationError: 'postApplicationError',
    }),
  },
}
</script>
