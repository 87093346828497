/* eslint-disable */

export default () => ({
    execute() {
        var interval;

        var getButton = function () {
            interval = setInterval(function () {
                var helpButtonEnabled = document.getElementsByClassName("helpButtonEnabled");
                if (!helpButtonEnabled[0]) {
                }
                else {
                    clearInterval(interval);
                    helpButtonEnabled[0].addEventListener("click", function () {
                        var interval2;
                        interval2 = setInterval(function () {
                            var chasitorText = document.getElementsByClassName("chasitorText");
                            if (!chasitorText[0]) {
                            }
                            else {
                                clearInterval(interval2);

                                var botaoEnviarCB = document.createElement("button");
                                botaoEnviarCB.setAttribute("class", "botaoEnviarCB");

                                const keyboardEvent = new KeyboardEvent('keydown', {
                                    code: 'Enter',
                                    key: 'Enter',
                                    charCode: 13,
                                    keyCode: 13,
                                    view: window,
                                    bubbles: true
                                });

                                var keyboardEventFunction = function () {
                                    chasitorText[0].dispatchEvent(keyboardEvent)
                                };

                                botaoEnviarCB.addEventListener("click", keyboardEventFunction);

                                var lightningIcon = document.createElement("lightning-icon");
                                lightningIcon.setAttribute("class", "slds-icon-utility-close slds-icon_container");
                                botaoEnviarCB.appendChild(lightningIcon);

                                var lightningPrimitiveIcon = document.createElement("lightning-primitive-icon");
                                lightningIcon.appendChild(lightningPrimitiveIcon);

                                var img = document.createElement("img");
                                img.setAttribute("src", "https://movistarmoney.secure.force.com/leads/resource/1634323226000/seta");
                                lightningPrimitiveIcon.appendChild(img);


                                var chasitorControls = document.getElementsByClassName("chasitorControls");
                                chasitorControls[0].appendChild(botaoEnviarCB);
                            }
                        }, 500);

                    });
                }
            }, 500);
        }
        getButton();
    }
})

