<template>
  <div class="container-fluid mb-5">
    <b-container>
      <StripesTopImage />

      <MovistarMoneyLogo />

      <Stepper :step="step" />

      <b-row class="text-center" align-h="center">
        <b-col cols="12" md="6" class="no-padding">
          <b-row>
            <b-col>
              <p class="title-onboard mb-3">¡Queremos confirmar tus datos!</p>
              <div v-if="openBox" align="center">
                <CardMensagem
                  texto="¡No hay prisa! Asegúrate de escribir bien tus datos y en los campos correctos."
                  v-bind:abrirApos="2"
                  v-bind:fecharApos="8"
                />
              </div>
              <p class="subtitle-onboard mb-4 mx-4">
                Inicia tu solicitud de crédito validando tus datos registrados en Movistar
              </p>
            </b-col>
          </b-row>

          <div class="container-form">
            <b-row>
              <b-col>
                <b-form @submit.prevent="submit">
                  <input type="hidden" id="user_prefs" name="jsc" />
                  <input type="hidden" id="user_prefs2" name="hdim" />
                  <div class="mb-3">
                    <b-input-group :class="{ formInputError: $v.form.identityCardNumber.$error }">
                      <b-input-group-prepend>
                        <b-img src="../assets/icons/id-card.svg" alt="Document icon" />
                      </b-input-group-prepend>
                      <input
                        type="tel"
                        class="v-mask pb-2 form-input-item"
                        placeholder="Documento de identidad"
                        v-mask="['# ## ###', '# ### ###', '# ### ### ###', '## ### ####']"
                        v-model="form.identityCardNumber"
                        @click="openCardMensagem"
                        :disabled="botaoDesativado"
                      />
                    </b-input-group>
                    <p v-if="$v.form.identityCardNumber.$error" class="form-input-error">
                      Este número de cédula debe tener entre 6 a 14 dígitos
                    </p>
                    <p v-if="formErrorArray && formErrorArray.includes('documentMovistar')" class="form-input-error">
                      El documento no es el titular de la línea.
                    </p>
                    <p
                      v-if="formErrorArray && formErrorArray.includes('documentAcessoFinter')"
                      class="form-input-error"
                    >
                      Este número de cédula no es válido
                    </p>
                  </div>
                  <div class="mb-3">
                    <b-input-group :class="{ formInputError: $v.form.email.$error }">
                      <b-input-group-prepend>
                        <b-img src="../assets/icons/mail.svg" alt="Mail icon" />
                      </b-input-group-prepend>

                      <input
                        type="email"
                        class="v-mask pb-2 form-input-item"
                        placeholder="Ingresa tu correo electrónico"
                        maxlength="100"
                        v-model="form.email"
                      />
                    </b-input-group>
                    <p v-if="$v.form.email.$error" class="form-input-error">
                      No encontramos este correo, verifica que sea correcto
                    </p>
                  </div>
                  <div class="mb-3">
                    <b-input-group :class="{ formInputError: $v.form.mobile.$error }">
                      <b-input-group-prepend>
                        <b-img src="../assets/icons/phone.svg" alt="Phone icon" />
                      </b-input-group-prepend>
                      <input
                        type="tel"
                        class="v-mask pb-2 form-input-item"
                        placeholder="Número de celular"
                        v-mask="'### ### ## ##'"
                        v-model="form.mobile"
                      />
                    </b-input-group>
                    <p
                      v-if="$v.form.mobile.$error || (formErrorArray && formErrorArray.includes('mobile'))"
                      class="form-input-error"
                    >
                      Este no es un número Movistar válido
                    </p>

                    <p
                      v-if="validationPhone === 'true' && form.mobile.length > 0 && form.mobile[0] !== '3'"
                      class="form-input-error"
                    >
                      El número debe comenzar con 3
                    </p>

                    <p
                      v-if="
                        validationPhone === 'false' &&
                        form.mobile.length > 0 &&
                        form.mobile[0] !== '2' &&
                        form.mobile[0] !== '3'
                      "
                      class="form-input-error"
                    >
                      El número debe comenzar con 2 o 3
                    </p>
                  </div>
                  <ModalTermsAndConditions :terms="terms" title="Términos y Condiciones Movistar Money" />
                  <div class="mt-5">
                    <FormCheckbox
                      id="termsAndConditions"
                      label="Acepto los términos y condiciones, la política de cobranzas y política de datos personales, declaro que los he leído en su totalidad y estoy de acuerdo con su contenido."
                      @change="acceptTermsConditions"
                      v-b-modal.modal-terms-and-conditions
                    />
                    <div v-show="form.acceptTermsConditions">
                      <PreferenciaContato
                        textoInfo="Selecciona tus preferencias de contacto"
                        @updateContactPreferences="atualizarPreferencia"
                      />
                    </div>

                    <FormCheckbox
                      id="dataPolicy"
                      label="Autorizo recibir información comercial conforme a la Política de Datos Personales."
                      @change="acceptDataTreatment"
                    />
                  </div>

                  <div class="container-sticky">
                    <div class="pt-2 pb-3">
                      <ButtonContinue text="Continuar" :disable="desabilitarBotao()" :origin="this.origin" />
                    </div>
                  </div>
                </b-form>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

import { CHANGE_CHANNEL_ORIGIN } from '@/store/mutations.type'
import {
  DESTROY_TOKEN,
  FETCH_MY_IP,
  FETCH_TERMS_CONDITIONS,
  FETCH_URL_CALLBACK,
  SEND_FORM,
  BUSCAR_MINHA_LOCALIZACAO,
  BUSCAR_PARAMETROS_CANAL,
} from '@/store/actions.type'

import { validationMixin } from 'vuelidate'
import { email, maxLength, minLength, required } from 'vuelidate/lib/validators'
import experianCollector from '@/plugins/experian'
import Cookies from '@/plugins/vue-cookies'
import { emailFormatter } from '@/helpers/common'
import { gtmDataLayerCheckout, gtmDataLayerTrackEvent } from '@/helpers/gtmHelper'
import { Persist } from '@/helpers/persist.service'

import StripesTopImage from '@/components/StripesTopImage.vue'
import MovistarMoneyLogo from '@/components/MovistarMoneyLogo.vue'
import Stepper from '@/components/Stepper.vue'
import CardMensagem from '@/components/CardMensagem.vue'
import ButtonContinue from '@/components/buttons/ButtonContinue.vue'
import ModalTermsAndConditions from '@/components/modal/ModalTermsAndConditions.vue'
import FormCheckbox from '@/components/buttons/FormCheckbox.vue'
import PreferenciaContato from '@/components/PreferenciaContato.vue'

export default {
  name: 'Form',
  components: {
    FormCheckbox,
    ModalTermsAndConditions,
    ButtonContinue,
    Stepper,
    MovistarMoneyLogo,
    StripesTopImage,
    CardMensagem,
    PreferenciaContato,
  },
  mixins: [validationMixin],
  data() {
    return {
      origin: 'Finter',
      step: 1,
      openBox: false,
      valoresSimulador: {},
      botaoDesativado: false,
      form: {
        id_access: '',
        mobile: '',
        identityCardNumber: '',
        email: '',
        acceptTermsConditions: false,
        acceptDataTreatment: false,
        jsc: '',
        hdim: '',
        client: '',
        intendedInstallmentsNumber: null,
        intendedValue: null,
        geo_location_longitude: '',
        geo_location_latitude: '',
        contactPreferences: {
          billingSms: true,
          marketingSms: false,
          billingWhatsapp: true,
          marketingWhatsapp: false,
          billingEmail: true,
          marketingEmail: false,
          billingCall: true,
          marketingCall: false,
        },
      },
      validationPhone: process.env.VUE_APP_VALIDATION_PHONE,
      geoLocalizacaoAtiva: null,
    }
  },
  validations: {
    form: {
      mobile: { required, min: minLength(13) },
      identityCardNumber: { required, min: minLength(8), max: maxLength(19) },
      email: { required, email: (val) => email(emailFormatter(val)), max: maxLength(100) },
      acceptTermsConditions: { required },
    },
  },
  async mounted() {
    await this.getInfoStorage()
    this.geoLocalizacaoAtiva = await this.$store.dispatch(BUSCAR_PARAMETROS_CANAL, {
      nomeParametro: 'activeCepsRules',
      canal: this.origin,
    })
    Persist.clearContractTerms()
    this.$store.dispatch(DESTROY_TOKEN)
    await this.$store.dispatch(BUSCAR_MINHA_LOCALIZACAO, { canal: this.origin })
    experianCollector.initiate()
    this.dispatchEvents()
    this.$store.dispatch(FETCH_MY_IP)
    this.getGeoLocalizacao()
    this[CHANGE_CHANNEL_ORIGIN](this.origin)
    if (this.origin === 'Finter') {
      localStorage.clear()
    }
    setTimeout(() => {
      this.experianCollector()
    }, 500)
  },
  watch: {
    phoneDetails: function () {
      if (this.origin === 'Finter') {
        gtmDataLayerCheckout(
          window.dataLayer,
          'checkOutView',
          this.phoneDetails.deviceModel,
          this.phoneDetails.sku,
          this.phoneDetails.price,
          this.phoneDetails.deviceModel,
          'movistar money',
          this.phoneDetails.deviceColor,
          this.step
        )
      }
    },
  },
  methods: {
    desabilitarBotao() {
      return !this.form.acceptTermsConditions || !this.form.email || this.validationError
    },
    ...mapMutations([CHANGE_CHANNEL_ORIGIN]),
    openCardMensagem() {
      if (!this.openBox) {
        this.openBox = true
      }
    },
    getGeoLocalizacao() {
      if (this.geoLocalizacaoAtiva == 'true') {
        if (!('geolocation' in navigator)) {
          return
        }
        var self = this
        navigator.geolocation.getCurrentPosition((pos) => {
          self.form.geo_location_latitude = pos.coords.latitude
          self.form.geo_location_longitude = pos.coords.longitude
        })
      }
    },
    getGeoLocalizacaoIP() {
      if (this.localizacao) {
        this.form.geo_location_latitude = this.localizacao.lat
        this.form.geo_location_longitude = this.localizacao.lng
      }
    },
    getInfoStorage() {
      this.valoresSimulador = JSON.parse(localStorage.getItem('oferta_simulador'))

      if (!this.valoresSimulador)
        if (Cookies.isKey('oferta_simulador')) this.valoresSimulador = Cookies.get('oferta_simulador')

      if (this.valoresSimulador) {
        this.origin = 'Libre'
      }
    },
    submit() {
      if (this.form.geo_location_latitude === '' && this.geoLocalizacaoAtiva == 'true') this.getGeoLocalizacaoIP()
      this.experianCollector()
      this.$v.form.$touch()
      if (this.$v.form.$error) return
      if (!this.form.acceptTermsConditions) return
      this.$store.dispatch(DESTROY_TOKEN)
      const newForm = this.newForm()
      this.$store.dispatch(SEND_FORM, newForm)
      gtmDataLayerTrackEvent(
        window.dataLayer,
        'trackEvent',
        'movistar money',
        'click::financiamiento::paso 1',
        'confirmar datos',
        this.CryptoJS.SHA1(this.form.mobile.replace(/ /g, '')).toString()
      )
    },
    newForm() {
      this.preencherMarketing()
      let newForm = Object.assign({}, this.form)
      newForm.mobile = newForm.mobile.replace(/ /g, '')
      newForm.identityCardNumber = newForm.identityCardNumber.replace(/ /g, '')
      newForm.client = this.CryptoJS.AES.encrypt(this.ip, process.env.VUE_APP_SECRET_SITE).toString()
      newForm.origin = this.origin
      if (this.origin === 'Libre') {
        newForm.intendedInstallmentsNumber = this.valoresSimulador.installment
        newForm.intendedValue = this.valoresSimulador.intendedValue
        newForm.id_access = this.valoresSimulador.accessId
      }
      return newForm
    },
    acceptTermsConditions(newValue) {
      this.form.acceptTermsConditions = newValue
      this.experianCollector()
    },
    acceptDataTreatment(newValue) {
      this.form.acceptDataTreatment = newValue
    },
    experianCollector() {
      this.form.jsc = experianCollector.collectJsc()
      this.form.hdim = experianCollector.collectHdim()
      if (!this.form.hdim) {
        experianCollector.initiate()
      }
    },
    dispatchEvents() {
      if (this.$route.query.data) {
        this.origin = 'Finter'
        let queryData = decodeURIComponent(this.$route.query.data)
        let decryptedObject = this.CryptoJS.AES.decrypt(queryData, process.env.VUE_APP_SECRET_SITE).toString(
          this.CryptoJS.enc.Utf8
        )
        decryptedObject = JSON.parse(decryptedObject)
        this.form.id_access = decryptedObject.id
        if ('document' in decryptedObject) {
          this.form.mobile = decryptedObject.mobile
          this.form.identityCardNumber = decryptedObject.document
          this.botaoDesativado = true
        }
        this.$store.dispatch(FETCH_URL_CALLBACK, decryptedObject.urlRetorno)
      }
      this.$store.dispatch(FETCH_TERMS_CONDITIONS, this.origin)
      this.$store.dispatch(FETCH_MY_IP)
    },
    atualizarPreferencia(value) {
      this.form.contactPreferences = value
    },
    preencherMarketing() {
      this.form.contactPreferences.marketingCall = this.form.acceptDataTreatment
      this.form.contactPreferences.marketingEmail = this.form.acceptDataTreatment
      this.form.contactPreferences.marketingSms = this.form.acceptDataTreatment
      this.form.contactPreferences.marketingWhatsapp = this.form.acceptDataTreatment
    },
  },
  computed: {
    ...mapGetters({
      phoneDetails: 'phoneDetails',
      terms: 'termsAndConditions',
      ip: 'ip',
      formErrorArray: 'formErrorArray',
      currentApplication: 'currentApplication',
      localizacao: 'localizacao',
    }),
    validationError() {
      let billingContact = Object.entries(this.form.contactPreferences).filter(([key, value]) => {
        if (key.startsWith('billing') && value) {
          return value
        }
      })
      if (billingContact.length === 0) return true

      return false
    },
  },
}
</script>

<style scoped>
.container-form {
  margin-top: 50px;
}
</style>
