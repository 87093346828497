<template>
  <b-modal :id="id" header-border-variant="white" footer-border-variant="white" hide-footer>
    <template #modal-header>
      <b-button variant="blank" @click="fecharModal">
        <IconeFechar />
      </b-button>
    </template>
    <p class="text-center title-onboard">Ingresa el correo al que quieres enviar el link de pago</p>
    <form class="card-form" @submit.prevent="validarForm">
      <div class="email-inputs">
        <InputGroup
          v-model.trim="form.email"
          :invalid="$v.form.email.$error"
          :messages="{
            'El correo es requerido': $v.form.email.$error && !$v.form.email.required,
            'El correo no es válido': $v.form.email.$error && !$v.form.email.email,
          }"
        >
          <template #prepend>
            <IconeEmail />
          </template>
        </InputGroup>

        <label>Confirma el correo</label>
        <InputGroup
          v-model.trim="form.emailConfirmacao"
          :invalid="$v.form.emailConfirmacao.$error"
          :messages="{
            'El correo es requerido': $v.form.emailConfirmacao.$error && !$v.form.emailConfirmacao.required,
            'Los correos no coinciden': $v.form.emailConfirmacao.$error && !$v.form.emailConfirmacao.email,
          }"
        >
          <template #prepend>
            <IconeEmail />
          </template>
        </InputGroup>
        <small v-if="$v.form.emailConfirmacao"></small>
      </div>
      <div class="termos-condicoes">
        <InputCheckbox v-model="form.aceitoTermosCondicoes" />
        <p>
          Acepto los Términos y Condiciones y la Política de Datos Personales, declaro que los he leído en su totalidad
          y que estoy de acuredo con su contenido.
        </p>
      </div>
      <div class="alerta">
        <p>
          Recuerda que el correo es un dato muy importante para estar en contacto con el cliente, asegúrate de que sea
          correcto
        </p>
      </div>
      <ButtonContinue text="Continuar" :disable="$v.form.aceitoTermosCondicoes.$invalid" />
    </form>
  </b-modal>
</template>
<script>
import { TOGGLE_OVERLAY } from '@/store/actions.type'
import { required, email, sameAs } from 'vuelidate/lib/validators'
import api from '@/plugins/api'

import InputGroup from '@/components/inputs/InputGroup.vue'
import InputCheckbox from '@/components/inputs/InputCheckbox.vue'
import ButtonContinue from '@/components/buttons/ButtonContinue.vue'
import IconeFechar from '@/components/icones/IconeFechar.vue'
import IconeEmail from '@/components/icones/IconeEmail.vue'

export default {
  components: {
    InputGroup,
    InputCheckbox,
    ButtonContinue,
    IconeFechar,
    IconeEmail,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    applicationId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      form: {
        email: '',
        emailConfirmacao: '',
        aceitoTermosCondicoes: false,
      },
    }
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
      emailConfirmacao: {
        required,
        sameAsEmail: sameAs('email'),
      },
      aceitoTermosCondicoes: {
        checked: (value) => value === true,
      },
    },
  },
  methods: {
    validarForm() {
      this.$v.$touch()
      if (this.$v.form.$invalid) return
      this.fecharModal()
      this.$store.dispatch(TOGGLE_OVERLAY, true)
      api
        .postReenviarEmailAntecipo(this.applicationId, this.form.email)
        .then(() => {
          this.$emit('continuar')
        })
        .catch((error) => {
          this.$bvToast.toast(error.response.data.message, {
            title: '¡Ocurrio un error!',
            autoHideDelay: 5000,
            variant: 'danger',
          })
        })
        .finally(() => {
          this.$store.dispatch(TOGGLE_OVERLAY, false)
        })
    },
    fecharModal() {
      this.$bvModal.hide(this.id)
      this.$emit('fechar')
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .modal-footer {
  justify-content: center !important;
}
::v-deep .modal-header {
  justify-content: flex-end !important;
  padding-bottom: 0px;
}
::v-deep .modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.card-form {
  max-width: 360px;
  .email-inputs {
    padding: 0 1rem;
    .input-group {
      margin-bottom: 1.5rem;
    }
    label {
      text-align: center;
      width: 100%;
      font-weight: bold;
    }
  }
  .termos-condicoes {
    display: flex;
    margin-bottom: 1.5rem;
    p {
      padding-left: 1rem;
      text-decoration: underline black;
    }
  }
  .alerta {
    border: 1px solid #019df4;
    border-radius: 10px;
    padding: 0.75rem 2.25rem;
    margin-bottom: 2rem;
    p {
      text-align: center;
      margin: 0;
    }
  }
  .btn-continue {
    width: 60%;
    margin: 0 auto 1.75rem auto;
  }
}
</style>
