<template>
  <b-modal :id="id" header-border-variant="white" footer-border-variant="white">
    <template #modal-header>
      <b-button variant="blank" @click="fecharModal">
        <IconeFechar />
      </b-button>
    </template>
    <div class="mx-2">
      <div class="py-1">
        <p class="text-center title-onboard">¿Quieres volver a enviar el link para la validación de identidad?</p>
        <p class="text-email">{{ email }}</p>
        <p v-if="limite" class="error">Has superado el número de envíos permitidos</p>
      </div>
    </div>
    <template #modal-footer>
      <b-row cols="1" cols-md="2">
        <b-col>
          <ButtonContinue :disable="limite" text="Volver a enviar" class="btn-continue" @click="enviarEmailVeridas()" />
        </b-col>
        <b-col>
          <ButtonContinue
            :disable="limite"
            text="Enviar a otro correo"
            class="btn-continue"
            inverted
            @click="$emit('novo_email')"
          />
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>
<script>
import { TOGGLE_OVERLAY } from '@/store/actions.type'
import api from '@/plugins/api'

import ButtonContinue from '@/components/buttons/ButtonContinue.vue'
import IconeFechar from '@/components/icones/IconeFechar.vue'

export default {
  components: {
    ButtonContinue,
    IconeFechar,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    email: {
      type: String,
      required: true,
    },
    applicationId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      limite: false,
    }
  },
  methods: {
    fecharModal() {
      this.$bvModal.hide(this.id)
      this.$emit('fechar')
    },
    enviarEmailVeridas() {
      this.$store.dispatch(TOGGLE_OVERLAY, true)
      api
        .postReenviarEmailVeridas(this.applicationId)
        .then((resposta) => {
          this.$emit('email_enviado', resposta.data)
          this.fecharModal()
        })
        .catch((error) => {
          if (error.message == 'Superaste el límite de reenvíos permitido.') this.limite = true
          else
            this.$bvToast.toast(error.message, {
              title: '¡Ocurrio un error!',
              autoHideDelay: 25000,
              variant: 'danger',
            })
        })
        .finally(() => {
          this.$store.dispatch(TOGGLE_OVERLAY, false)
        })
    },
  },
}
</script>
<style lang="scss" scoped>
.error {
  color: var(--movistar-pink);
}
::v-deep .modal-header {
  justify-content: flex-end !important;
  padding-bottom: 0px;
}

::v-deep .modal-footer {
  justify-content: space-around !important;
  .row {
    width: 100%;
    .col {
      display: flex;
      justify-content: center;
      .btn-continue {
        width: 90%;
        margin-bottom: 1rem;
      }
    }
  }
}

.text-email {
  color: #019df4;
  text-align: center;
}
</style>
