<template>
  <div class="checkbox-container mb-2">
    <template v-if="id === 'termsAndConditions'">
      <input type="checkbox" class="checkbox-round" :id="id" v-model="checked" @change="check" />
      <label :for="id" class="checkbox-text decoration-underline">{{ label }}</label>
    </template>
    <template v-else>
      <input type="checkbox" class="checkbox-round" :id="id" v-model="checked" @change="check" />
      <label :for="id" class="checkbox-text">{{ label }}</label>
    </template>
  </div>
</template>

<script>
export default {
  name: 'FormCheckbox',
  props: {
    label: String,
    id: String,
  },
  data() {
    return {
      checked: false,
    }
  },
  methods: {
    check() {
      this.$emit('change', this.checked)
    },
  },
}
</script>

<style scoped>
.checkbox-container {
  display: flex;
  align-items: center;
}

.checkbox-text {
  font-size: 12px;
  color: var(--movistar-grey-4);
  margin-bottom: 0;
  text-align: left;
}

.decoration-underline {
  text-decoration: underline;
}

.checkbox-round {
  width: 20px;
  height: 20px;
  -webkit-appearance: none;
  cursor: pointer;
  padding-right: 20px;
  margin-right: 10px;
  background-image: url(@/assets/icons/checkbox-unmarked.svg);
}

.checkbox-round:checked {
  background-image: url(@/assets/icons/checkbox.svg);
  border: none;
}
</style>
