<template>
  <div>
    <b-row align-h="center">
      <p class="font-16 my-4 mx-4 text-custom">{{ title }}</p>
    </b-row>

    <b-row>
      <b-col class="px-4" v-for="item in options" :key="item.id" cols="6">
        <div class="grid-wrapper word-wrap">
          <div class="box">
            <label class="flex-item">
              <input type="radio" :name="name" :value="item.texto" @change="option(item.id, name)" />
              <span></span>
            </label>
          </div>
          <div class="box">
            <label class="flex-item">
              <span class="text-break text-custom">{{ item.texto }}</span>
            </label>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'RadioButton',
  props: {
    name: String,
    label: String,
    value: String,
    options: Array,
    title: String,
  },
  methods: {
    option(respuesta, orden) {
      this.$emit('change', { answer: respuesta, order: orden })
    },
  },
}
</script>

<style lang="scss" scoped>
.grid-wrapper {
  display: grid;
  grid-template-columns: 20px minmax(50px, 200px);
  grid-gap: 10px;
}

.flex-item {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #ffffff;
  justify-content: left;
  text-align: left;
}

.flex-item:nth-child(1n) {
  border: none;
  font-size: 12px;
  color: var(--movistar-grey);
}

[type='radio'] {
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 0;
}

[type='radio'] + span:before {
  content: '';
  display: inline-block;
  width: 20px;
  height: 20px;
  vertical-align: -0.25em;
  border-radius: 50%;
  border: 0.25em solid #fff;
  box-shadow: 0 0 0 0.1em #019df4;
  transition: 0.5s ease all;
}

[type='radio']:checked + span:before {
  background: #019df4;
}
</style>
