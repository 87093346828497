<template>
  <b-card border-variant="light" v-if="parcelaSelecionada">
    <div class="card-title">
      <h4 class="title">Tasa</h4>
      <span class="tip" v-text="parcelaSelecionada.interestCETYearlyFmt" />
    </div>
  </b-card>
</template>

<script setup>
import { computed } from 'vue'
import store from '@/store'

const parcelaSelecionada = computed(() => store.getters.buscadorParcelaSelecionada)
</script>

<style scoped lang="scss">
.card {
  max-width: 41.25rem;
  background-color: #f5f5f5;
  border-radius: 10px;

  .card-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
    color: #019df4;
    .title {
      font-size: 25px;
      margin-bottom: 0;
      font-weight: bold;
    }
    .tip {
      font-size: 20px;
    }
  }

  .card-body {
    padding: 23px 41px;
  }
}
</style>
