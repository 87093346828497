import Api from '../plugins/api'
import {
  FETCH_ADDRESS_CITIES,
  FETCH_ADDRESS_DEPARTMENTS,
  FETCH_ADDRESS_NEIGHBORHOOD,
  GET_ADDRESS_CLIENT,
  GET_ADDRESS_CLIENT_FINAL,
  SAVE_CHOOSEN_ADDRESS,
  FETCH_APPLICATION_REDIRECT,
  SEND_ADDRESS_FORM,
  SEND_SURVEY,
  CLEAR_CITIES_AND_NEIGHBORHOOD,
  TOGGLE_OVERLAY,
  SEND_LOG_FRONT,
} from './actions.type'
import {
  SET_ADDRESS_CITIES,
  SET_ADDRESS_DEPARTMENTS,
  SET_ADDRESS_NEIGHBORHOOD,
  SET_ADDRESS_FORM,
  SET_ADDRESS_CLIENT,
  MUTATE_CITIES_AND_NEIGHBORHOOD,
  SET_ADDRESS_CLIENT_FINAL,
} from './mutations.type'

import router from '../router'
import { isNotSelectedDeliveryAddress, isSelectedDeliveryAddress } from '../helpers/common'
import store from './index'

const state = {
  addressDepartments: [],
  addressCities: [],
  addressNeighborhood: [],
  addressFormCallejero: {},
  addressClient: '',
}

const mutations = {
  [SET_ADDRESS_DEPARTMENTS](state, departments) {
    state.addressDepartments = departments.map((department) => {
      return {
        value: department.departmentDaneCode,
        text: department.departamentName,
      }
    })
  },
  [SET_ADDRESS_CITIES](state, cities) {
    state.addressCities = cities.map((cities) => {
      return {
        value: cities.cityDaneCode,
        text: cities.cityName,
      }
    })
  },
  [SET_ADDRESS_NEIGHBORHOOD](state, neighborhood) {
    state.addressNeighborhood = neighborhood.map((neighborhood) => {
      return {
        value: neighborhood.neighborhoodCode,
        text: neighborhood.neighborhoodName,
      }
    })
  },
  [SET_ADDRESS_FORM](state, params) {
    state.addressFormCallejero = params
  },
  [SET_ADDRESS_CLIENT](state, params) {
    const selected = params.find(isSelectedDeliveryAddress)
    if (selected) {
      if (selected.addressType === 'WORK') {
        state.addressClient = selected
        var address = params.find(isNotSelectedDeliveryAddress)
        if (address.deliveryDate) state.addressFormCallejero = address
      } else {
        state.addressClient = params.find(isNotSelectedDeliveryAddress)
        state.addressFormCallejero = selected
      }
    } else {
      state.addressClient = params.find((item) => {
        if (item.addressType === 'WORK') {
          return item
        }
      })
      state.addressFormCallejero = params.find((item) => {
        if (item.deliveryDate) {
          if (item.addressType === 'HOME') {
            return item
          }
        }
      })
    }
  },
  [SET_ADDRESS_CLIENT_FINAL](state, response) {
    state.addressClient = response.find(isSelectedDeliveryAddress)
  },
  [MUTATE_CITIES_AND_NEIGHBORHOOD](state) {
    state.addressCities = []
    state.addressNeighborhood = []
  },
}

const actions = {
  [FETCH_ADDRESS_DEPARTMENTS](context) {
    context.dispatch(CLEAR_CITIES_AND_NEIGHBORHOOD)
    Api.getAddressDepartments()
      .then((response) => {
        context.commit(SET_ADDRESS_DEPARTMENTS, response.data)
      })
      .catch((e) => console.log(e))
  },
  [CLEAR_CITIES_AND_NEIGHBORHOOD](context) {
    context.commit(MUTATE_CITIES_AND_NEIGHBORHOOD)
  },
  [FETCH_ADDRESS_CITIES](context, departmentCode) {
    context.dispatch(CLEAR_CITIES_AND_NEIGHBORHOOD)
    Api.getAddressCities(departmentCode)
      .then((response) => {
        context.commit(SET_ADDRESS_CITIES, response.data)
      })
      .catch((e) => console.log(e))
  },
  [FETCH_ADDRESS_NEIGHBORHOOD](context, cityCode) {
    Api.getAddressNeighborhood(cityCode)
      .then((response) => {
        context.commit(SET_ADDRESS_NEIGHBORHOOD, response.data)
      })
      .catch((e) => console.log(e))
  },
  [SEND_ADDRESS_FORM](context, body) {
    store.dispatch(TOGGLE_OVERLAY, true)
    Api.postAddressForm(context.getters.applicationId, body)
      .then((response) => {
        store.dispatch(TOGGLE_OVERLAY, false)
        context.commit(SET_ADDRESS_FORM, response.data)
      })
      .catch((e) => {
        console.log(e)
        let error = {
          applicationId: context.getters.applicationId,
          payload: body,
          error: e.message,
        }
        context.dispatch(SEND_LOG_FRONT, { data: JSON.stringify(error) })
      })
  },
  [SAVE_CHOOSEN_ADDRESS](context, payload) {
    store.dispatch(TOGGLE_OVERLAY, true)
    Api.executeAction(context.getters.applicationId, payload.sendBody)
      .then((response) => {
        if (response.data.situation === 'contract') {
          context.dispatch(FETCH_APPLICATION_REDIRECT, context.getters.currentApplication.origin)
        } else if (response.data.situation === 'reproved' && response.data.response) {
          router.push({
            name: 'MensagemReprovacao',
            params: {
              data: {
                response: response.data.response,
              },
              updateMode: true,
              editionMode: true,
            },
          })
        } else {
          store.dispatch(TOGGLE_OVERLAY, false)
          router.push({ name: 'CantProcessRequest' })
        }
      })
      .catch((e) => {
        console.log(e)
        let error = {
          applicationId: context.getters.applicationId,
          payload: payload,
          error: e.message,
        }
        context.dispatch(SEND_LOG_FRONT, { data: JSON.stringify(error) })
      })
  },
  [GET_ADDRESS_CLIENT](context) {
    Api.getAddressClient()
      .then((response) => {
        context.commit(SET_ADDRESS_CLIENT, response.data)
      })
      .catch((e) => console.log(e))
  },
  [GET_ADDRESS_CLIENT_FINAL](context) {
    Api.getAddressClient()
      .then((response) => {
        context.commit(SET_ADDRESS_CLIENT_FINAL, response.data)
      })
      .catch((e) => console.log(e))
  },
  [SEND_SURVEY](context, form) {
    return Api.executeAction(context.getters.applicationId, form)
      .then((response) => {
        return true
      })
      .catch((e) => console.log(e))
  },
}

const getters = {
  addressDepartments: (state) => state.addressDepartments,
  addressCities: (state) => state.addressCities,
  addressNeighborhood: (state) => state.addressNeighborhood,
  addressFormCallejero: (state) => state.addressFormCallejero,
  addressClient: (state) => state.addressClient,
}

export default {
  state,
  mutations,
  actions,
  getters,
}
