<template>
  <b-modal
    id="modal-confirm"
    cancel-title=""
    cancel-variant="hidden"
    size="xl"
    hide-header
    hide-footer
    ok-title=""
    ok-variant="hidden"
    centered
    header-border-variant="white"
    footer-border-variant="white"
  >
    <div class="text-right">
      <b-button variant="none" @click="$bvModal.hide('modal-confirm')">
        <b-img src="@/assets/icons/cancel.svg" alt="Cancel icon" />
      </b-button>
    </div>
    <div class="my-3">
      <div class="text-center">
        <b-img src="~@/assets/images/solicitud-en-vuelo.png" alt="solicitud icon" />
      </div>
      <p class="text-center subtitle-onboard">{{ title }}!</p>
      <p class="text-center subtitle-onboard">{{ subtitle }}</p>
      <b-row>
        <b-col>
          <div class="mt-3">
            <button class="blue-button continue-button-text" @click="confirm">
              <span>{{ confirmText }}</span>
            </button>
          </div>
        </b-col>
        <b-col>
          <div class="mt-3">
            <button class="blue-button continue-button-text inverted" @click="cancel">
              <span>{{ cancelText }}</span>
            </button>
          </div>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'ModalConfirm',
  props: {
    title: String,
    subtitle: String,
    confirmText: String,
    cancelText: String,
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    confirm() {
      this.$emit('confirm')
    },
  },
}
</script>

<style scoped>
.inverted {
  background: #ffffff;
  color: var(--movistar-blue);
  border: 1px solid var(--movistar-blue);
}
</style>
