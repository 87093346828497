<template>
  <div class="bg-sm">
    <SMNavbar />
    <Profile @editContactPreferences="openContactPreferences" />
    <div v-if="showContactPreferences">
      <ContactPreferences @editContactPreferences="openContactPreferences" />
    </div>
    <div v-else>
      <Applications />
      <Credits />
      <Claims />
      <Banner />
      <Finance />
      <Footer />
      <ModalRetrieveCart
        id="modal-retrieve-cart"
        title="Tienes una solicitud en proceso, ¿quieres continuar?"
        :available-cart="retrieveCart"
      />
    </div>
  </div>
</template>

<script>
import SMNavbar from '../../components/SelfManage/SMNavbar'
import Profile from '../../components/SelfManage/Profile'
import Credits from '../../components/SelfManage/Credits'
import ContactPreferences from '../../components/SelfManage/ContactPreferences'
import Applications from '../../components/SelfManage/Applications'
import Claims from '../../components/SelfManage/Claims'
import Footer from '../../components/SelfManage/Footer'
import Finance from '../../components/SelfManage/Finance.vue'
import Banner from '../../components/SelfManage/Banner.vue'
import store from '../../store'
import {
  FETCH_APPLICATION,
  SELF_MANAGE_GET_APPLICATIONS,
  SELF_MANAGE_GET_APPLICATIONS_LIBERADAS,
  SELF_MANAGE_GET_BORROWER,
  SELF_MANAGE_GET_CLAIMS,
} from '../../store/actions.type'
import ModalRetrieveCart from '../../components/modal/ModalRetrieveCart'
import { mapGetters } from 'vuex'

export default {
  name: 'Home',
  data() {
    return {
      showContactPreferences: false,
    }
  },
  components: {
    ModalRetrieveCart,
    Footer,
    Applications,
    Credits,
    Claims,
    Profile,
    SMNavbar,
    Finance,
    Banner,
    ContactPreferences,
  },
  mounted() {
    const dadosGetAplications = {
      retrieveCard: true,
    }
    store.dispatch(SELF_MANAGE_GET_BORROWER)
    store.dispatch(FETCH_APPLICATION)
    store.dispatch(SELF_MANAGE_GET_APPLICATIONS, dadosGetAplications)
    store.dispatch(SELF_MANAGE_GET_APPLICATIONS_LIBERADAS)
    store.dispatch(SELF_MANAGE_GET_CLAIMS)
  },
  computed: {
    ...mapGetters({
      retrieveCart: 'selfManageApplicationsRetrieveCart',
    }),
  },
  watch: {
    retrieveCart(value) {
      if (value) this.$bvModal.show('modal-retrieve-cart')
    },
  },
  methods: {
    openContactPreferences() {
      this.showContactPreferences = !this.showContactPreferences
    },
  },
}
</script>
