<template>
  <b-card no-body class="sm-card-installments mb-2">
    <b-card-body class="card-default">
      <b-row class="text-lef">
        <b-col class="px-1 text-center">
          <p class="sm-installment-title mb-2">Estado</p>
          <p class="sm-installment-subtitle mb-2">
            {{ installmentValue.number }}. {{ $t('mambu.' + installmentValue.status) }}
          </p>
          <b-img
            v-if="installmentValue.status === 'PENDING'"
            src="@/assets/icons/pago-pendiente.svg"
            alt="Pago pendiente icon"
          />
          <b-img v-if="installmentValue.status === 'PAID'" src="@/assets/icons/pagado.svg" alt="Pagado icon" />
          <b-img
            v-if="installmentValue.status === 'LATE'"
            src="@/assets/icons/pago-atrasado.svg"
            alt="Pago atrasado icon"
          />
          <b-img
            v-if="installmentValue.status === 'PARTIALLY_PAID'"
            src="@/assets/icons/pago-parcial.svg"
            alt="Pagado pacial"
          />
        </b-col>

        <b-col class="px-1 text-center">
          <p class="sm-installment-title mb-2">Fecha límite de pago</p>
          <p class="sm-installment-subtitle mb-2">{{ installmentValue.dateDueFmt }}</p>
        </b-col>

        <b-col class="px-1 text-center">
          <p class="sm-installment-title mb-2">Valor a pagar</p>
          <p class="sm-installment-subtitle mb-2">{{ installmentValue.totalDueFmt }}</p>
        </b-col>
      </b-row>

      <ToggleCollapse
        v-bind:class="[isClicked ? card.activeClass : card.defaultClass]"
        :id="installmentValue.number"
        icon-up="arrow-up-sm.svg"
        icon-down="arrow-down-sm.svg"
        @click="openInstallment"
      />
      <b-collapse :id="installmentValue.number" class="installment-card-border">
        <b-card no-body class="border-0 installment-card-border">
          <b-card-body>
            <b-row>
              <b-col cols="7" class="text-left pr-1 pl-1">
                <p class="installment-details-item pr-1">Total esperado</p>
              </b-col>
              <b-col cols="5" class="text-right pl-1 pr-2">
                <p class="installment-details-item-value pl-2">{{ installmentValue.totalExpectedFmt }}</p>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="pr-1">
                <b-row>
                  <b-col cols="6" sm="7" class="text-left pr-1 pl-1">
                    <p class="installment-details-item pr-1">Capital esperado</p>
                  </b-col>
                  <b-col cols="6" sm="5" class="text-right pl-1">
                    <p class="installment-details-item-value">{{ installmentValue.capitalExpectedFmt }}</p>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="6" sm="7" class="text-left pr-1 pl-1">
                    <p class="installment-details-item pr-1">Interés esperado</p>
                  </b-col>
                  <b-col cols="6" sm="5" class="text-right pl-1">
                    <p class="installment-details-item-value">{{ installmentValue.interestExpectedFmt }}</p>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="6" sm="7" class="text-left pr-1 pl-1">
                    <p class="installment-details-item pr-1">Cargos esperados</p>
                  </b-col>
                  <b-col cols="6" sm="5" class="text-right pl-1">
                    <p class="installment-details-item-value">{{ installmentValue.feesExpectedFmt }}</p>
                  </b-col>
                </b-row>
              </b-col>
              <div class="vertical-line"></div>
              <b-col class="pl-2 pr-2">
                <b-row>
                  <b-col cols="7" class="text-left pr-1">
                    <p class="installment-details-item pr-1">Penalización esperada</p>
                  </b-col>
                  <b-col cols="5" class="text-right pl-1">
                    <p class="installment-details-item-value">{{ installmentValue.penaltyExpectedFmt }}</p>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="7" class="text-left pr-1">
                    <p class="installment-details-item pr-1">Impuestos esperados</p>
                  </b-col>
                  <b-col cols="5" class="text-right pl-1">
                    <p class="installment-details-item-value">{{ installmentValue.taxExpectedFmt }}</p>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>

            <hr />

            <b-row>
              <b-col cols="7" class="text-left pr-1 pl-1">
                <p class="installment-details-item pr-1">Total pagado</p>
              </b-col>
              <b-col cols="5" class="text-right pl-1 pr-2">
                <p class="installment-details-item-value pl-2">{{ installmentValue.totalPaidFmt }}</p>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="pr-1">
                <b-row>
                  <b-col cols="6" sm="7" class="text-left pr-1 pl-1">
                    <p class="installment-details-item pr-1">Capital pagado</p>
                  </b-col>
                  <b-col cols="6" sm="5" class="text-right pl-1">
                    <p class="installment-details-item-value">{{ installmentValue.capitalPaidFmt }}</p>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="6" sm="7" class="text-left pr-1 pl-1">
                    <p class="installment-details-item pr-1">Interés pagado</p>
                  </b-col>
                  <b-col cols="6" sm="5" class="text-right pl-1">
                    <p class="installment-details-item-value">{{ installmentValue.interestPaidFmt }}</p>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="6" sm="7" class="text-left pr-1 pl-1">
                    <p class="installment-details-item pr-1">Cargos pagados</p>
                  </b-col>
                  <b-col cols="6" sm="5" class="text-right pl-1">
                    <p class="installment-details-item-value">{{ installmentValue.feesPaidFmt }}</p>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="5" sm="7" class="text-left pr-1 pl-1">
                    <p class="installment-details-item pr-1">Fecha del pago</p>
                  </b-col>
                  <b-col cols="7" sm="5" class="text-right pl-1">
                    <p class="installment-details-item-value">{{ installmentValue.datePaidFmt }}</p>
                  </b-col>
                </b-row>
              </b-col>
              <div class="vertical-line"></div>
              <b-col class="pl-2 pr-2">
                <b-row>
                  <b-col cols="6" sm="7" class="text-left pr-1">
                    <p class="installment-details-item pr-1">Penalización pagado</p>
                  </b-col>
                  <b-col cols="6" sm="5" class="text-right pl-1">
                    <p class="installment-details-item-value">{{ installmentValue.penaltyPaidFmt }}</p>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="6" sm="7" class="text-left pr-1">
                    <p class="installment-details-item pr-1">Impuestos pagados</p>
                  </b-col>
                  <b-col cols="6" sm="5" class="text-right pl-1">
                    <p class="installment-details-item-value">{{ installmentValue.taxPaidFmt }}</p>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>

            <hr />

            <b-row>
              <b-col cols="7" class="text-left pr-1 pl-1">
                <p class="installment-details-item pr-1">Total pendiente por pagar</p>
              </b-col>
              <b-col cols="5" class="text-right pl-1 pr-2">
                <p class="installment-details-item-value pl-2">{{ installmentValue.totalDueFmt }}</p>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="pr-1">
                <b-row>
                  <b-col cols="6" sm="7" class="text-left pr-1 pl-1">
                    <p class="installment-details-item pr-1">Capital pendiente</p>
                  </b-col>
                  <b-col cols="6" sm="5" class="text-right pl-1">
                    <p class="installment-details-item-value">{{ installmentValue.capitalDueFmt }}</p>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="6" sm="7" class="text-left pr-1 pl-1">
                    <p class="installment-details-item pr-1">Interés pendiente</p>
                  </b-col>
                  <b-col cols="6" sm="5" class="text-right pl-1">
                    <p class="installment-details-item-value">{{ installmentValue.interestDueWithoutTaxFmt }}</p>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="6" sm="7" class="text-left pr-1 pl-1">
                    <p class="installment-details-item pr-1">Cargos pendientes</p>
                  </b-col>
                  <b-col cols="6" sm="5" class="text-right pl-1">
                    <p class="installment-details-item-value">{{ installmentValue.feesDueFmt }}</p>
                  </b-col>
                </b-row>
              </b-col>
              <div class="vertical-line"></div>
              <b-col class="pl-2 pr-2">
                <b-row>
                  <b-col cols="6" sm="7" class="text-left pr-1">
                    <p class="installment-details-item pr-1">Penalización pendiente</p>
                  </b-col>
                  <b-col cols="6" sm="5" class="text-right pl-1">
                    <p class="installment-details-item-value">{{ installmentValue.penaltyDueFmt }}</p>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="6" sm="7" class="text-left pr-1">
                    <p class="installment-details-item pr-1">Impuestos pendientes</p>
                  </b-col>
                  <b-col cols="6" sm="5" class="text-right pl-1">
                    <p class="installment-details-item-value">{{ installmentValue.taxDueFmt }}</p>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-collapse>
    </b-card-body>
  </b-card>
</template>

<script>
import ToggleCollapse from '../buttons/ToggleCollapse'

export default {
  name: 'CreditsPaymentInstallmentsCard',
  props: {
    installmentValue: Object,
    installmentIndex: Number,
    stateValue: Number,
  },
  components: { ToggleCollapse },
  data() {
    return {
      isClicked: false,
      card: {
        defaultClass: 'card-default',
        activeClass: 'card-clicked',
      },
    }
  },
  methods: {
    openInstallment(evt) {
      this.isClicked = evt
    },
  },
}
</script>

<style scoped>
.card-clicked {
  background-color: var(--movistar-light-blue);
  border-radius: 10px;
}

.card-default {
  background-color: var(--white);
  border-radius: 10px;
}

.installment-card-border {
  border-radius: 10px;
}

.installment-details-title {
  font: 15px Telefonica, sans-serif;
  color: var(--movistar-grey-2);
}

.installment-details-title-value {
  font: 15px Telefonica, sans-serif;
  color: var(--movistar-pine-green);
}

.installment-details-item {
  font: 11px Telefonica, sans-serif;
  color: var(--movistar-grey-2);
}

.installment-details-item-value {
  font: 11px Telefonica, sans-serif;
  color: var(--movistar-pine-green);
}

.vertical-line {
  border-left: 1px solid #0000001a;
  margin-bottom: 16px;
}
</style>
