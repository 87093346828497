<template>
  <div class="form-mensagem" v-if="this.mostrarMensagem">
    <div>
      <div>
        <button type="button" class="close botao-fechar" @click="fecharMensagemApos(0)">
          <img src="@/assets/icons/cancelar-regular.svg" alt="Close" @click="fecharBox" />
        </button>
      </div>
      <div v-html="texto" class="texto-mensagem"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardMensagem',
  props: {
    texto: String,
    link: String,
    linkTexto: String,
    texto2: String,
    abrirApos: Number,
    fecharApos: Number,
  },
  data() {
    return {
      mostrarMensagem: true,
    }
  },
  mounted() {
    setTimeout(() => this.fecharMensagemApos(this.fecharApos), this.abrirApos)
  },
  methods: {
    fecharMensagemApos(segundos) {
      setTimeout(() => this.fecharBox(), segundos * 1000)
    },
    fecharBox() {
      this.mostrarMensagem = false
    },
  },
}
</script>

<style scoped>
a {
  text-decoration: underline;
}
.form-mensagem {
  background: #f3f3f5;
  border-radius: 7px;
  border-left: 10px solid var(--movistar-blue);
  padding-bottom: 20px;
  width: 325px;
  min-height: 93px;
  z-index: 1;
  position: absolute;
  -webkit-box-shadow: 2px 5px 15px -6px rgba(0, 0, 0, 0.55);
  box-shadow: 2px 5px 15px -6px rgba(0, 0, 0, 0.55);
  left: 50%;
  transform: translate(-50%, 0);
}

/* @media (max-width: 800px) {
  .form-mensagem {
    margin-left: 40px;
  }
}

@media (max-width: 500px) {
  .form-mensagem {
    margin-left: 0px;
  }
} */

.texto-mensagem {
  padding: 0px 20px 0px 10px;
  margin-top: 25px;
  font-family: Telefonica;
  font-size: 14px;
  color: #707070;
  text-align: justify;
}

.botao-fechar {
  font-family: Telefonica;
  font-size: 20px;
  color: var(--movistar-blue);
  position: absolute;
  top: 0.3rem;
  right: 0.8rem;
}
</style>
