<template>
  <b-modal :id="id" header-border-variant="white" footer-border-variant="white" size="sm">
    <template #modal-header>
      <h1 class="text-center title-onboard">¡Recuérdale al cliente!</h1>
      <b-button variant="blank" @click="$bvModal.hide(id)">
        <IconeFechar />
      </b-button>
    </template>
    <p class="text-center">
      Al finalizar el proceso de crédito se le enviará a su correo el link del pago inicial del equipo.
    </p>
    <template #modal-footer>
      <ButtonContinue text="Continuar" class="btn-continue" @click.once="$emit('continuar')" />
    </template>
  </b-modal>
</template>
<script>
import ButtonContinue from '@/components/buttons/ButtonContinue.vue'
import IconeFechar from '@/components/icones/IconeFechar.vue'
export default {
  components: {
    ButtonContinue,
    IconeFechar,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .modal-sm {
  max-width: 412px;
  .modal-body {
    max-width: 412px;
  }
  .modal-content {
    max-width: 412px;
  }
}

::v-deep .modal-header {
  padding-bottom: 0px;
  justify-content: center;
  position: relative;
  h1 {
    margin-top: 0.5rem;
    margin-bottom: 0;
  }

  .btn-blank {
    position: absolute;
    right: 1rem;
    top: 1rem;
  }
}

::v-deep .modal-body {
  padding: 2rem 3rem 0rem 3rem;
}

::v-deep .modal-footer {
  justify-content: center;

  .btn-continue {
    width: 50%;
  }
}
</style>
