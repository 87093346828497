<template>
  <div class="finish-container">
    <b-container class="custom-padding-bottom">
      <MovistarMoneyLogo />

      <b-row class="text-center pb-4 pt-3 mx-1" align-h="center">
        <b-col cols="12" md="6" class="no-padding">
          <p class="onboard-finish-title">¡Estas a un paso de finalizar la orden!</p>
        </b-col>
      </b-row>

      <CardFinal />
    </b-container>
  </div>
</template>

<script>
import MovistarMoneyLogo from '@/components/MovistarMoneyLogo.vue'
import CardFinal from '@/components/resumo/CardFinal.vue'

import { mapGetters } from 'vuex'
import store from '@/store'
import { FETCH_APPLICATION_REDIRECT, GET_ADDRESS_CLIENT_FINAL } from '@/store/actions.type'
import { gtmDataLayerCustom } from '@/helpers/gtmHelper'

export default {
  name: 'Resumo',
  components: {
    CardFinal,
    MovistarMoneyLogo,
  },
  data() {
    return {
      step: 5,
      borrowerName: '',
    }
  },
  mounted() {
    store.dispatch(FETCH_APPLICATION_REDIRECT, 'Finter')
    store.dispatch(GET_ADDRESS_CLIENT_FINAL)
    this.setName()
    if (this.currentApplication.origin == 'Finter')
      gtmDataLayerCustom(window.dataLayer, {
        eventChannel: this.currentApplication.origin,
        entorno: process.env.VUE_APP_TAGMANAGER_ENTORNO,
        event: 'trackTransaction',
        compra: { id: this.currentApplication.idOrderAmount, revenue: this.phoneDetails.price },
        productos: [
          {
            name: this.phoneDetails.deviceModel,
            id: this.phoneDetails.sku,
            price: this.phoneDetails.price,
            brand: '__MARCA__',
            category: 'movistar money',
            variant: this.phoneDetails.deviceColor,
            quantity: '__CANTIDAD__',
            coupon: '__CUPON__',
          },
        ],
      })
  },
  methods: {
    setName() {
      if (this.currentApplication) {
        this.borrowerName = this.currentApplication.borrower.name.toLowerCase()
      }
    },
    abrirModalEnviarEmail() {
      this.$bvModal.hide('modal-recado-email-antecipo')
      this.$bvModal.show('modal-enviar-email')
    },
    abrirModalConfirmarNovoEmail() {
      this.$bvModal.hide('modal-enviar-email')
      this.$bvModal.show('modal-confirmar-novo-email')
    },
    reenviarEmailAnticipo() {},
  },
  computed: {
    ...mapGetters({
      phoneDetails: 'phoneDetails',
      currentApplication: 'currentApplication',
    }),
  },
}
</script>

<style lang="scss" scoped>
.finish-container {
  min-height: 100vh;
  background-color: white;
}
.custom-padding-bottom {
  padding-bottom: 100px;
}
.onboard-finish-title {
  color: var(--movistar-blue);
  font-size: 1.75rem;
}
</style>
