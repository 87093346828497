<template>
  <div></div>
</template>

<script>
import store from '../../store'
import { mapGetters } from 'vuex'
import chat from '../../../public/js/chatbot/index'

export default {
  name: 'Chatbot',
  created() {
    if (this.chatRoute) {
      this.montarChatBot()
    }
  },
  data() {
    return {
      chatRoute: false,
      chatId: process.env.VUE_APP_CHATBOT_ID,
      deploymentId: process.env.VUE_APP_CHATBOT_DEPLOYMENT_ID,
      buttonId: '',
      chatAtivo: '',
    }
  },
  computed: {
    ...mapGetters({
      documentNumber: 'documentNumber',
    }),
  },
  watch: {
    '$route.params': {
      handler: function () {
        if (this.$route.path.includes('self-manage')) {
          this.chatRoute = true
          this.chatAtivo = 'chatAuto'
          this.buttonId = process.env.VUE_APP_CHATBOT_BUTTON_ID_AUTO
        } else if (
          (store.getters.isAuthenticated || store.getters.authStatus === 'success') &&
          !this.$route.path.includes('self-manage')
        ) {
          this.chatRoute = true
          this.chatAtivo = 'chatOnBoarding'
          this.buttonId = process.env.VUE_APP_CHATBOT_BUTTON_ID
        } else {
          this.chatRoute = false
          this.chatAtivo = ''
          this.buttonId = ''
        }
      },
      immediate: true,
    },
    chatRoute: function (newValue, oldValue) {
      if (newValue != oldValue) this.mostrarChatBot()
    },
    chatAtivo: function (newValue, oldValue) {
      if (newValue != oldValue) this.montarChatBot()
    },
  },
  methods: {
    mostrarChatBot(mostrar) {
      const chatElemento = document.querySelector('.embeddedServiceHelpButton')
      if (chatElemento) chatElemento.style.display = mostrar ? 'block' : 'none'
    },
    montarChatBot() {
      if (window.embedded_svc) {
        window.embedded_svc = undefined
      }
      chat.esw()
      chat.chatBotButton().execute()

      chat.chatSF().execute(this.documentNumber, this.chatAtivo, this.chatId, this.deploymentId, this.buttonId)
    },
  },
}
</script>

<style>
.embeddedServiceHelpButton .helpButton .uiButton {
  background-color: #005290;
  font-family: 'Arial', sans-serif;
}
.embeddedServiceHelpButton .helpButton .uiButton:focus {
  outline: 1px solid #005290;
}

.avatar {
  display: none;
}

.botaoEnviarCB {
  height: 100%;
  width: 20%;
  display: block;
  border: 0px solid #ec624b;
  background: none;
  padding: 0px;
  cursor: pointer;
}
</style>
