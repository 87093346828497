<template>
  <b-modal
    :id="id"
    class="modal-body"
    cancel-title=""
    cancel-variant="hidden"
    hide-header
    hide-footer
    ok-title=""
    ok-variant="hidden"
    centered
    header-border-variant="white"
    footer-border-variant="white"
    no-close-on-esc
    no-close-on-backdrop
  >
    <div class="mx-2">
      <b-row align-h="end">
        <b-button variant="blank" @click="cancellAction(id)">
          <b-img src="@/assets/icons/cancel.svg" alt="Cancel icon" />
        </b-button>
      </b-row>

      <div class="py-1">
        <p class="text-center title-onboard">
          ¡Por favor verifica que el correo electrónico esté escrito correctamente y sea válido!
        </p>
        <p class="text-content-email">{{ email }}</p>
        <b-img class="mail-image" src="../../assets/icons/email-assistido.png" alt="Lock icon" />
        <p class="text-content-email">Este dato es clave para las transacciones post venta del cliente.</p>
      </div>
    </div>

    <div class="container-btn-modal">
      <button class="btn-modal-evidente-cancell mr-4" @click="cancellAction(id)">Volver</button>
      <button class="btn-modal-evidente-accept" @click.once=";[$emit('validarEmail'), cancellAction(id)]">
        Continuar
      </button>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'ModalValidarEmailAssistido',
  components: {},
  props: {
    id: String,
    email: String,
  },
  methods: {
    cancellAction(id) {
      this.$bvModal.hide(id)
    },
  },
}
</script>

<style scoped>
.text-cancell-footer {
  text-align: center;
  margin-top: 1.5rem;
  padding-left: 20px;
  padding-right: 20px;
}
.container-btn-modal {
  display: flex;
  justify-content: space-around;
  margin-top: 2rem;
}
.btn-modal-evidente-cancell,
.btn-modal-evidente-accept {
  width: 45%;
  height: 50px;
  border-radius: 40px;
  background-color: #5cb630;
  color: #ffffff;
  font-size: 16px;
  border: none;
  font-weight: bold;
}

.btn-modal-evidente-accept {
  border: 1px solid #5cb630;
  background: #ffffff;
  color: #5cb630;
}

.text-content-email {
  text-align: center;
  font-size: 1.1rem;
  font-weight: 500;
}

.mail-image {
  margin: auto;
  display: block;
  height: 90px;
  margin-bottom: 20px;
  margin-top: 20px;
}
</style>
