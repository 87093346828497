<template>
  <div>
    <div class="container-body">
      <div
        class="container-list-cuotas"
        v-for="(offer, index) in arrayOffers"
        :key="offer.principalDue"
        @click="handleSelectOffer(index)"
      >
        <div class="container-coutas-offer">
          <div class="container-cuotas" :class="{ isActive: offer.isActive }">
            <section class="container-value-cuotas">
              <img
                src="@/assets/icons/orange-right.svg"
                alt="heade-image"
                class="img-orange-right"
                v-if="offer.isActive"
              />
              <h1 class="text-value-cuotas">{{ offer.installment }}</h1>
              <p class="text-cuotas">cuotas</p>
            </section>
            <section class="content-value-total">
              <h3 class="value-total">Valor total</h3>
              <div class="container-value-moth">
                <h3 class="for-moth" :class="{ fontValueMoth: offer.isActive }">{{ offer.totalFmt }}/Mes</h3>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-against">
      <FooterCounterOffer v-bind:footerArrayOffers="mutablefooterArrayProps" />
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import { MUTATION_SELECT_OFFER } from '../../../store/mutations.type'
import { gtmDataLayerTrackEvent } from '../../../helpers/gtmHelper'
import FooterCounterOffer from './FooterCounterOffer.vue'

export default {
  components: { FooterCounterOffer },
  data() {
    return {
      mutablefooterArrayProps: this.footerArrayProps,
    }
  },
  props: {
    arrayOffers: {},
    footerArrayProps: {},
    payloadContraOferta: {},
    offerValue: {},
  },
  watch: {
    counterOfferData: {
      handler() {
        if (this.counterOfferData) {
          if (this.intendedInstallments) {
            const selectedOfferIndex = this.arrayOffers.findIndex(
              (offer) => offer.installment === this.intendedInstallments
            )
            if (selectedOfferIndex >= 0) {
              this.handleSelectOffer(selectedOfferIndex)
            }
          }
        }
      },
    },
    offerValue: {
      handler() {
        if (this.offerValue != 0 && this.arrayOffers && this.arrayOffers.length > 0) {
          this.handleSelectOffer(this.arrayOffers.length - 1)
        }
      },
    },
  },

  mounted() {
    const selectedOfferIndex = this.arrayOffers.findIndex((offer) => offer.installment === this.intendedInstallments)
    if (selectedOfferIndex >= 0) {
      this.handleSelectOffer(selectedOfferIndex)
    }
  },

  computed: {
    ...mapGetters({
      valueInputOfferSimulation: 'valueInputOfferSimulation',
      valueInputOfferSimulationFmt: 'valueInputOfferSimulationFmt',
      minValueOfferSimulation: 'minValueOfferSimulation',
      maxValueOfferSimulation: 'maxValueOfferSimulation',
      minValueOfferSimulationFmt: 'minValueOfferSimulationFmt',
      maxValueOfferSimulationFmt: 'maxValueOfferSimulationFmt',
      rangeOfferSimulation: 'rangeOfferSimulation',
      getOffers: 'getOffers',
      lastArrayInOffer: 'lastArrayInOffer',
      counterOfferData: 'counterOfferData',
      arrayOption: 'arrayOption',
      minAgainstOffer: 'minAgainstOffer',
      maxAgainstOffer: 'maxAgainstOffer',
      valueAgainstOffer: 'valueAgainstOffer',
      intendedInstallments: 'intendedInstallments',
      valueAgainsOfferFmt: 'valueAgainsOfferFmt',
      lastArrayAgainsOffer: 'lastArrayAgainsOffer',
      currentApplication: 'currentApplication',
    }),
  },
  methods: {
    ...mapMutations([MUTATION_SELECT_OFFER]),
    handleSelectOffer(event) {
      this.arrayOffers.map((arrayOffer, index) => {
        if (event === index) {
          this.arrayOffers[index].isActive = true
          this.mutablefooterArrayProps = Object.assign({}, this.arrayOffers[index])
          this[MUTATION_SELECT_OFFER](this.mutablefooterArrayProps)
        } else {
          this.arrayOffers[index].isActive = false
        }
      })
      gtmDataLayerTrackEvent(
        window.dataLayer,
        'trackEvent',
        'movistar money',
        'click::selección cuotas::paso 2',
        this.arrayOffers[event].installment,
        this.CryptoJS.SHA1(this.currentApplication.mobile).toString()
      )
    },
  },
}
</script>

<style scoped>
.footer-against {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.footer-offer {
  position: relative;
  top: 88%;
}
.container-info-cuotas {
  height: 100%;
}
.img-orange-right {
  position: absolute;
  left: 16%;
  width: 20px;
  height: 20px;
}

.container-coutas-offer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container-cuotas:hover {
  cursor: pointer;
}

.isActive {
  border: solid 2px #ec624b;
  color: #019df4;
}

.fontValueMoth {
  color: #019df4;
}

.container-cuotas {
  background: #ffffff;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 16px;
  width: 240px;
  height: 48px;
  border-radius: 8px;
  margin-top: 20px;
}

.container-value-cuotas {
  display: flex;
  align-items: center;
}

.text-value-cuotas {
  display: flex;
  font-size: 24px;
  align-items: center;
  margin: 0;
  margin-right: 5px;
}

.text-cuotas {
  font-size: 18px;
  margin: 0;
}

.content-value-total {
  width: 104px;
}

.value-total {
  font-size: 12px;
  margin: 0;
  text-align: start;
  line-height: 1.67;
  font-family: Telefonica;
}

.container-value-moth {
  display: flex;
  font-family: Telefonica;
  font-size: 14px;
  font-weight: 300;
  color: #50535a;
}

.value-moth {
  font-size: 12px;
  margin: 0;
  color: #50535a;
}

.for-moth {
  font-size: 12px;
  margin: 0;
}

.info-about-coutas {
  font-size: 12px;
  height: 35px;
  padding-top: 6px;
  color: #d3d4d3;
}
.container-about-coutas {
  height: 35px;
  display: flex;
  justify-content: center;
  align-content: center;
  background-image: linear-gradient(272deg, rgba(11, 39, 57, 80%), rgb(55 84 102 / 80%));
}
.container-list-cuotas {
  position: relative;
  top: -42px;
  margin-top: 1rem;
}

@media (max-width: 3px) {
  .container-list-cuotas {
    top: -34px;
  }
  .img-orange-right {
    left: 10%;
  }
}

@media (min-width: 359px) and (max-width: 400px) {
  .container-list-cuotas {
    top: -40px;
  }
  .img-orange-right {
    left: 12%;
  }
}

@media (min-width: 370px) and (max-width: 400px) {
  .container-list-cuotas {
    top: -40px;
  }
  .img-orange-right {
    left: 14%;
  }
}

@media (min-width: 400px) {
  .container-list-cuotas {
    top: -44px;
  }
  .img-orange-right {
    left: 19%;
  }
}

@media (min-width: 590px) and (max-width: 610px) {
  .container-list-cuotas {
    top: -44px;
  }
  .img-orange-right {
    left: 27.5%;
  }
}

@media (min-width: 310px) and (max-width: 320px) {
  .container-list-cuotas {
    top: -30px;
  }
  .img-orange-right {
    left: 6%;
  }
}

@media (min-width: 700px) and (max-width: 730px) {
  .container-list-cuotas {
    top: -50px;
  }
  .img-orange-right {
    left: 31%;
  }
}

@media (max-width: 400px) {
  .info-about-coutas {
    font-size: 12px;
  }
}

@media (max-height: 580px) {
  .container-cuotas {
    background: #ffffff;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 22px;
    width: 240px;
    height: 40px;
    border-radius: 8px;
    margin-top: 20px;
  }
  .container-list-cuotas {
    position: relative;
    top: -12px;
    margin-top: 1px;
  }
}
@media (max-height: 660px) {
  .container-cuotas {
    background: #ffffff;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 22px;
    width: 240px;
    height: 50px;
    border-radius: 8px;
    margin-top: 12px;
  }
}

@media (min-width: 410px) and (max-width: 420px) {
  .container-list-cuotas {
    top: -40px;
  }
  .img-orange-right {
    left: 16.5%;
  }
}
@media (min-width: 270px) and (max-width: 300px) {
  .container-list-cuotas {
    top: -40px;
  }
  .img-orange-right {
    left: -1%;
  }
}

@media (min-height: 800px) {
  .footer-offer {
    position: fixed;
    bottom: 0;
    left: 0;
  }
}

@media (min-width: 341px) and (min-height: 538px) {
  .img-orange-right {
    position: absolute;
    left: 8%;
    width: 20px;
    height: 20px;
  }
}
</style>
