<template>
  <div class="contact-preferences-body">
    <b-container align-h="left" class="contact-info-container">
      <b-row>
        <b-col>
          <b-row>
            <b-col class="title-container">
              <b-img class="img-preferencia-contacto" src="@/assets/icons/contact-preference-icon.svg"></b-img>
              <p class="preferencias-de-contacto-title">Preferencias de contacto</p>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="3" class="no-padding">
              <hr class="separator-orange" />
            </b-col>
            <b-col cols="9" class="no-padding">
              <hr class="separator" />
            </b-col>
          </b-row>
          <b-row class="info-title-container">
            <p class="notificacion">Notificación</p>
          </b-row>
          <b-row>
            <b-col class="info-container">
              <p class="info-titles">Información para mantener mi crédito al día</p>
              <b-img
                id="required-tooltip-icon"
                class="icon-aditional-info"
                src="@/assets/icons/aditional-info.svg"
              ></b-img>
              <b-tooltip target="required-tooltip-icon" triggers="hover"> Obligatorio </b-tooltip>
            </b-col>
            <b-col class="info-text-container">
              <p class="info-text">Recordatorios del pago de tu cuota y gestión de cobranza</p>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="info-container">
              <p class="info-titles">Información comercial</p>
              <b-img
                id="optional-tooltip-icon"
                class="icon-aditional-info"
                src="@/assets/icons/aditional-info.svg"
              ></b-img>
              <b-tooltip target="optional-tooltip-icon" triggers="hover"> Opcional </b-tooltip>
            </b-col>
            <b-col class="info-text-container">
              <p class="info-text">
                Información del producto, cuotas, promociones, novedades, nuevas ofertas, campañas educativas.
              </p>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
    <b-container class="contact-options-container">
      <b-row>
        <b-col class="contact-options">
          <b-row>
            <b-img class="icon-options" src="@/assets/icons/message.svg"></b-img>
          </b-row>
          <b-row>
            <p class="option-title">Mensaje de texto</p>
          </b-row>
          <b-row>
            <input
              id="billing-sms"
              :class="{ 'error-validation-input': validationError }"
              v-model="contactPreferences.billingSms"
              type="checkbox"
            />
          </b-row>
          <b-row>
            <input id="marketing-sms" v-model="contactPreferences.marketingSms" type="checkbox" />
          </b-row>
        </b-col>

        <b-col class="contact-options">
          <b-row>
            <b-img class="icon-options" src="@/assets/icons/whatsapp-icon.svg"></b-img>
          </b-row>
          <b-row>
            <p class="option-title">Whatsapp</p>
          </b-row>
          <b-row>
            <input
              id="billing-whatsapp"
              :class="{ 'error-validation-input': validationError }"
              v-model="contactPreferences.billingWhatsapp"
              type="checkbox"
            />
          </b-row>
          <b-row>
            <input id="marketing-whatsapp" v-model="contactPreferences.marketingWhatsapp" type="checkbox" />
          </b-row>
        </b-col>

        <b-col class="contact-options">
          <b-row>
            <b-img class="icon-options" src="@/assets/icons/mail-blue.svg"></b-img>
          </b-row>
          <b-row>
            <p class="option-title">Correo electrónico</p>
          </b-row>
          <b-row>
            <input
              id="billing-email"
              :class="{ 'error-validation-input': validationError }"
              v-model="contactPreferences.billingEmail"
              type="checkbox"
            />
          </b-row>
          <b-row>
            <input id="marketing-email" v-model="contactPreferences.marketingEmail" type="checkbox" />
          </b-row>
        </b-col>

        <b-col class="contact-options">
          <b-row>
            <b-img class="icon-options" src="@/assets/icons/telephone.svg"></b-img>
          </b-row>
          <b-row>
            <p class="option-title">Llamada</p>
          </b-row>
          <b-row>
            <input
              id="billing-call"
              :class="{ 'error-validation-input': validationError }"
              v-model="contactPreferences.billingCall"
              type="checkbox"
            />
          </b-row>
          <b-row>
            <input id="marketing-call" v-model="contactPreferences.marketingCall" type="checkbox" />
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col v-if="validationError" class="validation-error-container">
          <div class="validations">
            <b-img class="validation-error-icon" src="@/assets/icons/negado.svg"></b-img>
            <p class="validation-error-text">Debes marcar al menos un canal*</p>
          </div>
        </b-col>
        <b-col class="save-button-container">
          <b-button class="save-button" @click="saveContactPreferences">Guardar</b-button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import store from '../../store'
import { SELF_MANAGE_UPDATE_CONTACT_PREFERENCES } from '../../store/actions.type'
import { mapGetters } from 'vuex'

export default {
  name: 'ContactPreferences',

  data() {
    return {
      contactPreferences: {
        billingSms: false,
        marketingSms: false,
        billingWhatsapp: false,
        marketingWhatsapp: false,
        billingEmail: false,
        marketingEmail: false,
        billingCall: false,
        marketingCall: false,
      },
      validationError: false,
    }
  },
  mounted() {
    if (this.borrower.contactPreferences) {
      this.contactPreferences = this.borrower.contactPreferences
    }
  },
  computed: {
    ...mapGetters({
      borrower: 'selfManageBorrower',
    }),
  },
  methods: {
    saveContactPreferences() {
      let billingContact = Object.entries(this.contactPreferences).filter(([key, value]) => {
        if (key.startsWith('billing') && value) {
          return value
        }
      })
      if (billingContact.length === 0) {
        this.validationError = true
      } else {
        store.dispatch(SELF_MANAGE_UPDATE_CONTACT_PREFERENCES, this.contactPreferences)
        this.$emit('editContactPreferences')
      }
    },
  },
}
</script>
<style scoped>
.contact-preferences-body {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.contact-info-container {
  position: relative;
  left: 7%;
  width: 40% !important;
  height: 40%;
  margin: 25px 10px 63px 0 !important;
  padding: 19px 19.5px 18px 14px;
  border-radius: 10px;
  background-color: #fff;
}

.contact-options-container {
  position: relative;
  right: 2%;
  width: 40% !important;
  margin-top: 175px;
}

.title-container {
  display: flex;
  align-content: flex-start;
}

.img-preferencia-contacto {
  width: 86px;
  height: 80px;
  margin: 10px 5px 16px 14px;
  object-fit: contain;
}

.preferencias-de-contacto-title {
  margin: 55px 23px 18px 0px;
  font-family: Telefonica;
  font-size: 28px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--0-b-2739);
}

.separator {
  width: 75%;
  margin: 18.5px 8.5px 53.5px 89px;
  background-color: var(--movistar-grey-5);
  height: 1px;
}

.separator-orange {
  width: 135%;
  margin: 18.5px 80.5px 53.5px 30px;
  border-radius: 1px;
  background-color: var(--movistar-orange);
  height: 2px;
}

.info-title-container {
  width: 81px;
  height: 20px;
  margin: -20px 173.5px 25px 18px;
}

.icon-aditional-info {
  width: 16.6px;
  height: 16.6px;
  margin: 10px 0px 0px 20px;
}

.notificacion {
  font-family: Telefonica;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #0b2739;
}

.info-container {
  width: 30% !important;
  height: 50px;
  margin: 25px 24.5px 18px 18px;
  display: flex;
  align-content: flex-start;
}

.info-titles {
  width: 250px;
  font-family: Telefonica;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--0-b-2739);
}

.info-text-container {
  display: flex;
  align-content: flex-start;
}

.info-text {
  margin: 15px 8.5px 0 23px;
  font-family: Telefonica;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #50535a;
}

.contact-options {
  width: 250px;
  margin: 0px 15px 63px 20px;
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.icon-options {
  width: 24.4px;
  height: 22.4px;
  margin: 10px 45.3px 9.6px 45.3px;
}

.option-title {
  margin: 5px 0 30px;
  font-family: Telefonica;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--0-b-2739);
}

input {
  width: 16.6px;
  height: 16.6px;
  margin: 10px 0px 65px 0px;
  border-radius: 3px;
  background-color: var(--019-df-4);
}

.save-button-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.save-button {
  width: 250px;
  height: 70px;
  margin: 5px 0px 65px 0px;
  border-radius: 50px;
  background-color: #019df4;
  font-size: 22px;
  font-weight: 600;
}

.validation-error-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.validations {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: relative;
  left: 110%;
  top: -25%;
}

.validation-error-text {
  width: 260px;
  height: 20px;
  margin: 0px 0 25px 5.2px;
  font-family: Telefonica;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #e63780;
}

.validation-error-icon {
  width: 18px;
  height: 18px;
  margin-top: 2px;
}

.error-validation-input {
  outline: solid 1px #e63780;
  background-color: #fff;
}

@media (max-width: 800px) {
  .title-container {
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    padding-left: 50px;
  }

  .separator {
    width: 70%;
    margin: 19px 8.5px 53.5px 62px;
    background-color: var(--movistar-grey-5);
    height: 1px;
  }

  .separator-orange {
    width: 135%;
    margin: 18.5px 50px 53.5px 30px;
    border-radius: 1px;
    background-color: var(--movistar-orange);
    height: 2px;
  }

  .contact-preferences-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5px 20px 10px -30px;
  }

  .contact-info-container {
    position: relative;
    width: 90% !important;
    margin: 25px 10px 30px 0 !important;
    padding: 19px 19.5px 18px 14px;
    border-radius: 10px;
    background-color: #fff;
  }

  .info-container {
    margin: 25px 24.5px 50px 18px;
  }

  .info-text-container {
    margin-bottom: 30px;
  }

  .contact-options-container {
    position: relative;
    width: 90% !important;
    margin: 0px 20px 130px 80px;
  }

  .validation-error-container {
    justify-content: center;
  }

  .validations {
    left: 5%;
  }

  .save-button-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
</style>
