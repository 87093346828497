<template>
  <div class="conteudo">
    <MovistarMoneyLogo class="logo" />
    <h1 class="titulo">¡Reenvía el link del pago único fianza!</h1>
    <p class="descricao">Aquí puedes reenviar el link de pago.</p>
    <p class="descricao">
      Antes de empezar, ingresa el documento ó el número de celular con el que se realizó la solicitud.
    </p>
    <b-form @submit.prevent="enviarForm" class="formulario">
      <InputGroup
        :invalid="$v.form.document.$error"
        placeholder="Documento de identidad"
        :mask="['# ## ###', '# ### ###', '# ### ### ###', '## ### ####']"
        v-model="form.document"
        :messages="{ 'Documento inválido': $v.form.document.$error }"
        input-help-align="right"
      >
        <template #prepend>
          <IconeCredencial />
        </template>
      </InputGroup>

      <InputGroup
        :invalid="$v.form.mobile.$error"
        placeholder="Número de celular"
        mask="### ### ## ##"
        v-model="form.mobile"
        :messages="{ 'Número de celular inválido': $v.form.mobile.$error }"
        input-help-align="right"
      >
        <template #prepend>
          <IconeTelefone />
        </template>
      </InputGroup>

      <VueRecaptcha
        class="recaptcha"
        :loadRecaptchaScript="true"
        :sitekey="siteKey"
        @verify="onCaptchaVerified"
        @expired="onCaptchaExpired"
      />

      <ButtonContinue
        class="btn-continue"
        text="Continuar"
        :disable="!validRecaptcha || (!form.document && !form.mobile)"
      />
    </b-form>

    <ModalEnviarEmail
      id="modal-enviar-email"
      @novo_email="abrirModalNovoEmail"
      :email="email"
      :applicationId="applicationId"
      @continuar="emailEnviado"
    />
    <ModalConfirmarNovoEmail id="modal-confirmar-novo-email" :applicationId="applicationId" @continuar="emailEnviado" />
    <ModalConfirmarRecebimentoEmailFianca
      title="¿El cliente recibió el link en su correo?"
      redirect-page="ReenviarLinkFiancaAgradecimento"
      :send-gtm="false"
    />
  </div>
</template>

<script>
import { TOGGLE_OVERLAY } from '@/store/actions.type'
import api from '@/plugins/api'
import apiCaptcha from '@/plugins/apiCaptcha'

import VueRecaptcha from 'vue-recaptcha'
import MovistarMoneyLogo from '@/components/MovistarMoneyLogo.vue'
import InputGroup from '@/components/inputs/InputGroup.vue'
import ButtonContinue from '@/components/buttons/ButtonContinue.vue'

import ModalEnviarEmail from '@/components/modal/fianca/ModalEnviarEmail.vue'
import ModalConfirmarNovoEmail from '@/components/modal/fianca/ModalConfirmarNovoEmail.vue'

import IconeCredencial from '@/components/icones/IconeCredencial.vue'
import IconeTelefone from '@/components/icones/IconeTelefone.vue'
import { maxLength, minLength, requiredUnless } from 'vuelidate/lib/validators'
import ModalConfirmarRecebimentoEmailFianca from '@/components/fianca/modal/ModalConfirmarRecebimentoEmail.vue'

export default {
  components: {
    MovistarMoneyLogo,
    InputGroup,
    ButtonContinue,
    VueRecaptcha,
    ModalEnviarEmail,
    ModalConfirmarNovoEmail,
    ModalConfirmarRecebimentoEmailFianca,
    IconeCredencial,
    IconeTelefone,
  },
  data() {
    return {
      siteKey: process.env.VUE_APP_G_RECAPTCHA_SITE_KEY,
      validRecaptcha: false,
      form: {
        document: null,
        mobile: null,
      },
      email: '',
      applicationId: '',
    }
  },
  validations: {
    form: {
      mobile: {
        requiredIf: requiredUnless(function () {
          return this.form.document !== null
        }),
        min: minLength(13),
      },
      document: {
        requiredIf: requiredUnless(function () {
          return this.form.mobile !== null
        }),
        min: minLength(8),
        max: maxLength(19),
      },
    },
  },
  methods: {
    onCaptchaVerified(recaptchaToken) {
      apiCaptcha.validacaoCaptcha(recaptchaToken).then(() => {
        this.validRecaptcha = true
      })
    },
    onCaptchaExpired() {
      this.validRecaptcha = false
    },

    async enviarForm() {
      this.$v.form.$touch()
      if (this.$v.form.$error) return
      this.$store.dispatch(TOGGLE_OVERLAY, true)
      await api
        .getEmailReenvioLinkFianca({
          document: this.form.document?.replace(/ /g, ''),
          mobile: this.form.mobile?.replace(/ /g, ''),
        })
        .then((response) => {
          this.email = response.data.email
          this.applicationId = response.data.proposalId

          this.$bvModal.show('modal-enviar-email')
        })
        .catch((error) => {
          if (error.response.data.message === 'Pago fianza: Superaste el límite de reenvíos permitido.') {
            this.$router.push({ name: 'ManyAttemptsResendLinkFianca' })
          } else {
            this.$router.push({ name: 'NotFoundResendLink' })
          }
        })
        .finally(() => {
          this.$store.dispatch(TOGGLE_OVERLAY, false)
        })
    },
    abrirModalNovoEmail() {
      this.$bvModal.hide('modal-enviar-email')
      this.$bvModal.show('modal-confirmar-novo-email')
    },
    emailEnviado() {
      this.$bvModal.show('modal-confirmar-recebimento-email-fianca')
    },
  },
}
</script>

<style lang="scss" scoped>
.conteudo {
  background-image: url(@/assets/images/bg-anticiporesendlink.webp);
  background-size: 100% 100%;
  background-attachment: fixed;

  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  .logo {
    padding-top: 88px !important;
    padding-bottom: 54px !important;
  }

  .titulo {
    font-size: 22px;
    color: #019df4;
    margin-bottom: 23px;
  }

  .descricao {
    font-size: 18px;
    margin-bottom: 40px;
    max-width: 437px;
  }

  .form-input-error_right {
    margin-top: 3px;
    margin-left: auto;
    margin-right: 0;
    text-align: right;
    color: var(--movistar-pink);
    font-size: 12px;
  }

  .formulario {
    width: 300px;

    .input-group {
      margin-bottom: 1rem;
      &:nth-child(2) {
        margin-bottom: 2rem;
      }
    }

    .recaptcha {
      margin-bottom: 2rem;
    }

    .btn-continue {
      margin: 0 1rem;
    }
  }
}
</style>
