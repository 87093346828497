<template>
  <b-img src="../assets/images/stripes.png" alt="Stripes image" class="stripes-start" />
</template>

<script>
export default {
  name: 'StripesTopImage',
}
</script>

<style scoped>
.stripes-start {
  display: inline-block;
  position: absolute;
  z-index: 100;
  top: -9rem;
  left: -9rem;
}
</style>
