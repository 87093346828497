<template>
  <div class="bg-sm">
    <SMNavbar />
    <FastPaymentHeader />
    <FastPayment />
    <PaymentTypes />
    <hr class="no-margin" />
    <Footer />
  </div>
</template>

<script>
import SMNavbar from '@/components/SelfManage/SMNavbar.vue'
import FastPaymentHeader from '@/components/AcessoRapido/FastPaymentHeader.vue'
import FastPayment from '@/components/AcessoRapido/FastPayment.vue'
import Footer from '@/components/SelfManage/Footer.vue'
import PaymentTypes from '@/components/SelfManage/PaymentTypes.vue'

export default {
  name: 'PagamentoAcessoRapido',
  components: {
    FastPaymentHeader,
    SMNavbar,
    FastPayment,
    PaymentTypes,
    Footer,
  },
}
</script>
