<template>
  <PreValidacaoLayout circles-gray>
    <img src="@/assets/images/buscador-reprovacao.svg" alt="" />
    <b-card
      v-for="(message, index) in reprovedMessages"
      :key="index"
      class="card-reprovacao mt-4"
      border-variant="light"
    >
      <div>
        <p v-text="message.titulo" />
        <p v-text="message.mensagem" />
      </div>
      <b-img src="@/assets/icons/x-red.svg" />
    </b-card>

    <Button class="button-volver" outline @click="voltarPaginaAnterior()">
      <img class="arrow" src="@/assets/icons/arrow-blue.svg" width="16" />
      <span>Volver</span>
    </Button>
  </PreValidacaoLayout>
</template>

<script setup>
import { computed } from 'vue'
import PreValidacaoLayout from '@/components/PreValidacao/PreValidacaoLayout.vue'
import Button from '@/components/PreValidacao/Button.vue'

import store from '@/store'

const reprovedMessages = computed(() => {
  if (store.getters.buscadorReprovedMessages.length === 0) {
    return [
      {
        titulo: 'Rechazado',
      },
    ]
  } else return store.getters.buscadorReprovedMessages.slice(0, 4)
})

function voltarPaginaAnterior() {
  window.history.back()
}
</script>

<style scoped lang="scss">
.card-reprovacao {
  max-width: 41.25rem;
  background-color: #fbd9e7;
  border-radius: 10px;
  margin: 0 auto;
  margin-bottom: 1rem;
  p {
    margin-bottom: 0;
    text-align: left;
  }
  .card-body {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.button-volver .arrow {
  transform: rotate(90deg);
  margin-right: 0.75rem;
  margin-bottom: 0.2rem;
}
</style>
