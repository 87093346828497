import { render, staticRenderFns } from "./PagamentoInicial.vue?vue&type=template&id=c7b20886&scoped=true&"
import script from "./PagamentoInicial.vue?vue&type=script&setup=true&lang=js&"
export * from "./PagamentoInicial.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./PagamentoInicial.vue?vue&type=style&index=0&id=c7b20886&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c7b20886",
  null
  
)

export default component.exports