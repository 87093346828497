<template>
  <div class="container-sm-my-claim">
    <b-row class="my-claims-padding title-claims" align-v="end">
      <b-img left class="image-size" src="@/assets/icons/claims.svg" alt="Paper plane img" />
      <span class="mis-peticiones">Mis peticiones</span>
    </b-row>
    <b-row>
      <b-col class="line" cols="6">
        <b-row class="my-claim-padding border-row">
          <b-col cols="3" class="no-padding">
            <hr class="separator-orange" />
          </b-col>
          <b-col cols="9" class="no-padding">
            <hr class="separator" />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <div></div>
    <div v-if="!selfManageClaims" class="table-my-claims">
      <div class="texto-centro">Cargando...</div>
    </div>
    <div v-else-if="!selfManageClaims.done" class="table-my-claims">
      <div class="texto-centro">No se han encontrado peticiones.</div>
    </div>
    <div v-else-if="selfManageClaims.size === 0 && selfManageClaims.done" class="table-my-claims">
      <div class="texto-centro">No hay quejas registradas.</div>
    </div>
    <div v-else-if="selfManageClaims.size > 0" class="table-my-claims">
      <div class="claim-rect" v-for="(value, index) in selfManageClaims.claims" :key="index">
        <div class="claim-rect-resume" :id="'table-resume-' + value.protocol">
          <table width="100%" class="table-my-claim table-min-width" aria-describedby="table-sm-my-claims">
            <tr>
              <th class="tabela-header" width="70%">Asunto</th>
              <th class="tabela-header">Estado</th>
            </tr>
            <tbody>
              <tr>
                <td class="tabela-rows">{{ value.subject }}</td>
                <td class="tabela-body">{{ formatStatus(value.status) }}</td>
              </tr>
              <tr>
                <td class="tabela-header">
                  <p class="m-0 limit-line">{{ value.description }}</p>
                </td>
              </tr>
              <tr>
                <td class="tabela-header pt-3 pb-3">
                  Fecha de solicitud &nbsp; &nbsp;<b>{{ formatDate(value.createdDate) }}</b>
                </td>
              </tr>
            </tbody>
          </table>
          <table width="100%" class="table-my-claim table-max-width" aria-describedby="table-sm-my-claims">
            <tr>
              <th class="tabela-header">Asunto</th>
              <th class="tabela-header">Estado</th>
              <th class="tabela-header">Fecha de creación</th>
              <th class="tabela-header">Número de Caso</th>
            </tr>
            <tbody>
              <tr>
                <td class="tabela-body">{{ value.subject }}</td>
                <td class="tabela-body">{{ formatStatus(value.status) }}</td>
                <td class="tabela-body">{{ formatDate(value.createdDate) }}</td>
                <td class="tabela-body">{{ value.protocol }}</td>
              </tr>
            </tbody>
          </table>
          <div :id="'detail-btn-' + value.protocol" class="see-more-btn" @click="viewClaimDetail(value.protocol)">
            <img :id="'arrow-' + value.protocol" src="@/assets/icons/arrow-down-white.svg" class="arrow-down" />
          </div>
        </div>
        <div :id="value.protocol" class="padding-detail" hidden="true">
          <table width="100%" class="table-my-claim" aria-describedby="table-sm-my-claims">
            <tr>
              <th class="tabela-header">Número de caso</th>
              <th class="tabela-header">Fecha de cierre</th>
            </tr>
            <tbody>
              <tr>
                <td class="tabela-body">{{ value.protocol }}</td>
                <td class="tabela-body">{{ formatDate(value.closedDate) }}</td>
              </tr>
            </tbody>
          </table>
          <table width="100%" class="table-my-claim" aria-describedby="table-sm-my-claims">
            <tr>
              <th class="tabela-header">Descripción de la petición</th>
            </tr>
            <tbody>
              <tr>
                <td class="tabela-body">{{ formatText(value.description) }}</td>
              </tr>
            </tbody>
          </table>
          <table width="100%" class="table-my-claim" aria-describedby="table-sm-my-claims">
            <tr>
              <th class="tabela-header">Solución</th>
            </tr>
            <tbody>
              <tr>
                <td class="tabela-body">{{ formatText(value.solution) }}</td>
              </tr>
            </tbody>
          </table>
          <table width="100%" class="table-my-claim" aria-describedby="table-sm-my-claims">
            <tr>
              <th class="tabela-header-center">¿Tu caso fue resuelto satisfactoriamente?</th>
            </tr>
            <tbody>
              <tr>
                <td class="tabela-body-center">
                  <img
                    v-if="value.caseStatus === 1"
                    @click="setCaseSolved(value.protocol, -1)"
                    :id="'solved-' + value.protocol"
                    src="@/assets/icons/case-solved-blue.svg"
                    class="case-solved"
                  />
                  <img
                    v-if="value.caseStatus !== 1"
                    @click="setCaseSolved(value.protocol, 1)"
                    :id="'solved-' + value.protocol"
                    src="@/assets/icons/case-solved-gray.svg"
                    class="case-solved"
                  />&nbsp;&nbsp;
                  <img
                    v-if="value.caseStatus === 0"
                    @click="setCaseSolved(value.protocol, -1)"
                    :id="'not-solved-' + value.protocol"
                    src="@/assets/icons/case-not-solved-blue.svg"
                    class="case-solved"
                  />
                  <img
                    v-if="value.caseStatus !== 0"
                    @click="setCaseSolved(value.protocol, 0)"
                    :id="'not-solved-' + value.protocol"
                    src="@/assets/icons/case-not-solved-gray.svg"
                    class="case-solved"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import { SELF_MANAGE_POST_UPDATE_CASE_STATUS } from '../../store/actions.type'

export default {
  name: 'Claims',
  computed: {
    ...mapGetters({
      selfManageClaims: 'selfManageClaims',
      selfManageUpdateCaseStatus: 'selfManageUpdateCaseStatus',
    }),
  },
  methods: {
    viewClaimDetail(id) {
      document.getElementById(id).hidden = !document.getElementById(id).hidden
      document.getElementById('arrow-' + id).className = document.getElementById(id).hidden ? 'arrow-down' : 'arrow-up'
      document.getElementById('table-resume-' + id).className = document.getElementById(id).hidden
        ? 'claim-rect-resume'
        : 'claim-rect-resume border-bottom'
      document.getElementById('detail-btn-' + id).className = document.getElementById(id).hidden
        ? 'see-more-btn'
        : 'see-less-btn'
    },
    formatDate(value) {
      return !value ? '-' : moment(value).format('DD/MM/YYYY HH:mm')
    },
    async setCaseSolved(protocol, solved) {
      await this.$store.dispatch(SELF_MANAGE_POST_UPDATE_CASE_STATUS, { caseId: protocol, status: solved })
      const index = this.selfManageClaims.claims.findIndex((claim) => claim.protocol === protocol)
      this.selfManageClaims.claims[index].caseStatus = solved
    },
    formatStatus(value) {
      switch (value.trim()) {
        case 'New':
          return 'Nuevo'
        case 'Working':
          return 'Trabajando'
        case 'Escalated':
          return 'Escalado'
        case 'Closed':
          return 'Cerrado'
        case 'On Hold':
          return 'En espera'
      }
      return value
    },
    formatText(value) {
      return !value ? '-' : value
    },
    loaded() {
      return !!this.selfClaims.claims
    },
  },
}
</script>

<style scoped>
.limit-line {
  max-width: 25ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.container-sm-my-claim {
  margin: 70px 60px 20px 60px;
}

.title-claims {
  position: relative;
  top: -50px;
  margin-left: 10px;
}

.line {
  margin-left: 30px;
  margin-top: -10px;
  margin-bottom: 15px;
}

.border-row {
  position: relative;
  top: -50px;
}

.claim-rect {
  position: relative;
  top: -12px;
  margin-top: 10px;
  padding-right: 100px;
  padding-left: 100px;
  line-height: 1.6;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #d3d4d3;
  background-color: #fff;
}

.claim-rect-resume {
  position: relative;
  min-height: 120px;
  padding-top: 20px;
}

.table-resume {
  margin-top: 10px;
}

.see-more-btn:hover,
.see-less-btn:hover {
  cursor: pointer;
}

.case-solved {
  cursor: pointer;
}

.border-bottom {
  border-bottom: 1px solid #d3d4d3;
}

.padding-detail {
  margin-top: 20px;
  margin-bottom: 20px;
}

.texto-centro {
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  min-height: 120px;
  height: 100%;
  padding-top: 45px;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #d3d4d3;
  background-color: #fff;
  font-family: Telefonica;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #86888c;
}

.see-more-btn {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: -100px;
  max-width: 55px;
  max-height: 59px;
  min-width: 55px;
  min-height: 59px;
  border-radius: 40px 40px 10px 0px;
  background-color: var(--azure);
  z-index: 2;
}

.see-less-btn {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: -100px;
  max-width: 82px;
  max-height: 43px;
  min-width: 82px;
  min-height: 43px;
  border-radius: 40px 0px 10px 40px;
  background-color: var(--azure);
  z-index: 2;
}

img.arrow-down {
  margin-bottom: -32px;
}

img.arrow-up {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  margin-bottom: -16px;
  margin-right: -9px;
}

.list-claims {
  border-bottom: 1px solid #d3d4d3;
  align-items: center;
}

.table-my-claims {
  position: relative;
  top: -55px;
}

.table-my-claim {
  position: relative;
  border: none;
  table-layout: fixed;
}

td,
th {
  word-wrap: break-word;
}

@media (max-width: 500px) {
  .table-max-width {
    display: none;
  }
}
@media (min-width: 500px) {
  .table-min-width {
    display: none;
  }
}

.tabela-header {
  font-family: Telefonica;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #86888c;
}

.tabela-header-center {
  font-family: Telefonica;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0b2739;
}

.tabela-body {
  font-family: Telefonica;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: left;
  color: #50535a;
}

.tabela-rows {
  font-family: Telefonica;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #50535a;
}

.separator {
  background-color: var(--movistar-grey-5);
  height: 1px;
}

.separator-orange {
  background-color: var(--movistar-orange);
  height: 2px;
}

.image-size {
  height: 80px;
  border-color: #019df4;
}

.my-claims-padding {
  padding: 0 0px;
}

.my-claims-title {
  font: 300 14px Telefonica, sans-serif;
  color: var(--movistar-pine-green);
  text-align: left;
}

.mis-peticiones {
  font-family: Telefonica;
  font-size: 22px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--pine-green);
}

@media (max-width: 790px) {
  .container-sm-my-claim {
    margin: 70px 10px 20px 10px;
  }

  .mis-peticiones {
    font-size: 16px;
    color: var(--pine-green);
  }

  .line {
    margin-left: 18px;
    margin-top: -10px;
    margin-bottom: -5px;
  }

  .title-claims {
    position: relative;
    top: -20px;
    margin-left: 0px;
  }

  .image-size {
    width: 40px;
    height: 40px;
  }

  .border-row {
    position: relative;
    top: -20px;
  }
  .table-my-claims {
    position: relative;
    width: 100%;
    top: -20px;
  }
  .tabela-header {
    font-family: Telefonica;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #86888c;
  }

  .tabela-body {
    font-family: Telefonica;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    vertical-align: top;
    color: #50535a;
  }

  .claim-rect {
    position: relative;
    top: -12px;
    margin-top: 10px;
    padding-right: 10px;
    padding-left: 10px;
    line-height: 1.6;
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #d3d4d3;
    background-color: #fff;
  }

  .see-more-btn {
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: -10px;
    max-width: 30px;
    max-height: 40px;
    min-width: 30px;
    min-height: 40px;
    border-radius: 40px 40px 10px 0px;
    background-color: var(--azure);
    z-index: 2;
  }

  .see-less-btn {
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: -10px;
    max-width: 40px;
    max-height: 30px;
    min-width: 40px;
    min-height: 30px;
    border-radius: 40px 0px 10px 40px;
    background-color: var(--azure);
    z-index: 2;
  }

  img.arrow-down {
    position: absolute;
    right: 6px;
    bottom: 11px;
    width: 17px;
    height: 17px;
    margin-bottom: 0px;
  }

  img.arrow-up {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    position: absolute;
    right: 18px;
    bottom: 8px;
    width: 17px;
    height: 17px;
    margin-bottom: 0px;
  }
}
</style>
