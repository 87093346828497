<template>
  <div>
    <div class="container-assistido-info">
      <section>
        <h1 class="title-assistido-info">¡Ya casi esta!</h1>
      </section>
      <section>
        <div class="box-header-offer">
          <h1 class="title-header-offer">
            Para finalizar la transacción debes indicarle al cliente que firmará el contrato con Movistar Consumer
            Finance Colombia (Movistar Money) con las condiciones pactadas durante la venta:
          </h1>
        </div>
        <div class="list-number">
          <span class="number-in-list">1.</span>
          <p class="list-with-number">
            Comunícale las condiciones pactadas: Cuota, plazo {{ selectedOfferAssisted.installment }} meses.
          </p>
        </div>
        <div class="list-number">
          <span class="number-in-list">2.</span>
          <p class="list-with-number">Debes darle check al boton de “He leído y estoy de acuerdo con el contrato”.</p>
        </div>
        <div class="list-number">
          <span class="number-in-list">3.</span>
          <p class="list-with-number">
            Infórmale a tu cliente que se enviará una copia del contrato inmediatamente a su correo electrónico.
          </p>
        </div>
        <div class="list-number">
          <span class="number-in-list">4.</span>
          <p class="list-with-number">
            Infórmale que le llegará un código corto por mensaje de texto el cual te debe dictar como declaración de la
            aceptación del contrato de crédito.
          </p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      currentApplication: 'currentApplication',
      contract: 'contract',
      phoneDetailtUrl: 'phoneDetailtUrl',
      selectedOfferAssisted: 'selectedOfferAssisted',
    }),
  },
}
</script>

<style scoped>
.box-cancelar {
  display: flex;
  align-items: flex-start;
}

.title-header-offer {
  font-size: 16px;
  text-align: start;
  margin-bottom: 3rem;
}

.header-simulation {
  display: flex;
  width: 509px;
}

.container-assistido-info {
  width: 540px;
  height: 650px;
  border: solid 1px rgba(1, 157, 244, 0.6);
  border-radius: 10px;
  background-color: #f5f5f5;
  padding: 42px 45px 81.4px 27px;
}

.list-assistido-info-guia {
  margin-top: 1rem;
  padding-left: 30px;
}

.btn-simulation-box-first,
.btn-simulation-box-second {
  border: none;
  height: 40px;
  font-size: 16px;
  color: #50535a;
}

.title-assistido-info {
  font-size: 22px;
  margin: 0 10px 20px;
}

.content-assistido-info {
  font-size: 16px;
  width: 413px;
  height: 100%;
  text-align: start;
}

.content-assistido-info::marker {
  color: #019df4;
  font-size: 1rem;
}

.content-assistido-info-guia {
  font-size: 16px;
  width: 420px;
  height: 100%;
  text-align: start;
  margin-bottom: 8px;
}

.content-assistido-info-guia::marker {
  color: #019df4;
  font-size: 1.3rem;
}

.modificarEstiloBtn {
  background: #019df4;
  color: #ffffff;
}

.list-number {
  display: flex;
}

.number-in-list {
  margin-right: 14px;
  font-weight: bold;
}

.list-with-number {
  width: 430px;

  font-size: 15px;
  text-align: start;
  margin-bottom: 1rem;
}

.box-informacoes-assistido {
  margin: 5rem auto;
}
</style>
