import {
  TOGGLE_OVERLAY,
  ENVIAR_EMAIL_VALIDACAO,
  PRE_VALIDADOR_REQUEST_BASIC,
  PRE_VALIDADOR_REQUEST_FULL,
  VALIDAR_EMAIL_BUSCADOR,
  ENVIAR_PIN_BUSCADOR,
  VALIDAR_PIN_BUSCADOR,
} from '@/store/actions.type'
import {
  SET_HASH_VALIDACAO_EMAIL,
  SET_BUSCADOR_BASIC_DATA,
  SET_BUSCADOR_BASIC_DATA_REQUEST,
  SET_BUSCADOR_FULL_DATA,
  SET_BUSCADOR_PARCELA_SELECIONADA,
  SET_BUSCADOR_FULL_OPTIONS,
  SET_BUSCADOR_REPROVED_MESSAGES,
  SET_HASH_VALIDACAO_OTP,
  SET_QUERY_DATA,
} from '@/store/mutations.type'

import router from '@/router'
import apiBuscador from '@/services/buscador.service'
import { Persist } from '../helpers/persist.service'

import CryptoJS from 'crypto-js'

const state = {
  queryData: Persist.getpreValidacaoDataQuery(),
  buscadorFullOptions: {},
  hashValidacaoEmail: '',
  hashValidacaoOTP: '',
  buscadorBasicData: {},
  buscadorFullData: {},
  buscadorReprovedMessages: [],
  buscadorBasicDataRequest: {},
  buscadorParcelaSelecionada: null,
}

const mutations = {
  [SET_QUERY_DATA](state, queryData) {
    Persist.savepreValidacaoDataQuery(queryData)
    state.queryData = queryData
  },
  [SET_HASH_VALIDACAO_EMAIL](state, hashValidacaoEmail) {
    state.hashValidacaoEmail = hashValidacaoEmail
  },
  [SET_HASH_VALIDACAO_OTP](state, hashValidacaoOTP) {
    state.hashValidacaoOTP = hashValidacaoOTP
  },
  [SET_BUSCADOR_BASIC_DATA](state, buscadorBasicData) {
    state.buscadorBasicData = buscadorBasicData
  },
  [SET_BUSCADOR_BASIC_DATA_REQUEST](state, buscadorBasicData) {
    state.buscadorBasicDataRequest = buscadorBasicData
  },
  [SET_BUSCADOR_FULL_DATA](state, buscadorFullData) {
    state.buscadorFullData = buscadorFullData
  },
  [SET_BUSCADOR_FULL_OPTIONS](state, buscadorFullData) {
    const values = [3, 6, 12, 18, 24, 36]
    const base = [
      { text: 3, value: 3, disabled: true },
      { text: 6, value: 6, disabled: true },
      { text: 12, value: 12, disabled: true },
      { text: 18, value: 18, disabled: true },
      { text: 24, value: 24, disabled: true },
      { text: 36, value: 36, disabled: true },
    ]
    if (!buscadorFullData.offers) {
      state.buscadorFullOptions = {}
    } else {
      let newOptions = buscadorFullData.offers.ops[0]?.data?.offers.map((offer) => {
        if (values.includes(offer.installment)) {
          return {
            text: offer.installment,
            value: offer.installment,
            disabled: false,
          }
        }
      })
      newOptions = base.map((obj) => newOptions.find((o) => o.value === obj.value) || obj)
      state.buscadorFullOptions = newOptions
    }
  },
  [SET_BUSCADOR_PARCELA_SELECIONADA](state, buscadorParcelaSelecionada) {
    state.buscadorParcelaSelecionada = buscadorParcelaSelecionada
  },
  [SET_BUSCADOR_REPROVED_MESSAGES](state, buscadorReprovedMessages) {
    state.buscadorReprovedMessages = buscadorReprovedMessages
  },
}

const actions = {
  async [ENVIAR_EMAIL_VALIDACAO]({ commit, dispatch }, requestBody) {
    dispatch(TOGGLE_OVERLAY, true)
    await apiBuscador
      .postEnviarEmailValidacao(requestBody)
      .then((response) => {
        commit(SET_HASH_VALIDACAO_EMAIL, response.data.hash)
      })
      .finally(() => {
        dispatch(TOGGLE_OVERLAY, false)
      })
  },
  async [PRE_VALIDADOR_REQUEST_BASIC]({ commit, dispatch }) {
    dispatch(TOGGLE_OVERLAY, true)
    commit(SET_BUSCADOR_FULL_DATA, {})
    commit(SET_BUSCADOR_FULL_OPTIONS, {})
    commit(SET_BUSCADOR_PARCELA_SELECIONADA, null)
    let errorCode
    await apiBuscador
      .postPreValidadorRequestBasic(state.buscadorBasicDataRequest)
      .then(
        (response) => {
          commit(SET_BUSCADOR_BASIC_DATA, response.data)
          let channel = CryptoJS.AES.decrypt(
            state.buscadorBasicDataRequest.origin,
            process.env.VUE_APP_SECRET_SITE
          ).toString(CryptoJS.enc.Utf8)
          if (response.data.situation === 'reproved') {
            commit(SET_BUSCADOR_REPROVED_MESSAGES, response.data.messages)
            router.push({ name: 'PreValidacaoReprovacao' })
          } else if (channel === 'Libre' || channel === 'Finter') {
            router.push({ name: 'PreValidacaoResultado' })
          } else {
            router.push({ name: 'PreValidacaoResultadoAssistido' })
          }
        },
        (error) => {
          if (error.response.data.errorCode === 'Reprovada') {
            router.push({
              name: 'CantProcessRequest',
              params: {
                htmlMessage: error.response.data.message,
              },
            })
          }
          errorCode = error.response.data.message
        }
      )
      .finally(() => {
        dispatch(TOGGLE_OVERLAY, false)
      })
    if (errorCode) return Promise.reject(errorCode)
  },
  [PRE_VALIDADOR_REQUEST_FULL]({ commit, dispatch, state }, requestBody) {
    dispatch(TOGGLE_OVERLAY, true)
    apiBuscador
      .postPreValidadorRequestFull(requestBody)
      .then((response) => {
        commit(SET_BUSCADOR_FULL_DATA, response.data)
        if (response.data.situation === 'reproved') {
          commit(SET_BUSCADOR_REPROVED_MESSAGES, response.data.messages)
          router.push({ name: 'PreValidacaoReprovacao' })
        } else {
          commit(SET_BUSCADOR_FULL_OPTIONS, response.data)
          const lastOption = state.buscadorFullOptions.filter((option) => !option.disabled).at(-1)
          commit(
            SET_BUSCADOR_PARCELA_SELECIONADA,
            response.data.offers?.ops[0]?.data?.offers.filter((offer) => offer.installment === lastOption.value)[0]
          )
        }
      })
      .catch((error) => {
        if (error.response.data.errorCode === 'Reprovada') {
          router.push({
            name: 'CantProcessRequest',
            params: {
              htmlMessage: error.response.data.message,
            },
          })
        }
      })
      .finally(() => {
        dispatch(TOGGLE_OVERLAY, false)
      })
  },
  async [VALIDAR_EMAIL_BUSCADOR]({ commit, dispatch }, hashEmail) {
    dispatch(TOGGLE_OVERLAY, true)
    let errorCode
    await apiBuscador
      .validarEmail(hashEmail)
      .then(
        () => {},
        (error) => {
          errorCode = error.response.data.message
        }
      )
      .finally(() => {
        dispatch(TOGGLE_OVERLAY, false)
      })
    if (errorCode) return Promise.reject(errorCode)
  },
  async [ENVIAR_PIN_BUSCADOR]({ commit, dispatch }, requestBody) {
    dispatch(TOGGLE_OVERLAY, true)
    await apiBuscador
      .enviarNovoPin(requestBody)
      .then((response) => {
        commit(SET_HASH_VALIDACAO_OTP, response.data.hash)
      })
      .finally(() => {
        dispatch(TOGGLE_OVERLAY, false)
      })
  },
  async [VALIDAR_PIN_BUSCADOR]({ commit, dispatch, state }, pin) {
    dispatch(TOGGLE_OVERLAY, true)
    let response = null
    await apiBuscador
      .validarPin({
        hash: state.hashValidacaoOTP,
        pin: pin,
      })
      .then(
        () => {},
        (error) => {
          response = error.response.data
        }
      )
      .finally(() => {
        dispatch(TOGGLE_OVERLAY, false)
      })
    if (response?.pinError) return Promise.reject(response)
  },
}

const getters = {
  queryData: (state) => state.queryData,
  hashValidacaoEmail: (state) => state.hashValidacaoEmail,
  hashValidacaoOTP: (state) => state.hashValidacaoOTP,
  buscadorBasicData: (state) => state.buscadorBasicData,
  buscadorFullData: (state) => state.buscadorFullData,
  buscadorBasicDataRequest: (state) => state.buscadorBasicDataRequest,
  buscadorParcelaSelecionada: (state) => state.buscadorParcelaSelecionada,
  buscadorFullOptions: (state) => state.buscadorFullOptions,
  buscadorReprovedMessages: (state) => state.buscadorReprovedMessages,
}

export default {
  state,
  mutations,
  actions,
  getters,
}
