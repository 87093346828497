<template>
  <div class="container-counter-offer">
    <HeaderCounterOffer />
    <InputCounterOffer />
  </div>
</template>

<script>
import HeaderCounterOffer from '../../components/OfferSimulation/CounterOfferSimulation/HeaderCounterOffer.vue'
import InputCounterOffer from '../../components/OfferSimulation/CounterOfferSimulation/InputCounterOffer.vue'
export default {
  name: 'CounterOfferSimulation',
  components: { HeaderCounterOffer, InputCounterOffer },
}
</script>

<style scoped>
.container-counter-offer {
  padding: 15px 16px 30px 16px;
}
</style>
