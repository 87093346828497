<template>
  <div class="container-evidente-data">
    <div>
      <h1 class="header-title-date-assistido">
        Indícale al cliente que se realizará la validación de identidad a través de preguntas
      </h1>
    </div>

    <div>
      <h1 class="title-instruction">
        Antes de empezar esta validación ingresa la fecha de expedición del documento de identidad
      </h1>
      <form>
        <b-row align-h="center" class="mx-5 pt-4">
          <b-img src="@/assets/icons/calendar.svg" fluid alt="Movistar money logo" class="calendario-assistido" />
          <input
            id="date"
            name="date"
            type="date"
            v-model="date"
            :class="{ formInputError: $v.date.$error }"
            class="datepicker"
            placeholder="DD / MM / AAAA"
          />
          <p v-if="$v.date.$error" class="form-input-error no-margin">Esta fecha de expedición no es válida</p>
        </b-row>
      </form>
    </div>
    <div class="container-stepper-horizontal">
      <StepperVertical :step="step" />
    </div>
    <div class="container-btn">
      <ButtonContinue text="Confirmar" class="btn-assistido" @click="validate" :disable="validateDataInput" />
    </div>
  </div>
</template>

<script>
import StepperVertical from '../../StepperVertical.vue'
import ButtonContinue from '../../buttons/ButtonContinue.vue'
import { required } from 'vuelidate/lib/validators'
import { ACTION_WORKFLOW_STATUS_ASSISTED } from '../../../store/actions.type'
import router from '../../../router'
import store from '../../../store'

export default {
  components: { StepperVertical, ButtonContinue },
  data() {
    return {
      date: '',
      step: 2,
    }
  },
  validations: {
    date: { required },
  },
  methods: {
    validate() {
      if (this.$v.date.$error) return
      store
        .dispatch(ACTION_WORKFLOW_STATUS_ASSISTED, {
          action: 'listQuestions',
          isPartial: true,
          dateExpedition: this.date,
        })
        .then((response) => {
          if (response.response.Pregunta.length > 0) {
            router.push({ name: 'EvidenteValidarAssistido' })
          }
        })
    },
  },
  computed: {
    validateDataInput() {
      return this.date.length < 10
    },
  },
}
</script>

<style scoped>
.container-evidente-data {
  position: relative;
}

.container-stepper-horizontal {
  position: absolute;
  right: -8rem;
  top: 200px;
  margin-left: 100px;
}

.container-btn {
  display: flex;
  justify-content: center;
  margin-top: 5rem;
}

.btn-assistido {
  width: 278px;
  height: 42px;
  margin-top: 50px;
}

.datepicker {
  border: none;
  border-bottom: 1px solid var(--movistar-grey-3);
  color: var(--movistar-pine-green);
  text-align: center;
  width: 300px;
  height: 36.1px;
  padding: 0 10px;
}

.calendario-assistido {
  position: relative;
  left: 22px;
}

input[type='date']::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.header-title-date-assistido {
  width: 385px;
  height: 50px;
  margin: 157.7px 96px 70px 101px;
  font-family: Telefonica;
  font-size: 20px;
}

.title-instruction {
  width: 376px;
  height: 46px;
  margin: 0 auto 60.7px;
  font-family: Telefonica;
  font-size: 18px;
}

@media (max-width: 1380px) {
  .container-stepper-horizontal {
    position: absolute;
    right: -8rem;
    top: 200px;
    margin-left: 120px;
  }

  .container-btn {
    display: flex;
    justify-content: center;
    margin-top: 3rem;
  }
}
</style>
