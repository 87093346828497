<template>
  <div class="anticipo-assistido-guia">
    <b-card>
      <b-card-title>Indícale al cliente</b-card-title>
      <ol>
        <li>
          El sistema determinará si el cliente debe dar una cuota inicial obligatoria, te saldrá precargado el valor.
          <br />
          Si no se llena el campo con ningún valor el cliente puede elegir dar una cuota inicial voluntaria.
          <br />
          Si el cliente no quiere hacer pago de cuota inicial voluntario se deja vacio y avanza con el botón "continuar"
        </li>
      </ol>
    </b-card>
    <div class="btn-cancelar">
      <ButtonCancel text="Cancelar solicitud" @click="modalCancel" />
    </div>

    <ModalCancelarAssistido
      id="modal-basic"
      title="Estás a punto de cancelar tu propuesta"
      subtitle="¿Quieres continuar con esta acción?"
      image="congratulations.svg"
    />
  </div>
</template>

<script>
import ButtonCancel from '@/components/buttons/ButtonCancel.vue'
import ModalCancelarAssistido from '@/components/modal/ModalCancelarAssistido.vue'

export default {
  components: {
    ButtonCancel,
    ModalCancelarAssistido,
  },
  methods: {
    modalCancel() {
      this.$bvModal.show('modal-basic')
    },
  },
}
</script>

<style scoped lang="scss">
.anticipo-assistido-guia {
  margin: 0 4rem;
  .card {
    border: 1px solid #5cb615;
    border-radius: 10px;
    background-color: #f5f5f5;
    ol li {
      padding-left: 10px;
      text-align: left;
      &::marker {
        font-weight: bold;
      }
    }
  }
  .btn-cancelar {
    margin-top: 12rem;
  }
}
</style>
