<template>
  <iframe
    style="display: block; border: none; height: 100vh; width: 100vw"
    id="XpressID-iframe"
    allow="camera; microphone; geolocation;"
    :src="url"
    allowfullscreen
  >
  </iframe>
</template>

<script>
import { mapGetters } from 'vuex'
import { VERIDAS_CRIAR_TOKEN, VERIDAS_VALIDAR_ID, REDIRECT_USER, BUSCAR_PARAMETROS } from '../store/actions.type'

export default {
  name: 'Veridas',
  watch: {
    veridasAccessToken: {
      handler() {
        this.updateUrl()
      },
    },
  },
  data() {
    return {
      url: '',
      veridasUrl: '',
    }
  },
  created() {
    window.addEventListener('message', this.receiveMessage, false)
  },
  destroyed() {
    window.removeEventListener('message', this.receiveMessage)
  },
  async mounted() {
    this.veridasUrl = await this.$store.dispatch(BUSCAR_PARAMETROS, 'veridasXpressIdUrl')
    this.$store.dispatch(VERIDAS_CRIAR_TOKEN)
  },
  methods: {
    updateUrl() {
      this.url = this.veridasUrl.replace('%s', this.veridasAccessToken)
      console.log(this.url)
    },
    receiveMessage(event) {
      console.log('event: ', event)
      console.log('code: ', event.data.code)
      if (event.origin.includes('xpressid') && event.data.code == 'ProcessCompleted') {
        const validationId = event.data.additionalData.validationId
        this.$store.dispatch(VERIDAS_VALIDAR_ID, validationId)
      } else if (event.origin.includes('xpressid') && event.data.code == 'ProcessError') {
        this.$store.dispatch(REDIRECT_USER, 'documents')
      }
    },
  },
  computed: {
    ...mapGetters({
      veridasAccessToken: 'veridasAccessToken',
    }),
  },
}
</script>
