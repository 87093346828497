<template>
  <PreValidacaoLayout>
    <template #title>
      <h1 class="title-email">
        <span v-text="message"></span>
      </h1>
    </template>
    <a href="https://www.movistarmoney.co">
      <Button class="button-cerrar">
        <span>Cerrar</span>
        <img src="@/assets/icons/check.svg" class="arrow" width="15" />
      </Button>
    </a>
  </PreValidacaoLayout>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { useRoute } from 'vue-router/composables'
import PreValidacaoLayout from '@/components/PreValidacao/PreValidacaoLayout.vue'
import Button from '@/components/PreValidacao/Button.vue'

import store from '@/store'
import { VALIDAR_EMAIL_BUSCADOR } from '@/store/actions.type'

const messageCode = ref(null)
const message = computed(() => {
  if (messageCode?.value === null) {
    return 'Validando tu correo...'
  } else if (messageCode?.value === 'EXPIRED_VERIFICATION_URL') {
    return 'El enlace de verificación ha expirado'
  } else if (messageCode?.value === 'SUCCESS') {
    return 'Has aceptado los términos y condiciones de Movistar Money para la consulta de tu cupo en línea'
  }
  return 'Ha ocurrido un error al validar tu correo, por favor intenta de nuevo más tarde'
})

onMounted(() => {
  store
    .dispatch(VALIDAR_EMAIL_BUSCADOR, useRoute().params.hash)
    .then(() => {
      messageCode.value = 'SUCCESS'
    })
    .catch((message) => {
      messageCode.value = message
    })
})
</script>

<style scoped lang="scss">
.title-email {
  font-size: 2rem;
  font-weight: 200;
  color: #0b2739;
  margin-top: 2rem;
  margin-bottom: 0;
}
.button-cerrar {
  .arrow {
    margin-left: 12px;
  }
}
</style>
