<template>
  <div>
    <div class="floating-input" :class="{ error }">
      <label :for="id" v-text="label" />
      <input v-if="mask" :id="id" :value="value" @input="updateValue" v-mask="mask" />
      <input v-else :id="id" :value="value" @input="updateValue" />
      <slot v-if="icon" name="icon">
        <div class="icon"><img :src="icone" alt="" /></div>
      </slot>
    </div>
    <div v-if="error" class="error-message" v-text="errorMessage" />
  </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  id: {
    type: String,
    default: '',
  },
  label: {
    type: String,
    default: '',
  },
  value: {
    type: String,
    default: '',
  },
  mask: {
    default: null,
  },
  error: {
    type: Boolean,
    default: false,
  },
  errorMessage: {
    type: String,
    default: 'Error de validacion',
  },
  icon: {
    type: String,
    default: '',
  },
})

const emits = defineEmits(['input'])

const updateValue = (event) => {
  emits('input', event.target.value)
}
const icone = computed(() => {
  return require('@/assets/icons/' + props.icon)
})
</script>

<style scoped lang="scss">
.floating-input {
  border: solid 1px #86888c;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0.9rem;
  position: relative;
  &.error {
    border: solid 1px #ff0000;
  }
  &:focus-within {
    border: solid 1px #019df4;
  }
  label {
    margin: 0;
    text-align: left;
    font-size: 1.25rem;
    color: #019df4;
  }
  input {
    border: none;
    padding: 0;
    height: 100%;
    background-color: transparent;
    font-size: 1.25rem;
    &:focus {
      outline: none;
    }
  }

  .icon {
    position: absolute;
    right: 2rem;
    top: 1.5rem;
  }
}

.error-message {
  color: #ff0000;
  text-align: right;
}
</style>
