<template>
  <div>
    <div class="container-assistido-info">
      <section>
        <h1 class="title-assistido-info">Recuerda que</h1>
      </section>
      <section>
        <div class="list-number">
          <span class="number-in-list">1.</span>
          <p class="list-with-number">
            Esta es la oferta personalizada de tu cliente, debes recordarle cual es el equipo que esta financiando
          </p>
        </div>
        <div class="list-number">
          <span class="number-in-list">2.</span>
          <p class="list-with-number">
            Las opciones de cuotas a la que puede acceder este cliente son las sombreadas, indícale cuales son las
            posibilidades que tiene
          </p>
        </div>
        <div class="list-number">
          <span class="number-in-list">3.</span>
          <p class="list-with-number">Al dar clic en la caja de la cuota se calculará el valor de la cuota mensual</p>
        </div>
        <div class="list-number">
          <span class="number-in-list">4.</span>
          <p class="list-with-number">
            Al dar clic en el símbolo “v” se desplegará el detalle de la cuota. Indícale al cliente cada uno de los
            cargos
          </p>
        </div>
        <ul class="list-assistido-info-guia">
          <li class="content-assistido-info-guia">
            <span class="text-bold">Total cuota / mes: {{ selectedOfferAssisted.totalFmt }}</span>
            Valor mensual que deberá pagar cada mes, es un valor fijo durante la vigencia del crédito.
          </li>
          <li class="content-assistido-info-guia">
            <span class="text-bold">Intereses: </span>
            Es la porción mensual de intereses
          </li>
          <li class="content-assistido-info-guia">
            <span class="text-bold">IVA intereses: </span>
            Solo aplica para los equipos con IVA y es el 19% sobre el valor de intereses
          </li>
          <li class="content-assistido-info-guia">
            <span class="text-bold">Valor a financiar: </span>
            Valor del equipo
          </li>
          <li class="content-assistido-info-guia">
            <span class="text-bold">Tasa efectiva anual: </span>
            Tasa anual que aplica para el crédito
          </li>
          <li class="content-assistido-info-guia">
            <span class="text-bold">Tasa nominal mensual: </span>
            Tasa con la que se calculan los intereses mes a mes
          </li>
        </ul>
      </section>
    </div>
    <div class="box-cancelar">
      <ButtonCancel text="Cancelar solicitud" @click="modalCancel" />
    </div>

    <ModalCancelarAssistido
      id="modal-basic"
      title="Estás a punto de cancelar tu propuesta"
      subtitle="¿Quieres continuar con esta acción?"
      image="congratulations.svg"
    />
  </div>
</template>

<script>
import ButtonCancel from '@/components/buttons/ButtonCancel.vue'
import ModalCancelarAssistido from '@/components/modal/ModalCancelarAssistido.vue'
import { mapGetters } from 'vuex'

export default {
  components: { ButtonCancel, ModalCancelarAssistido },
  methods: {
    showModal(modalId) {
      this.$bvModal.show(modalId)
    },
    modalCancel() {
      this.showModal('modal-basic')
    },
  },
  computed: {
    ...mapGetters({
      selectedOfferAssisted: 'selectedOfferAssisted',
    }),
  },
}
</script>

<style scoped>
.header-simulation {
  display: flex;
  width: 509px;
}

.box-cancelar {
  display: flex;
  align-items: flex-start;
}

.container-assistido-info {
  width: 540px;
  height: 100%;
  max-height: 680px;
  border: solid 1px rgba(1, 157, 244, 0.6);
  border-radius: 10px;
  background-color: #f5f5f5;
  padding: 42px 45px 81.4px 27px;
}

.list-assistido-info-guia {
  margin-top: 1rem;
  padding-left: 30px;
}

.btn-simulation-box-first,
.btn-simulation-box-second {
  border: none;
  height: 40px;
  font-size: 16px;
  color: #50535a;
}

.title-assistido-info {
  font-size: 22px;
  margin: 0 10px 20px;
}

.content-assistido-info {
  font-size: 16px;
  width: 413px;
  height: 100%;
  text-align: start;
}

.content-assistido-info::marker {
  color: #019df4;
  font-size: 1rem;
}

.content-assistido-info-guia {
  font-size: 16px;
  width: 420px;
  height: 100%;
  text-align: start;
  margin-bottom: 8px;
}

.content-assistido-info-guia::marker {
  color: #019df4;
  font-size: 1.3rem;
}

.modificarEstiloBtn {
  background: #019df4;
  color: #ffffff;
}

.list-number {
  display: flex;
}

.number-in-list {
  margin-right: 14px;
  font-weight: bold;
}

.list-with-number {
  width: 430px;

  font-size: 15px;
  text-align: start;
  margin-bottom: 1rem;
}

.teste {
  font-weight: bold;
}

.box-informacoes-assistido {
  margin: 5rem auto;
}

@media (max-width: 1380px) {
  .box-cancelar {
    margin-left: 8rem;
    display: flex;
    align-items: flex-start;
  }

  .container-assistido-info {
    margin-left: 8rem;
    width: 540px;
    height: 100%;
    max-height: 680px;
    border: solid 1px rgba(1, 157, 244, 0.6);
    border-radius: 10px;
    background-color: #f5f5f5;
    padding: 42px 45px 81.4px 27px;
  }
}
</style>
