<template>
  <div class="container-guia">
    <h1>Recuerda que</h1>
    <ol>
      <li>Notifícale al cliente que se envío el link al correo que declaró</li>
      <li>Asegúrate de que el cliente haya recibido el link</li>
      <li>Si el link no llego al cliente da clic en “Volver a enviar link” puedes enviar hasta 3 veces el link</li>
      <li>
        Puedes ingresar otro correo y enviar el link ahí <br />
        Si el cliente recibe el link solo debes dar clic en “continuar”
      </li>
    </ol>

    <ul>
      <li>
        Confírmale a tu cliente que ha sido enviado el link de pago a su correo electrónico y deberá ejecutar el pago en
        máximo {{ validadeEmail }} horas
      </li>
      <li><b>¿El link de pago no ha llegado?</b></li>
    </ul>

    <MVButton label="Volver a enviar" variant="movistar-green" outline v-b-modal.modal-recado-email-fianca />

    <ModalRecadoEmailFianca id="modal-recado-email-fianca" @continuar="abrirModalEnviarEmail" />
    <ModalEnviarEmail
      id="modal-enviar-email"
      @novo_email="abrirModalNovoEmail"
      :email="currentApplication.email"
      :applicationId="currentApplication.applicationId"
    />
    <ModalConfirmarNovoEmail id="modal-confirmar-novo-email" :applicationId="currentApplication.applicationId" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

import MVButton from '@/components/movistar/MVButton.vue'
import ModalRecadoEmailFianca from '@/components/modal/fianca/ModalRecadoEmailFianca.vue'
import ModalEnviarEmail from '@/components/modal/fianca/ModalEnviarEmail.vue'
import ModalConfirmarNovoEmail from '@/components/modal/fianca/ModalConfirmarNovoEmail.vue'

export default {
  components: {
    MVButton,
    ModalRecadoEmailFianca,
    ModalEnviarEmail,
    ModalConfirmarNovoEmail,
  },
  data() {
    return {
      validadeEmail: process.env.VUE_APP_ANTECIPO_VALIDADE_EMAIL_HORAS,
    }
  },
  mounted() {
    if (this.currentApplication.guaranteePayment) this.$bvModal.show('modal-recado-email-fianca')
  },
  methods: {
    abrirModalEnviarEmail() {
      this.$bvModal.hide('modal-recado-email-fianca')
      this.$bvModal.show('modal-enviar-email')
    },
    abrirModalNovoEmail() {
      this.$bvModal.hide('modal-enviar-email')
      this.$bvModal.show('modal-confirmar-novo-email')
    },
  },
  computed: {
    ...mapGetters({
      currentApplication: 'currentApplication',
    }),
  },
}
</script>

<style scoped lang="scss">
.container-guia {
  width: 509px;
  border: 1px solid #5cb615;
  border-radius: 10px;
  background-color: #f5f5f5;
  margin-top: 12px;
  padding: 25px 21px 25px 45px;
  text-align: left;

  h1 {
    font-size: 20px;
    margin-bottom: 20px;
    text-align: center;
  }

  ol {
    text-align: left;
    padding: 0px;
    margin-bottom: 0.75rem;
    li {
      padding-left: 0.4rem;
      height: 3.75rem;
      &::marker {
        font-weight: bold;
      }
    }
  }

  ul {
    text-align: left;
    padding: 0px;
    margin-bottom: 0.75rem;
    li {
      &:nth-child(1) {
        padding-bottom: 0.75rem;
      }
      padding-left: 0.4rem;
      list-style-type: none;
      list-style-image: url('@/assets/icons/list-dot.svg');
      &::marker {
        font-weight: bold;
      }
    }
  }

  .btn {
    width: 50%;
  }
}
</style>
