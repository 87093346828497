<template>
  <div class="container-fluid bg-login">
    <b-container class="py-5">
      <b-row class="text-center py-3" align-h="center">
        <b-col cols="12" md="6" class="no-padding">
          <div class="card-wrapper-login">
            <MovistarMoneyLogoLogin />

            <p class="font-14 text-pine-green mb-4">
              Hemos enviado un código a tu celular, ingresa aquí los 4 dígitos del código:
            </p>

            <div class="card-inner">
              <OTPInput @password="updatePassword" :clearOtp="clearOtp" />

              <div class="py-5">
                <StepperLogin :step="1" />
              </div>

              <div class="py-4">
                <ButtonContinueLogin text="Continuar" :disable="disableButton" @click="submitOTP" />
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import MovistarMoneyLogoLogin from '../../components/SelfManage/MovistarMoneyLogoLogin'
import StepperLogin from '../../components/StepperLogin'
import ButtonContinueLogin from '../../components/buttons/ButtonContinueLogin'
import OTPInput from '../../components/OTPInput'
import store from '../../store'
import { RESET_AUTH_ERROR, SELF_MANAGE_LOGIN_OTP, TOGGLE_OVERLAY } from '../../store/actions.type'
import { mapGetters } from 'vuex'

export default {
  name: 'LoginOTP',
  components: { ButtonContinueLogin, StepperLogin, OTPInput, MovistarMoneyLogoLogin },
  data() {
    return {
      disableButton: true,
      clearOtp: 0,
      userAuth: {
        grant_type: 'password',
        username: '',
        password: '',
      },
    }
  },
  mounted() {
    store.dispatch(RESET_AUTH_ERROR)
  },
  validations: {
    userAuth: {
      password: { required },
    },
  },
  methods: {
    submitOTP() {
      if (this.disableButton) return
      this.$v.userAuth.$touch()
      if (this.$v.userAuth.$error) return
      this.userAuth.username = this.onboardLoginForm.identityCardNumber
      store.dispatch(TOGGLE_OVERLAY, true)
      store.dispatch(SELF_MANAGE_LOGIN_OTP, this.userAuth).then(() => {
        this.disableButton = true
        this.clearOtp += 1
      })
    },
    updatePassword(value) {
      this.userAuth.password = value
      this.disableButton = value.length <= 3
    },
  },
  computed: {
    ...mapGetters({
      onboardLoginForm: 'onboardLoginForm',
    }),
  },
}
</script>

<style scoped>
.card-inner {
  margin: 20px 35px;
}

.login-otp-icon {
  height: 20px;
}
</style>
'
