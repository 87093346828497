<template>
  <div>
    <b-row align-h="start" align-v="start" class="pt-4 ml-2 nowrap">
      <b-img
        v-if="pagamentoSelecionado !== 'liquidar'"
        src="../../../assets/icons/checkbox-unmarked.svg"
        alt="Contract icon"
        @click="selecionarPagamento('liquidar')"
      />
      <b-img
        v-else-if="pagamentoSelecionado === 'liquidar'"
        src="../../../assets/icons/checkbox.svg"
        alt="Contract icon"
      />
      <p
        v-b-modal.modal-terms-and-conditions
        @click="selecionarPagamento('liquidar')"
        class="no-margin pl-2 texto-pagamento"
      >
        Aquí puedes pagar el total pendiente de tu crédito
      </p>
    </b-row>
    <!-- LIQUIDAR EMPRESTIMO -->
    <div v-if="pagamentoSelecionado === 'liquidar'">
      <b-row class="pt-3" v-if="atrasado">
        <b-col cols="1">
          <img src="@/assets/icons/warning-yellow.svg" alt="" />
        </b-col>
        <b-col>
          <p class="ft-12">Tienes pagos pendientes, estos serán acumulados al total de tu pago de crédito</p>
        </b-col>
      </b-row>
      <b-row class="pt-3">
        <b-col class="text-right"><p>Pago total crédito</p></b-col>
        <b-col class="text-left"
          ><p>$ {{ valorLiquidacaoFormatado }}</p></b-col
        >
      </b-row>
      <b-row align-v="center" class="input-max-width">
        <b-col>
          <ButtonContinueBlue text="Continuar" @click="liquidar()" />
        </b-col>
      </b-row>
    </div>

    <b-row align-h="start" align-v="center" class="pt-4 ml-2">
      <b-img
        v-if="pagamentoSelecionado !== 'Abono a capital'"
        src="../../../assets/icons/checkbox-unmarked.svg"
        alt="Contract icon"
        @click="selecionarPagamento('Abono a capital')"
      />
      <b-img
        v-else-if="pagamentoSelecionado === 'Abono a capital'"
        src="../../../assets/icons/checkbox.svg"
        alt="Contract icon"
      />
      <p v-b-modal.modal-terms-and-conditions @click="selecionarPagamento('Abono a capital')" class="no-margin pl-2">
        Aquí puedes abonar a capital
      </p>
    </b-row>
    <!-- PAGAR ABONO -->
    <div v-if="pagamentoSelecionado === 'Abono a capital'" class="mt-3">
      <b-form v-if="!atrasado" @submit.prevent="emitPayment">
        <b-row align-v="center">
          <b-col class="pl-2 pr-2">
            <p class="texto-titulo-abono-capital">¿Cuánto es el monto que vas a adelantar?</p>
          </b-col>
        </b-row>

        <b-row align-v="center">
          <b-col>
            <p class="texto-body">
              Recuerda que lo que pagues aquí, se verá reflejado como abono para disminución de cuota o de plazo según
              tu elección.
            </p>
          </b-col>
        </b-row>

        <b-row align-v="center">
          <b-col align-v="center">
            <p class="texto-valor-pagar">
              <b-input-group class="input-max-width">
                <b-input-group-prepend class="prepend-icon">
                  <b-img src="@/assets/icons/cifrao.svg" alt="Dollar icon" />
                </b-input-group-prepend>
                <CurrencyInput v-model="value" />
              </b-input-group>
            </p>
            <p
              v-if="pagamento.valorPagar === null || pagamento.valorPagar < valorMinimo"
              class="form-input-error text-center"
            >
              Escribe el valor mayor que $ {{ valorMinimoFormatado }}
            </p>
            <p v-if="pagamento.valorPagar > saldoTotal" class="form-input-error text-center">
              No puede pagar más del monto total adeudado ({{ valorLiquidacaoFormatado }})
            </p>
          </b-col>
        </b-row>
        <p class="ft-16">"Quieres que el abono se vea reflejado como"</p>

        <div class="radio-custom" @click="changeCheckbox('Diminuir valor parcela')">
          <input
            v-model="pagamento.abono"
            value="Diminuir valor parcela"
            id="radio-1"
            name="valorParcela"
            type="radio"
          />
          <label for="radio-1" class="radio-label ft-14">
            Mismo plazo y disminución de cuota
            <b-img
              class="ml-2"
              src="@/assets/icons/aditional-info.svg"
              alt="Aditional info"
              v-b-tooltip.hover.topleft="{
                title: `El valor de tu cuota disminuirá y el plazo seguirá siendo el mismo`,
              }"
            />
          </label>
        </div>

        <div class="radio-custom" @click="changeCheckbox('Diminuir quantidade parcelas')">
          <input
            v-model="pagamento.abono"
            value="Diminuir quantidade parcelas"
            id="radio-2"
            name="qtdParcela"
            type="radio"
          />
          <label for="radio-2" class="radio-label ft-14">
            Disminución de plazo y misma cuota
            <b-img
              class="ml-2"
              src="@/assets/icons/aditional-info.svg"
              alt="Aditional info"
              v-b-tooltip.hover.topleft="{
                title: `El número de cuotas disminuirá, manteniendo el mismo valor de cada cuota`,
              }"
            />
          </label>
        </div>

        <br />
        <p class="texto-body mb-3">
          Si tienes valores pendientes por pagar, estos serán cubiertos primero y el restante se abonará de acuerdo a la
          opción que elijas
        </p>
        <b-row align-v="center" class="input-max-width">
          <b-col>
            <ButtonContinueBlue text="Continuar" :desabilitar="desabilitar" />
          </b-col>
        </b-row>
      </b-form>
      <div v-if="atrasado">
        <b-row>
          <b-col cols="1">
            <img src="@/assets/icons/warning-yellow.svg" alt="" />
          </b-col>
          <b-col>
            <p class="ft-12">
              Tienes pagos pendientes, debes pagar tus cuotas atrasadas para poder hacer abonos a capital
            </p>
          </b-col>
        </b-row>
        <b-row align-v="center" class="input-max-width-2">
          <b-col>
            <ButtonContinueBlue text="Pagar cuotas atrasadas" @click="emitPagarAtrasados()" />
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonContinueBlue from '@/components/buttons/ButtonContinueBlue.vue'
import CurrencyInput from '@/components/inputs/CurrencyInput.vue'
import { Persist } from '@/helpers/persist.service'
import { SELF_MANAGE_GET_APPLICATION_BALANCE } from '@/store/actions.type'
import store from '@/store'
import { mapGetters } from 'vuex'

export default {
  name: 'PagarAbono',
  components: { ButtonContinueBlue, CurrencyInput },
  props: {
    keyMambu: String,
    planoPagamento: {},
    faturaMovistar: Boolean,
    meioPagamento: String,
  },

  data() {
    let selectedCheckbox = Persist.getDecreasePayment()
    if (selectedCheckbox == '') {
      selectedCheckbox = 'Diminuir valor parcela'
    }
    return {
      pagamentoSelecionado: '',
      value: null,
      valorMinimo: 0,
      pagamento: {
        keyMambu: '',
        valorPagar: null,
        tipoPagamento: 'Abono a capital',
        abono: selectedCheckbox,
        descricao: '',
        processamentoBackend: '',
        meioPagamento: 'Efectivo',
      },
      primeiraParcela: this.planoPagamento.ops[0].data.accountInstallments[0].total_expected,
    }
  },
  mounted() {
    store.dispatch(SELF_MANAGE_GET_APPLICATION_BALANCE, this.keyMambu)
    this.pagamento.meioPagamento = this.meioPagamento
    this.calcularValorMinimo()
  },
  methods: {
    liquidar() {
      let pagamento = {
        keyMambu: this.keyMambu,
        valorPagar: this.saldoTotal,
        tipoPagamento: 'Pagar total',
        abono: '',
        descricao: '',
        processamentoBackend: 'Pagar total',
        meioPagamento: 'Efectivo',
      }
      this.$emit('pagar', pagamento)
    },
    selecionarPagamento(value) {
      this.pagamentoSelecionado = value
    },
    calcularValorMinimo() {
      let soma = 0
      this.planoPagamento.ops[0].data.accountInstallments
        .filter((p) => p.status === ('PENDING' || 'PARTIALLY_PAID'))
        .slice(0, 2)
        .map((p) => (soma += p.total_due))
      this.valorMinimo = soma
    },
    emitPagarAtrasados() {
      if (this.faturaMovistar) {
        window.location =
          'https://movistar.recaudo.epayco.co/?_ga=2.10144117.1301339125.1632781748-264939336.1631815530&tipo_pago=movistar&tipo_servicio=movil'
      }
      this.$emit('pagarAtrasados')
    },
    prePagamnto() {
      this.pagamento.keyMambu = this.keyMambu
      this.pagamento.processamentoBackend = this.pagamento.tipoPagamento + ' - ' + this.pagamento.abono
      this.pagamento.descricao = this.pagamento.tipoPagamento
    },
    async emitPayment() {
      if (this.pagamento.valorPagar === this.saldoTotal) {
        this.liquidar()
      } else {
        this.prePagamnto()
        this.$emit('pagar', this.pagamento)
      }
    },
    verificaValorPagar() {
      const valorPendente = this.planoPagamento.globalData.lastDueValue

      if (this.pagamento.abono === 'Diminuir valor parcela' && this.pagamento.valorPagar >= valorPendente) return false
      if (this.pagamento.abono === 'Diminuir quantidade parcelas' && this.pagamento.valorPagar >= valorPendente)
        return false

      return true
    },
    changeCheckbox(state) {
      Persist.saveDecreasePayment(state)
    },
  },
  computed: {
    ...mapGetters({
      saldoTotal: 'totalBalance',
    }),
    valorMinimoFormatado() {
      let valorFormatado = Intl.NumberFormat('es-CO')
      return valorFormatado.format(this.valorMinimo)
    },
    valorLiquidacaoFormatado() {
      let valorFormatado = Intl.NumberFormat('es-CO')
      return valorFormatado.format(this.saldoTotal)
    },
    atrasado() {
      if (this.planoPagamento.globalData.lastDueDate === 'Inmediato') {
        return true
      }
      return false
    },
    desabilitar() {
      if (
        this.pagamento.valorPagar === null ||
        this.pagamento.valorPagar < this.valorMinimo ||
        this.pagamento.valorPagar > this.saldoTotal
      ) {
        return true
      }
      if (this.verificaValorPagar()) {
        return true
      }
      return false
    },
    optionsCurrency() {
      return {
        currency: null,
        distractionFree: {
          hideNegligibleDecimalDigits: false,
          hideCurrencySymbol: true,
          hideGroupingSymbol: false,
        },
        autoDecimalMode: true,
      }
    },
  },
  watch: {
    value() {
      this.pagamento.valorPagar = this.value
    },
  },
}
</script>

<style scoped>
.nowrap {
  flex-wrap: nowrap;
}
.texto-pagamento {
  word-wrap: normal;
  text-align: left;
}
.ft-16 {
  font-family: Telefonica;
  font-size: 16px;
}
.ft-14 {
  font-family: Telefonica;
  font-size: 14px;
}
.ft-12 {
  font-family: Telefonica;
  font-size: 12px;
}
.texto-valor-pagar {
  font-family: Telefonica, sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--movistar-pine-green);
}

.valor-pendiente-de-pago {
  font-family: Telefonica, sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #50535a;
}

.texto-body {
  font-family: Telefonica, sans-serif;
  font-size: 12px;
  text-align: center;
  color: #50535a;
}

.contorno-botao {
  border-radius: 18px;
}

.texto-titulo-abono-capital {
  font-family: Telefonica;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--movistar-pine-green);
}

.prepend-icon {
  background-color: var(--white);
}

.input-max-width {
  display: flex;
  margin: auto;
  width: 170px;
}
.input-max-width-2 {
  display: flex;
  margin: auto;
  width: 270px;
}

.payment-type-text {
  font: 14px Telefonica, sans-serif;
  color: var(--movistar-grey-2);
  text-align: left;
}
</style>
