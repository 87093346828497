<template>
  <div v-if="offerAssisted && offerAssisted.length > 0">
    <div class="container-assisted-phone-details">
      <section class="header-mais-informacoes">
        <p class="titulo-mais-informacoes">Valor solicitado</p>
        <p class="valor-mais-informacoes">{{ valorSolicitadoFmt }}</p>
      </section>

      <b-row align-h="center" align-v="center" class="mb-1 mt-1">
        <b-img src="@/assets/icons/money.svg" alt="Money icon" />
        <b-card-text class="choose-quotas">Elige tus cuotas</b-card-text>
      </b-row>

      <b-card-text class="offer-subtitle">Puedes simular varias veces la cuota del crédito</b-card-text>

      <div class="simulador-calculadora">
        <section v-for="button of objectButton" :key="button.value">
          <button
            class="btn-parcel"
            @click="changeValue(button)"
            :class="{ btnVerificado: button.verified, disabledButton: button.disabled }"
            :disabled="button.disabled"
          >
            {{ button.value }}
          </button>
        </section>
      </div>

      <div class="more-information">
        <section class="container-more-information container-total-cueta padding-header">
          <h1 class="content-total-cueta">Total cuota / mes</h1>
          <span class="value-total-cueta"
            >{{ footerOffer.totalFmt }}
            <p class="legend-con-iva" v-if="footerOffer.feesDue > 0">Con iva</p></span
          >
        </section>

        <section class="container-more-information">
          <h1 class="legend-value">Cuota capital</h1>
          <span class="content-value legend-value">{{ footerOffer.principalDueFmt }}</span>
        </section>

        <section class="container-more-information">
          <h1 class="legend-value">Intereses</h1>
          <span class="content-value legend-value">{{ footerOffer.interestDueFmt }} </span>
        </section>

        <section class="container-more-information">
          <h1 class="legend-value">IVA intereses</h1>
          <span class="content-value legend-value">{{ footerOffer.taxInterestDueFmt }} </span>
        </section>
        <section class="container-more-information">
          <h1 class="legend-value">IVA cargo administrativo</h1>
          <span class="content-value legend-value">{{ footerOffer.taxFeesDueFmt }}</span>
        </section>

        <section class="container-more-information">
          <h1 class="legend-value">Total cuota</h1>
          <span class="content-value legend-value">{{ footerOffer.totalFmt }}</span>
        </section>
      </div>

      <div class="segunda-mais-informacao">
        <section class="container-more-information">
          <h1 class="legend-value">Valor a financiar</h1>
          <span class="content-value legend-value">{{ valorSolicitadoFmt }}</span>
        </section>

        <section class="container-more-information">
          <h1 class="legend-value">Tasa efectiva anual (TEA)</h1>
          <span class="content-value legend-value">{{ footerOffer.interestCETYearlyFmt }}</span>
        </section>
      </div>

      <section>
        <p class="footer-legenda">*Este es un valor aproximado de la composición de la cuota</p>
      </section>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'DetalhesTelefoneAssistido',
  props: {
    offerAssisted: [],
    requestedValue: {},
  },
  data() {
    return {
      editarCota: false,
      footerOffer: {},
      objectButton: [
        {
          value: 3,
          verified: false,
          disabled: true,
        },
        {
          value: 6,
          verified: false,
          disabled: true,
        },
        {
          value: 12,
          verified: false,
          disabled: true,
        },
        {
          value: 18,
          verified: false,
          disabled: true,
        },
        {
          value: 24,
          verified: false,
          disabled: true,
        },
        {
          value: 36,
          verified: false,
          disabled: true,
        },
      ],
    }
  },
  computed: {
    valorSolicitadoFmt() {
      let valorFormatado = Intl.NumberFormat('es-CO')
      return '$ ' + valorFormatado.format(this.requestedValue)
    },
  },
  watch: {
    offerAssisted(value) {
      if (value) {
        this.checkButtons()
      }
    },
  },
  methods: {
    checkButtons() {
      this.objectButton.map((objButton, index) => {
        this.offerAssisted?.map((singleOffer) => {
          if (singleOffer.installment == objButton.value) {
            this.changeValue(this.objectButton[index])
            this.objectButton[index].disabled = false
          }
        })
      })
    },
    changeValue(btnObject) {
      this.objectButton.map((button, index) => {
        if (button.value == btnObject.value) {
          this.objectButton[index].verified = true
          this.offerAssisted?.map((offer) => {
            if (offer.installment == button.value) {
              this.footerOffer = offer
            }
          })
        }
        if (button.value !== btnObject.value) {
          this.objectButton[index].verified = false
        }
      })
    },
  },
}
</script>

<style scoped>
.footer-informacoes-assistido {
  width: 308px;
  height: 40px;
  border-radius: 40px;
  background-color: #5cb630;
  border: none;
  color: #ffffff;
  font-weight: bold;
}

.header-box-information {
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid #d3d4d3;
  margin-bottom: 1rem;
}

.phone-svg-assistido {
  color: #ffffff;
  margin-top: 3px;
}

.title-header-assistido {
  color: #ffffff;
  margin-bottom: 0px;
  font-size: 19px;
}

.title-in-headear-asssistido {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
  top: -45px;
}

.header-svg-assistido {
  position: absolute;
  top: -10px;
  left: 22px;
}

.box-informacoes-assistido {
  position: relative;
  width: 340px;
  height: 170px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  padding: 37.4px 12.7px 23.6px 12.5px;
  background: #ffffff;
}

.value-total-cueta {
  font-weight: bold;
  font-size: 1rem;
}
.titulo-cifrao-assistido {
  font-size: 16px;
  color: #0b2739;
  font-weight: bold;
  margin-left: 1rem;
  margin-bottom: 0px;
}

.valor-mais-informacoes {
  font-weight: bold;
  color: #fff;
  font-size: 16px;
  margin-bottom: 0px;
}

.header-mais-informacoes {
  background-color: #019df4;
  border-radius: 10px 10px 0 0;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 30px;
  position: relative;
  top: -20px;
  width: 100%;
}

.valor-monetario {
  font-weight: bold;
  border-left: 2px solid #d3d4d3;
  padding-left: 1rem;
}

.legend-value {
  font-size: 14px;
  color: #86888c;
}

.more-information {
  display: flex;
  flex-direction: column;
}

.btn-parcel {
  width: 50px;
  height: 35px;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  border: none;
  text-align: center;
  color: #86888c;
}

.container-more-information {
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
  height: 17px;
}
.container-total-cueta {
  margin-bottom: 7px;
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.simulador-calculadora {
  height: 70px;
  flex-direction: row;
  border-bottom: 1px solid #d3d4d3;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.choose-quotas {
  margin: 0 0 1.6px 9.6px;
  font-weight: bold;
}

.offer-subtitle {
  font-size: 0.86rem;
  color: #019df4;
}

.container-assisted-phone-details {
  box-shadow: 0 3px 6px #00000029;
  border-radius: 10px;
  width: 340px;
  background-color: #fff;
}

.btnVerificado {
  background: #019df4;
  color: #fff;
  font-weight: bold;
}
.disabledButton {
  background-color: #b6b9bf;
  color: #ffffff;
}

.content-total-cueta {
  font-size: 1rem;
  color: #86888c;
  font-weight: bold;
  margin-top: 8px;
}

.segunda-mais-informacao {
  margin-top: 1.5rem;
}

.container-more-information {
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
  height: 17px;
}

.footer-legenda {
  color: #86888c;
  font-size: 12px;
  margin-top: 14px;
}

.legend-con-iva {
  font-size: 14px;
  margin-bottom: 0px;
  position: relative;
  top: 5px;
  margin-left: 5px;
}

.titulo-mais-informacoes {
  font-size: 17px;
  color: #fff;
  margin-bottom: 0px;
}
.arrow-blue-assistido {
  position: relative;
  top: 1px;
  cursor: pointer;
}
.padding-header {
  padding: 1rem;
}
</style>
