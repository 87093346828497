<template>
  <div class="container-info-cuotas">
    <div class="container-about-coutas">
      <p class="info-about-coutas">*Te llamaremos para confirmar tu desembolso</p>
    </div>
    <div class="container-offer">
      <div
        class="container-list-cuotas"
        v-for="(offer, index) in arrayOffers"
        :key="offer.principalDue"
        @click="handleSelectOffer(index)"
      >
        <div class="container-coutas-offer">
          <div class="container-cuotas" :class="{ isActive: offer.isActive }">
            <section class="container-value-cuotas">
              <img
                src="@/assets/icons/orange-right.svg"
                alt="heade-image"
                class="img-orange-right"
                v-if="offer.isActive"
              />
              <h1 class="text-value-cuotas">{{ offer.installment }}</h1>
              <p class="text-cuotas">cuotas</p>
            </section>
            <section class="content-value-total">
              <h3 class="value-total">Valor total</h3>
              <div class="container-value-moth">
                <h3 class="for-moth" :class="{ fontValueMoth: offer.isActive }">{{ offer.totalFmt }}/Mes</h3>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
    <b-row>
      <FooterOfferSimulation v-bind:footerArrayOffers="mutablefooterArrayProps" />
    </b-row>
  </div>
</template>

<script>
import FooterOfferSimulation from './FooterOfferSimulation.vue'
import { MUTATION_SELECT_OFFER } from '../../store/mutations.type'
import { gtmDataLayerCustom } from '../../helpers/gtmHelper'
import { mapMutations, mapGetters } from 'vuex'
export default {
  components: { FooterOfferSimulation },
  data: function () {
    return {
      footerArrayProps: {},
      mutablefooterArrayProps: this.footerArrayProps,
    }
  },
  props: {
    arrayOffers: Array,
  },
  watch: {
    arrayOffers: {
      handler() {
        if (this.arrayOffers && this.arrayOffers.length > 0) {
          const lastIndex = this.arrayOffers.length - 1
          this.handleSelectOffer(lastIndex)
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      currentApplication: 'currentApplication',
    }),
  },
  methods: {
    ...mapMutations([MUTATION_SELECT_OFFER]),
    handleSelectOffer(event) {
      this.arrayOffers.map((arrayOffer, index) => {
        if (event === index) {
          this.arrayOffers[index].isActive = true
          this.mutablefooterArrayProps = Object.assign({}, this.arrayOffers[index])
          this[MUTATION_SELECT_OFFER](this.mutablefooterArrayProps)
        } else {
          this.arrayOffers[index].isActive = false
        }
      })
      gtmDataLayerCustom(window.dataLayer, {
        event: 'trackEvent',
        eventCategory: 'movistar money web',
        eventAction: 'click:: inicio',
        eventLabel: `simulador préstamo selección cuota ${this.arrayOffers[event].installment}`,
      })
    },
  },
}
</script>

<style scoped>
.container-offer {
  margin-bottom: 1rem;
}

.footer-offer {
  position: fixed;
  bottom: 0;
  left: 0;
}
.container-info-cuotas {
  height: 100%;
}

.img-orange-right {
  position: absolute;
  left: 16%;
  width: 20px;
  height: 20px;
}

.container-coutas-offer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container-cuotas:hover {
  cursor: pointer;
}

.isActive {
  border: solid 2px #ec624b;
  color: #019df4;
}

.fontValueMoth {
  color: #019df4;
}

.container-cuotas {
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 16px;
  width: 240px;
  height: 48px;
  border-radius: 8px;
  margin-top: 20px;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.3);
}

.container-value-cuotas {
  display: flex;
  align-items: center;
}

.text-value-cuotas {
  display: flex;
  font-size: 24px;
  align-items: center;
  margin: 0;
  margin-right: 5px;
}

.text-cuotas {
  font-size: 18px;
  margin: 0;
}

.content-value-total {
  width: 104px;
}

.value-total {
  font-size: 12px;
  margin: 0;
  text-align: start;
  line-height: 1.67;
  font-family: Telefonica;
}

.container-value-moth {
  display: flex;
  font-family: Telefonica;
  font-size: 14px;
  font-weight: 300;
  color: #50535a;
}

.value-moth {
  font-size: 12px;
  margin: 0;
  color: #50535a;
}

.for-moth {
  font-size: 12px;
  margin: 0;
}

.info-about-coutas {
  font-size: 12px;
  height: 35px;
  padding-top: 6px;
  color: #50535a;
}
.container-about-coutas {
  height: 35px;
  display: flex;
  justify-content: center;
  align-content: center;
  -webkit-backdrop-filter: blur(13.4px);
  backdrop-filter: blur(13.4px);
  background-image: linear-gradient(274deg, rgba(11, 39, 57, 0.1), rgba(182, 183, 183, 0.1));
}
.container-list-cuotas {
  position: relative;
  top: -42px;
  margin-top: 1.1rem;
}

@media (max-width: 359px) {
  .container-list-cuotas {
    top: -34px;
  }
  .img-orange-right {
    left: 10%;
  }
}

@media (min-width: 359px) and (max-width: 400px) {
  .container-list-cuotas {
    top: -32px;
  }
  .img-orange-right {
    left: 15%;
  }
}

@media (min-width: 400px) {
  .container-list-cuotas {
    top: -40px;
  }
  .img-orange-right {
    left: 19%;
  }
}

@media (max-width: 400px) {
  .info-about-coutas {
    font-size: 12px;
  }
}

@media (max-height: 580px) {
  .container-cuotas {
    background: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 22px;
    width: 240px;
    height: 40px;
    border-radius: 8px;
    margin-top: 20px;
  }
  .container-list-cuotas {
    position: relative;
    top: -12px;
    margin-top: 1px;
  }
}
@media (max-height: 660px) {
  .container-cuotas {
    background: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 22px;
    width: 240px;
    height: 50px;
    border-radius: 8px;
    margin-top: 12px;
  }
}
</style>
