<template>
  <div class="container-btn-cancell">
    <button class="continue-button continue-button-text" @click="emitClick" :disabled="desabilitar">
      <span>{{ text }}</span>
    </button>
    <div>
      <span id="disabled-wrapper" class="d-inline-block" tabindex="0">
        <b-avatar button variant="secondary" style="pointer-events: none" disable class="box-tooltip">!</b-avatar>
      </span>
      <b-tooltip variant="secondary" target="disabled-wrapper" class="text-tooltip">
        Dando clic aquí cancelas la solicitud de crédito, pero no se cancela la orden del equipo.
      </b-tooltip>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ButtonContinueBlue',
  props: {
    text: String,
    desabilitar: Boolean,
  },
  methods: {
    emitClick() {
      this.$emit('click')
    },
  },
}
</script>

<style scoped>
.box-tooltip {
  margin-top: 1rem;
}

.text-tooltip {
  color: #313235;
}

.continue-button {
  width: 100%;
  height: 49px;
  margin: 20px 9px 0 0;
  padding: 15px 39px 14px;
  border-radius: 40px;
  border: solid 1px #5cb630;
  background: none;
}
.continue-button:disabled {
  height: 36px;
  background-color: rgba(83, 80, 80, 0.5);
  cursor: default;
}
.continue-button-text {
  color: #5cb630;
}

.container-btn-cancell {
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
</style>
