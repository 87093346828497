import store from '../store'

export function gtmErrorTags(gtm, e, eCategory, eAction, eLabel, userId) {
  try {
    window.google_tag_manager[process.env.VUE_APP_TAGMANAGER].dataLayer.reset()
    gtm.push({
      eventChannel: store.getters.channelOrigin,
      entorno: process.env.VUE_APP_TAGMANAGER_ENTORNO,
      event: e,
      eventCategory: eCategory,
      eventAction: eAction,
      eventLabel: eLabel,
      userIdMoney: userId,
    })
  } catch (error) {
    console.log(error)
  }
}

export function gtmDataLayerCheckout(dataLayer, e, name, id, price, brand, eCategory, variant, step, userId) {
  try {
    if (store.getters.channelOrigin == 'Finter')
      window.google_tag_manager[process.env.VUE_APP_TAGMANAGER].dataLayer.reset()
    dataLayer.push({
      eventChannel: store.getters.channelOrigin,
      entorno: process.env.VUE_APP_TAGMANAGER_ENTORNO,
      event: e,
      eventCategory: eCategory,
      productos: [
        {
          name: name,
          id: id,
          price: price,
          brand: brand,
          category: eCategory,
          variant: variant,
        },
      ],
      step: step,
    })
  } catch (error) {
    console.log(error)
  }
}

export function gtmDataLayerCustom(dataLayer, data) {
  try {
    window.google_tag_manager[process.env.VUE_APP_TAGMANAGER].dataLayer.reset()
    dataLayer.push(data)
  } catch (error) {
    console.log(error)
  }
}

export function gtmDataLayerTrackEvent(dataLayer, e, eCategory, eAction, eLabel, userId) {
  try {
    window.google_tag_manager[process.env.VUE_APP_TAGMANAGER].dataLayer.reset()
    dataLayer.push({
      eventChannel: store.getters.channelOrigin,
      entorno: process.env.VUE_APP_TAGMANAGER_ENTORNO,
      event: e,
      eventCategory: eCategory,
      eventAction: eAction,
      eventLabel: eLabel,
      userIdMoney: userId,
    })
  } catch (error) {
    console.log(error)
  }
}
