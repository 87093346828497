<template>
  <div class="container-fluid mb-5">
    <StripesTopImage />
    <div class="container-fluid">
      <MovistarMoneyLogo />
      <div class="row">
        <div class="col-md-12">
          <b-img :src="require(`@/assets/images/${image}`)" fluid alt="Cover logo" class="my-5" />
        </div>
      </div>
      <div v-if="html" class="row">
        <div class="col-md-12 no-padding pb-3">
          <span class="onboard-error-title" :class="{ 'onboard-error-title-bold': titleBold }" v-html="html"></span>
        </div>
      </div>
      <div v-else class="row">
        <div class="col-md-12 no-padding pb-3">
          <span class="onboard-error-title" :class="{ 'onboard-error-title-bold': titleBold }" v-html="title"></span>
        </div>
        <div v-if="subtitle" class="col-md-12 no-padding">
          <p class="onboard-error-subtitle">{{ subtitle }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StripesTopImage from './StripesTopImage'
import MovistarMoneyLogo from './MovistarMoneyLogo'

export default {
  name: 'Error',
  components: { MovistarMoneyLogo, StripesTopImage },
  props: {
    title: String,
    subtitle: String,
    html: String,
    image: String,
    titleBold: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
