<template>
  <MVModal id="modal-fianca-requerido">
    <template #header>
      <div class="titulo">
        <b-img src="@/assets/icons/warning-blue.svg" />
        <h2 v-text="messages.title" />
      </div>
    </template>
    <p v-text="messages.description" />
    <p class="blue-text" v-text="messages.info" />

    <template #footer>
      <MVButton :label="messages.button" variant="movistar-green" @click="$bvModal.hide('modal-fianca-requerido')" />
    </template>
  </MVModal>
</template>
<script setup>
import { computed } from 'vue'

import MVModal from '@/components/movistar/MVModal.vue'
import MVButton from '@/components/movistar/MVButton.vue'

const props = defineProps({
  channel: {
    type: String,
    default: 'Finter',
  },
})

const messages = computed(() => {
  if (props.channel === 'Assistido') {
    return {
      title: '¡Este crédito requiere una cobertura!',
      description:
        'La fianza es una cobertura que respalda las obligaciones del cliente y  le permite ampliar las posibilidades de aprobación del crédito',
      info: 'El cliente pagará el valor de la fianza al finalizar esta transacción',
      button: 'Continuar',
    }
  }
  return {
    title: '¡Este crédito requiere una cobertura!',
    description:
      'La fianza es una cobertura que respalda tus obligaciones y permite ampliar las posibilidades de aprobación del crédito',
    info: 'Pagarás el valor de la fianza al finalizar la transacción',
    button: 'Ver valor',
  }
})
</script>
<style lang="scss" scoped>
.titulo {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 0 1.5rem;
  img {
    width: 50px;
    margin: 1rem 0 1.5rem 0;
  }
  h2 {
    font-size: 26px;
  }
}
p {
  font-size: 20px;
}
</style>
