<template>
  <div>
    <b-row align-h="center" align-v="center">
      <b-col cols="1" class="py-0">
        <b-img src="@/assets/icons/shipping-dark.svg" alt="Clock icon" />
      </b-col>
      <b-col cols="10" class="text-left px-0 pl-3">
        <b-row align-v="center">
          <span class="font-16 text-grey pl-3">Costo del envío</span>
        </b-row>
      </b-col>
    </b-row>
    <b-row align-h="center" align-v="start" class="mt-2">
      <b-col cols="1" class="py-0"></b-col>
      <b-col cols="10" class="text-left px-0 pl-3">
        <b-row align-v="center" class="pl-3 pb-2">
          <VMoney :value="shippingCost" />
        </b-row>
      </b-col>
      <span class="text-grey">*El costo del envió se verá reflejado en tu siguiente factura del servicio</span>
    </b-row>
  </div>
</template>

<script>
import VMoney from './VMoney'

export default {
  name: 'CardAddressDeliveryCost',
  components: { VMoney },
  props: ['shippingCost'],
}
</script>
