<template>
  <b-modal
    :id="id"
    class="modal-body"
    cancel-title=""
    cancel-variant="hidden"
    size="sm"
    hide-header
    hide-footer
    ok-title=""
    ok-variant="hidden"
    centered
    header-border-variant="white"
    footer-border-variant="white"
    no-close-on-esc
    no-close-on-backdrop
  >
    <div class="mx-2">
      <b-row align-h="end">
        <b-button variant="blank" @click="cancellAction(id)">
          <b-img src="@/assets/icons/cancel.svg" alt="Cancel icon" />
        </b-button>
      </b-row>
      <div v-if="title" class="py-1">
        <p class="text-center title-onboard">¿Estás seguro de cancelar la solicitud?</p>
        <p class="text-center subtitle-onboard mx-4">
          Perderás el proceso de solicitud de crédito con Movistar Money y tendrás que volver a iniciar
        </p>
      </div>
    </div>
    <div class="container-btn-modal">
      <button class="btn-modal-evidente-cancell" @click="cancellAction(id)">No</button>
      <button class="btn-modal-evidente-accept" @click="closeApp">Si</button>
    </div>
    <p class="text-cancell-footer">
      Si tienes alguna falla en el proceso comunícate con el #409 para ayudarte con tu inconveniente
    </p>
  </b-modal>
</template>

<script>
import router from '../../router'
import { ACTION_WORKFLOW_STATUS_ASSISTED } from '../../store/actions.type'
import store from '../../store'
import { mapGetters } from 'vuex'

export default {
  name: 'ModalCancelarAssistido',
  props: {
    id: String,
    title: String,
    subtitle: String,
    image: String,
    modalType: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    cancellAction(id) {
      this.$bvModal.hide(id)
    },
    closeApp() {
      store.dispatch(ACTION_WORKFLOW_STATUS_ASSISTED, { action: 'cancel', status: 'CANCELLED' })
    },
  },
}
</script>
<style scoped>
.text-cancell-footer {
  text-align: center;
  margin-top: 1.5rem;
  padding-left: 20px;
  padding-right: 20px;
}

.container-btn-modal {
  display: flex;
  justify-content: space-around;
  margin-top: 2rem;
}

.btn-modal-evidente-cancell,
.btn-modal-evidente-accept {
  width: 45%;
  height: 50px;
  border-radius: 40px;
  background-color: #5cb630;
  color: #ffffff;
  font-size: 16px;
  border: none;
  font-weight: bold;
}
.btn-modal-evidente-accept {
  border: 1px solid #5cb630;
  background: #ffffff;
  color: #5cb630;
}
</style>
