<template>
  <b-container fluid>
    <StripesTopImage />
    <MovistarMoneyLogo />
    <b-img class="alert" src="@/assets/images/oh-no.svg" alt="Cover logo" />
    <h1>¡Lo sentimos!</h1>
    <p>Has excedido el límite de intentos de reenvío de link revisa en tus correos no deseados</p>
    <p>Comunícate al número de <b>WhatsApp: +57 315 2008280</b> para solicitar tu equipo y recibir más información</p>
    <MVButton label="Finalizar" variant="movistar-green" @click="finalizar()" />
  </b-container>
</template>

<script setup>
import StripesTopImage from '@/components/StripesTopImage.vue'
import MovistarMoneyLogo from '@/components/MovistarMoneyLogo'
import MVButton from '@/components/movistar/MVButton.vue'

function finalizar() {
  window.location.replace('https://www.movistarmoney.co/')
}
</script>

<style lang="scss" scoped>
img.alert {
  width: 400px;
  margin-top: 4rem;
  margin-bottom: 6rem;
}

h1 {
  font-size: 2rem;
  margin-bottom: 2rem;
}

p {
  margin: 0;
  font-size: 1.3rem;
}

.btn {
  margin-top: 2rem;
  width: 22rem;
}
</style>
