<template>
  <div class="conteudo">
    <MovistarMoneyLogo class="logo" />
    <h1 class="titulo">¡<b>Reenvía</b> el link del pago inicial del equipo!</h1>
    <p class="descricao">
      Aquí puedes reenviar el link de pago a tu cliente, antes de empezar ingresa el documento de identidad o el número
      de celular con el que se realizó la solicitud.
    </p>
    <b-form @submit.prevent="enviarForm" class="formulario">
      <InputGroup
        placeholder="Documento de identidad"
        :mask="['# ## ###', '# ### ###', '# ### ### ###', '## ### ####']"
        v-model="form.document"
      >
        <template #prepend>
          <IconeCredencial />
        </template>
      </InputGroup>

      <InputGroup placeholder="Número de celular" mask="### ### ## ##" v-model="form.mobile">
        <template #prepend>
          <IconeTelefone />
        </template>
      </InputGroup>

      <VueRecaptcha
        class="recaptcha"
        :loadRecaptchaScript="true"
        :sitekey="sitekey"
        @verify="onCaptchaVerified"
        @expired="onCaptchaExpired"
      />

      <ButtonContinue
        class="btn-continue"
        text="Continuar"
        :disable="!validRecaptcha || (!form.document && !form.mobile)"
      />
    </b-form>

    <ModalEnviarEmail
      id="modal-enviar-email"
      @novo_email="abrirModalNovoEmail"
      :email="email"
      :applicationId="applicationId"
      @continuar="emailEnviado"
    />
    <ModalConfirmarNovoEmail id="modal-confirmar-novo-email" :applicationId="applicationId" @continuar="emailEnviado" />
  </div>
</template>

<script>
import { TOGGLE_OVERLAY } from '@/store/actions.type'
import api from '@/plugins/api'
import apiCaptcha from '@/plugins/apiCaptcha'

import VueRecaptcha from 'vue-recaptcha'
import MovistarMoneyLogo from '@/components/MovistarMoneyLogo.vue'
import InputGroup from '@/components/inputs/InputGroup.vue'
import ButtonContinue from '@/components/buttons/ButtonContinue.vue'

import ModalEnviarEmail from '@/components/modal/antecipo/ModalEnviarEmail.vue'
import ModalConfirmarNovoEmail from '@/components/modal/antecipo/ModalConfirmarNovoEmail.vue'

import IconeCredencial from '@/components/icones/IconeCredencial.vue'
import IconeTelefone from '@/components/icones/IconeTelefone.vue'

export default {
  components: {
    MovistarMoneyLogo,
    InputGroup,
    ButtonContinue,
    VueRecaptcha,
    ModalEnviarEmail,
    ModalConfirmarNovoEmail,
    IconeCredencial,
    IconeTelefone,
  },
  data() {
    return {
      sitekey: process.env.VUE_APP_G_RECAPTCHA_SITE_KEY,
      validRecaptcha: false,
      form: {
        document: null,
        mobile: null,
      },
      email: '',
      applicationId: '',
    }
  },
  methods: {
    onCaptchaVerified(recaptchaToken) {
      apiCaptcha
        .validacaoCaptcha(recaptchaToken)
        .then(() => {
          this.validRecaptcha = true
        })
        .catch((error) => this.captchaFailed(error))
    },
    onCaptchaExpired() {
      this.validRecaptcha = false
    },

    async enviarForm() {
      this.$store.dispatch(TOGGLE_OVERLAY, true)
      await api
        .getEmailReenvioLinkAntecipo({
          document: this.form.document?.replace(/ /g, ''),
          mobile: this.form.mobile?.replace(/ /g, ''),
        })
        .then((response) => {
          this.email = response.data.email
          this.applicationId = response.data.proposalId

          this.$bvModal.show('modal-enviar-email')
        })
        .catch((error) => {
          this.$bvToast.toast(error.response.data.message, {
            title: '¡Ocurrio un error!',
            autoHideDelay: 5000,
            variant: 'danger',
          })
        })
        .finally(() => {
          this.$store.dispatch(TOGGLE_OVERLAY, false)
        })
    },
    abrirModalNovoEmail() {
      this.$bvModal.hide('modal-enviar-email')
      this.$bvModal.show('modal-confirmar-novo-email')
    },
    emailEnviado() {
      this.$router.push({ name: 'ReenviarLinkPagamentoAgradecimento' })
    },
  },
}
</script>

<style lang="scss" scoped>
.conteudo {
  background-image: url(@/assets/images/bg-anticiporesendlink.webp);
  background-size: 100% 100%;
  background-attachment: fixed;

  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  .logo {
    padding-top: 88px !important;
    padding-bottom: 54px !important;
  }

  .titulo {
    font-size: 22px;
    color: #019df4;
    margin-bottom: 23px;
  }

  .descricao {
    font-size: 18px;
    margin-bottom: 40px;
    max-width: 437px;
  }

  .formulario {
    width: 300px;

    .input-group {
      margin-bottom: 1rem;
      &:nth-child(2) {
        margin-bottom: 2rem;
      }
    }

    .recaptcha {
      margin-bottom: 2rem;
    }

    .btn-continue {
      margin: 0 1rem;
    }
  }
}
</style>
