<template>
  <b-container fluid>
    <StripesTopImage />
    <MovistarMoneyLogo />
    <Stepper :step="step" />
    <div class="content">
      <b-card class="py-3 px-4 mb-4" border-variant="movistar" no-body>
        <b-row align-h="center" align-v="center" class="mb-2">
          <b-img src="@/assets/icons/money.svg" alt="Money icon" class="mr-2" />
          <b-card-text class=""><b>Pago inicial del equipo</b></b-card-text>
        </b-row>
        <b-card-text class="subtitle">
          El Sistema determinará si debes hacer un pago inicial del equipo o puedes hacer un pago voluntario y reducir
          el valor de tu crédito.
        </b-card-text>

        <div class="equipamento d-flex justify-content-between">
          <span>Valor total del equipo</span>
          <div class="divisor"></div>
          <span class="valor" v-text="currentApplication.intendedRequestedValueFmt"></span>
        </div>

        <label class="label" for="valorAntecipado">Valor a pagar para financiar el restante</label>
        <b-input-group class="input" :class="{ 'input-error': !valorValido }">
          <b-input-group-prepend class="d-flex justify-content-center align-items-center">
            <img src="@/assets/icons/cifrao.svg" alt="Dollar icon" height="18" />
          </b-input-group-prepend>
          <CurrencyInput v-model="valorAntecipado" :precision="0" />
        </b-input-group>
        <b-form-text class="input-help" :class="{ 'input-error': !valorValido }">
          <span v-if="valorAntecipado > maximumAdvancedPayment">
            No puedes anticipar más de {{ maximumAdvancedPaymentFmt }}<br />
          </span>
          <span v-if="advancedPayment > valorAntecipado">
            No puedes anticipar menos de {{ advancedPaymentFmt }}<br />
          </span>
          <span v-if="advancedPayment > 0 && valorValido">El valor mínimo es de {{ advancedPaymentFmt }}<br /></span>
          <span v-if="currentApplication.intendedRequestedValue - valorAntecipado < minimumOrderValue">
            El valor de la orden es menor de {{ minimumOrderValueFmt }}
          </span>
        </b-form-text>
        <!-- <label class="label mt-4" for="valorAntecipado">Selecciona el medio de pago</label>
        <div class="d-flex radio-custom">
          <fieldset class="mr-4">
            <input type="radio" id="tarjeta" value="Tarjeta-PSE" v-model="paymentType" />
            <label for="tarjeta" class="radio-label">Tarjeta / PSE</label>
          </fieldset>
          <fieldset>
            <input type="radio" id="efectivo" value="Efectivo" v-model="paymentType" />
            <label for="efectivo" class="radio-label">Efectivo (puntos físicos)</label>
          </fieldset>
        </div> -->
      </b-card>
      <div class="card-phone-details-wrapper">
        <CardPhoneDetails
          :phone-details="phoneDetails"
          :showPhoneFeatures="true"
          :current-application="currentApplication"
          :step="step"
        />
      </div>

      <div class="mt-5">
        <ButtonContinue text="Confirmar" @click="proximaPagina" :disable="!valorValido" />
      </div>
    </div>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex'

import StripesTopImage from '@/components/StripesTopImage.vue'
import MovistarMoneyLogo from '@/components/MovistarMoneyLogo.vue'
import Stepper from '@/components/Stepper.vue'
import CardPhoneDetails from '@/components/CardPhoneDetails.vue'
import ButtonContinue from '@/components/buttons/ButtonContinue.vue'
import CurrencyInput from '@/components/inputs/CurrencyInput.vue'

import { DEFINE_ANTICIPO, FETCH_ANTICIPO } from '@/store/actions.type'

export default {
  components: {
    StripesTopImage,
    MovistarMoneyLogo,
    Stepper,
    CardPhoneDetails,
    ButtonContinue,
    CurrencyInput,
  },
  data() {
    return {
      step: 2,
      valorAntecipado: 0,
      paymentType: 'Tarjeta-PSE',
    }
  },
  mounted() {
    if (this.advancedPayment > 0) this.valorAntecipado = this.advancedPayment
  },
  methods: {
    proximaPagina() {
      this.$store.dispatch(DEFINE_ANTICIPO, {
        action: 'setPreOffer',
        advancedPayment: this.valorAntecipado || 0,
        paymentType: this.paymentType,
      })
    },
  },
  computed: {
    ...mapGetters({
      currentApplication: 'currentApplication',
      phoneDetails: 'phoneDetails',
      advancedPayment: 'advancedPayment',
      advancedPaymentFmt: 'advancedPaymentFmt',
      maximumAdvancedPayment: 'maximumAdvancedPayment',
      maximumAdvancedPaymentFmt: 'maximumAdvancedPaymentFmt',
      minimumOrderValue: 'minimumOrderValue',
      minimumOrderValueFmt: 'minimumOrderValueFmt',
    }),
    valorValido() {
      return this.valorAntecipado >= this.advancedPayment && this.valorAntecipado <= this.anticipoMaximo
    },
    anticipoMaximo() {
      return this.currentApplication.intendedRequestedValue - this.maximumAdvancedPayment < this.minimumOrderValue
        ? this.currentApplication.intendedRequestedValue - this.minimumOrderValue
        : this.maximumAdvancedPayment
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .border-movistar {
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-color: white;
}

.content {
  max-width: 30rem;
  margin: 0 auto;
}

.subtitle {
  color: #019df4;
}

.equipamento {
  margin-bottom: 1rem;
  font-size: 16px;
  .divisor {
    border: 1px solid #e6e6e6;
  }
  .valor {
    font-weight: bold;
    color: #0b2739;
  }
}

.label {
  font-weight: bold;
  color: #9d9d9d;
  text-align: left;
}

.input-help {
  text-align: left;
  &.input-error {
    color: #e63780 !important;
  }
}

.radio-label {
  font-size: 14px;
  color: #5d5d5d;
  &:before {
    margin-right: 0.375rem !important;
  }
}

.input-group.input-error {
  border-bottom: 1px solid #e63780;
}
</style>
