<template>
  <div class="mb-5">
    <b-col class="p-0">
      <b-row v-if="step === 5" class="text-center" align-h="center">
        <div class="step step-past">1</div>
        <hr class="line line-past" />
        <div class="step step-past">2</div>
        <hr class="line line-past" />
        <div class="step step-past">3</div>
        <hr class="line line-past" />
        <div v-if="contractTerms" class="step step-almost">4</div>
        <div v-else class="step step-almost step-button" @click="stepBack">4</div>
        <hr class="line line-past" />
        <div class="step step-active">5</div>
      </b-row>

      <b-row v-else-if="step === 4" class="text-center" align-h="center">
        <div class="step step-past">1</div>
        <hr class="line line-past" />
        <div class="step step-past">2</div>
        <hr class="line line-past" />
        <div class="step step-past">3</div>
        <hr class="line line-past" />
        <div class="step step-active">4</div>
        <hr class="line" />
        <div class="step">5</div>
      </b-row>

      <b-row v-else-if="step === 3" class="text-center" align-h="center">
        <div class="step step-past">1</div>
        <hr class="line line-past" />
        <div class="step step-past">2</div>
        <hr class="line line-past" />
        <div class="step step-active">3</div>
        <hr class="line" />
        <div class="step">4</div>
        <hr class="line" />
        <div class="step">5</div>
      </b-row>

      <b-row v-else-if="step === 2 || applicationStatus === 'offer'" class="text-center" align-h="center">
        <div class="step step-past">1</div>
        <hr class="line line-past" />
        <div class="step step-active">2</div>
        <hr class="line" />
        <div class="step">3</div>
        <hr class="line" />
        <div class="step">4</div>
        <hr class="line" />
        <div class="step">5</div>
      </b-row>

      <b-row v-else class="text-center" align-h="center">
        <div class="step step-active">1</div>
        <hr class="line" />
        <div class="step">2</div>
        <hr class="line" />
        <div class="step">3</div>
        <hr class="line" />
        <div class="step">4</div>
        <hr class="line" />
        <div class="step">5</div>
      </b-row>
    </b-col>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { CHANGE_STATUS } from '../store/actions.type'
import store from '../store'
import { gtmDataLayerTrackEvent } from '../helpers/gtmHelper'

export default {
  name: 'Stepper',
  props: {
    step: Number,
  },
  methods: {
    stepBack() {
      if (this.contractTerms) return
      gtmDataLayerTrackEvent(
        window.dataLayer,
        'trackEvent',
        'movistar money',
        'click::verificación de contrato::paso 5',
        'volver al paso 4',
        this.CryptoJS.SHA1(this.currentApplication.mobile).toString()
      )
      if (this.currentApplication.origin === 'Finter')
        store.dispatch(CHANGE_STATUS, { status: 'DELIVERY', origin: this.currentApplication.origin })
      else if (this.currentApplication.origin === 'Libre')
        store.dispatch(CHANGE_STATUS, { status: 'ADDRESS', origin: this.currentApplication.origin })
    },
  },
  computed: {
    ...mapGetters({
      phoneDetails: 'phoneDetails',
      applicationStatus: 'applicationStatus',
      contractTerms: 'contractTerms',
      currentApplication: 'currentApplication',
    }),
  },
}
</script>

<style lang="scss" scoped>
.step {
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50%;
  font-size: 18px;
  text-align: center;
  background: #ffffff;
  color: #d3d4d3;
  border-style: solid;
  border-width: 1px;
  border-color: #d3d4d3;
  @media (min-width: 410px) {
    width: 39px;
    height: 39px;
    line-height: 39px;
  }
}

.step-active {
  color: #ffffff;
  border: 1px solid #019df4;
  background: #019df4;
}

.step-past {
  color: #ffffff;
  border-color: #a3ddee;
  background: #a3ddee;
}

.step-almost {
  color: #019df4;
  border: 1px solid #019df4;
  background: #ffffff;
}

.step-button {
  cursor: pointer;
}

.step-button:hover {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #019df4;
  color: #ffffff;
}

.line {
  height: 1px;
  border-width: 0;
  color: #d3d4d3;
  background-color: #d3d4d3;
  margin-left: 5px;
  margin-right: 5px;
  width: 25px;
  @media (min-width: 410px) {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.line-past {
  background-color: #019df4;
}
</style>
