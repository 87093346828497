<template>
  <p class="font-14 text-left text-pine-green mb-4 pb-3">{{ text }}</p>
</template>

<script>
export default {
  name: 'LoginSubtitle',
  props: {
    text: String,
  },
}
</script>
