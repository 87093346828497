<template>
  <div class="conteudo">
    <MovistarMoneyLogo />
    <div class="col-md-12">
      <b-img class="my-5" src="@/assets/images/backpack-guy.png" alt="Cover logo" />
    </div>
    <h1>¡Lo sentimos!</h1>
    <p>Has excedido el límite de intentos de reenvío de link revisa en tus correos no deseados</p>
    <MVButton label="Finalizar" variant="movistar-green" @click="finalizar()" />
  </div>
</template>

<script setup>
import StripesTopImage from '@/components/StripesTopImage.vue'
import MovistarMoneyLogo from '@/components/MovistarMoneyLogo'
import MVButton from '@/components/movistar/MVButton.vue'

function finalizar() {
  window.location.replace('https://www.movistarmoney.co/')
}
</script>

<style lang="scss" scoped>
.conteudo {
  background-image: url(@/assets/images/bg-anticiporesendlink.webp);
  background-size: 100% 100%;
  background-attachment: fixed;

  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
img.alert {
  width: 400px;
  margin-top: 4rem;
  margin-bottom: 6rem;
}

h1 {
  font-size: 2rem;
  margin-bottom: 2rem;
}

p {
  margin: 0;
  font-size: 1.3rem;
}

.btn {
  margin-top: 2rem;
  width: 22rem;
}
</style>
