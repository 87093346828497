import Api from '../plugins/api'

import { BUSCAR_LISTA_OFERTAS, VALIDAR_CAMPANHA } from './actions.type'

import { MUTATION_SET_ARRAY_RATE, MUTATION_CHANGE_VALUE_INPUT } from './mutations.type'

import store from '../store'
import router from '../router'

const state = {
  valueInputOfferSimulation: 0,
  getOffers: {},
  minValueOfferSimulation: 0,
  maxValueOfferSimulation: 0,
  rangeOfferSimulation: 0,
  valueInputOfferSimulationFmt: 'Buscando ofertas',
  lastArrayInOffer: [],
  offerAccessId: '',
}

const mutations = {
  [MUTATION_SET_ARRAY_RATE](state, response) {
    state.getOffers = JSON.parse(JSON.stringify(response))
    state.valueInputOfferSimulation = response.maximumValue
    state.valueInputOfferSimulationFmt = response.maximumValueFmt
    state.lastArrayInOffer = response.options[response.options.length - 1].offers
    state.minValueOfferSimulation = response.minimumValue
    state.maxValueOfferSimulation = response.maximumValue
    state.minValueOfferSimulationFmt = response.minimumValueFmt
    state.maxValueOfferSimulationFmt = response.maximumValueFmt
    state.rangeOfferSimulation = response.range
    state.offerAccessId = response.accessId
  },
  [MUTATION_CHANGE_VALUE_INPUT](state, value) {
    state.valueInputOfferSimulation = value
    state.getOffers.options.filter((option) => {
      if (option.value == value) {
        state.valueInputOfferSimulationFmt = option.valueFmt
      }
    })
  },
}

const actions = {
  [VALIDAR_CAMPANHA](context, payload) {
    Api.getValidarCampanhaLibre(payload.campaign)
      .then((response) => {
        if (response.data.status === 'ATIVA') store.dispatch(BUSCAR_LISTA_OFERTAS, payload.data)
        else router.push({ name: 'ServiceUnavailable' })
      })
      .catch((e) => console.log(e))
  },

  [BUSCAR_LISTA_OFERTAS](context, payload) {
    Api.getOfferSimulation(payload)
      .then((response) => {
        context.commit(MUTATION_SET_ARRAY_RATE, response.data.calculator)
      })
      .catch((e) => console.log(e))
  },
}

const getters = {
  valueInputOfferSimulation: (state) => state.valueInputOfferSimulation,
  valueInputOfferSimulationFmt: (state) => state.valueInputOfferSimulationFmt,
  lastArrayInOffer: (state) => state.lastArrayInOffer,
  getOffers: (state) => state.getOffers,
  minValueOfferSimulation: (state) => state.getOffers.minimumValue,
  maxValueOfferSimulation: (state) => state.getOffers.maximumValue,
  minValueOfferSimulationFmt: (state) => state.getOffers.minimumValueFmt,
  maxValueOfferSimulationFmt: (state) => state.getOffers.maximumValueFmt,
  rangeOfferSimulation: (state) => state.getOffers.range,
  offerAccessId: (state) => state.offerAccessId,
}

export default {
  state,
  mutations,
  actions,
  getters,
}
