import { render, staticRenderFns } from "./ManyAttemptsResendLinkFianca.vue?vue&type=template&id=6afd50d0&scoped=true&"
import script from "./ManyAttemptsResendLinkFianca.vue?vue&type=script&setup=true&lang=js&"
export * from "./ManyAttemptsResendLinkFianca.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./ManyAttemptsResendLinkFianca.vue?vue&type=style&index=0&id=6afd50d0&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6afd50d0",
  null
  
)

export default component.exports