<template>
  <div class="container-sm-profile">
    <b-row class="text-center text-white text-lowercase" align-h="center">
      <p v-if="borrower.personalData" class="sm-title">
        ¡Hola
        {{ borrower.personalData.name | capitalize }}
        {{ borrower.personalData.firstSurname | capitalize }}!
      </p>
    </b-row>

    <b-container fluid>
      <b-col>
        <b-row>
          <b-col>
            <b-row align-h="center" class="my-1">
              <b-row align-h="center">
                <b-img src="@/assets/icons/fire-icon.svg" alt="Fire icon" class="fire" />
              </b-row>
            </b-row>
            <b-col class="text-center text-white" align-h="center">
              <p class="font-weight-bold">Eres cliente pionero</p>
            </b-col>
          </b-col>
          <b-col>
            <b-row>
              <b-col>
                <b-row class="text-white text-left">
                  <b-col>
                    <span class="font-14">Número de teléfono</span>
                    <p v-if="currentApplication.mobile">{{ currentApplication.mobile | maskPhone }}</p>
                  </b-col>
                </b-row>
                <b-row class="text-white text-left" v-if="borrower.personalData">
                  <b-col>
                    <span class="font-14">Correo</span>
                    <p>{{ borrower.personalData.emailMovistar }}</p>
                  </b-col>
                </b-row>
              </b-col>
              <b-col>
                <hr class="separator-edit separator" />
              </b-col>
            </b-row>
          </b-col>

          <template v-if="showEdit">
            <b-col>
              <b-row>
                <b-col>
                  <b-row class="text-white text-left">
                    <b-col>
                      <p class="sm-profile-edit-title no-margin">Otro número de contacto</p>
                      <input
                        type="tel"
                        class="sm-profile-edit-input mb-2"
                        maxlength="13"
                        v-mask="'### ### ## ##'"
                        v-model="editPhone"
                      />
                    </b-col>
                  </b-row>
                  <hr v-if="this.$v.editPhone.$error" class="separator no-margin" />
                  <hr v-else class="separator-edit" />
                  <p v-if="this.$v.editPhone.$error" class="form-input-error">Este no es un número válido</p>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <b-row class="text-white text-left">
                    <b-col>
                      <p class="sm-profile-edit-title no-margin">Otro correo</p>
                      <input type="email" class="sm-profile-edit-input mb-2" v-model="editEmail" />
                    </b-col>
                  </b-row>
                  <hr v-if="this.$v.editEmail.$error" class="separator no-margin" />
                  <hr v-else class="separator-edit" />
                  <p v-if="$v.editEmail.$error" class="form-input-error mb-2">El correo es inválido</p>
                </b-col>
              </b-row>
            </b-col>
          </template>

          <template v-else>
            <b-col>
              <b-row>
                <b-col>
                  <b-row class="text-white text-left pt-3">
                    <b-col v-if="borrower.contactData">
                      <span class="sm-profile-edit-title">Otro número de contacto </span>
                      <p v-if="borrower.contactData.length > 0">{{ borrowerPhone | maskPhone }}</p>
                      <p v-else></p>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <b-row class="text-white text-left">
                    <b-col>
                      <span class="sm-profile-edit-title">Otro correo</span>
                      <p v-if="borrower.personalData" class="text-white">{{ applicationEmail }}</p>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
          </template>
        </b-row>
      </b-col>
      <b-col>
        <b-row>
          <div class="container-btn-edit">
            <div class="content-btn-edit">
              <b-button v-if="showEdit" variant="primary" size="sm" class="btn-update" @click="editPhoneEmail">
                <span class="font-14">Guardar</span>
              </b-button>
              <button
                v-if="showEdit"
                class="button-hide-show-password float-right edit-button"
                @click="contactShowHide"
              >
                <span class="font-14 text-white">Editar</span>
                <b-img src="@/assets/icons/edit-pencil-white.svg" alt="Pencil icon" class="edit-img" />
              </button>
            </div>
          </div>
          <b-col>
            <div class="container-btn-edit">
              <button v-if="false" class="button-hide-show-password float-right" @click="contactShowHide">
                <span class="font-14 blue-text">Editar</span>
                <b-img src="@/assets/icons/edit-pencil.svg" alt="Pencil icon" class="edit-img" />
              </button>
              <button
                v-if="!showEdit"
                class="button-hide-show-password float-right edit-contact-preferences-button"
                @click="showContactPreferences"
              >
                <b-img left src="@/assets/icons/message.svg" alt="Contact icon" class="edit-contact-preferences-img" />
                <span class="font-14 blue-text edit-contact-preferences-text">Preferencias de contacto</span>
              </button>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-container>
  </div>
</template>

<script>
import { SELF_MANAGE_UPDATE_CONTACT, SELF_MANAGE_UPDATE_EMAIL } from '../../store/actions.type'
import store from '../../store'
import { email, minLength, required } from 'vuelidate/lib/validators'
import { emailFormatter } from '../../helpers/common'
import { MUTATE_APPLICATION_EMAIL, MUTATE_BORROWER_PHONE } from '../../store/mutations.type'
import { mapGetters } from 'vuex'

export default {
  name: 'Profile',
  data() {
    return {
      showEdit: false,
      fullname: '',
      contact: {
        name: '',
        telephoneNumber: '',
        preference: 'PRIMARY',
      },
    }
  },
  validations: {
    editPhone: { required, min: minLength(10) },
    editEmail: { required, editEmail: (val) => email(emailFormatter(val)) },
  },
  filters: {
    capitalize: function (value) {
      if (!value) {
        return ''
      }
      return value.toString().toLowerCase()
    },
    maskPhone: function (value) {
      if (!value) {
        return ''
      }
      let part1, part2, part3, part4, concat
      part1 = value.slice(0, 3)
      part2 = value.slice(3, 6)
      part3 = value.slice(6, 8)
      part4 = value.slice(8, 10)
      concat = part1.concat(' ', part2, ' ', part3, ' ', part4)
      return concat
    },
  },
  methods: {
    contactShowHide() {
      this.showEdit ? (this.showEdit = false) : (this.showEdit = true)
    },
    editPhoneEmail() {
      this.$v.$touch()
      if (this.$v.$error) return
      this.contactShowHide()
      this.contact.name = this.borrower.personalData.name + ' ' + this.borrower.personalData.firstSurname
      this.contact.telephoneNumber = this.editPhone.replace(/ /g, '')
      if (this.borrowerPhone !== this.borrower.contactData[0].telephoneNumber) {
        store.dispatch(SELF_MANAGE_UPDATE_CONTACT, this.contact)
      }
      if (this.applicationEmail !== this.currentApplication.email) {
        store.dispatch(SELF_MANAGE_UPDATE_EMAIL, { email: this.editEmail })
      }
    },
    showContactPreferences() {
      this.$emit('editContactPreferences')
    },
  },
  computed: {
    ...mapGetters({
      currentApplication: 'currentApplication',
      borrower: 'selfManageBorrower',
      borrowerPhone: 'borrowerPhone',
      applicationEmail: 'applicationEmail',
    }),
    editEmail: {
      get() {
        if (!this.applicationEmail) return
        return this.applicationEmail
      },
      set(email) {
        this.$store.commit(MUTATE_APPLICATION_EMAIL, email)
      },
    },
    editPhone: {
      get() {
        if (!this.borrowerPhone) return
        return this.borrowerPhone
      },
      set(phone) {
        this.$store.commit(MUTATE_BORROWER_PHONE, phone.replace(/ /g, ''))
      },
    },
  },
}
</script>

<style scoped>
.separator {
  background-color: var(--movistar-orange);
  height: 100%;
  width: 1px;
}

@media (max-width: 938px) {
  .separator {
    height: 1px;
    width: 288px;
  }
}

@media (max-width: 350px) {
  .separator {
    width: 230px;
  }
}

.edit-img {
  position: relative;
  top: 4px;
  margin-left: 2px;
}

.container-btn-edit {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.content-btn-edit {
  width: 300px;
  display: flex;
  align-items: center;
}

.btn-update {
  height: 30px;
  width: 150px;
  border-radius: 40px;
}

.separator-edit {
  background-color: var(--movistar-orange);
  margin: 0 0 16px;
}

.sm-title {
  font: 300 30px Telefonica, sans-serif;
  text-transform: capitalize;
}

@media (max-width: 700px) {
  .sm-title {
    font-size: 18px;
  }

  .fire {
    height: 30px;
    width: 30px;
  }

  .separator-edit {
    background-color: var(--movistar-orange);
    margin: 0 0 16px;
    min-width: 99%;
  }
}

.edit-button {
  position: relative;
  left: 100%;
}

.edit-contact-preferences-button {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 200px;
  height: 30px;
  margin: 4px 0px 0 28px;
  padding: 8.9px 10.3px 7.9px 10.3px;
  border-radius: 5px;
  background-color: #fff;
}

.edit-contact-preferences-text {
  height: 16px;
  font-family: Telefonica;
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--0-b-2739);
}
.edit-contact-preferences-img {
  width: 17px;
  height: 17px;
}
</style>
