export const FETCH_TERMS_CONDITIONS = 'termsAndConditions'
export const FETCH_CONTRACT = 'contract'
export const FETCH_CONTRACT_ASSISTED = 'contractAssisted'
export const FETCH_APPLICATION_REDIRECT = 'getApplicationRedirect'
export const FETCH_APPLICATION = 'getApplication'
export const FETCH_APPLICATION_ASSISTED = 'getApplicationAssisted'
export const FETCH_URL_CALLBACK = 'getUrlCallback'
export const REFRESH_OFFER = 'refreshOffer'
export const FETCH_OFFER = 'postOffer'
export const UPDATE_SELECTED = 'updateOffer'
export const ACTION_LISTAR_OFERTAS = 'actionListarOfertas'
export const UPDATE_SELECTED_BIGGEST_OFFER = 'updateLargeOffer'
export const SEND_FORM = 'postApplication'
export const LOGIN_OTP = 'loginOTP'
export const DESTROY_TOKEN = 'destroyToken'
export const ACTION_POST_REQUEST_VALUE = 'actionPostRequestValue'
export const RESET_AUTH_ERROR = 'resetAuthError'
export const NEW_PIN = 'newPin'
export const ACTION_WORKFLOW_STATUS_ASSISTED = 'actionChangeStatusAssisted'
export const BUSCAR_LISTA_OFERTAS = 'ActionSetArrayRate'
export const VALIDAR_CAMPANHA = 'validarCampanha'
export const NEW_PIN_SELF_MANAGE = 'newPinSelfManage'
export const ACTION_FETCH_OFFER_ASSISTED = 'actionFetchOfferAssisted'
export const CANCEL_PROPOSAL = 'cancelClientProposal'
export const CHANGE_STATUS = 'changeStatus'
export const DEFINE_OFFER = 'defineOffer'
export const SALVAR_DADOS_BANCARIOS = 'salvarBankData'
export const BUSCAR_CONTRA_OFERTA = 'fetchCounterOffer'
export const BUSCAR_BANCOS = 'fetchBanks'
export const SALVAR_CONTRA_OFERTA = 'salvarContraOferta'
export const FETCH_EVIDENTE_QUESTIONS = 'listEvidenteQuestions'
export const SEND_EVIDENTE_QUESTIONS = 'postEvidenteQuestions'
export const VERIDAS_CRIAR_TOKEN = 'veridasCriarToken'
export const VERIDAS_VALIDAR_ID = 'veridasValidarId'
export const FETCH_ADDRESS_DEPARTMENTS = 'addressDepartments'
export const FETCH_ADDRESS_CITIES = 'addressCities'
export const FETCH_ADDRESS_NEIGHBORHOOD = 'addressNeighborhood'
export const CLEAR_CITIES_AND_NEIGHBORHOOD = 'clearCitiesAndNeighborhood'
export const SEND_ADDRESS_FORM = 'addressForm'
export const SAVE_CHOOSEN_ADDRESS = 'saveChoosenAddress'
export const GET_ADDRESS_CLIENT = 'addressClient'
export const GET_ADDRESS_CLIENT_FINAL = 'addressClientFinal'
export const TOGGLE_OVERLAY = 'overlay'
export const CREATE_CONTRACT = 'createContract'
export const CRIAR_CONTRATO_LIBRE = 'createContractLibre'
export const REDIRECT_USER = 'redirectUser'
export const FETCH_MY_IP = 'fetchMyIP'
export const BUSCAR_MINHA_LOCALIZACAO = 'buscarMinhaLocalizacao'
export const CHANGE_DEVICE = 'changeDevice'
export const SAVE_CONTRACT_TERMS = 'saveContractTerms'
export const SAVE_CONTRACT_FIANCA = 'saveContractFianca'
export const SEND_SURVEY = 'sendSurvey'
export const SEND_LOG_FRONT = 'sendLogFront'
export const VALIDAR_OTP_CLIENTE = 'validarOTPCliente'
export const CONFIRMAR_ACEITE_CLIENTE_ASSISTED = 'confirmarAceiteCliente'
export const FETCH_PRE_OFFER = 'buscarPreOferta'
export const FETCH_PRE_OFFER_ASSISTED = 'buscarPreOfertaAssistido'
export const DEFINE_ANTICIPO = 'defineAnticipo'
export const VERIDAS_ASSISTIDO_VALIDAR_URL = 'veridasAssistidoValidarUrl'
export const VERIDAS_ASSISTIDO_INVALIDAR_URL = 'veridasAssistidoInvalidarUrl'
export const ACTION_VALIDAR_VERIDAS_ASSISTED = 'validarVeridasAssistido'

//SELF MANAGE ACTIONS
export const MOBILE_DISPONIVEIS = 'mobileDisponiveis'
export const REMOVER_MOBILE_DISPONIVEIS = 'removerMobilesDisponiveis'
export const SELF_MANAGE_LOGIN = 'onboardLogin'
export const SELF_MANAGE_LOGIN_OTP = 'onboardLoginOTP'
export const SELF_MANAGE_UPDATE_PASSWORD = 'selfManageUpdatePassword'
export const SELF_MANAGE_LOGIN_PASSWORD = 'selfManageLoginPassword'
export const SELF_MANAGE_RESET_PASSWORD = 'selfManageResetPassword'
export const SELF_MANAGE_NEW_FORGOTTEN_PASSWORD = 'selfManageNewForgottenPassword'
export const SELF_MANAGE_GET_BORROWER = 'selfManageGetBorrower'
export const SELF_MANAGE_UPDATE_CONTACT = 'selfManageUpdateContact'
export const SELF_MANAGE_UPDATE_EMAIL = 'selfManageUpdateEmail'
export const SELF_MANAGE_GET_APPLICATIONS = 'selfManageGetApplications'
export const SELF_MANAGE_GET_APPLICATIONS_LIBERADAS = 'selfManageGetApplicationsLiberadas'
export const SELF_MANAGE_GET_APPLICATION_PAYMENT_PLAN = 'selfManageGetApplicationPaymentPlan'
export const SELF_MANAGE_GET_APPLICATION_MAMBU = 'selfManageGetApplicationMambu'
export const SELF_MANAGE_GET_APPLICATION_CONTRACT = 'selfManageGetApplicationConctract'
export const SELF_MANAGE_GET_PRODUCT_CERTIFICATE = 'selfManageGetProductCertificate'
export const SELF_MANAGE_GET_ACQUITTANCE_CERTIFICATE = 'selfManageGetAcquittanceCertificate'
export const SELF_MANAGE_GET_APPLICATION_EXTRACT = 'selfManageGetApplicationExtract'
export const SELF_MANAGE_GET_CLAIMS = 'selfManageGetClaims'
export const SELF_MANAGE_POST_UPDATE_CASE_STATUS = 'selfManagePostUpdateCaseStatus'
export const SELF_MANAGE_COMPROVANTE_EPAYCO_EMAIL = 'selfManageComprovanteEpaycoEmail'
export const SELF_MANAGE_GET_APPLICATION_APOLICE_SEGURO = 'selfManageGetApplicationApoliceSeguro'
export const SELF_MANAGE_GET_APPLICATION_BALANCE = 'selfManageApplicationBalance'
export const SELF_MANAGE_VALIDATE_LINK_FORGOTTEN_PASSWORD = 'selfManageValidateLinkForgottenPassword'
export const SELF_MANAGE_UPDATE_CONTACT_PREFERENCES = 'selfManageUpdateContactPreferences'
//SELF MANAGE ACTIONS END

//EPAYCO ACTIONS
export const EPAYCO_POST_RESPONSE = 'epaycoPostResponse'
export const EPAYCO_MOBILE_POST_RESPONSE = 'epaycoMobilePostResponse'
export const EPAYCO_SALVAR_CHECKOUT_REQUISICAO = 'epaycoCheckoutRequest'
export const EPAYCO_POST_RESPONSE_ACESSO_RAPIDO = 'epaycoPostResponseFastPayment'
export const EPAYCO_MOBILE_POST_RESPONSE_ACESSO_RAPIDO = 'epaycoMobilePostResponseFastPayment'
export const EPAYCO_SALVAR_CHECKOUT_REQUISICAO_ACESSO_RAPIDO = 'epaycoCheckoutRequestFastPayment'
//EPAYCO ACTIONS END

//FAST PAYMENT ACTIONS
export const FAST_PAYMENT_LOGIN = 'fastPaymentLogin'
export const FAST_PAYMENT_GET_APPLICATION_MAMBU = 'fastPaymentGetApplicationMambu'
export const FAST_PAYMENT_GET_APPLICATION_PAYMENT_PLAN = 'fastPaymentGetApplicationPaymentPlan'
//FAST PAYMENT ACTIONS END

// ADVANCED PAYMENT ACTIONS
export const GET_PROPOSAL_ADVANCED_PAYMENT = 'consultarPropostaPagamentoAntecipado'
export const EPAYCO_SALVAR_CHECKOUT_REQUISICAO_PAGAMENTO_ANTECIPADO = 'salvarRequisicaoEpaycoAntecipado'
export const EPAYCO_POST_RESPONSE_ANTECIPADO = 'epaycoSalvarResponsePagamentoAntecipado'
export const EPAYCO_MOBILE_POST_RESPONSE_ANTECIPADO = 'epaycoMobileSalvarResponsePagamentoAntecipado'
export const ENVIAR_COMPROVANTE_EPAYCO_EMAIL_ANTECIPO = 'enviarComprovanteEpaycoEmailAntecipado'
export const EPAYCO_SALVAR_CHECKOUT_REQUISICAO_PAGAMENTO_FIANCA = 'salvarRequisicaoEpaycoFianca'
export const GET_PROPOSAL_ADVANCED_PAYMENT_FIANCA = 'consultarPropostaPagamentoFianca'
export const EPAYCO_POST_RESPONSE_FIANCA = 'epaycoSalvarResponsePagamentoFianca'
export const EPAYCO_MOBILE_POST_RESPONSE_FIANCA = 'epaycoMobileSalvarResponsePagamentoFianca'
export const ENVIAR_COMPROVANTE_EPAYCO_EMAIL_FIANCA = 'enviarComprovanteEpaycoEmailFianca'
// ADVANCED PAYMENT ACTIONS END

// PRE_VALIDACAO
export const ENVIAR_EMAIL_VALIDACAO = 'enviarEmailValidacao'
export const PRE_VALIDADOR_REQUEST_BASIC = 'preValidadorRequestBasic'
export const PRE_VALIDADOR_REQUEST_FULL = 'preValidadorRequestFull'
export const VALIDAR_EMAIL_BUSCADOR = 'validarEmailBuscador'
export const ENVIAR_PIN_BUSCADOR = 'enviarPinBuscador'
export const VALIDAR_PIN_BUSCADOR = 'validarPinBuscador'

// PARAMETROS
export const BUSCAR_PARAMETROS = 'buscarParametros'
export const BUSCAR_PARAMETROS_CANAL = 'buscarParametrosCanal'
export const REDIRECT_MANUTENCAO = 'redirecionarManutencao'
