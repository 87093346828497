import axios from 'axios'
import store from '../store'
import { REFRESH_TOKEN } from '../store/mutations.type'
import { datadogRum } from '@datadog/browser-rum'

export default function setup() {
  axios.interceptors.request.use(async (request) => {
    if (request.url.startsWith('publicdata')) {
      request.headers.Authorization = null
      return request
    } else if (request.url === 'oauth/token') {
      return request
    }

    if (!JSON.parse(store.getters.isAuthenticated)) return request
    if (isLoading(store.getters.refreshTokenStatus)) return request
    if (!store.getters.accessToken) {
      await store.dispatch(REFRESH_TOKEN)
      return request
    } else {
      return request
    }
  })

  axios.interceptors.response.use(
    (response) => {
      return response
    },
    async (error) => {
      if (process.env.VUE_APP_DD_ENV === 'prod') {
        if (!error.response) {
          datadogRum.addError(error)
        } else {
          datadogRum.addError(error.response)
        }
      }
      return Promise.reject(error)
    }
  )

  function isLoading(status) {
    return status === 'loading'
  }
}
