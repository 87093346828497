<template>
  <div>
    <SMNavBar />
    <b-container>
      <PagamentoAntecipadoHeader />
      <PagamentoAvancadoForm @pagar="configurarCheckout" />
      <PaymentTypes :antecipo="true" />
    </b-container>
    <Footer />
  </div>
</template>
<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { uuid } from 'vue-uuid'
import {
  EPAYCO_SALVAR_CHECKOUT_REQUISICAO_PAGAMENTO_ANTECIPADO,
  GET_PROPOSAL_ADVANCED_PAYMENT,
} from '@/store/actions.type'

import SMNavBar from '@/components/SelfManage/SMNavbar.vue'
import PagamentoAntecipadoHeader from '@/components/PagamentoAntecipado/PagamentoAntecipadoHeader.vue'
import PagamentoAvancadoForm from '@/components/PagamentoAntecipado/PagamentoAvancadoForm.vue'
import PaymentTypes from '@/components/SelfManage/PaymentTypes.vue'
import Footer from '@/components/SelfManage/Footer.vue'

export default {
  components: {
    SMNavBar,
    PagamentoAntecipadoHeader,
    PagamentoAvancadoForm,
    PaymentTypes,
    Footer,
  },
  data() {
    return {
      epaycoData: {
        proposalId: this.$route.params.idProposta,
        name: 'Pago inicial',
        description: 'Pago inicial',
        invoice: '',
        currency: 'COP',
        amount: '',
        tax_base: '',
        tax: '',
        country: 'CO',
        lang: 'ES',
        test: process.env.VUE_APP_ANTECIPO_EPAYCO_TEST,
        methodsDisable: [],
        external: false,
        extra1: 'Pago inicial',
        extra2: '',
        extra3: '',
        extra5: '',
        method: 'GET',
        response: process.env.VUE_APP_FRONT_URL + '/advanced-payment/' + this.$route.params.idProposta + '/response',
        confirmation:
          process.env.VUE_APP_API_URL +
          '/publicdata/advanced-payment/epayco/' +
          this.$route.params.idProposta +
          '/confirmation',
        name_billing: '',
        address_billing: '',
        type_doc_billing: 'CC',
        number_doc_billing: '',
        mobilephone_billing: '',
        meioPagamento: '',
      },
    }
  },
  mounted() {
    Vue.loadScript('/js/epayco/checkout.js')
      .then((ePaycoScript) => {
        ePaycoScript.id = 'epaycoScript'
      })
      .catch(() => {
        console.log('Problemas ao carregar script epayco.')
      })
    this.$store.dispatch(GET_PROPOSAL_ADVANCED_PAYMENT, this.$route.params)
  },
  methods: {
    configurarCheckout() {
      console.log(this.advancedPayment)
      if (this.advancedPayment.paymentType === 'Efectivo') {
        window.handler = window.ePayco.checkout.configure({
          key: process.env.VUE_APP_ANTECIPO_EPAYCO_KEY_AGREGADOR,
          test: process.env.VUE_APP_ANTECIPO_EPAYCO_TEST,
        })
      }
      if (this.advancedPayment.paymentType === 'Tarjeta-PSE') {
        window.handler = window.ePayco.checkout.configure({
          key: process.env.VUE_APP_ANTECIPO_EPAYCO_KEY_GATEWAY,
          test: process.env.VUE_APP_ANTECIPO_EPAYCO_TEST,
        })
      }
      this.salvarDadosRequisicaoEpayco()
    },
    salvarDadosRequisicaoEpayco() {
      this.epaycoData.invoice = uuid.v4()
      this.epaycoData.amount = this.advancedPayment.advancedPayment
      this.epaycoData.method = 'GET'
      this.epaycoData.extra2 = this.advancedPayment.paymentType
      this.epaycoData.extra3 = this.advancedPayment.idSalesOrder
      this.epaycoData.extra5 = this.propostaMambu.contractNumber
      this.epaycoData.name_billing = this.advancedPayment.borrower.name
      this.epaycoData.type_doc_billing = 'CC'
      this.epaycoData.number_doc_billing = this.documentNumber
      this.epaycoData.mobilephone_billing = this.advancedPayment.mobile
      this.$store.dispatch(EPAYCO_SALVAR_CHECKOUT_REQUISICAO_PAGAMENTO_ANTECIPADO, this.epaycoData).then((response) => {
        if (response.status == 200) {
          window.ePayco.checkout.open(this.epaycoData)
        }
      })
    },
  },
  computed: {
    ...mapGetters({
      advancedPayment: 'advancedPayment',
    }),
  },
}
</script>

<style scoped lang="scss">
.container {
  max-width: 30rem;
}
</style>
