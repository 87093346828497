<template>
  <b-card no-body border-variant="white">
    <div class="card-wrapper">
      <b-row al no-gutters>
        <b-col class="mx-4 my-4">
          <b-row align-h="center">
            <p class="font-16">{{ tituloComputed }}</p>
          </b-row>

          <CardAddressOption
            id="initial-address"
            class="mb-3"
            :selected.sync="selectedAddress"
            :label="opcaoAtualComputed"
            label-blue
            :formatted-address="addressInitial?.formattedAddress"
            @click="selecionarWork()"
          />
          <CardAddressOption
            v-b-modal.modal-address
            class="mb-2"
            id="address-callejero"
            :selected.sync="selectedAddress"
            sync-icon
            :label="opcaoAlternativaComputed"
            :formatted-address="addressFormCallejero?.formattedAddress"
            button-style
            @click="getDepartments()"
          />

          <div v-if="origin === 'Libre'">
            <p class="info-card mb-0 mt-1">*Recuerda que recibirás tu factura de forma digital</p>
          </div>
          <h1 v-if="!initialAddress && !completeDirection" class="address-warning">
            Cuidado, no rellenas correctamente los datos de la nueva dirección
          </h1>
          <div v-if="origin === 'Finter'">
            <div v-if="initialAddress">
              <template v-if="addressInitial.deliveryPeriod">
                <CardAddressDeliveryDate
                  :delivery-period="addressInitial.deliveryPeriod"
                  :delivery-date="addressInitial.deliveryDate"
                  :delivery-error="addressInitial.deliveryError"
                />
                <CardAddressDeliveryCost :shipping-cost="phoneDetails.shippingCost" />
              </template>
            </div>
            <div v-else>
              <template v-if="deliveryPeriod">
                <CardAddressDeliveryDate
                  :delivery-period="deliveryPeriod"
                  :delivery-date="addressFormCallejero.deliveryDate"
                  :delivery-error="deliveryError"
                />
                <CardAddressDeliveryCost :shipping-cost="phoneDetails.shippingCost" />
              </template>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <div v-if="origin === 'Finter'">
      <ModalAddress title="Escribe la dirección, ¿donde quieres recibir tu equipo?" @form="showAddress" />
    </div>
    <div v-if="origin === 'Libre'">
      <ModalAddress title="Escribe tu dirección" @form="showAddress" />
    </div>
  </b-card>
</template>

<script>
import { mapGetters } from 'vuex'
import store from '@/store'
import { FETCH_ADDRESS_DEPARTMENTS } from '@/store/actions.type'
import { gtmDataLayerTrackEvent } from '@/helpers/gtmHelper'

import ModalAddress from '@/components/modal/ModalAddress.vue'
import CardAddressOption from '@/components/address/CardAddressOption.vue'
import CardAddressDeliveryDate from '@/components/CardAddressDeliveryDate.vue'
import CardAddressDeliveryCost from '@/components/CardAddressDeliveryCost.vue'

export default {
  name: 'CardAddress',
  components: {
    CardAddressDeliveryCost,
    CardAddressDeliveryDate,
    CardAddressOption,
    ModalAddress,
  },
  props: {
    addressFormCallejero: Object,
    origin: {
      type: String,
      default: 'Finter',
    },
  },
  data() {
    return {
      initialAddress: true,
      deliveryPeriod: '',
      deliveryError: '',
      completeDirection: false,
      selectedAddress: '',
    }
  },
  methods: {
    showAddress() {
      this.initialAddress = false
    },
    getDepartments() {
      this.changeActive('HOME')
      store.dispatch(FETCH_ADDRESS_DEPARTMENTS)
    },
    selecionarWork() {
      if (this.addressInitial.validAddress) this.changeActive('WORK')
    },
    changeActive(active) {
      if (active === 'WORK') {
        if (this.origin === 'Libre') {
          gtmDataLayerTrackEvent(
            window.dataLayer,
            'trackEvent',
            'movistar money',
            'click::completar datos::paso 5',
            'mi dirección actual',
            this.CryptoJS.SHA1(this.currentApplication.mobile).toString()
          )
        }
        if (this.origin === 'Finter') {
          gtmDataLayerTrackEvent(
            window.dataLayer,
            'trackEvent',
            'movistar money',
            'click::dirección de entrega::paso 4',
            'enviar a mi dirección actual',
            this.CryptoJS.SHA1(this.currentApplication.mobile).toString()
          )
        }
        this.initialAddress = true
        this.$emit('selectAddress', 'WORK')
        this.$emit('enableButton', true)
        this.completeDirection = true
      } else {
        if (this.origin === 'Libre') {
          gtmDataLayerTrackEvent(
            window.dataLayer,
            'trackEvent',
            'movistar money',
            'click::completar datos::paso 5',
            'quiero otra dirección',
            this.CryptoJS.SHA1(this.currentApplication.mobile).toString()
          )
        }
        if (this.origin === 'Finter') {
          gtmDataLayerTrackEvent(
            window.dataLayer,
            'trackEvent',
            'movistar money',
            'click::dirección de entrega::paso 4',
            'enviar a otra dirección',
            this.CryptoJS.SHA1(this.currentApplication.mobile).toString()
          )
        }
        this.initialAddress = false
        this.$emit('selectAddress', 'HOME')
        if (this.origin === 'Finter') {
          if (this.deliveryPeriod) {
            this.completeDirection = true
            this.$emit('enableButton', true)
          } else {
            this.completeDirection = false
            this.$emit('enableButton', false)
          }
        } else {
          if (this.addressFormCallejero && this.addressFormCallejero?.formattedAddress) {
            this.completeDirection = true
            this.$emit('enableButton', true)
          } else {
            this.completeDirection = false
            this.$emit('enableButton', false)
          }
        }
      }
    },
    updateDeliveryPeriod(deliveryPeriod) {
      this.deliveryPeriod = deliveryPeriod
      this.completeDirection = true
      this.$emit('enableButton', true)
    },
    updateDeliveryError(deliveryError) {
      this.deliveryError = deliveryError
    },
  },
  computed: {
    ...mapGetters({
      phoneDetails: 'phoneDetails',
      addressInitial: 'addressClient',
      currentApplication: 'currentApplication',
    }),
    tituloComputed() {
      if (this.origin === 'Libre') {
        return 'Escribe una dirección'
      } else {
        return 'Elige la mejor opción para ti'
      }
    },
    opcaoAtualComputed() {
      if (this.origin === 'Libre') {
        return 'Mi dirección actual'
      } else {
        return 'Enviar a mi dirección actual'
      }
    },
    opcaoAlternativaComputed() {
      if (this.origin === 'Libre') {
        return 'Quiero otra dirección'
      } else {
        return 'Quiero enviar a otra dirección'
      }
    },
  },
  watch: {
    addressFormCallejero: {
      handler(params) {
        if (this.addressFormCallejero?.selectedDeliveryAddress) {
          this.changeActive('HOME')
        }
        this.updateDeliveryPeriod(params?.deliveryPeriod)
        this.updateDeliveryError(params?.deliveryError)
      },
    },
    addressInitial(value) {
      this.$emit('enableButton', value.validAddress)
      if (!value.validAddress) {
        this.initialAddress = false
      } else {
        this.selectedAddress = 'initial-address'
      }
    },
  },
}
</script>
<style scoped>
.address-warning {
  color: #e63780;
  font-size: 12px;
  font-family: Telefonica;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
}
.info-card {
  font-family: Telefonica;
  font-size: 14px;
  color: #707070;
  text-align: left;
}
</style>
