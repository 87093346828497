<template>
  <div class="mt-3">
    <b-form @submit.prevent="emitPayment" id="ePaycoForm">
      <b-row align-v="center">
        <b-col class="pl-2 pr-2">
          <p class="selecciona-el-valor-a-pagar">Selecciona el valor a pagar</p>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="m-0 p-0">
          <div class="radio-custom" @click="checkboxChange('saldoPendente')">
            <input v-model="selected" value="saldoPendente" id="radio-1" name="radio" type="radio" />
            <label for="radio-1" class="radio-label pagar-valor-pendiente">Pagar valor pendiente</label>
          </div>
        </b-col>
        <b-col class="pl-1 pr-0">
          <div class="radio-custom" @click="checkboxChange('outroValor')">
            <input v-model="selected" value="outroValor" id="radio-2" name="radio" type="radio" />
            <label for="radio-2" class="radio-label pagar-otro-valor">Pagar otro valor</label>
          </div>
        </b-col>
      </b-row>

      <b-row v-if="selected === 'outroValor'" align-v="center" class="mx-2">
        <b-col>
          <p class="texto-body">
            Ten en cuenta que el valor pagado se abonará en orden ascendente de tu cuota pendiente mas antigua a la mas
            reciente
          </p>
        </b-col>
      </b-row>

      <p v-if="selected === 'saldoPendente'">Total a pagar {{ saldoPendenteFmt }}</p>

      <b-row v-else align-v="center" class="mx-2">
        <b-col align-v="center">
          <b-input-group class="input-max-width">
            <b-input-group-prepend class="prepend-icon">
              <b-img src="@/assets/icons/cifrao.svg" alt="Dollar icon" />
            </b-input-group-prepend>
            <CurrencyInput v-model="value" />
          </b-input-group>
          <p v-if="pagamento.valorPagar <= 0" class="form-input-error text-center">
            Seleccione el método de pago / escribe el valor
          </p>
        </b-col>
      </b-row>
      <b-row align-v="center" v-if="pagamento.valorPagar > 0">
        <b-col v-if="selected === 'outroValor' && desabilitar">
          <p v-if="pagamento.valorPagar > valorMinimo" class="texto-pendiente-por-pago">
            Puedes pagar máximo el saldo actual pendiente por pago
          </p>
          <p v-else-if="pagamento.valorPagar < valorMinimo" class="texto-pendiente-por-pago">
            El monto mínimo de pago es de ${{ valorMinimo }}
          </p>
        </b-col>
      </b-row>
      <br />
      <b-row align-v="center" class="input-max-width">
        <b-col>
          <ButtonContinueBlue text="Pagar" :desabilitar="desabilitar" />
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import ButtonContinueBlue from '@/components/buttons/ButtonContinueBlue.vue'
import CurrencyInput from '@/components/inputs/CurrencyInput.vue'
import { Persist } from '@/helpers/persist.service'

export default {
  name: 'PagarCuota',
  components: { ButtonContinueBlue, CurrencyInput },
  props: {
    saldoPendente: Number,
    saldoPendenteFmt: String,
    keyMambu: String,
    meioPagamento: String,
  },
  data() {
    return {
      value: 0,
      valorMinimo: process.env.VUE_APP_EPAYCO_MIN_PAYMENT,
      pagamento: {
        keyMambu: '',
        valorPagar: null,
        tipoPagamento: 'Pagar cuota',
        abono: '',
        descricao: '',
        processamentoBackend: '',
        meioPagamento: 'Efectivo',
      },
      selected: '',
    }
  },
  mounted() {
    this.selected = Persist.getInstallmentPayment() == '' ? 'saldoPendente' : Persist.getInstallmentPayment()
    if (this.saldoPendente) {
      this.value = this.saldoPendente
      this.pagamento.valorPagar = this.saldoPendente
    }
    this.pagamento.meioPagamento = this.meioPagamento
    this.pagamento.descricao = this.pagamento.tipoPagamento
  },
  methods: {
    prePagamento() {
      this.pagamento.keyMambu = this.keyMambu
      this.pagamento.processamentoBackend = this.pagamento.tipoPagamento
      this.pagamento.descricao = this.pagamento.tipoPagamento
      this.pagamento.valorPagar = this.selected === 'outroValor' ? this.value : this.saldoPendente
    },
    async emitPayment() {
      if (!this.desabilitar) {
        this.prePagamento()
        this.$emit('pagar', this.pagamento)
      }
    },
    checkboxChange(state) {
      Persist.saveInstallmentPayment(state)
    },
  },
  watch: {
    value() {
      if (this.selected === 'outroValor') {
        this.pagamento.valorPagar = this.value
      }
    },
  },
  computed: {
    desabilitar() {
      return (
        this.saldoPendente <= 0 ||
        (this.selected === 'outroValor' &&
          (this.pagamento.valorPagar === null ||
            this.pagamento.valorPagar < this.valorMinimo ||
            this.pagamento.valorPagar > this.saldoPendente))
      )
    },
  },
}
</script>

<style scoped>
.texto-pendiente-por-pago {
  font-family: Telefonica;
  font-size: 12px;
  color: var(--movistar-pink);
}
.texto-valor-pagar {
  font-family: Telefonica, sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--movistar-pine-green);
}

.pagar-valor-pendiente,
.pagar-otro-valor {
  font-size: 13px;
}

.valor-pendiente-de-pago {
  font-family: Telefonica, sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #50535a;
}

.texto-body {
  font-family: Telefonica, sans-serif;
  font-size: 12px;
  text-align: center;
  color: #50535a;
}

.contorno-botao {
  border-radius: 18px;
}

.texto-titulo-abono-capital {
  font-family: Telefonica, sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--movistar-pine-green);
}

.prepend-icon {
  background-color: var(--white);
}

.input-max-width {
  display: flex;
  margin: auto;
  width: 150px;
}

.payment-type-text {
  font: 14px Telefonica, sans-serif;
  color: var(--movistar-grey-2);
  text-align: left;
}
</style>
