<template>
  <div>
    <button v-if="disable" class="continue-button continue-button-text disable" :disabled="disable" @click="emitClick">
      <span>{{ text }}</span>
    </button>
    <button v-else class="continue-button continue-button-text" :disabled="disable" @click="emitClick">
      <span>{{ text }}</span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'ButtonContinueLogin',
  props: {
    disable: {
      type: Boolean,
      default: false,
    },
    text: String,
  },
  methods: {
    emitClick() {
      this.$emit('click')
    },
  },
}
</script>

<style scoped lang="scss">
.continue-button {
  height: 36px;
  width: 180px;
}

.disable {
  background: #d3d4d3;
  color: var(--movistar-grey);
  pointer-events: none;
  cursor: default;
}
</style>
