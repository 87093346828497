<template>
  <div class="container-fluid mb-5">
    <Error
      title="¡Has superado el número de intentos de registro de información!"
      subtitle="Por favor inténtalo de nuevo en 24 horas"
      image="many-attempts.png"
    />
    <ErrorPagesButton />
  </div>
</template>

<script>
import Error from '../../components/Error'
import store from '../../store'
import { DESTROY_TOKEN, TOGGLE_OVERLAY } from '../../store/actions.type'
import ErrorPagesButton from '../../components/buttons/ErrorPagesButton'
import { gtmErrorTags } from '../../helpers/gtmHelper'
import { mapGetters } from 'vuex'

export default {
  name: 'ManyAttempts',
  components: {
    ErrorPagesButton,
    Error,
  },
  mounted() {
    gtmErrorTags(
      window.dataLayer,
      'trackEvent',
      'movistar money',
      'error',
      'exceso número de intentos de registros',
      this.CryptoJS.SHA1(this.currentApplication.mobile).toString()
    )
    store.dispatch(DESTROY_TOKEN)
    store.dispatch(TOGGLE_OVERLAY, false)
  },
  computed: {
    ...mapGetters({
      currentApplication: 'currentApplication',
    }),
  },
}
</script>
