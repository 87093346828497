<template>
  <div>
    <b-form @submit.prevent="emitPayment">
      <b-row v-if="atrasado" class="atraso-color">
        <b-col class="mt-4 ml-2" cols="1">
          <img src="@/assets/icons/warning-yellow.svg" alt="" />
        </b-col>
        <b-col>
          <p class="ft-12 max-width text-left mb-2 mt-2">
            Parece que tienes pagos pendientes, ten en cuenta que estos serán cubiertos primero y el restante se abonará
            a tu próxima cuota
          </p>
        </b-col>
      </b-row>
      <br />
      <b-row align-v="center">
        <b-col class="pl-2 pr-2">
          <p class="selecciona-el-valor-a-pagar">¿Cuánto es el monto que vas a adelantar?</p>
        </b-col>
      </b-row>
      <b-row align-v="center">
        <b-col>
          <p class="texto-body">
            El abono de las cuotas que pagues anticipadamente se irán descontando mes a mes con el pago correspondiente
            de tu factura.
          </p>
        </b-col>
      </b-row>
      <b-row align-v="center">
        <b-col align-v="center">
          <p class="texto-valor-pagar">
            <b-input-group class="input-max-width">
              <b-input-group-prepend class="prepend-icon">
                <b-img src="@/assets/icons/cifrao.svg" alt="Dollar icon" />
              </b-input-group-prepend>
              <CurrencyInput />
            </b-input-group>
          </p>
          <p
            v-if="pagamento.valorPagar === null || pagamento.valorPagar < this.valorMinimo"
            class="form-input-error text-center"
          >
            Escribe el valor mayor que ${{ valorMinimo }}
          </p>
          <p v-if="pagamento.valorPagar > saldoTotal" class="form-input-error text-center">
            No puede pagar más del monto total adeudado ({{ valorLiquidacaoFormatado }})
          </p>
        </b-col>
      </b-row>
      <br />
      <b-row align-v="center" class="input-max-width">
        <b-col>
          <ButtonContinueBlue text="Continuar" :desabilitar="desabilitar" />
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import ButtonContinueBlue from '@/components/buttons/ButtonContinueBlue.vue'
import CurrencyInput from '@/components/inputs/CurrencyInput.vue'
import { SELF_MANAGE_GET_APPLICATION_BALANCE } from '@/store/actions.type'
import store from '@/store'
import { mapGetters } from 'vuex'

export default {
  name: 'PagarAdelantoCuota',
  components: { ButtonContinueBlue, CurrencyInput },
  props: {
    keyMambu: String,
    planoPagamento: {},
    meioPagamento: String,
  },
  data() {
    return {
      value: '',
      valorMinimo: process.env.VUE_APP_EPAYCO_MIN_PAYMENT,
      pagamento: {
        keyMambu: '',
        valorPagar: null,
        tipoPagamento: 'Adelanto de cuota',
        abono: '',
        descricao: '',
        processamentoBackend: '',
        meioPagamento: 'Efectivo',
      },
    }
  },
  mounted() {
    this.pagamento.meioPagamento = this.meioPagamento
    store.dispatch(SELF_MANAGE_GET_APPLICATION_BALANCE, this.keyMambu)
  },
  methods: {
    prePagamento() {
      this.pagamento.keyMambu = this.keyMambu
      this.pagamento.processamentoBackend = this.pagamento.tipoPagamento
      this.pagamento.descricao = this.pagamento.tipoPagamento
    },
    async emitPayment() {
      this.prePagamento()
      this.$emit('pagar', this.pagamento)
    },
  },
  computed: {
    ...mapGetters({
      saldoTotal: 'totalBalance',
    }),
    valorLiquidacaoFormatado() {
      let valorFormatado = Intl.NumberFormat('es-CO')
      return valorFormatado.format(this.saldoTotal)
    },
    atrasado() {
      if (this.planoPagamento.globalData.lastDueDate === 'Inmediato') {
        return true
      }
      return false
    },
    desabilitar() {
      if (
        this.pagamento.valorPagar === null ||
        this.pagamento.valorPagar < this.valorMinimo ||
        this.pagamento.valorPagar > this.saldoTotal
      ) {
        return true
      }
      return false
    },
    optionsCurrency() {
      return {
        currency: null,
        distractionFree: {
          hideNegligibleDecimalDigits: false,
          hideCurrencySymbol: true,
          hideGroupingSymbol: false,
        },
        autoDecimalMode: true,
      }
    },
  },
  watch: {
    value() {
      this.pagamento.valorPagar = this.value
    },
  },
}
</script>

<style scoped>
.ft-12 {
  font-family: Telefonica;
  font-size: 12px;
}
.texto-valor-pagar {
  font-family: Telefonica, sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--movistar-pine-green);
}

.valor-pendiente-de-pago {
  font-family: Telefonica, sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #50535a;
}

.texto-body {
  font-family: Telefonica, sans-serif;
  font-size: 12px;
  text-align: center;
  color: #50535a;
}

.contorno-botao {
  border-radius: 18px;
}

.texto-titulo-abono-capital {
  font-family: Telefonica, sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--movistar-pine-green);
}

.prepend-icon {
  background-color: var(--white);
}

.input-max-width {
  display: flex;
  margin: auto;
  width: 150px;
}
.max-width {
  margin: auto;
  width: 253px;
}

.payment-type-text {
  font: 14px Telefonica, sans-serif;
  color: var(--movistar-grey-2);
  text-align: left;
}
.atraso-color {
  background: #d8d8d8;
}
</style>
