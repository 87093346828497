<template>
  <b-modal
    id="modal-forgot-password"
    class="modal"
    cancel-title=""
    cancel-variant="hidden"
    size="sm"
    ok-title=""
    ok-variant="hidden"
    centered
    header-border-variant="white"
    footer-border-variant="white"
    no-close-on-esc
    no-close-on-backdrop
    hide-header
    hide-footer
  >
    <div class="mx-3">
      <b-row align-h="end">
        <b-button variant="blank" @click="closeModal">
          <b-img src="@/assets/icons/cancel.svg" alt="Cancel icon" />
        </b-button>
      </b-row>
      <div class="py-5">
        <p v-if="message" class="text-center onboard-error-subtitle">{{ message }}</p>
        <div v-else>
          <b-skeleton animation="wave" width="85%"></b-skeleton>
          <b-skeleton animation="wave" width="55%"></b-skeleton>
          <b-skeleton animation="wave" width="70%"></b-skeleton>
        </div>
        <b-img
          class="pt-4 image-custom-height"
          center
          align-h="center"
          src="@/assets/images/check-email.png"
          alt="Modal image"
        />
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'ModalForgotPassword',
  props: {
    image: String,
    message: String,
  },
  methods: {
    closeModal() {
      this.$bvModal.hide('modal-forgot-password')
    },
  },
}
</script>

<style>
.image-custom-height {
  width: 150px;
}
</style>
