<template>
  <div>
    <header v-if="status">
      <img src="@/assets/images/icon-money-1.png" alt="heade-image" class="img-money-offer" />
      <Stepper :step="step" class="step-counter-offer" />
      <section class="header-text-info" v-if="status == 'DOWNGRADE'">
        <h1 class="text-intro-counter-offer">
          Este es el monto máximo que te podemos <br />
          prestar, elige el plazo que prefieras
        </h1>
        <h2 class="legend-intro-counter-offer">Elige el nuevo monto y el plazo de tu crédito</h2>
      </section>
      <section class="header-text-info" v-if="status == 'UPGRADE'">
        <h1 class="text-intro-counter-offer">¡Tenemos una mejor oferta para ti!</h1>
        <h2 class="legend-intro-counter-offer">
          Eres un cliente premium, hemos ampliado el <br />
          monto de tu crédito y mejoramos tus condiciones.
        </h2>
      </section>
      <section class="header-text-info" v-if="status == 'MAINTAIN'">
        <h1 class="text-intro-counter-offer">¡Selecciona el plazo que prefieras!</h1>
        <h2 class="legend-intro-counter-offer">Elige el nuevo monto y el plazo de tu crédito</h2>
      </section>
    </header>
  </div>
</template>

<script>
import Stepper from '../../Stepper'
import { mapGetters } from 'vuex'
import { gtmDataLayerCustom } from '../../../helpers/gtmHelper'
export default {
  components: { Stepper },
  data() {
    return {
      step: 2,
      status: undefined,
    }
  },
  watch: {
    counterOfferData: {
      handler() {
        if (this.counterOfferData) {
          this.status = this.counterOfferData.response.calculator.status
          let pageName
          switch (this.counterOfferData.response.calculator.status) {
            case 'DOWNGRADE':
              pageName = 'menor-capacidad-prestamo'
              break
            case 'UPGRADE':
              pageName = 'mayor-capacidad-prestamo'
              break
            case 'MAINTAIN':
              pageName = 'modificar-tasa-credito'
              break
          }
          gtmDataLayerCustom(window.dataLayer, {
            event: 'trackPageview',
            pageName: '/movistar-money/prestamo-libre-inversión/' + pageName,
          })
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      counterOfferData: 'counterOfferData',
    }),
  },
}
</script>

<style scoped>
.text-intro-counter-offer {
  font-family: Telefonica;
  font-size: 18px;
  font-weight: 300;
  color: #0b2739;
}

.legend-intro-counter-offer {
  font-family: Telefonica;
  font-size: 12px;
  font-weight: 300;
  color: #0b2739;
}

.step-counter-offer {
  margin-top: 1.5rem;
}
</style>
