<template>
  <div class="container-fluid mb-5">
    <b-container>
      <StripesTopImage />

      <MovistarMoneyLogo />

      <Stepper :step="step" />

      <b-row v-if="evidenteQuestions" class="text-center" align-h="center">
        <b-col cols="12" md="6" class="no-padding">
          <p class="title-onboard mb-1">Vamos a validar tu identidad</p>
          <div v-for="item in evidenteQuestions.response.Pregunta" :key="item.orden" class="radio-buttons-container">
            <RadioButtonGroup :title="item.texto" :name="item.orden" :options="item.Respuesta" @change="updateOption" />
          </div>
          <b-row>
            <b-col>
              <div class="mt-5">
                <ButtonContinue text="Confirmar" @click="goNextPage" :disable="this.disableButton" origin="Libre" />
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <ModalEvidente
        title="Por tu seguridad vamos a validar tu identidad con unas preguntas"
        subtitle="Antes de empezar esta validación ingresa la fecha de expedición de tu documento"
        @next="closeModal"
        :origin="this.currentApplication.origin"
      />

      <Modal
        v-if="this.currentApplication.origin === 'Finter'"
        id="modal-basic"
        subtitle="¡Felicidades aprobamos la solicitud de préstamo para tu equipo!"
        image="felicitaciones.png"
      />
      <Modal
        v-if="this.currentApplication.origin === 'Libre'"
        id="modal-basic"
        subtitle="¡Felicidades hemos validado tu identidad y tu solicitud de crédito ha sido recibida correctamente!"
        image="felicitaciones.png"
      />
    </b-container>
  </div>
</template>

<script>
import StripesTopImage from '../components/StripesTopImage'
import MovistarMoneyLogo from '../components/MovistarMoneyLogo'
import Stepper from '../components/Stepper'
import RadioButtonGroup from '../components/buttons/RadioButtonGroup'
import ButtonContinue from '../components/buttons/ButtonContinue'
import ModalEvidente from '../components/modal/ModalEvidente'
import store from '../store'
import {
  FETCH_APPLICATION_REDIRECT,
  FETCH_EVIDENTE_QUESTIONS,
  SEND_EVIDENTE_QUESTIONS,
  TOGGLE_OVERLAY,
} from '../store/actions.type'
import { mapGetters } from 'vuex'
import Modal from '../components/modal/Modal'
import { gtmDataLayerCheckout, gtmDataLayerTrackEvent } from '../helpers/gtmHelper'

export default {
  name: 'Evidente',
  components: {
    ButtonContinue,
    Modal,
    ModalEvidente,
    RadioButtonGroup,
    Stepper,
    MovistarMoneyLogo,
    StripesTopImage,
  },
  data() {
    return {
      disableButton: true,
      step: 3,
      respuesta: {},
      date: '',
      controleRespostas: [],
      form: {
        action: 'verifyAnswers',
        respuestas: {
          idCuestionario: '',
          regCuestionario: '',
          respuesta: '',
        },
      },
    }
  },
  mounted() {
    if (this.currentApplication.origin === 'Finter') {
      gtmDataLayerCheckout(
        window.dataLayer,
        'checkOutView',
        this.phoneDetails.deviceModel,
        this.phoneDetails.sku,
        this.phoneDetails.price,
        '__MARCA__',
        'movistar money',
        this.phoneDetails.deviceColor,
        this.step
      )
    }
    if (this.currentApplication.origin === 'Libre') {
      gtmDataLayerCheckout(
        window.dataLayer,
        'checkOutView',
        this.currentApplication.requestedValueFmt,
        this.currentApplication.installmentsNumber,
        this.currentApplication.offer.feesDueFmt,
        'prestamo libre inversión',
        'movistar money',
        '0',
        this.step
      )
    }
    this.showModal('modal-evidente')
  },
  methods: {
    showModal(modalId) {
      this.$bvModal.show(modalId)
    },
    updateOption(param) {
      const order = param.order
      const newElement = {
        [order]: {
          idPregunta: order,
          idRespuesta: param.answer,
        },
      }
      this.respuesta = Object.assign(this.respuesta, newElement)
      this.controleRespostas[param.order] = true
      if (Object.keys(this.controleRespostas).length >= this.evidenteQuestions.response.Pregunta.length)
        this.disableButton = false
    },
    goNextPage() {
      this.disableButton = true
      this.handleForm()
      gtmDataLayerTrackEvent(
        window.dataLayer,
        'trackEvent',
        'movistar money',
        'click::preguntas de seguridad::paso 3',
        'validar respuestas de las preguntas de seguridad',
        this.CryptoJS.SHA1(this.currentApplication.mobile).toString()
      )
      store.dispatch(SEND_EVIDENTE_QUESTIONS, this.form).then((response) => {
        let status
        if (response.situation) status = response.situation.toLowerCase()

        if (status === 'reproved' || status === 'infolaftfailure') {
          this.$router.push({ name: 'CantProcessRequest' })
        } else {
          this.showModal('modal-basic')
          setTimeout(() => {
            this.$store.dispatch(FETCH_APPLICATION_REDIRECT)
          }, 5000)
        }
      })
    },
    handleForm() {
      this.form.respuestas.respuesta = Object.values(this.respuesta)
      this.form.respuestas.idCuestionario = this.evidenteQuestions.response.id
      this.form.respuestas.regCuestionario = this.evidenteQuestions.response.registro
    },
    closeModal(date) {
      this.$bvModal.hide('modal-evidente')
      this.date = date
      this.getQuestions()
    },
    getQuestions() {
      store.dispatch(TOGGLE_OVERLAY, true)
      store.dispatch(FETCH_EVIDENTE_QUESTIONS, this.bodyListQuestions())
    },
    bodyListQuestions() {
      return {
        action: 'listQuestions',
        dateExpedition: this.date,
        isPartial: true,
      }
    },
  },
  computed: {
    ...mapGetters({
      phoneDetails: 'phoneDetails',
      applicationOffer: 'applicationOffer',
      evidenteQuestions: 'evidenteQuestions',
      currentApplication: 'currentApplication',
    }),
  },
}
</script>
<style scoped>
.disable {
  background: #d3d4d3;
  color: var(--movistar-grey);
  pointer-events: none;
  cursor: default;
}
.continue-libre {
  background: #ec624b !important;
}
</style>
