<template>
  <PreValidacaoLayout
    subtitle="Validación de identidad"
    :description="`Hemos enviado un código a la línea ${basicDataRequest.mobile},  lo recibirás en unos segundos`"
  >
    <b-card border-variant="light">
      <template #header>
        <div class="card-title">
          <h4 class="title">Ingresa aquí el código con máximo 3 intentos</h4>
        </div>
        <div class="divider"></div>
      </template>
      <b-form class="d-flex flex-column align-items-center">
        <div class="d-flex">
          <div class="d-flex flex-column">
            <v-otp-input
              ref="otpInput"
              :input-classes="'otp-input ' + (errorResponse?.pinError === 'WRONG_PIN' ? 'invalid' : '')"
              separator=""
              :num-inputs="4"
              :should-auto-focus="true"
              :is-input-num="true"
              @on-change="aoAlterarPin"
            />
            <div class="otp-tip mt-3 d-flex justify-content-between">
              <span>Válido por <span class="blue" v-text="contador" /> segundos</span>
              <b-button
                variant="link"
                class="font-12 no-margin resend-code no-padding"
                @click="enviarPin($refs.otpInput)"
                :disabled="!ativarEnviarCodigo"
              >
                <span>Enviar un código nuevo</span>
              </b-button>
            </div>
          </div>
          <img class="lock" src="@/assets/icons/lock-gray.svg" alt="Lock icon" />
        </div>
        <p class="text-red font-14 mt-4 mb-0" v-text="pinErrorMessage" />
      </b-form>
    </b-card>
    <div class="button-content">
      <Button
        class="button-continuar"
        @click="validarPin($bvModal, $refs.otpInput)"
        :disabled="desativarBotaoContinuar"
      >
        <span>Continuar</span>
        <img src="@/assets/icons/arrow-white.svg" class="arrow" width="15" />
      </Button>
    </div>
    <Modal id="modal-tec-negado" message="Pendiente aceptar TyC enviados al correo">
      <template #icon>
        <img src="@/assets/icons/negado.svg" alt="Error icon" width="43px" />
      </template>
      <template #button>
        <Button class="button-voltar" @click="$bvModal.hide('modal-tec-negado')">
          <img src="@/assets/icons/arrow-white.svg" class="arrow" width="15" />
          <span>Volver</span>
        </Button>
      </template>
    </Modal>
  </PreValidacaoLayout>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { useRoute } from 'vue-router/composables'

import PreValidacaoLayout from '@/components/PreValidacao/PreValidacaoLayout.vue'
import Button from '@/components/PreValidacao/Button.vue'
import Modal from '@/components/PreValidacao/Modal.vue'
import store from '@/store'
import { ENVIAR_PIN_BUSCADOR, VALIDAR_PIN_BUSCADOR, PRE_VALIDADOR_REQUEST_BASIC } from '@/store/actions.type'
import { SET_BUSCADOR_BASIC_DATA_REQUEST } from '@/store/mutations.type'

import CryptoJS from 'crypto-js'

const route = useRoute()
const basicDataRequest = computed(() => store.getters.buscadorBasicDataRequest)
const hashValidacaoOTP = computed(() => store.getters.hashValidacaoOTP)
const contador = ref(80)

onMounted(() => {
  enviarPin()
  setInterval(() => {
    if (contador.value > 0) contador.value--
  }, 1000)
})

function enviarPin(input) {
  if (input) {
    input.clearInput()
  }
  contador.value = 80
  errorResponse.value = null

  const queryData = decodeURIComponent(route.query.data)
  let decryptedObject = JSON.parse(
    CryptoJS.AES.decrypt(queryData, process.env.VUE_APP_SECRET_SITE).toString(CryptoJS.enc.Utf8)
  )
  store.dispatch(ENVIAR_PIN_BUSCADOR, {
    documentNumber: basicDataRequest.value.identityCardNumber,
    mobile: basicDataRequest.value.mobile,
    email: basicDataRequest.value.email,
    requestCode: decryptedObject.userCode,
    requestName: decryptedObject.name,
  })
}

const pinDigitado = ref('')
const errorResponse = ref(null)

const pinErrorMessage = computed(() => {
  if (errorResponse.value?.pinError === 'TTL_EXPIRED') return 'El código ha expirado, por favor solicita uno nuevo'
  if (errorResponse.value?.attemptsRemaining == 0 || errorResponse.value?.pinError === 'NO_MORE_PIN_ATTEMPTS')
    return 'Has superado el número de intentos permitidos, por favor solicita un nuevo código'
  return null
})

function aoAlterarPin(pin) {
  pinDigitado.value = pin
}

function validarPin(modalContext, input) {
  store
    .dispatch(VALIDAR_PIN_BUSCADOR, pinDigitado.value)
    .then(() => {
      errorResponse.value = null
      store.commit(SET_BUSCADOR_BASIC_DATA_REQUEST, {
        ...basicDataRequest.value,
        hashOTP: hashValidacaoOTP.value,
      })
      store.dispatch(PRE_VALIDADOR_REQUEST_BASIC).catch((errorCode) => {
        if (errorCode === 'PENDING_TERMS_AND_CONDITIONS') {
          modalContext.show('modal-tec-negado')
        }
      })
    })
    .catch((error) => {
      errorResponse.value = error
      input.clearInput()
    })
}

const desativarBotaoContinuar = computed(() => {
  return (
    pinDigitado.value.length < 4 ||
    errorResponse.value?.pinError === 'TTL_EXPIRED' ||
    errorResponse.value?.attemptsRemaining == 0
  )
})

const ativarEnviarCodigo = computed(() => contador.value === 0)
</script>

<style scoped lang="scss">
.card {
  max-width: 41.25rem;
  margin: 0 auto;
  background-color: #f5f5f5;
  border-radius: 10px;
  .card-header {
    background: none;
    border-bottom: none;
    padding: 27px 37px;
    .card-title {
      .title {
        font-size: 25px;
        text-align: left;
        margin-bottom: 0;
        font-weight: bold;
      }
    }

    .divider {
      width: 100%;
      height: 0px;
      margin-top: 1rem;
      border-bottom: solid 0.5px #707070;
    }
  }

  .card-body {
    padding: 27px 37px 40px 37px;
  }
}

::v-deep .otp-input {
  width: 3.75rem;
  height: 3.75rem;
  border: 1px solid #86888c;
  border-radius: 3.5px;
  font-size: 16px;
  background-color: transparent;
  &.invalid {
    border: 1px solid #ff0000;
  }
}

.otp-tip {
  margin: 0 10px;
  font-size: 14px;
  a {
    color: #019df4;
    text-decoration: underline;
  }
  .blue {
    color: #019df4;
  }
}

.text-red {
  color: #ff0000;
}

.lock {
  margin-left: 0.625rem;
  margin-bottom: 2rem;
}

.button-content {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.875rem;
}

.button-continuar {
  .arrow {
    margin-left: 12px;
    transform: rotate(270deg);
  }
}

.button-voltar {
  .arrow {
    margin-right: 12px;
    transform: rotate(90deg);
  }
}
</style>
