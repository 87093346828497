<template>
  <b-form @submit.prevent="enviarFormulario()" class="radio-custom my-5">
    <!-- <fieldset>
      <input type="radio" id="tarjeta" value="pagarTotal" v-model="selected" />
      <label for="tarjeta" class="radio-label">Pagar valor pendiente</label>
    </fieldset>
    <p class="total-pagar">
      Total a pagar <span class="value">{{ advancedPayment.advancedPaymentFmt }}</span>
    </p> -->
    <div class="d-flex justify-content-center mt-5">
      <ButtonContinueBlue text="Pagar" class="w-50" :desabilitar="desabilitar" />
    </div>
    <span class="texto-error">
      {{ errorEpaycoResponse?.data?.message }}
    </span>
  </b-form>
</template>
<script>
import ButtonContinueBlue from '../buttons/ButtonContinueBlue.vue'
import { mapGetters } from 'vuex'
export default {
  components: { ButtonContinueBlue },
  data() {
    return {
      selected: 'pagarTotal',
      desabilitar: false,
    }
  },
  computed: {
    ...mapGetters({
      advancedPayment: 'advancedPayment',
      errorEpaycoResponse: 'errorEpaycoResponse',
    }),
  },
  methods: {
    enviarFormulario() {
      this.$emit('pagar', this.selected)
      this.desabilitarBotao()
    },
    desabilitarBotao() {
      this.desabilitar = true
      setTimeout(() => {
        this.desabilitar = false
      }, 60000)
    },
  },
}
</script>
<style lang="scss">
.total-pagar {
  font-size: 18px;
  color: #3b3a3a;
  .value {
    font-size: 20px;
    color: #0b2739;
    margin-left: 0.5rem;
  }
}
.texto-error {
  color: #ff0000;
}
</style>
