<template>
  <div>
    <b-row class="text-center" align-h="center">
      <b-col cols="12" md="6" class="no-padding">
        <select v-model="selectBank" class="select-bank">
          <option value="0" class="default-option-bank">Selecciona tu banco</option>
          <option v-for="option in banks" v-bind:value="option" :key="option.id" class="default-option-bank">
            {{ option.name }}
          </option>
        </select>
        <h1 class="title-container-radio mt-3">Selecciona el tipo de cuenta</h1>
        <b-row align-h="center" class="mt-3 mr-1 ml-1">
          <b-col class="radio-custom" @click="modificarValorInput('Ahorros')">
            <input id="Ahorros" value="Ahorros" v-model="payload.accountType" type="radio" />
            <label for="radio-1" class="radio-label ft-14"> Ahorros </label>
          </b-col>
          <b-col class="radio-custom" @click="modificarValorInput('Corriente')">
            <input id="Corriente" value="Corriente" v-model="payload.accountType" type="radio" />
            <label for="radio-2" class="radio-label ft-14"> Corriente </label>
          </b-col>
        </b-row>
        <fieldset class="m-3">
          <label class="label-account">Escribe tu número de cuenta</label>
          <div class="input-group">
            <img src="../../assets/icons/conta.jpeg" alt="Phone icon" class="icone-conta" />
            <input
              type="text"
              maxlength="26"
              v-mask="'####################'"
              v-model="payload.accountNumber"
              placeholder="000 000 000 000 000 000"
              class="input-number-account"
              @click="exibirCardMensagem"
            />
          </div>
        </fieldset>
        <div class="container-box-bank">
          <BoxBankData />
        </div>

        <div class="m-2">
          <ButtonContinue text="Continuar" origin="Libre" @click="showModal" :disable="disableButton" />
        </div>
        <b-modal ref="my-modal" cancel-title="" cancel-variant="hidden" hide-footer hide-header>
          <div class="text-right">
            <b-button variant="none" @click="closeModal">
              <b-img src="@/assets/icons/cancelar-regular.svg" alt="Cancel icon" />
            </b-button>
          </div>
          <div class="d-block text-center">
            <h2 class="title-bank-modal">Antes de continuar</h2>
            <section class="container-legend-modal">
              <img src="@/assets/icons/alerta-azul.svg" alt="heade-image" class="arrow-blue" />
              <h3 class="legend-bank-modal">Verifica los datos tu cuenta</h3>
            </section>
          </div>

          <div>
            <ul class="list-modal-bank">
              <li>
                <h1 class="list-header-modal">Banco:</h1>
                <p class="list-content-modal">{{ selectBank.name }}</p>
              </li>
              <li>
                <h1 class="list-header-modal">Tipo de cuenta:</h1>
                <p class="list-content-modal">{{ payload.accountType }}</p>
              </li>
              <li>
                <h1 class="list-header-modal">Número de cuenta:</h1>
                <p class="list-content-modal">{{ payload.accountNumber }}</p>
              </li>
            </ul>
          </div>
          <div class="footer-modal-bank">
            <button @click="closeModal" class="edit-bank-data">Editar</button>
            <button @click="handleBankData" class="confirm-bank-data">Continuar</button>
          </div>
        </b-modal>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { SALVAR_DADOS_BANCARIOS, TOGGLE_OVERLAY } from '../../store/actions.type'
import store from '../../store'
import ButtonContinue from '../../components/buttons/ButtonContinue.vue'
import BoxBankData from './BoxBankData.vue'
import { BUSCAR_BANCOS } from '../../store/actions.type'
import { gtmDataLayerTrackEvent, gtmDataLayerCheckout } from '../../helpers/gtmHelper'

export default {
  name: 'InputMoneyBankData',
  components: { ButtonContinue, BoxBankData },
  data: function () {
    return {
      moreInfo: false,
      selectBank: 0,
      payload: {
        action: 'saveBankData',
        bankName: '',
        bankNumber: '',
        accountType: 'Ahorros',
        accountNumber: '',
      },
    }
  },
  mounted() {
    this.$store.dispatch(BUSCAR_BANCOS)
    gtmDataLayerCheckout(
      window.dataLayer,
      'checkOutView',
      this.currentApplication.requestedValueFmt,
      this.currentApplication.installmentsNumber,
      this.currentApplication.offer.feesDueFmt,
      'prestamo libre inversión',
      'movistar money',
      '0',
      this.step
    )
  },
  watch: {
    banks() {
      this.changeBank()
    },
  },
  computed: {
    ...mapGetters({
      currentApplication: 'currentApplication',
      banks: 'banks',
    }),
    disableButton: function () {
      return !(this.selectBank != 0 && this.payload.accountType && this.payload.accountNumber)
    },
  },
  methods: {
    modificarValorInput(payload) {
      this.payload.accountType = payload
    },
    exibirCardMensagem() {
      this.$emit('exibirCardMensagem', true)
    },
    showModal() {
      this.$refs['my-modal'].show()
      gtmDataLayerTrackEvent(
        window.dataLayer,
        'trackEvent',
        'movistar money',
        'click::datos de cuenta::paso 4',
        'continuar',
        this.CryptoJS.SHA1(this.currentApplication.mobile).toString()
      )
    },
    closeModal() {
      this.$refs['my-modal'].hide()
    },
    changeBank() {
      if (this.currentApplication.bankData && this.banks.length > 0) {
        this.payload.bankName = this.currentApplication.bankData.bankName
        this.payload.accountType = this.currentApplication.bankData.accountType
        this.payload.accountNumber = this.currentApplication.bankData.accountNumber
        this.selectBank = this.banks.filter((value) => value.name === this.currentApplication.bankData.bankName)[0]
        gtmDataLayerTrackEvent(
          window.dataLayer,
          'trackEvent',
          'movistar money',
          'click::datos de cuenta::paso 4',
          'selección de banco',
          this.CryptoJS.SHA1(this.currentApplication.mobile).toString()
        )
      }
    },
    handleBankData() {
      this.closeModal()
      store.dispatch(TOGGLE_OVERLAY, true)
      this.payload.bankName = this.selectBank.name
      this.payload.bankNumber = String(this.selectBank.id)
      store.dispatch(SALVAR_DADOS_BANCARIOS, this.payload)
      gtmDataLayerTrackEvent(
        window.dataLayer,
        'trackEvent',
        'movistar money',
        'click::datos de cuenta::paso 4',
        'verificar datos de cuenta',
        this.CryptoJS.SHA1(this.currentApplication.mobile).toString()
      )
    },
  },
}
</script>

<style scoped>
.btn {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.radio-custom {
  font-size: 14px;
  color: var(--pine-green);
}
.icone-conta {
  width: 22px;
  height: 15.6px;
  position: relative;
  top: 5px;
  margin-right: 10px;
}

.confirm-bank-data {
  font-size: 14px;
  font-weight: bold;
  width: 122px;
  height: 36px;
  margin: 0 0 0 0.5rem;
  border-radius: 40px;
  background-color: var(--movistar-orange);
  border: none;
  color: #ffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit-bank-data {
  font-size: 14px;
  font-weight: bold;
  color: var(--movistar-orange);
  width: 122px;
  height: 36px;
  margin: 0 0 0 0;
  border-radius: 40px;
  border: solid 1px var(--movistar-orange);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  background: #ffff;
}

.footer-modal-bank {
  display: flex;
  align-content: center;
  justify-content: center;
}

.container-legend-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}
.container-box-bank {
  margin: 1rem;
}
.title-bank-modal {
  color: #50535a;
  font-size: 14px;
  text-align: start;
  padding-left: 24px;
  margin: 0;
}

.legend-bank-modal {
  color: var(--azure);
  font-size: 18px;
  position: relative;
  margin: 0 0 0 0.5rem;
}

.list-header-modal {
  color: #50535a;
  font-size: 12px;
  margin-bottom: 3px;
}

.list-content-modal {
  color: #0b2739;
  font-size: 14px;
  margin-bottom: 5px;
}

.list-modal-bank {
  list-style: none;
  padding-left: 20px;
  margin-top: 10px;
}

.label-account {
  font-size: 14px;
  position: relative;
  left: -45px;
}

.input-number-account {
  border: 0;
  width: 279.6px;
  text-align: left;
}

.input-radio {
  width: 20px;
  height: 20px;
}

.title-container-radio {
  font-size: 14px;
  color: var(--pine-green);
}

.container-input-radio {
  margin-top: 2rem;
}

.content-input-radio {
  display: flex;
  justify-content: center;
}

.ahorros-field {
  margin-right: 1.5rem;
}

.select-bank {
  background: white;
  border: none;
  border-bottom: 1px solid #b6b7b7;
  flex: 1;
  font-size: 16px;
  text-align: center;
}

.default-option-bank {
  text-align: left;
  color: #86888c;
  font-size: 14px;
}

.field {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}

input[type='radio']:checked + label:before,
input[type='checkbox']:checked + label:before {
  background: #019df4;
}

.label-input-radio {
  margin-left: 8px;
  position: relative;
  top: -5px;
  font-size: 14px;
}

@media (min-width: 320px) and (max-width: 340px) {
  .input-number-account {
    border: 0;
    width: 240px;
    text-align: left;
  }
  .legend-bank-modal {
    color: var(--azure);
    font-size: 18px;
    position: relative;
    top: 5px;
    margin: 0 0.5rem 0;
  }
}

.xxx {
  margin-bottom: 3rem;
}
</style>
