<template>
  <b-modal
    id="modal-rechazar"
    cancel-title=""
    cancel-variant="hidden"
    size="xl"
    hide-header
    hide-footer
    ok-title=""
    ok-variant="hidden"
    centered
    header-border-variant="white"
    footer-border-variant="white"
  >
    <div class="my-3">
      <p class="text-center subtitle-onboard text-grey-2 mb-4">{{ title }}!</p>

      <p class="text-center subtitle-onboard">{{ subtitle }}</p>

      <b-row>
        <b-col>
          <div class="mt-3">
            <ButtonContinue text="Rechazar" :inverted="true" @click="rechazar" />
          </div>
        </b-col>
        <b-col>
          <div class="mt-3">
            <ButtonContinue text="Permitir" @click="goNextPage" />
          </div>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import ButtonContinue from '../buttons/ButtonContinue'

export default {
  name: 'ModalRechazar',
  components: { ButtonContinue },
  props: {
    title: String,
    subtitle: String,
  },
  methods: {
    rechazar() {
      this.$emit('rechazar')
    },
    goNextPage() {
      this.$emit('nextPage')
    },
  },
}
</script>
