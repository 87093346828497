<template>
  <div class="container-offer-simulation">
    <div class="content-offer-simsulation">
      <HeaderOfferSimulation />
      <InputRangeOfferSimulation />
    </div>
  </div>
</template>

<script>
import HeaderOfferSimulation from '../../components/OfferSimulation/HeaderOfferSimulation.vue'
import InputRangeOfferSimulation from '../../components/OfferSimulation/InputRangeOfferSimulation.vue'
import { gtmDataLayerCustom } from '../../helpers/gtmHelper'
export default {
  name: 'HomeOfferSimulation',
  components: { HeaderOfferSimulation, InputRangeOfferSimulation },
  mounted() {
    gtmDataLayerCustom(window.dataLayer, {
      event: 'trackPageview',
      pageName: '/movistar-money/prestamo-libre-inversión',
    })
  },
}
</script>

<style scoped>
.container-offer-simulation {
  background: #ffffff;
  height: 100vh;
  width: 100%;
}

.content-offer-simsulation {
  display: flex;
  position: relative;
  top: 24px;
  align-items: center;
  flex-direction: column;
}
</style>
