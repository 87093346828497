import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from '../languages/index'

Vue.use(VueI18n)

export default new VueI18n({
  locale: process.env.VUE_APP_LOCALE, // set locale
  fallbackLocale: process.env.VUE_APP_FALLBACK_LOCALE, // set fallback locale
  messages, // set locale messages
})
