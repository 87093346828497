<template>
  <div>
    <header>
      <img src="@/assets/images/icon-money-1.png" alt="heade-image" class="img-money-offer" />
      <Stepper :step="step" class="step-counter-offer" />
      <p class="title-resumen">¡Genial, ya casi está!</p>
    </header>
  </div>
</template>

<script>
import Stepper from '../Stepper'

export default {
  components: { Stepper },
  data() {
    return {
      step: 5,
    }
  },
}
</script>

<style scoped>
.title-resumen {
  font-size: 20px;
  color: #0b2739;
}
</style>
