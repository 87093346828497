<template>
  <div class="container-sm-credits">
    <FastPaymentCard :propostaMambu="this.propostaMambu" :planoPagamento="planoPagamento" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FastPaymentCard from '@/components/AcessoRapido/FastPaymentCard.vue'

export default {
  name: 'FastPayment',
  components: { FastPaymentCard },
  computed: {
    ...mapGetters({
      propostaMambu: 'fastPaymentApplicationMambuSelecionado',
      planoPagamento: 'fastPaymentApplicationPaymentPlan',
    }),
  },
}
</script>
