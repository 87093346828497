<template>
  <div class="container-otp">
    <p class="title-onboard-otp mb-5 mx-4">
      Hemos enviado un código a la línea <br />
      {{ phoneDetails.phoneNumber }}, lo recibirás en unos segundos
    </p>

    <b-row>
      <b-col>
        <p>Ingresa aquí el código con un máximo de 3 intentos</p>
      </b-col>
    </b-row>

    <div>
      <b-row align-h="center">
        <b-img src="../../../assets/icons/lock.svg" alt="Lock icon" />
        <v-otp-input
          ref="otpInput"
          input-classes="otp-input"
          separator=""
          :num-inputs="4"
          :should-auto-focus="true"
          :is-input-num="true"
          @on-change="handleOnChange"
          @on-complete="handleOnComplete"
        />
      </b-row>

      <b-row class="mt-2 text-left" align-v="center" align-h="center">
        <b-col class="otp-error">
          <p v-if="authErrorPin === 'TTL_EXPIRED'" class="font-12 no-margin text-pink">
            Tu tiempo ha caducado, solicita un nuevo código.
          </p>
          <p v-if="authErrorPin === 'WRONG_PIN'" class="font-12 no-margin text-pink">Código Incorrecto.</p>
          <p v-if="authErrorPin === 'NO_MORE_PIN_ATTEMPTS'" class="font-12 no-margin text-pink">
            Has superado el límite de reintentos. Solicita un nuevo código.
          </p>
          <p v-if="authErrorPin === 'Token no es válido.'" class="font-12 no-margin text-pink">Código Incorrecto.</p>
        </b-col>
      </b-row>
      <b-row class="mb-5 padding-header" align-v="end">
        <b-col class="no-padding">
          <p class="font-12 no-margin">
            Válido por <span class="blue-text">{{ countdown }}</span> segundos
          </p>
        </b-col>
        <b-col class="no-padding">
          <b-button
            variant="link"
            class="font-12 no-margin resend-code no-padding"
            @click="newPin"
            :disabled="disableSendCode"
          >
            <span>Enviar un código nuevo</span>
          </b-button>
        </b-col>
      </b-row>
    </div>

    <DetalhesTelefoneAssistido
      :phoneDetailsAssisted="phoneDetailtUrl"
      :footerInformationAssisted="selectedOfferAssisted"
    />

    <MVButton label="Continuar" variant="movistar-green" @click="validarOTP" />

    <StepperVertical :step="4" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import {
  FETCH_APPLICATION_ASSISTED,
  TOGGLE_OVERLAY,
  VALIDAR_OTP_CLIENTE,
  NEW_PIN,
  RESET_AUTH_ERROR,
  CONFIRMAR_ACEITE_CLIENTE_ASSISTED,
} from '@/store/actions.type'

import MVButton from '@/components/movistar/MVButton.vue'
import StepperVertical from '@/components/StepperVertical.vue'
import DetalhesTelefoneAssistido from '@/components/DetalhesTelefoneAssistido.vue'

export default {
  components: {
    MVButton,
    StepperVertical,
    DetalhesTelefoneAssistido,
  },
  data: function () {
    return {
      disableSendCode: true,
      openInformation: false,
      countdown: 80,
      data: {},
      informationAssisted: {},
      phone: {},
      userAuth: {
        grant_type: 'password',
        username: '',
        password: '',
        applicationId: '',
      },
    }
  },
  watch: {
    countdown: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.countdown--
          }, 1000)
        }
        if (value === 0) {
          this.disableSendCode = false
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },
  created() {
    this.$store.dispatch(FETCH_APPLICATION_ASSISTED)
  },
  mounted() {
    this.$store.dispatch(NEW_PIN, this.phoneDetailtUrl.id)
  },

  methods: {
    handleOnComplete(value) {
      this.disableButton = false
      this.handleOnChange(value)
    },
    handleOnChange(value, disabled) {
      if (disabled) return
      if (value.length < 4) {
        this.disableButton = true
      }
      this.userAuth.password = value
    },
    abrirInformacao() {
      this.openInformation = !this.openInformation
    },
    newPin() {
      //TODO unblock input
      this.$store.dispatch(TOGGLE_OVERLAY, true)
      this.$store.dispatch(NEW_PIN, this.phoneDetailtUrl.id)
      this.disableSendCode = true
      this.countdown = 80
      this.$store.dispatch(RESET_AUTH_ERROR)
      this.$store.dispatch(TOGGLE_OVERLAY, false)
    },
    validarOTP() {
      if (this.disableButton) return
      this.userAuth.username = this.currentApplication.access.userId
      this.userAuth.applicationId = this.currentApplication.applicationId
      this.disableButton = true
      this.$store.dispatch(TOGGLE_OVERLAY, true)
      this.$store
        .dispatch(VALIDAR_OTP_CLIENTE, this.userAuth)
        .then(() => {
          this.$store.dispatch(CONFIRMAR_ACEITE_CLIENTE_ASSISTED)
        })
        .catch((e) => {
          this.$refs.otpInput.clearInput()
          console.error(e)
        })
    },
  },
  computed: {
    ...mapGetters({
      selectedOfferAssisted: 'selectedOfferAssisted',
      phoneDetails: 'phoneDetails',
      authErrorPin: 'authErrorPin',
      applicationId: 'applicationId',
      currentApplication: 'currentApplication',
      phoneDetailtUrl: 'phoneDetailtUrl',
    }),
  },
}
</script>

<style lang="scss" scoped>
.container-otp {
  .detalhes-telefone {
    margin-top: 3rem;
    max-width: 22rem;
    margin: 2rem auto 2rem auto;
  }
  .stepper {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: -3rem;
  }

  .btn-movistar-green {
    width: 90%;
  }
}
</style>
