<template>
  <div class="container-fluid bg-login">
    <b-container class="py-5">
      <b-row class="text-center py-3" align-h="center">
        <b-col cols="12" md="6" class="no-padding">
          <div class="card-wrapper-login">
            <MovistarMoneyLogoLogin />

            <div class="card-inner">
              <LoginSubtitle text="Ingresa tu contraseña" />

              <b-form @submit.prevent="submit">
                <b-form-group>
                  <div class="mb-3">
                    <b-input-group :class="{ formInputError: authError }">
                      <b-input-group-prepend>
                        <b-img src="@/assets/icons/lock-open.svg" alt="Lock icon" />
                      </b-input-group-prepend>
                      <input
                        :type="passwordFieldType"
                        class="v-mask pb-2 form-input-item"
                        placeholder="Contraseña"
                        v-model="form.password"
                      />
                      <b-input-group-append @click.prevent="switchVisibility('password')">
                        <button class="button-hide-show-password">
                          <b-img :src="require(`@/assets/icons/${passwordImage}`)" alt="Eye icon" />
                        </button>
                      </b-input-group-append>
                    </b-input-group>
                    <p v-if="authError" class="form-input-error">
                      {{ authError.message }}
                    </p>
                  </div>
                </b-form-group>
                <div class="pb-4">
                  <b-button variant="link" class="font-12 resend-code" @click="newPassword">
                    <span>Olvide mi contraseña</span>
                  </b-button>
                </div>
                <div class="pb-4 pt-5">
                  <ButtonContinueLogin text="Ingresar" :disable="disableButton" />
                </div>
              </b-form>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <ModalForgotPassword :message="selfManageResetPassword" />
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import MovistarMoneyLogoLogin from '../../components/SelfManage/MovistarMoneyLogoLogin'
import ButtonContinueLogin from '../../components/buttons/ButtonContinueLogin'
import ModalForgotPassword from '../../components/modal/ModalForgotPassword'
import LoginSubtitle from '../../components/SelfManage/LoginSubtitle'
import { SELF_MANAGE_LOGIN_PASSWORD, SELF_MANAGE_RESET_PASSWORD } from '../../store/actions.type'
import store from '../../store'
import { mapGetters } from 'vuex'

export default {
  name: 'LoginPassword',
  components: { LoginSubtitle, ModalForgotPassword, ButtonContinueLogin, MovistarMoneyLogoLogin },
  data() {
    return {
      passwordFieldType: 'password',
      passwordImage: 'eye.svg',
      disableButton: true,
      form: {
        login_type: 'password',
        grant_type: 'password',
        username: '',
        password: '',
      },
    }
  },
  validations: {
    form: {
      password: { required },
    },
  },
  watch: {
    form: {
      handler: function (newValue) {
        this.disableButton = newValue.password <= 0
      },
      deep: true,
    },
  },
  methods: {
    submit() {
      this.$v.form.$touch()
      if (this.$v.form.$error) return
      store.dispatch(SELF_MANAGE_LOGIN_PASSWORD, this.form)
    },
    newPassword() {
      store.dispatch(SELF_MANAGE_RESET_PASSWORD)
      this.$bvModal.show('modal-forgot-password')
    },
    switchVisibility(password) {
      if (password === 'password') {
        this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
      }
    },
  },
  computed: {
    ...mapGetters({
      selfManageResetPassword: 'selfManageResetPassword',
      authError: 'authError',
    }),
  },
}
</script>

<style scoped>
.card-inner {
  margin: 20px 35px;
}
.button-hide-show-password {
  display: flex;
  align-items: center;
}
</style>
