<template>
  <div class="container-sm-my-applications">
    <b-row class="my-applications-padding no-margin" align-v="end">
      <b-img left class="image-size" src="@/assets/icons/mis-solicitudes.svg" alt="Paper plane img" />
      <span class="mis-solicitudes">Mis solicitudes</span>
    </b-row>
    <b-row class="border-row no-margin">
      <b-col cols="3" class="no-padding">
        <hr class="separator-orange" />
      </b-col>
      <b-col cols="9" class="no-padding">
        <hr class="separator" />
      </b-col>
    </b-row>
    <div class="table-my-applications">
      <table width="100%" class="table-my-applications" aria-describedby="table-sm-my-applications">
        <thead>
          <tr>
            <th scope="col" class="texto-header text-close">Fecha de solicitud</th>
            <th scope="col" class="texto-header text-value text-value-header">Valor</th>
            <th scope="col" class="texto-header text-number">Número de cuotas</th>
            <th scope="col" class="texto-header text-status">Estatus</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(value, index) in selfApplications.applications" :key="index" class="table-read">
            <td class="fecha-solicitud" nowrap>{{ value.registryDate }}</td>
            <td class="text-value text-with-img" nowrap v-if="!value.requestedValueFmt">
              <b-img
                src="@/assets/icons/cuidado-svg-cinza.svg"
                alt="Spinner"
                v-if="exibirTooltip(value)"
                v-b-tooltip.hover
                v-bind:title="nomeProduto(value)"
                class="with-img"
              />
              {{ value.intendedRequestedValueFmt }}
            </td>
            <td class="text-value text-with-img" nowrap v-else>
              <b-img
                src="@/assets/icons/cuidado-svg-cinza.svg"
                alt="Spinner"
                v-if="exibirTooltip(value)"
                v-b-tooltip.hover
                v-bind:title="nomeProduto(value)"
                class="with-img"
              />
              {{ value.requestedValueFmt }}
            </td>
            <td class="number-shares" nowrap>{{ value.intendedInstallmentsNumber }}</td>
            <td class="texto-body" nowrap>{{ $t('application.' + value.situation) }}</td>
            <td class="aplications-buttons-cart" v-if="displayButton(value)">
              <button class="button-retrieve-cart" @click="retrieveCart(value)">
                <span class="text-button-retrieve-cart">Continuar</span>
              </button>
            </td>
            <td class="aplications-buttons-cart" v-if="displayButton(value)">
              <button class="button-cancel-cart" @click="showModal(value.applicationId)">
                <span class="text-button-cancel-cart">Cancelar</span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <ModalConfirm
      title="¿Estas seguro de que quieres cancelar tu solicitud?"
      subtitle="¡Perderás los datos!"
      confirmText="Si"
      cancelText="No"
      @cancel="cancelModal"
      @confirm="confirmModal"
    />
  </div>
</template>

<script>
import ModalConfirm from '../modal/ModalConfirm.vue'
import { mapGetters } from 'vuex'
import { CANCEL_PROPOSAL, REDIRECT_USER } from '../../store/actions.type'
import store from '../../store'
import { gtmDataLayerTrackEvent } from '../../helpers/gtmHelper'
import { FETCH_APPLICATION_REDIRECT } from '../../store/actions.type'
import { MUTATE_APPLICATION_ID } from '../../store/mutations.type'

export default {
  name: 'SMMyApplications',
  components: { ModalConfirm },
  data() {
    return {
      applicationId: '',
    }
  },
  methods: {
    retrieveCart(data) {
      this.$store.commit(MUTATE_APPLICATION_ID, data.applicationId)
      store.dispatch(FETCH_APPLICATION_REDIRECT)
    },
    showModal(id) {
      this.applicationId = id
      this.$bvModal.show('modal-confirm')
    },
    async cancelProposal(proposalId) {
      gtmDataLayerTrackEvent(
        window.dataLayer,
        'trackEvent',
        'movistar money',
        'click::identidad biometria::paso 3',
        'rechazar identidad biometria',
        this.CryptoJS.SHA1(this.currentApplication.mobile).toString()
      )
      const dadosCancelar = {
        idProposta: proposalId,
        retrieveCard: false,
        //redirect: 'Home'
      }
      store.dispatch(CANCEL_PROPOSAL, dadosCancelar)
      this.applicationId = ''
    },
    displayButton(value) {
      return (
        value.situation === 'EmAndamento' &&
        value.origin !== 'Assistido' &&
        value.activity !== 'Formalizacao' &&
        value.activity !== 'ConfirmacaoCompra' &&
        value.activity !== 'OrdemPagamento' &&
        value.activity !== 'EquipamentoEntregue' &&
        value.activity !== 'EquipamentoRecusado' &&
        value.activity !== 'BackOffice' &&
        value.activity !== 'ConfirmacaoAntecipado' &&
        value.activity !== 'ConfirmacaoFianca' &&
        value.activity !== 'ConfirmacaoDeposito'
      )
    },
    cancelModal() {
      this.$bvModal.hide('modal-confirm')
      this.applicationId = ''
    },
    confirmModal() {
      this.cancelProposal(this.applicationId)
      this.$bvModal.hide('modal-confirm')
    },
    nomeProduto(value) {
      if (value.access) {
        if (value.origin === 'Finter') {
          if (value.access.deviceModel) return value.access.deviceModel
          else return value.access.accessoryName
        }
        if (value.origin === 'Assistido') {
          if (value.access.skuName) return value.access.skuName
          else return value.access.accessoryName
        }
      } else return ''
    },
    exibirTooltip(value) {
      if (value.intendedRequestedValueFmt) {
        if (value.origin === 'Finter' || value.origin === 'Assistido') {
          return true
        }
      }
      return false
    },
  },
  computed: {
    ...mapGetters({
      selfApplications: 'selfManageApplications',
      currentApplication: 'currentApplication',
    }),
  },
}
</script>

<style scoped>
.container-sm-my-applications {
  background-color: var(--white);
  padding: 15px;
  margin: 70px 70px 20px 70px;
  border: 1px solid #d3d4d3;
}

.table-my-applications {
  position: relative;
  top: -12px;
  line-height: 1.6;
}

.border-row {
  position: relative;
  top: -48px;
}

.number-shares {
  text-align: start;
}

.table-read {
  border-bottom: 1px solid #d3d4d3;
}

.algo {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.text-value {
  text-align: start;
}

.cursor-pointer {
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
  position: relative;
  top: 50%;
  transform: translateY(25%);
}

.img-arrow {
  height: 18px;
  width: 18px;
  margin-left: 5px;
}

.see-more-btn {
  color: #0b2739;
  font-size: 18px;
}

.button-retrieve-cart,
.button-cancel-cart {
  width: 128px;
  height: 30px;
}

.aplications-buttons-cart {
  width: 100px;
  margin: 0;
}

.button-solicitude {
  display: flex;
  justify-content: space-around;
}

.separator {
  background-color: var(--movistar-grey-5);
  height: 1px;
}

.table-header {
  width: 100%;
}

.fecha-solicitud {
  color: #50535a;
  font-size: 20px;
  text-align: left;
}

.separator-orange {
  background-color: var(--movistar-orange);
  height: 2px;
}

.image-size {
  width: 86.2px;
  height: 80px;
}

.my-applications-padding {
  padding: 0 25px;
  position: relative;
  top: -40px;
}

.my-applications-title {
  font: 300 14px Telefonica, sans-serif;
  color: var(--movistar-pine-green);
  text-align: left;
}

.mis-solicitudes {
  height: 28px;
  margin: 0 26.5px 15px 7.5px;
  font-family: Telefonica, sans-serif;
  font-size: 22px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #0b2739;
}

.texto-header {
  margin: 16px 19px 23px 22px;
  font-family: Telefonica, sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #86888c;
}

.texto-body {
  margin: 10px 22px 20px 0;
  font-family: Telefonica, sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.4px;
  text-align: left;
  color: #50535a;
}

.text-buttons-cart {
  display: none;
}

.text-button-retrieve-cart,
.text-button-cancel-cart {
  font-size: 16px;
}

@media (max-width: 800px) {
  .text-with-img {
    display: flex;
  }

  .with-img {
    display: flex;
    align-items: flex-start;
    margin-right: 2px;
  }

  .container-sm-my-applications {
    background-color: var(--white);
    padding: 10px;
    margin: 40px 0px 0px 0px;
    width: 100%;
    border: 1px solid #d3d4d3;
  }

  .border-row {
    position: relative;
    top: -30px;
    padding: 0 15px;
  }
  .image-size {
    width: 43.1px;
    height: 40px;
  }

  .my-applications-padding {
    padding: 0 25px;
    position: relative;
    top: -20px;
  }

  .aplications-buttons-cart {
    width: 0;
  }

  .text-status {
    font-size: 10px;
  }

  .text-value-header {
    font-size: 10px;
  }

  .texto-body {
    font-size: 10px;
  }

  .text-button-retrieve-cart,
  .text-button-cancel-cart {
    font-size: 10px;
  }

  .text-buttons-cart {
    font-size: 12px;
    width: 120px;
    position: relative;
    top: 5px;
    display: block;
  }

  .see-more-btn {
    color: #0b2739;
    font-size: 14px;
  }

  .mis-solicitudes {
    height: 12px;
    position: relative;
    top: 5px;
    font-family: Telefonica, sans-serif;
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    align-items: center;
    color: #0b2739;
  }

  .texto-header {
    margin: 16px 19px 23px 22px;
    font-family: Telefonica, sans-serif;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #86888c;
  }
  .text-number {
    font-size: 10px;
    width: 1000px;
  }

  .fecha-solicitud {
    color: #50535a;
    font-size: 10px;
    text-align: left;
  }

  .number-shares {
    font-size: 10px;
    text-align: center;
    padding: 0;
  }

  .text-value {
    font-size: 10px;
    text-align: center;
  }

  .text-close {
    font-size: 10px;
    width: 1000px;
  }

  .see-more-btn {
    display: none;
  }

  .img-arrow {
    height: 18px;
    width: 18px;
    margin-left: 15px;
  }
}

@media (max-width: 450px) {
  .img-arrow {
    height: 18px;
    width: 18px;
    margin-left: 5px;
  }
  .button-retrieve-cart {
    width: 50px;
    height: 20px;
    border-radius: 10px;
    margin-bottom: 3px;
  }

  .button-cancel-cart {
    width: 50px;
    height: 20px;
    border-radius: 10px;
    margin-bottom: 3px;
  }

  .text-button-retrieve-cart,
  .text-button-cancel-cart {
    font-size: 10px;
  }
}
</style>
