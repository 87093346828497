<template>
  <PreValidacaoLayout>
    <b-card class="card-aprovacao" border-variant="light">
      <div class="card-title">
        <h4 class="title">Estado de cliente</h4>

        <span class="tip">
          {{ $t('situation.' + responseData.situation) }}
          <img class="ml-3" src="@/assets/icons/check-circle-white.svg" alt="Check icon" width="26px" />
        </span>
      </div>
    </b-card>

    <CardCupoCategoria />

    <CardPagamentoInicial v-if="canal === 'Finter'" />
    <div class="button-content">
      <a href="https://www.movistarmoney.co">
        <Button class="button-continuar">
          <span>Cerrar</span>
          <img src="@/assets/icons/check.svg" class="check" width="16" />
        </Button>
      </a>
    </div>
  </PreValidacaoLayout>
</template>

<script setup>
import { computed, onMounted } from 'vue'
import PreValidacaoLayout from '@/components/PreValidacao/PreValidacaoLayout.vue'
import CardPagamentoInicial from '@/components/PreValidacao/Card/PagamentoInicial.vue'
import CardCupoCategoria from '@/components/PreValidacao/Card/CupoCategoria.vue'
import Button from '@/components/PreValidacao/Button.vue'

import store from '@/store'
import CryptoJS from 'crypto-js'
import router from '@/router'

const responseData = computed(() => store.getters.buscadorBasicData)

const canal = computed(() => {
  if (store.getters.buscadorBasicDataRequest?.origin)
    return CryptoJS.AES.decrypt(
      store.getters.buscadorBasicDataRequest?.origin,
      process.env.VUE_APP_SECRET_SITE
    ).toString(CryptoJS.enc.Utf8)
  return null
})

onMounted(() => {
  if (Object.keys(store.getters.buscadorBasicDataRequest).length === 0) voltarPaginaAnterior()
})
function voltarPaginaAnterior() {
  router.push({ name: 'PreValidacao', query: { data: store.getters.queryData } })
}
</script>

<style scoped lang="scss">
.card-aprovacao {
  max-width: 41.25rem;
  margin: 0 auto;
  background-color: #019df4;
  border-radius: 10px;

  .card-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
    color: white;
    .title {
      font-size: 25px;
      margin-bottom: 0;
      font-weight: bold;
    }
    .tip {
      font-size: 25px;
    }
  }

  .card-body {
    padding: 23px 41px;
  }
}

.card-cupo {
  max-width: 41.25rem;
  margin: 1rem auto 0 auto;
  background-color: #f5f5f5;
  border-radius: 10px;

  .card-body {
    padding: 27px 37px;
    .key {
      font-size: 25px;
      margin-bottom: 0;
      font-weight: bold;
    }
    .value {
      font-size: 20px;
    }

    .divider {
      width: 100%;
      height: 0px;
      margin-top: 1rem;
      border-bottom: solid 0.5px #707070;
    }
  }
}

.button-content {
  margin-top: 4rem;
}

.button-continuar {
  .check {
    margin-left: 12px;
  }
}
</style>
