import Vue from 'vue'

const ApiFianca = {
  postReenviarEmailPagamento(proposalId, email) {
    return Vue.axios.post('publicdata/guarantee-payment/resend-email', { proposalId, email })
  },

  getEmail(form) {
    return Vue.axios.get(`publicdata/guarantee-payment/customer-email`, {
      params: {
        mobile: form.mobile !== '' ? form.mobile : null,
        document: form.document !== '' ? form.document : null,
      },
    })
  },
}

export default ApiFianca
