import Api from '@/plugins/api'
import store from '@/store'
import { BUSCAR_PARAMETROS, BUSCAR_PARAMETROS_CANAL, REDIRECT_MANUTENCAO } from '@/store/actions.type'
import { SET_PARAMETROS, SET_PARAMETROS_CANAL } from '@/store/mutations.type'
import router from '@/router'
import CryptoJS from 'crypto-js'

const state = {
  parametros: {
    veridasXpressIdUrl: null,
    epaycoAdvancedEmailLinkValidity: null,
    epaycoAdvancedEmailResendQuantity: null,
  },
  parametrosCanal: {
    channel: null,
    minimumTerminalValue: null,
    activeCepsRules: null,
    maintenance: null,
  },
}

const mutations = {
  [SET_PARAMETROS](state, parametros) {
    state.parametros = parametros
  },
  [SET_PARAMETROS_CANAL](state, parametrosCanal) {
    state.parametrosCanal = parametrosCanal
  },
}

const actions = {
  async [BUSCAR_PARAMETROS]({ state, commit, dispatch }, nomeParametro) {
    if (!state.parametros[nomeParametro]) {
      await Api.getParametros()
        .then((response) => {
          const decriptedResponse = JSON.parse(
            CryptoJS.AES.decrypt(response.data, process.env.VUE_APP_SECRET_SITE).toString(CryptoJS.enc.Utf8)
          )
          commit(SET_PARAMETROS, decriptedResponse.propertiesParameters)
        })
        .catch((e) => dispatch(REDIRECT_MANUTENCAO))
    }
    return state.parametros[nomeParametro]
  },

  async [BUSCAR_PARAMETROS_CANAL]({ state, commit, dispatch }, { nomeParametro, canal }) {
    const canalSelecionado = canal ? canal : store.getters.currentApplication?.channel

    if (!state.parametrosCanal[nomeParametro] || canalSelecionado !== state.parametrosCanal.channel) {
      await Api.getParametros(canal)
        .then((response) => {
          const decriptedResponse = JSON.parse(
            CryptoJS.AES.decrypt(response.data, process.env.VUE_APP_SECRET_SITE).toString(CryptoJS.enc.Utf8)
          )
          commit(SET_PARAMETROS_CANAL, {
            valores: decriptedResponse.channelParameters,
          })
          if (decriptedResponse.channelParameters.maintenance) {
            dispatch(REDIRECT_MANUTENCAO, decriptedResponse.channelParameters.maintenanceMessage)
          }
        })
        .catch((e) => dispatch(REDIRECT_MANUTENCAO))
    }
    return state.parametrosCanal[nomeParametro]
  },

  [REDIRECT_MANUTENCAO](context, mensagem) {
    router.push({
      name: 'MensagemReprovacao',
      params: {
        data: {
          response: {
            messages: [
              {
                titulo: 'Mantenimiento',
                mensagem: mensagem,
              },
            ],
          },
        },
      },
    })
  },
}

const getters = {
  parametros: (state) => state.parametros,
  parametrosCanal: (state) => state.parametrosCanal,
}

export default {
  state,
  mutations,
  actions,
  getters,
}
