<template>
  <div>
    <b-row>
      <b-col
        v-if="!this.faturaMovistar"
        cols="4"
        @click="emitPaymentType('Pagar cuota')"
        class="menu-on cursor-pointer no-padding"
        :class="{ 'menu-off': metodoPagamento != 'Pagar cuota' }"
      >
        <div class="menu-borda-on" :class="{ 'menu-borda-off': metodoPagamento != 'Pagar cuota' }">
          <p class="mb-3 font-12">Pagar Cuota</p>
        </div>
      </b-col>
      <b-col
        v-if="!this.faturaMovistar && !this.ultimaParcela"
        cols="4"
        @click="emitPaymentType('Adelanto de cuota')"
        class="menu-on cursor-pointer no-padding"
        :class="{ 'menu-off': metodoPagamento !== 'Adelanto de cuota' }"
      >
        <div class="menu-borda-on" :class="{ 'menu-borda-off': metodoPagamento != 'Adelanto de cuota' }">
          <p class="mb-3 font-12">Adelanto de cuota</p>
        </div>
      </b-col>
      <b-col
        v-if="!this.ultimaParcela"
        cols="4"
        @click="emitPaymentType('Abono a capital')"
        class="menu-on cursor-pointer no-padding"
        :class="{ 'menu-off': metodoPagamento != 'Abono a capital' }"
      >
        <div class="menu-borda-on" :class="{ 'menu-borda-off': metodoPagamento != 'Abono a capital' }">
          <p class="mb-3 font-12">Abonos a Capital</p>
        </div>
      </b-col>
      <b-col v-if="this.faturaMovistar" class="menu-off cursor-pointer no-padding">
        <div class="menu-borda-off">
          <p class="mb-3 font-12">&nbsp;</p>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'ApplyPaymentCardMenuItem',
  props: {
    faturaMovistar: Boolean,
    pagamento: String,
    ultimaParcela: Boolean,
  },
  mounted() {
    if (this.faturaMovistar) {
      if (!this.ultimaParcela) this.metodoPagamento = 'Abono a capital'
      else this.metodoPagamento = 'Pagar cuota'
    } else {
      this.metodoPagamento = this.pagamento
    }
  },
  data() {
    return {
      metodoPagamento: '',
    }
  },
  methods: {
    emitPaymentType(pagamentoEmitido) {
      this.metodoPagamento = pagamentoEmitido
      this.$emit('pagamentoSelecionado', pagamentoEmitido)
    },
  },
  watch: {
    pagamento: function () {
      if (!this.faturaMovistar) {
        this.metodoPagamento = this.pagamento
      }
    },
  },
}
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}

.menu-on {
  font-family: Telefonica, sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--movistar-pine-green);
}

.menu-off {
  font-family: Telefonica, sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #86888c !important;
}

.menu-borda-on {
  border-bottom: 2px solid var(--movistar-orange);
}

.menu-borda-off {
  border-bottom: 1px solid var(--movistar-grey);
}
</style>
