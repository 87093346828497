<template>
  <b-card class="card-cupo" border-variant="light" v-if="ofertas">
    <div class="d-flex justify-content-between align-items-center">
      <div class="key">
        <h4>Número de cuotas</h4>
        <p>Elige una opción</p>
      </div>
      <b-form-radio-group
        id="parcelas"
        v-model="selectedCuota"
        :options="parcelas"
        name="parcelas"
        buttons
        button-variant="movistar"
      ></b-form-radio-group>
    </div>
    <div class="divider mb-3"></div>
    <div class="d-flex justify-content-between">
      <h4 class="key">Total cuota</h4>
      <span class="value" v-text="parcelaSelecionada?.totalFmt" />
    </div>
  </b-card>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import store from '@/store'

import { SET_BUSCADOR_PARCELA_SELECIONADA } from '@/store/mutations.type'

const parcelas = computed(() => store.getters.buscadorFullOptions)
const ofertas = computed(() => store.getters.buscadorFullData?.offers?.ops[0]?.data?.offers)
const parcelaSelecionada = computed(() => store.getters.buscadorParcelaSelecionada)

const selectedCuota = ref(null)

watch(selectedCuota, (value) => {
  store.commit(SET_BUSCADOR_PARCELA_SELECIONADA, ofertas.value.filter((item) => item.installment === value)[0])
})

watch(parcelaSelecionada, (value) => {
  selectedCuota.value = value.installment
})
</script>

<style scoped lang="scss">
::v-deep(.btn.disabled, .btn:disabled) {
  opacity: 0.45 !important;
}

.card-cupo {
  max-width: 41.25rem;
  background-color: #f5f5f5;
  border-radius: 10px;

  .card-body {
    padding: 27px 37px;
    .key {
      font-size: 25px;
      margin-bottom: 0;
      font-weight: bold;
      text-align: left;
      h4 {
        margin-bottom: 0;
        font-size: 18px;
        color: #019df4;
        font-weight: bold;
      }
      p {
        margin: 0;
        font-size: 18px;
        color: #86888c;
        font-weight: normal;
      }
    }
    .value {
      font-size: 20px;
    }

    .divider {
      width: 100%;
      height: 0px;
      margin-top: 1rem;
      border-bottom: solid 0.5px #707070;
    }
  }
}

::v-deep .btn.btn-movistar {
  width: 3rem;
  height: 2.6rem;
  border-radius: 5px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  color: #86888c;
  font-size: 19px;
  margin-right: 0.25rem;
  &:last-child {
    margin-right: 0;
  }
  &.active {
    background-color: #019df4;
    color: #ffffff;
    font-weight: bold;
  }
}
</style>
