import Api from '@/plugins/api'
import {
  DEFINE_OFFER,
  FETCH_OFFER,
  REDIRECT_USER,
  REFRESH_OFFER,
  TOGGLE_OVERLAY,
  UPDATE_SELECTED,
  UPDATE_SELECTED_BIGGEST_OFFER,
} from '@/store/actions.type'
import {
  FLAG_DISABLE_OFFER,
  GET_APPLICATION_SUCCESS,
  GET_OFFER_SUCCESS,
  MUTATE_BIGGEST_OFFER_SELECTED,
  MUTATE_OFFER_SELECTED_ITEM,
  MUTATE_OFFER_SELECTED_NUMBER,
  SET_ADVANCED_PAYMENT,
  SET_ADVANCED_PAYMENT_FMT,
  SET_CHANGED_ADVANCED_PAYMENT,
  SET_FINANCED_AMOUNT,
  SET_FINANCED_AMOUNT_FMT,
  SET_GUARANTEE_PAYMENT,
  SET_GUARANTEE_PAYMENT_FMT,
  SET_CALCULATED_GUARANTEE_PAYMENT_IVA,
  SET_CALCULATED_GUARANTEE_PAYMENT_IVA_FMT,
  SET_GUARANTEE_PAYMENT_TOTAL,
  SET_GUARANTEE_PAYMENT_TOTAL_FMT,
  SET_OFFER_OPTIONS,
  SET_REAL_FINANCED_AMOUNT,
  SET_REAL_FINANCED_AMOUNT_FMT,
} from '@/store/mutations.type'
import { Persist } from '@/helpers/persist.service'
import router from '@/router'
import store from '@/store'
import { FETCH_PRE_OFFER } from './actions.type'

const state = {
  offer: Persist.getOffer(),
  offerEnabledOptions: Persist.getOfferEnabledOptions(),
  offerSelectedNumber: Persist.getOfferSelectedNumber(),
  offerSelectedItem: Persist.getOfferSelectedItem(),
  currentApplication: Persist.getCurrentApplication(),
  flagDisableOffer: true,
  offerTotalLoan: '  ',
}

const mutations = {
  [GET_OFFER_SUCCESS](state, offers) {
    Persist.saveOffer(offers)
  },
  [SET_OFFER_OPTIONS](state, response) {
    const values = [3, 6, 12, 18, 24, 36]
    const base = [
      { value: 3, disabled: true },
      { value: 6, disabled: true },
      { value: 12, disabled: true },
      { value: 18, disabled: true },
      { value: 24, disabled: true },
      { value: 36, disabled: true },
    ]
    let newOptions = response.map((offer) => {
      if (values.includes(offer.installment)) {
        return {
          value: offer.installment,
          disabled: false,
        }
      }
    })
    newOptions = base.map((obj) => newOptions.find((o) => o.value === obj.value) || obj)
    state.offerEnabledOptions = newOptions
    Persist.saveOfferEnabledOptions(newOptions)
  },
  [MUTATE_BIGGEST_OFFER_SELECTED](state, options) {
    const arrayReverse = options.reverse().map((offer) => {
      return offer
    })
    const selected = arrayReverse.find((item) => !item.disabled)
    if (selected) {
      state.offerSelectedNumber = selected
      Persist.saveOfferSelectedNumber(selected)
    } else return 36
  },
  [MUTATE_OFFER_SELECTED_NUMBER](state, selected) {
    if (selected) {
      Persist.saveOfferSelectedNumber(selected)
    } else return ''
  },
  [FLAG_DISABLE_OFFER](state, params) {
    state.flagDisableOffer = params
  },
  [MUTATE_OFFER_SELECTED_ITEM](state, selected) {
    const offer = Persist.getOffer()
    if (offer) {
      let offerObj = offer.find((o) => o.installment === selected.value)
      let item = {
        'Cuota capital': offerObj.principalDueFmt,
        Intereses: offerObj.interestDueFmt,
      }

      if (state.currentApplication.isTaxFree === false)
        Object.assign(item, { 'IVA intereses': offerObj.taxInterestDueFmt })
      Object.assign(item, {
        'Cargo administrativo': offerObj.feesDueFmt,
        Plazo: offerObj.installment,
        'Total cuota': offerObj.totalFmt,
        // Fianza: store.getters.guaranteePaymentTotalFmt,
        'Valor a financiar': state.currentApplication.requestedValueFmt || store.getters.financedAmountFmt,
        'Tasa efectiva anual  (TEA)': offerObj.interestCETYearlyFmt,
        'Tasa nominal mensual': offerObj.interestCETFmt,
      })
      state.offerSelectedItem = item
      Persist.saveOfferSelectedItem(item)
      state.offerTotalLoan = offerObj.totalFmt
    }
  },
}

const actions = {
  async [REFRESH_OFFER](context, body) {
    store.dispatch(TOGGLE_OVERLAY, true)
    for (var i = 0; i < state.offerEnabledOptions.length; i++) {
      state.offerEnabledOptions[i].disabled = true
    }
    state.offerSelectedItem = null
    Persist.saveOfferSelectedItem(null)
    Persist.saveOfferSelectedNumber(null)

    await Api.getApplication(context.getters.applicationId)
      .then((response) => {
        context.commit(GET_APPLICATION_SUCCESS, response.data)
        context.dispatch(REDIRECT_USER, response.data.status)
      })
      .catch((e) => console.log(e))
    await context.dispatch(FETCH_OFFER, body)
    context.dispatch(UPDATE_SELECTED, context.getters.offerSelectedNumber)
  },
  async [FETCH_OFFER](context, body) {
    await Api.executeAction(context.getters.applicationId, body)
      .then((response) => {
        if (response.data.situation !== 'offer') {
          if (response.data.situation === 'reproved' && response.data.response)
            router.push({
              name: 'MensagemReprovacao',
              params: {
                data: {
                  response: response.data.response,
                },
                updateMode: true,
                editionMode: true,
              },
            })
          else router.push({ name: 'CantProcessRequest' })
        } else if (response.status === 500) {
          router.push({ name: 'ServiceUnavailable' })
        } else {
          context.commit(SET_ADVANCED_PAYMENT, response.data.response.advancedPayment)
          context.commit(SET_ADVANCED_PAYMENT_FMT, response.data.response.advancedPaymentFmt)
          context.commit(SET_FINANCED_AMOUNT, response.data.response.financedAmount)
          context.commit(SET_FINANCED_AMOUNT_FMT, response.data.response.financedAmountFmt)
          context.commit(SET_REAL_FINANCED_AMOUNT, response.data.response.realFinancedAmount)
          context.commit(SET_REAL_FINANCED_AMOUNT_FMT, response.data.response.realFinancedAmountFmt)
          context.commit(SET_GUARANTEE_PAYMENT, response.data.response.guaranteePayment)
          context.commit(SET_GUARANTEE_PAYMENT_FMT, response.data.response.guaranteePaymentFmt)
          context.commit(SET_CALCULATED_GUARANTEE_PAYMENT_IVA, response.data.response.calculatedGuaranteePaymentIva)
          context.commit(
            SET_CALCULATED_GUARANTEE_PAYMENT_IVA_FMT,
            response.data.response.calculatedGuaranteePaymentIvaFmt
          )
          context.commit(SET_GUARANTEE_PAYMENT_TOTAL, response.data.response.guaranteePaymentTotal)
          context.commit(SET_GUARANTEE_PAYMENT_TOTAL_FMT, response.data.response.guaranteePaymentTotalFmt)
          context.commit(SET_CHANGED_ADVANCED_PAYMENT, response.data.response.changedAdvancedPayment)
          context.commit(SET_OFFER_OPTIONS, response.data.response.offers)
          context.commit(GET_OFFER_SUCCESS, response.data.response.offers)
          context.commit(FLAG_DISABLE_OFFER, false)
          context.dispatch(UPDATE_SELECTED_BIGGEST_OFFER, Persist.getOfferEnabledOptions())
        }
        store.dispatch(TOGGLE_OVERLAY, false)
      })
      .catch((e) => console.log(e))
  },
  [UPDATE_SELECTED_BIGGEST_OFFER](context, payment) {
    context.commit(MUTATE_BIGGEST_OFFER_SELECTED, payment)
    context.dispatch(UPDATE_SELECTED, context.getters.offerSelectedNumber)
  },
  [UPDATE_SELECTED](context, selected) {
    context.commit(MUTATE_OFFER_SELECTED_NUMBER, selected)
    context.commit(MUTATE_OFFER_SELECTED_ITEM, selected)
  },
  [DEFINE_OFFER](context, body) {
    return Api.executeAction(context.getters.applicationId, body)
      .then((resp) => {
        return resp.status === 200 || resp.data.situation !== 'offer'
      })
      .catch((e) => console.log(e))
  },

  async [REDIRECT_USER]({ dispatch }, statusOffer) {
    state.currentApplication = Persist.getCurrentApplication()
    let DestStatus
    if (window.location.pathname.includes('self-manage') || window.location.pathname.includes('on-boarding')) {
      if (window.location.pathname.includes('self-manage')) DestStatus = '/self-manage'
      else if (
        (store.getters.isAuthenticated || store.getters.authStatus === 'success') &&
        !window.location.pathname.includes('self-manage')
      )
        DestStatus = '/on-boarding'
      switch (statusOffer) {
        case 'infolaftFailure':
        case 'reproved': {
          DestStatus += '/process-request'
          break
        }
        case 'preOffer': {
          const situation = await dispatch(FETCH_PRE_OFFER)
          if (situation === 'preOffer') DestStatus += '/pre-offer'
          else if (situation === 'offer') DestStatus += '/offer-equipo'
          else DestStatus += '/process-request'
          break
        }
        case 'offer': {
          DestStatus += '/offer-equipo'
          break
        }
        case 'counterOffer': {
          DestStatus += '/counter-offer-simulation'
          break
        }
        case 'documents': {
          DestStatus += '/identity'
          break
        }
        case 'evidente': {
          DestStatus += '/evidente'
          break
        }
        case 'deliveryData': {
          DestStatus += '/direction'
          break
        }
        case 'contract': {
          if (state.currentApplication.origin === 'Finter') DestStatus += '/contract'
          else if (state.currentApplication.origin === 'Libre') DestStatus += '/contractLibre'
          break
        }
        case 'paymentOrder': {
          if (state.currentApplication.origin === 'Finter') DestStatus += '/details'
          else if (state.currentApplication.origin === 'Assistido') {
            router.push({ name: 'FinalAssistido' })
            return
          }
          break
        }
        case 'bankData': {
          DestStatus += '/bank-data'
          break
        }
        case 'financialData': {
          if (state.currentApplication.origin === 'Finter') DestStatus = '/details'
          else if (state.currentApplication.origin === 'Libre') DestStatus += '/detailsLibre'
          break
        }
        case 'addressData': {
          DestStatus += '/directionLibre'
          break
        }
        case 'veridas': {
          DestStatus += '/veridas'
          break
        }
        default: {
          DestStatus += '/process-request'
          break
        }
      }
      if (window.location.pathname !== DestStatus) router.push({ path: DestStatus })
    }
  },
}

const getters = {
  offer: (state) => state.offer,
  offerEnabledOptions: (state) => state.offerEnabledOptions,
  offerSelectedNumber: (state) => state.offerSelectedNumber,
  offerSelectedItem: (state) => state.offerSelectedItem,
  offerTotalLoan: (state) => state.offerTotalLoan,
  flagDisableOffer: (state) => state.flagDisableOffer,
}

export default {
  state,
  mutations,
  actions,
  getters,
}
