<template>
  <div>
    <b-card bg-variant="light" border-variant="light" class="my-4">
      <b-card-title>¡Hola!</b-card-title>
      <b-card-text class="card-text-bold">
        Paga aquí tu orden por valor de {{ advancedPayment.advancedPaymentFmt }}
      </b-card-text>
      <hr class="tracejado" />
      <b-card-text>
        Estás a un paso de tu celular
        <span v-if="advancedPayment.idSalesOrder">
          <br />
          orden #{{ advancedPayment.idSalesOrder }}
        </span>
      </b-card-text>
    </b-card>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'PagarAvancadoHeader',
  computed: {
    ...mapGetters({
      advancedPayment: 'advancedPayment',
    }),
  },
}
</script>
<style lang="scss">
.card-body {
  .card-title {
    font-size: 22px;
    font-weight: 300;
    color: #0b2739;
  }
  .card-text {
    font-size: 20px;
    color: #0b2739;
    &.card-text-bold {
      font-weight: bold;
    }
  }
}
hr.tracejado {
  border-top: 1px dashed #707070;
}
</style>
