<template>
  <div class="container-fluid bg-login">
    <b-container class="py-5">
      <b-row class="text-center py-3" align-h="center">
        <b-col cols="12" md="6" class="no-padding">
          <div class="card-wrapper-login">
            <MovistarMoneyLogoLogin />

            <div class="card-inner">
              <LoginSubtitle text="Crea una contraseña" />
              <SMPasswordForm :error-message="selfManageUpdatePasswordError" :stepper="2" @send-form="sendForm" />
              <br />
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import MovistarMoneyLogoLogin from '../../components/SelfManage/MovistarMoneyLogoLogin'
import LoginSubtitle from '../../components/SelfManage/LoginSubtitle'
import store from '../../store'
import { SELF_MANAGE_UPDATE_PASSWORD } from '../../store/actions.type'
import { mapGetters } from 'vuex'
import SMPasswordForm from '../../components/SelfManage/SMPasswordForm'

export default {
  name: 'LoginCreatePassword',
  components: { SMPasswordForm, LoginSubtitle, MovistarMoneyLogoLogin },
  data() {
    return {}
  },
  methods: {
    sendForm(params) {
      store.dispatch(SELF_MANAGE_UPDATE_PASSWORD, params)
    },
  },
  computed: {
    ...mapGetters({
      selfManageUpdatePasswordError: 'selfManageUpdatePasswordError',
    }),
  },
}
</script>

<style scoped>
.card-inner {
  margin: 20px 35px;
}
</style>
