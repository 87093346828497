<template>
  <MVModal id="modal-enviar-email">
    <template #header>
      <h2 class="titulo">¿Quieres volver a enviar el link de pago?</h2>
    </template>
    <p class="content" v-text="email" />
    <template #footer>
      <MVButton
        variant="movistar-green"
        label="Volver a enviar"
        size="sm"
        @click="enviarEmailPagamento($bvModal, $bvToast)"
      />
      <MVButton
        variant="movistar-green"
        label="Enviar a otro correo"
        outline
        size="sm"
        v-b-modal.modal-enviar-email-outro-endereco
        @click="$bvModal.hide('modal-enviar-email')"
      />
    </template>
  </MVModal>
</template>
<script setup>
import { computed } from 'vue'
import { useRouter } from 'vue-router/composables'

import store from '@/store'
import { TOGGLE_OVERLAY } from '@/store/actions.type'
import apiFianca from '@/services/fianca.service'

import MVModal from '@/components/movistar/MVModal.vue'
import MVButton from '@/components/movistar/MVButton.vue'

const email = computed(() => store.getters.currentApplication.email)
const applicationId = computed(() => store.getters.currentApplication.applicationId)

const router = useRouter()

function enviarEmailPagamento(modalContext) {
  modalContext.hide('modal-enviar-email')
  store.dispatch(TOGGLE_OVERLAY, true)
  apiFianca
    .postReenviarEmailPagamento(applicationId.value)
    .then(() => {
      modalContext.show('modal-confirmar-recebimento-email-fianca')
    })
    .catch((error) => {
      router.push({ name: 'ManyAttemptsResendLink' })
    })
    .finally(() => {
      store.dispatch(TOGGLE_OVERLAY, false)
    })
}
</script>
<style lang="scss" scoped>
.modal-footer {
  .btn {
    width: 100%;
    margin: 0.25rem 1.5rem;
  }
}
.titulo {
  font-size: 1.75rem;
  margin-top: 1rem;
  width: 100%;
  padding: 0 2rem;
}

p.content {
  font-size: 18px;
  padding: 0 1.5rem;
  color: var(--movistar-blue);
}
</style>
