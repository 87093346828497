var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container-assistido-info"},[_vm._m(0),_c('section',[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_c('ul',{staticClass:"list-assistido-info-guia"},[_c('li',{staticClass:"content-assistido-info-guia"},[_c('span',{staticClass:"text-bold"},[_vm._v("Total cuota / mes: "+_vm._s(_vm.selectedOfferAssisted.totalFmt))]),_vm._v(" Valor mensual que deberá pagar cada mes, es un valor fijo durante la vigencia del crédito. ")]),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9)])])]),_c('div',{staticClass:"box-cancelar"},[_c('ButtonCancel',{attrs:{"text":"Cancelar solicitud"},on:{"click":_vm.modalCancel}})],1),_c('ModalCancelarAssistido',{attrs:{"id":"modal-basic","title":"Estás a punto de cancelar tu propuesta","subtitle":"¿Quieres continuar con esta acción?","image":"congratulations.svg"}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('section',[_c('h1',{staticClass:"title-assistido-info"},[_vm._v("Recuerda que")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list-number"},[_c('span',{staticClass:"number-in-list"},[_vm._v("1.")]),_c('p',{staticClass:"list-with-number"},[_vm._v(" Esta es la oferta personalizada de tu cliente, debes recordarle cual es el equipo que esta financiando ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list-number"},[_c('span',{staticClass:"number-in-list"},[_vm._v("2.")]),_c('p',{staticClass:"list-with-number"},[_vm._v(" Las opciones de cuotas a la que puede acceder este cliente son las sombreadas, indícale cuales son las posibilidades que tiene ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list-number"},[_c('span',{staticClass:"number-in-list"},[_vm._v("3.")]),_c('p',{staticClass:"list-with-number"},[_vm._v("Al dar clic en la caja de la cuota se calculará el valor de la cuota mensual")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list-number"},[_c('span',{staticClass:"number-in-list"},[_vm._v("4.")]),_c('p',{staticClass:"list-with-number"},[_vm._v(" Al dar clic en el símbolo “v” se desplegará el detalle de la cuota. Indícale al cliente cada uno de los cargos ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"content-assistido-info-guia"},[_c('span',{staticClass:"text-bold"},[_vm._v("Intereses: ")]),_vm._v(" Es la porción mensual de intereses ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"content-assistido-info-guia"},[_c('span',{staticClass:"text-bold"},[_vm._v("IVA intereses: ")]),_vm._v(" Solo aplica para los equipos con IVA y es el 19% sobre el valor de intereses ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"content-assistido-info-guia"},[_c('span',{staticClass:"text-bold"},[_vm._v("Valor a financiar: ")]),_vm._v(" Valor del equipo ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"content-assistido-info-guia"},[_c('span',{staticClass:"text-bold"},[_vm._v("Tasa efectiva anual: ")]),_vm._v(" Tasa anual que aplica para el crédito ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"content-assistido-info-guia"},[_c('span',{staticClass:"text-bold"},[_vm._v("Tasa nominal mensual: ")]),_vm._v(" Tasa con la que se calculan los intereses mes a mes ")])
}]

export { render, staticRenderFns }