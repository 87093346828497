<template>
  <div class="container-formulario-assistido">
    <section class="header-titulo-assistido">
      <h1 class="titulo-formulario-assistido">
        ¡Tenemos una oferta personalizada, indícale a tu cliente el detalle de la oferta!
      </h1>
    </section>

    <b-row class="text-center" align-h="center">
      <DetalhesTelefoneAssistidoAceitaAbrir
        title="Elige tus cuotas"
        subTitle="El cobro de tu cuota se incluirá en tu factura Movistar"
        :selectedOfferAssisted="selectedOfferAssisted"
        :offerAssisted="listarOfertasAssistido"
        :phoneDetailsAssisted="phoneDetailtUrl"
        :arrayButtons="selectButtonsAssisted"
      />
    </b-row>
    <ButtonContinue text="Continuar" @click.once="goNextPage" class="btn-oferta-assistido" />
    <StepperVertical :step="step" />

    <ModalValorAlterado
      id="modal-valor-antecipo-alterado"
      @continuar="$bvModal.hide('modal-valor-antecipo-alterado')"
      assistido
    />
    <ModalFiancaRequerido channel="Assistido" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  ACTION_WORKFLOW_STATUS_ASSISTED,
  FETCH_APPLICATION_ASSISTED,
  ACTION_LISTAR_OFERTAS,
} from '@/store/actions.type'

import ButtonContinue from '@/components/buttons/ButtonContinue.vue'
import DetalhesTelefoneAssistidoAceitaAbrir from '@/components/assistido/OfertaAssistido/DetalhesTelefoneAssistidoAceitaAbrir.vue'
import StepperVertical from '@/components/StepperVertical.vue'
import ModalValorAlterado from '@/components/modal/antecipo/ModalValorAlterado.vue'
import ModalFiancaRequerido from '@/components/fianca/modal/ModalFiancaRequerido.vue'

export default {
  components: {
    StepperVertical,
    DetalhesTelefoneAssistidoAceitaAbrir,
    ButtonContinue,
    ModalValorAlterado,
    ModalFiancaRequerido,
  },
  data: function () {
    return {
      step: 1,
    }
  },
  created() {
    this.$store.dispatch(FETCH_APPLICATION_ASSISTED)
  },
  async mounted() {
    await this.$store.dispatch(ACTION_LISTAR_OFERTAS, { action: 'listOffer' })
    if (this.guaranteePayment) {
      this.$bvModal.show('modal-fianca-requerido')
    }
  },
  watch: {
    listarOfertasAssistido(newValue) {
      if (newValue) {
        return newValue
      }
    },
    changedAdvancedPayment(newValue) {
      if (newValue) this.$bvModal.show('modal-valor-antecipo-alterado')
    },
  },
  methods: {
    submit() {
      this.$v.form.$touch()
    },
    goNextPage() {
      if (this.selectedOfferAssisted && this.selectedOfferAssisted.installment)
        this.$store.dispatch(ACTION_WORKFLOW_STATUS_ASSISTED, {
          action: 'offer',
          installments: this.selectedOfferAssisted.installment,
        })
    },
  },

  computed: {
    ...mapGetters({
      phoneDetailtUrl: 'phoneDetailtUrl',
      selectedOfferAssisted: 'selectedOfferAssisted',
      listarOfertasAssistido: 'listarOfertasAssistido',
      selectButtonsAssisted: 'selectButtonsAssisted',
      changedAdvancedPayment: 'changedAdvancedPayment',
      guaranteePayment: 'guaranteePayment',
    }),
  },
}
</script>
<style scoped>
.segunda-mais-informacao {
  margin-top: 1.5rem;
}

.btn-parcel {
  width: 50px;
  height: 35px;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  border: none;
  text-align: center;
  color: #86888c;
}
.container-total-cueta {
  margin-bottom: 1rem;
}

.legend-value {
  font-size: 14px;
  color: #86888c;
}

.value-total-cueta {
  display: flex;
  font-size: 18px;
  font-weight: bold;
}

.content-total-cueta {
  font-size: 1rem;
  color: #86888c;
  font-weight: bold;
  margin-top: 8px;
}

.more-information {
  display: flex;
  flex-direction: column;
}

.container-more-information {
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
  height: 17px;
}

.simulador-calculadora {
  height: 70px;
  flex-direction: row;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.header-mais-informacoes {
  background-color: #019df4;
  border-radius: 10px 10px 0 0;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 30px;
  width: 342px;
  position: absolute;
  top: -20px;
  left: -2px;
}

.titulo-mais-informacoes {
  font-size: 17px;
  color: #fff;
  margin-bottom: 0px;
}

.valor-mais-informacoes {
  font-weight: bold;
  color: #fff;
  font-size: 16px;
  margin-bottom: 0px;
}
.stepper {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: -3rem;
}

.container-formulario-assistido {
  position: relative;
  width: 650px;
  height: 582px;
}

.card-phone-details-wrapper {
  margin-top: 40px;
}

.b-card-container {
  padding: 10px;
}

.button {
  margin: 0 1px;
  width: 50px;
  height: 40px;
  color: var(--movistar-grey);
}

.selected-button {
  background-color: #019df4;
  color: #ffffff;
  font-weight: bold;
}

.offer-subtitle {
  color: #019df4;
}

.choose-quotas {
  margin: 0 0 1.6px 9.6px;
  font-weight: bold;
}

.continue-button-text {
  font-weight: bold;
  text-align: left;
  color: var(--movistar-grey);
}

.grey {
  color: var(--movistar-grey);
}

.custom-shadow {
  box-shadow: 0 3px 6px #00000029;
}

.dropdown-items {
  font-size: 14px;
}

.total-cuota-margin {
  padding-bottom: 1em;
}

.button-disabled {
  background-color: #b6b9bf;
  color: #ffffff;
}

.error-container {
  display: flex;
  flex-direction: column;
}

.error-assistant {
  margin: 0px;
  margin-left: 1rem;
}

.input-email {
  margin: 0 auto;
  margin-top: 3rem;
}

.titulo-formulario-assistido {
  width: 487px;
  height: 75px;
  margin: 52.7px 47px 95px 50px;
  font-family: Telefonica;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--0-b-2739);
}

.conteudo-lista-formulario {
  text-align: start;
  color: #313235;
  margin-top: 1.5rem;
}
.disabledButton {
  background-color: #b6b9bf;
  color: #ffffff;
}

.btnVerificado {
  background: #019df4;
  color: #fff;
  font-weight: bold;
}

.footer-legenda {
  color: #86888c;
  font-size: 13px;
  margin-top: 14px;
  padding-bottom: 10px;
}
.arrow-blue-assistido {
  position: relative;
  top: 7px;
  cursor: pointer;
}

.container-card-information {
  height: 263px;
}

.expandInformation {
  height: 445px;
}

.openBox {
  animation: animationOpenBox 0.3s forwards;
}

.closeBox {
  animation: animationCloseBox 5s forwards;
}

.btn-oferta-assistido {
  width: 382px;
  height: 49px;
  margin: 3rem auto;
}
</style>
