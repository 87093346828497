import Cookies from '../plugins/vue-cookies'
import { capitalizeFirstLetter } from './common'

const ACCESS_TOKEN = 'access-token'
const TOKEN_TYPE = 'token-type'
const REFRESH_TOKEN = 'refresh-token'
const DOCUMENT_NUMBER = 'document-number'
const IS_AUTHENTICATED = 'is-authenticated'

export const jwt = {
  saveJwt: function (token) {
    const expirationTimeDouble = token.expires_in.toString() * 2

    Cookies.set(IS_AUTHENTICATED, true, token.expires_in)
    Cookies.set(ACCESS_TOKEN, token.access_token, token.expires_in)
    Cookies.set(TOKEN_TYPE, capitalizeFirstLetter(token.token_type), token.expires_in)
    Cookies.set(REFRESH_TOKEN, token.refresh_token, expirationTimeDouble)
    Cookies.set(DOCUMENT_NUMBER, token.documentNumber, token.expires_in)
  },

  getToken: function () {
    if (Cookies.isKey(ACCESS_TOKEN)) return Cookies.get(ACCESS_TOKEN)
    return null
  },
  getTokenType: function () {
    if (Cookies.isKey(TOKEN_TYPE)) return Cookies.get(TOKEN_TYPE)
    return null
  },
  getRefreshToken: function () {
    if (Cookies.isKey(REFRESH_TOKEN)) return Cookies.get(REFRESH_TOKEN)
    return null
  },
  getDocumentNumber: function () {
    if (Cookies.isKey(DOCUMENT_NUMBER)) return Cookies.get(DOCUMENT_NUMBER)
    return null
  },
  getIsAuthenticated: function () {
    if (Cookies.isKey(IS_AUTHENTICATED)) return Cookies.get(IS_AUTHENTICATED)
    return false
  },

  destroyJWT: function () {
    Cookies.remove(ACCESS_TOKEN)
    Cookies.remove(TOKEN_TYPE)
    Cookies.remove(REFRESH_TOKEN)
    Cookies.remove(DOCUMENT_NUMBER)
    Cookies.remove(IS_AUTHENTICATED)
  },
}
