<template>
  <div>
    <div class="container-assisted-phone-details" v-bind:class="[moreInformation ? 'openBox' : 'closeBox']">
      <!-- <section v-if="guaranteePayment" class="fianca">
        <h2>En este valor esta incluida la finaza</h2>
        <b-row>
          <b-col class="col-left">
            <p>Fianza *</p>
            <p class="model" v-text="phoneDetailsAssisted.deviceModel[0]" />
          </b-col>
          <b-col class="col-right">{{ realFinancedAmountFmt }} </b-col>
        </b-row>
      </section> -->
      <section class="header-mais-informacoes">
        <div class="alldevices">
          <ul class="no-bullets titulo-mais-informacoes">
            <li v-for="device in phoneDetailsAssisted.deviceModel" id="devices" class="devices" :key="device">
              {{ device }}
            </li>
          </ul>
          <img @click="showDevices()" id="arrow" src="@/assets/icons/arrow-down-white.svg" class="arrow-down" />
        </div>
        <p class="valor-mais-informacoes">{{ phoneDetailsAssisted.price }}</p>
      </section>

      <b-row align-h="center" align-v="center" class="mb-1 mt-1">
        <b-img src="@/assets/icons/money.svg" alt="Money icon" />
        <b-card-text class="choose-quotas">{{ title }}</b-card-text>
      </b-row>

      <b-card-text class="offer-subtitle">{{ subTitle }}</b-card-text>

      <div class="simulador-calculadora">
        <section v-for="button of arrayButtons" :key="button.value">
          <button
            class="btn-parcel"
            @click="changeValue(button)"
            :class="{ btnVerificado: button.verified, disabledButton: button.disabled }"
            :disabled="button.disabled"
          >
            {{ button.value }}
          </button>
        </section>
      </div>

      <div class="more-information">
        <section v-if="advancedPayment" class="container-more-information-top container-total-cueta">
          <h1 class="legend-value text-bold mb-0">Pago inicial equipo:</h1>
          <span class="value-total-cueta" v-text="advancedPaymentFmt" />
        </section>
        <section v-if="guaranteePayment" class="container-more-information-top container-total-cueta">
          <div class="d-flex align-items-center">
            <h1 class="legend-value text-bold mb-0 mr-2">Pago único fianza</h1>
            <b-button class="p-0" variant="blank" v-b-toggle.fianca-details>
              <b-img src="@/assets/icons/arrow-down.svg"></b-img>
            </b-button>
          </div>
          <div class="d-flex align-items-center">
            <b-img
              class="mr-2"
              src="@/assets/icons/warning-blue.svg"
              alt="Info fianca"
              width="18"
              v-b-tooltip.hover.topleft="{
                title: `Valor total de la fianza`,
              }"
            />
            <span class="value-total-cueta" v-text="guaranteePaymentTotalFmt" />
          </div>
        </section>
        <b-collapse id="fianca-details" class="fianca-details">
          <section class="container-more-information">
            <h1 class="legend-value">Fianza</h1>
            <span class="content-value legend-value" v-text="guaranteePaymentFmt" />
          </section>
          <section class="container-more-information">
            <h1 class="legend-value">IVA fianza</h1>
            <span class="content-value legend-value" v-text="calculatedGuaranteePaymentIvaFmt" />
          </section>
        </b-collapse>
        <section class="container-more-information-top container-total-cueta">
          <h1 class="legend-value text-bold mb-0">Total cuota / mes</h1>
          <span class="value-total-cueta">
            {{ footerOffer.totalFmt }}
            <span class="legend-con-iva" v-if="footerOffer.taxRate > 0">Con IVA</span>
            <b-img
              src="@/assets/icons/arrow-blue.svg"
              fluid
              alt="Movistar money logo"
              class="arrow-blue-assistido"
              @click="moreInformationArrow"
              v-bind:class="[moreInformation ? 'rotateArrowDown' : 'rotateArrowUp']"
            />
          </span>
        </section>
        <div v-if="moreInformation">
          <section class="container-more-information">
            <h1 class="legend-value">Cuota capital</h1>
            <span class="content-value legend-value">{{ footerOffer.principalDueFmt }}</span>
          </section>

          <section class="container-more-information">
            <h1 class="legend-value">Intereses</h1>
            <span class="content-value legend-value">{{ footerOffer.interestDueFmt }} </span>
          </section>

          <section class="container-more-information">
            <h1 class="legend-value">IVA intereses</h1>
            <span class="content-value legend-value">{{ footerOffer.taxInterestDueFmt }} </span>
          </section>

          <section class="container-more-information">
            <h1 class="legend-value">Cargo administrativo</h1>
            <span class="content-value legend-value">{{ footerOffer.feesDueFmt }}</span>
          </section>

          <section class="container-more-information">
            <h1 class="legend-value">IVA cargo administrativo</h1>
            <span class="content-value legend-value">{{ footerOffer.taxFeesDueFmt }}</span>
          </section>

          <section class="container-more-information">
            <h1 class="legend-value">Total cuota</h1>
            <span class="content-value legend-value">{{ footerOffer.totalFmt }}</span>
          </section>
        </div>
      </div>
      <div class="segunda-mais-informacao" v-if="moreInformation">
        <section class="container-more-information">
          <h1 class="legend-value">Valor a financiar</h1>
          <span class="content-value legend-value">{{ financedAmountFmt }}</span>
        </section>

        <section class="container-more-information">
          <h1 class="legend-value">Tasa efectiva anual (TEA)</h1>
          <span class="content-value legend-value">{{ footerOffer.interestCETYearlyFmt }}</span>
        </section>

        <section class="container-more-information">
          <h1 class="legend-value">Tasa nominal mensual</h1>
          <span class="content-value legend-value">{{ footerOffer.interestCETFmt }}</span>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import store from '../../../store'
import { MUTATION_SELECTED_ASSISTED } from '../../../store/mutations.type'
import { mapGetters } from 'vuex'

export default {
  name: 'DetalhesTelefoneAssistidoAceitaAbrir',
  props: {
    title: String,
    subTitle: String,
    selectedOfferAssisted: {},
    offerAssisted: {},
    phoneDetailsAssisted: {},
    arrayButtons: [],
  },
  data() {
    return {
      allDevices: false,
      moreInformation: false,
      footerOffer: {},
    }
  },
  watch: {
    offerAssisted(newValue) {
      if (newValue) {
        this.initialValue()
      }
    },
  },
  methods: {
    moreInformationArrow() {
      this.moreInformation = !this.moreInformation
    },
    showDevices() {
      document.getElementById('arrow').className = this.allDevices ? 'arrow-down' : 'arrow-up'
      document
        .querySelectorAll('[id=devices]')
        .forEach((element) => (element.className = this.allDevices ? 'devices' : 'devices-open'))
      this.allDevices = !this.allDevices
    },
    initialValue() {
      let ofertaSelecionadaHabilitada = false
      let ultimaOfertaHabilitada
      this.arrayButtons?.map((button, index) => {
        this.arrayButtons[index].disabled = true
        this.arrayButtons[index].verified = false
      })

      this.arrayButtons?.map((button, index) => {
        this.offerAssisted?.map((offer) => {
          if (offer.installment == button.value) {
            this.arrayButtons[index].disabled = false
            ultimaOfertaHabilitada = button
            if (offer.installment == this.selectedOfferAssisted.installment) {
              ofertaSelecionadaHabilitada = true
              this.arrayButtons[index].verified = true
              this.footerOffer = offer
              store.commit(MUTATION_SELECTED_ASSISTED, this.footerOffer)
            }
          }
        })
      })
      if (!ofertaSelecionadaHabilitada) {
        this.changeValue(ultimaOfertaHabilitada)
      }
    },
    changeValue(btnObject) {
      this.arrayButtons.map((button, index) => {
        if (button.value == btnObject.value) {
          this.arrayButtons[index].verified = true
          this.offerAssisted.map((offer) => {
            if (offer.installment == button.value) {
              this.footerOffer = offer
              store.commit(MUTATION_SELECTED_ASSISTED, this.footerOffer)
            }
          })
        }
        if (button.value !== btnObject.value) {
          this.arrayButtons[index].verified = false
        }
      })
    },
  },
  computed: {
    ...mapGetters({
      currentApplication: 'currentApplication',
      phoneDetailtUrl: 'phoneDetailtUrl',
      advancedPayment: 'advancedPayment',
      advancedPaymentFmt: 'advancedPaymentFmt',
      financedAmountFmt: 'financedAmountFmt',
      guaranteePayment: 'guaranteePayment',
      guaranteePaymentFmt: 'guaranteePaymentFmt',
      calculatedGuaranteePaymentIvaFmt: 'calculatedGuaranteePaymentIvaFmt',
      guaranteePaymentTotal: 'guaranteePaymentTotal',
      guaranteePaymentTotalFmt: 'guaranteePaymentTotalFmt',
      realFinancedAmountFmt: 'realFinancedAmountFmt',
    }),
  },
}
</script>

<style lang="scss" scoped>
.value-total-cueta {
  font-weight: bold;
  font-size: 1rem;
  display: flex;
  align-items: center;
}
.titulo-cifrao-assistido {
  font-size: 16px;
  color: #0b2739;
  font-weight: bold;
  margin-left: 1rem;
  margin-bottom: 0px;
}

.valor-mais-informacoes {
  font-weight: bold;
  color: #fff;
  font-size: 16px;
  margin-bottom: 0px;
  margin-left: 1rem;
}

.header-mais-informacoes {
  background-color: #019df4;
  border-radius: 10px 10px 0 0;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  display: flex;
  align-items: center;
  position: relative;
  top: -10px;
  width: 339px;
  left: -15px;
  padding: 8px 5px;
}

.header-box-information {
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid #d3d4d3;
  margin-bottom: 1rem;
}

.valor-monetario {
  font-weight: bold;
}

.legend-value {
  font-size: 14px;
  color: #86888c;
}

.more-information {
  display: flex;
  flex-direction: column;
}

.btn-parcel {
  width: 50px;
  height: 35px;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  border: none;
  text-align: center;
  color: #86888c;
}

.container-more-information-top {
  display: flex;
  justify-content: space-between;
  padding: 0 0.3rem;
  height: 17px;
}
.container-total-cueta {
  margin-bottom: 7px;
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.simulador-calculadora {
  margin-bottom: 10px;
  height: 70px;
  flex-direction: row;
  border-bottom: 1px solid #d3d4d3;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.choose-quotas {
  margin: 0 0 1.6px 9.6px;
  font-weight: bold;
}

.offer-subtitle {
  font-size: 0.86rem;
  color: #019df4;
}

.container-assisted-phone-details {
  box-shadow: 0 3px 6px #00000029;
  border-radius: 10px;
  width: 340px;
  background-color: #fff;
  padding: 0px 14px 20px 16px;
}

.btnVerificado {
  background: #019df4;
  color: #fff;
  font-weight: bold;
}
.disabledButton {
  background-color: #b6b9bf;
  color: #ffffff;
}

.content-total-cueta {
  font-size: 1rem;
  color: #86888c;
  font-weight: bold;
  margin-top: 8px;
}

.segunda-mais-informacao {
  margin-top: 1.5rem;
}

.container-more-information {
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
  height: 17px;
}

.footer-legenda {
  color: #86888c;
  font-size: 12px;
  margin-top: 14px;
}

.legend-con-iva {
  font-size: 14px;
  font-weight: lighter;
}

.titulo-mais-informacoes {
  font-size: 17px;
  color: #fff;
}
.arrow-blue-assistido {
  position: relative;
  top: 1px;
  cursor: pointer;
  margin-left: 8px;
}

.openBox {
  animation: animationOpenBox 0.2s forwards;
}

.closeBox {
  animation: animationCloseBox 0.2s forwards;
}

.rotateArrowDown {
  animation: rotateArrowDown 0.5s forwards;
}

.rotateArrowUp {
  animation: rotateArrowUp 0.5s forwards;
}

.devices {
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.devices-open {
  width: 150px;
  overflow: auto;
  word-wrap: break-word;
  height: fit-content;
}

ul.no-bullets {
  list-style-type: none;
  text-align: left;
  font-size: 14px;
  margin: 0;
  padding: 0;
  width: 170px;
}
.alldevices {
  display: flex;
  width: 190px;
  border-right: 2px solid #d3d4d3;
  padding-left: 10px;
  padding-right: 10px;
}

.fianca {
  background-color: #ec624b;
  color: white;
  border-radius: 10px 10px 0 0;
  padding: 10px 0;
  margin: 0 -14px 0 -16px;
  h2 {
    font-size: 1.25rem;
  }
  p {
    font-size: 0.8rem;
    margin: 0;
    &.model {
      font-size: 0.75rem;
      color: #f0f0f0;
    }
  }
  .col-left {
    padding-left: 2rem;
    text-align: left;
  }
  .col-right {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 2rem;
    border-left: 1px solid #d0d0d0;
  }
  &-details {
    margin-bottom: 8px;
    section {
      padding: 0 4.8px;
    }
  }
}

img.arrow-down {
  margin-top: 0px;
  width: 17px;
}

img.arrow-up {
  width: 17px;
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  margin-top: 0px;
}

@keyframes animationOpenBox {
  from {
    height: 250px;
  }
  to {
    height: 100%;
  }
}

@keyframes rotateArrowDown {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-180deg);
  }
}

@keyframes rotateArrowUp {
  from {
    transform: rotate(-180deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@keyframes animationCloseBox {
  from {
    height: 340px;
  }
  to {
    height: 100%;
  }
}
</style>
