<template>
  <div class="container-finance">
    <div class="header">
      <p class="sm-finance-title text-left">Conoce más sobre tus finanzas con nosotros</p>
      <hr class="rounded" />
    </div>
    <div class="content-finance">
      <b-card
        no-body
        class="overflow-hidden card-finance"
        style="max-width: 600px; border: 1px solid #d3d4d3; margin-bottom: 10px; border-radius: 16px"
      >
        <b-row no-gutters>
          <b-col md="6">
            <b-card-body>
              <b-card-text>
                <p class="sm-finance-card-title">Educación financiera</p>
                <p class="sm-finance-card-subtitle">
                  Educación financiera calcula en el simulador de préstamo tu cuota
                </p>
                <p class="sm-finance-card-date">Por Movistar Money el 10 de Mar. 2020</p>
                <h1 style="font-size: 14px">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum facilisis justo in dui eleifend,
                  quis volutpat ipsum hendrerit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum
                  facilisis justo in dui eleifend, quis volutpat ipsum hendrerit.
                </h1>
              </b-card-text>
            </b-card-body>
          </b-col>
          <b-col md="6">
            <b-card-img
              src="https://picsum.photos/400/400/?image=20"
              alt="Image"
              class="rounded-0"
              style="width: 270px; height: 100%; margin-left: 30px"
            ></b-card-img>
          </b-col>
        </b-row>
      </b-card>
      <b-card
        no-body
        class="overflow-hidden"
        style="max-width: 600px; border: 1px solid #d3d4d3; margin-bottom: 10px; border-radius: 16px"
      >
        <b-row no-gutters>
          <b-col md="6">
            <b-card-body>
              <b-card-text>
                <p class="sm-finance-card-title">Educación financiera</p>
                <p class="sm-finance-card-subtitle">
                  Educación financiera calcula en el simulador de préstamo tu cuota
                </p>
                <p class="sm-finance-card-date">Por Movistar Money el 10 de Mar. 2020</p>
                <h1 style="font-size: 14px">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum facilisis justo in dui eleifend,
                  quis volutpat ipsum hendrerit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum
                  facilisis justo in dui eleifend, quis volutpat ipsum hendrerit.
                </h1>
              </b-card-text>
            </b-card-body>
          </b-col>
          <b-col md="6">
            <b-card-img
              src="https://picsum.photos/400/400/?image=20"
              alt="Image"
              class="rounded-0"
              style="width: 270px; height: 100%; margin-left: 30px"
            ></b-card-img>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Finance',
}
</script>

<style scoped>
.sm-finance {
  background-color: var(--white);
}

.header {
  display: flex;
}

.sm-finance-title {
  font-size: 28px;
  padding: 54px 20px 20px 71px;
}

.sm-finance-card-date {
  text-align: start;
  margin-left: 10px;
}

.card-finance {
  border: 1px solid #d3d4d3;
  display: flex;
  align-items: center;
}
.container-finance {
  margin-bottom: 1.5rem;
  display: none;
}

.card-sm-finance {
  border-radius: 16px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #d3d4d3;
}

.container-finance {
  background: #ffffff;
}

.content-finance {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 20px;
  flex-wrap: wrap;
}

.content {
  display: flex;
  background: red;
}

.card {
  border: 0;
}

.card-img,
.card-img-top {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  height: 160px;
}
</style>
