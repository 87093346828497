<template>
  <div>
    <b-form>
      <b-form-group>
        <div class="mb-3">
          <b-input-group :class="{ formInputError: $v.form.password.$error }">
            <b-input-group-prepend>
              <b-img src="@/assets/icons/lock-open.svg" alt="Lock icon" />
            </b-input-group-prepend>
            <input
              :type="passwordFieldType"
              class="v-mask pb-2 form-input-item"
              maxlength="12"
              placeholder="Contraseña"
              v-model="form.password"
              autocomplete="new-password"
            />
            <b-input-group-append @click.prevent="switchVisibility('password')">
              <button class="button-hide-show-password">
                <b-img :src="require(`@/assets/icons/${passwordImage}`)" alt="Eye icon" />
              </button>
            </b-input-group-append>
          </b-input-group>
          <p v-if="$v.form.password.$error" class="form-input-error">Usa 8 caracteres como mínimo para la contraseña</p>
        </div>

        <div class="mb-3">
          <b-input-group :class="{ formInputError: $v.form.equalPassword.$error }">
            <b-input-group-prepend>
              <b-img src="@/assets/icons/lock-open.svg" alt="Lock icon" />
            </b-input-group-prepend>
            <input
              :type="equalPasswordFieldType"
              class="v-mask pb-2 form-input-item"
              maxlength="12"
              placeholder="Confirmar contraseña"
              v-model="form.equalPassword"
              autocomplete="new-password"
            />
            <b-input-group-append @click.prevent="switchVisibility('equalPassword')">
              <button class="button-hide-show-password">
                <b-img :src="require(`@/assets/icons/${equalPasswordImage}`)" alt="Eye icon" />
              </button>
            </b-input-group-append>
          </b-input-group>
          <p v-if="$v.form.equalPassword.$error" class="form-input-error">Las contraseñas no coinciden</p>
        </div>

        <div v-if="errorMessage">
          <p v-for="erro in regexBrokenLineError(errorMessage)" :key="erro.index" class="mb-1 form-input-error">
            {{ erro }}
          </p>
        </div>
      </b-form-group>
    </b-form>

    <StepperLogin v-if="stepper" :step="stepper" />

    <div class="pb-4 pt-5">
      <ButtonContinueLogin text="Ingresar" @click="submitPassword" :disable="disableButton" />
    </div>
  </div>
</template>

<script>
import { maxLength, minLength, required, sameAs } from 'vuelidate/lib/validators'
import StepperLogin from '../StepperLogin'
import ButtonContinueLogin from '../buttons/ButtonContinueLogin'

export default {
  name: 'SMPasswordForm',
  components: { ButtonContinueLogin, StepperLogin },
  props: {
    errorMessage: String,
    stepper: Number,
  },
  data() {
    return {
      passwordFieldType: 'password',
      equalPasswordFieldType: 'password',
      passwordImage: 'eye.svg',
      equalPasswordImage: 'eye.svg',
      disableButton: true,
      form: {
        password: '',
        equalPassword: '',
      },
    }
  },
  validations: {
    form: {
      password: { required, min: minLength(8) },
      equalPassword: { sameAsPassword: sameAs('password') },
    },
  },
  watch: {
    form: {
      handler: function (newValue) {
        this.disableButton = newValue.equalPassword <= 0 || newValue.password <= 0
      },
      deep: true,
    },
  },
  methods: {
    regexBrokenLineError(errorMessage) {
      var newstr = errorMessage.replaceAll('.', '')
      newstr = newstr.replaceAll('* ', '')
      function splitString(stringToSplit, separator) {
        var arrayOfStrings = stringToSplit.split(separator)
        return arrayOfStrings
      }
      var errors = splitString(newstr, ',')
      return errors
    },
    submitPassword() {
      this.$v.form.$touch()
      if (this.$v.form.$error) return
      this.$emit('send-form', this.form)
    },
    switchVisibility(password) {
      if (password === 'password') {
        this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
        this.passwordImage = this.passwordImage === 'eye.svg' ? 'eye-blue.svg' : 'eye.svg'
      } else {
        this.equalPasswordFieldType = this.equalPasswordFieldType === 'password' ? 'text' : 'password'
        this.equalPasswordImage = this.equalPasswordImage === 'eye.svg' ? 'eye-blue.svg' : 'eye.svg'
      }
    },
  },
}
</script>
