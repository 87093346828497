<template>
  <div class="container-fluid mb-5">
    <b-container>
      <StripesTopImage />

      <MovistarMoneyLogo />

      <Stepper :step="step" />

      <b-row class="text-center" align-h="center">
        <b-col cols="12" md="6" class="no-padding">
          <p class="onboard-contract-title">¡Genial, ya casi está!</p>
          <div class="mt-2">
            <CardTermsAndConditions @terms="updateTerms" />
          </div>

          <div class="mt-5">
            <CardPhoneDetails
              :phone-details="phoneDetails"
              :application-offer="applicationOffer"
              :current-application="currentApplication"
              :show-phone-review="true"
              :step="step"
            />
          </div>

          <div class="mt-5">
            <ButtonContinue text="Finalizar" :disable="desativarContinuar" @click="createContract" />
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import StripesTopImage from '@/components/StripesTopImage.vue'
import MovistarMoneyLogo from '@/components/MovistarMoneyLogo.vue'
import Stepper from '@/components/Stepper.vue'
import CardPhoneDetails from '@/components/CardPhoneDetails.vue'
import ButtonContinue from '@/components/buttons/ButtonContinue.vue'
import CardTermsAndConditions from '@/components/CardTermsAndConditions.vue'
import store from '@/store'
import {
  CREATE_CONTRACT,
  FETCH_APPLICATION_REDIRECT,
  FETCH_CONTRACT,
  SAVE_CONTRACT_TERMS,
  TOGGLE_OVERLAY,
} from '@/store/actions.type'
import { mapGetters } from 'vuex'
import { gtmDataLayerCheckout, gtmDataLayerTrackEvent } from '@/helpers/gtmHelper'
import { Persist } from '@/helpers/persist.service'

export default {
  name: 'Contract',
  components: { CardTermsAndConditions, ButtonContinue, CardPhoneDetails, Stepper, MovistarMoneyLogo, StripesTopImage },
  data() {
    return {
      step: 5,
      nextPage: 'Details',
      form: {
        action: 'createContract',
        isPartial: false,
      },
    }
  },
  mounted() {
    Persist.clearContractTerms()
    store.dispatch(FETCH_APPLICATION_REDIRECT, 'Finter')
    store.dispatch(FETCH_CONTRACT)
    gtmDataLayerCheckout(
      window.dataLayer,
      'checkOutView',
      this.phoneDetails.deviceModel,
      this.phoneDetails.sku,
      this.phoneDetails.price,
      this.phoneDetails.deviceModel,
      'movistar money',
      this.phoneDetails.deviceColor,
      this.step
    )
  },
  methods: {
    createContract() {
      if (!this.contractTerms) return
      gtmDataLayerTrackEvent(
        window.dataLayer,
        'trackEvent',
        'movistar money',
        'click::verificación de contrato::paso 5',
        'finalizar compra',
        this.CryptoJS.SHA1(this.currentApplication.mobile).toString()
      )
      store.dispatch(TOGGLE_OVERLAY, true)
      store.dispatch(CREATE_CONTRACT, this.form)
    },
    updateTerms() {
      gtmDataLayerTrackEvent(
        window.dataLayer,
        'trackEvent',
        'movistar money',
        'click::verificación de contrato::paso 5',
        'respuesta de validación dirección',
        this.CryptoJS.SHA1(this.currentApplication.mobile).toString()
      )
      store.dispatch(SAVE_CONTRACT_TERMS)
    },
  },
  computed: {
    ...mapGetters({
      phoneDetails: 'phoneDetails',
      currentApplication: 'currentApplication',
      applicationOffer: 'applicationOffer',
      contractTerms: 'contractTerms',
      contractFianca: 'contractFianca',
      guaranteePayment: 'guaranteePayment',
    }),
    desativarContinuar() {
      if (this.currentApplication.guaranteePayment) {
        return !this.contractTerms || !this.contractFianca
      }
      return !this.contractTerms
    },
  },
}
</script>
