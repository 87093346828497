<template>
  <b-card no-body border-variant="white">
    <div class="card-wrapper">
      <div class="py-4 px-3">
        <b-row align-h="center">
          <p class="text-pine-green">Para poder aprobar tu solicitud, es necesario que:</p>
        </b-row>
        <b-row align-h="start" class="mx-2 font-14">
          <p class="text-grey no-margin">1. Leas el contrato.</p>
        </b-row>
        <b-row align-h="start" class="mx-2 font-14">
          <p class="text-grey no-margin">2. Firmes el contrato marcando el ícono</p>
        </b-row>
        <b-row align-h="start" align-v="center" class="pl-4 pt-4">
          <b-img
            v-if="termsConditionsBool"
            v-b-modal.modal-terms-and-conditions
            src="@/assets/icons/terms-accept.svg"
            alt="Contract icon"
          />
          <b-img
            v-else
            v-b-modal.modal-terms-and-conditions
            @click="acceptTermsConditions(true)"
            src="@/assets/icons/terms.svg"
            alt="Contract icon"
          />
          <p
            v-b-modal.modal-terms-and-conditions
            @click="acceptTermsConditions(true)"
            class="no-margin onboard-contract-terms pl-2"
          >
            <u>He leído y estoy de acuerdo con el contrato</u>
          </p>
        </b-row>
        <b-row v-if="currentApplication.guaranteePayment" align-h="left" align-v="center" class="pl-4 pt-1">
          <b-img
            v-if="contratoFianca"
            v-b-modal.modal-contrato-fianca
            src="@/assets/icons/terms-accept.svg"
            alt="Fianza icon"
          />
          <b-img
            v-else
            v-b-modal.modal-contrato-fianca
            @click="aceitaContratoFianca(true)"
            src="@/assets/icons/terms.svg"
            alt="Fianza icon"
          />
          <p
            v-b-modal.modal-contrato-fianca
            @click="aceitaContratoFianca(true)"
            class="no-margin onboard-contract-terms pl-2"
          >
            <u>Acepto la Fianza</u>
          </p>
        </b-row>
      </div>
    </div>
    <ModalTermsAndConditions
      title="Contrato de crédito"
      :contract="contract"
      subtitle="¡Te enviaremos una copia de este contrato a tu correo electrónico!"
    />
    <ModalContratoFianca />
  </b-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { gtmDataLayerTrackEvent } from '../helpers/gtmHelper'
import { SAVE_CONTRACT_FIANCA } from '@/store/actions.type'

import ModalTermsAndConditions from '@/components/modal/ModalTermsAndConditions.vue'
import ModalContratoFianca from '@/components/fianca/modal/ModalContratoFianca.vue'

export default {
  name: 'CardTermsAndConditions',
  components: { ModalTermsAndConditions, ModalContratoFianca },
  props: {
    phoneDetails: Object,
  },
  data() {
    return {
      termsConditionsBool: false,
      contratoFianca: false,
    }
  },
  methods: {
    acceptTermsConditions(bool) {
      this.termsConditionsBool = bool
      this.$emit('terms')
      gtmDataLayerTrackEvent(
        window.dataLayer,
        'trackEvent',
        'movistar money',
        'click::verificación de contrato::paso 5',
        'leer acuerdo de contrato',
        this.CryptoJS.SHA1(this.currentApplication.mobile).toString()
      )
    },
    aceitaContratoFianca(bool) {
      this.contratoFianca = bool
      this.$store.dispatch(SAVE_CONTRACT_FIANCA)
    },
  },
  computed: {
    ...mapGetters({
      currentApplication: 'currentApplication',
      contract: 'contract',
      guaranteePayment: 'guaranteePayment',
    }),
  },
}
</script>
