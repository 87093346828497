<template>
  <b-card no-body border-variant="white" v-if="phoneDetails">
    <div class="card-wrapper">
      <b-row align-h="center" no-gutters>
        <b-col cols="8" class="pr-0">
          <b-row align-v="center">
            <b-col cols="4" class="pl-3 pr-0">
              <b-row align-h="center">
                <b-img class="phone-image" :src="phoneDetails.urlPhoto" alt="Phone" />
              </b-row>
            </b-col>
            <b-col cols="8" class="phone-header">
              <b-row>
                <p class="onboard-card-phone-title">{{ phoneDetails.deviceModel }}</p>
              </b-row>
              <b-row align-v="center">
                <span class="offer-subtitle text-pine-green mr-1">{{ phoneDetails.internMemory }}</span>
                <ColorCircle :big="true" :color="phoneDetails.deviceColorHex" />
                <span class="offer-subtitle text-grey">{{ phoneDetails.deviceColor }}</span>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="4">
          <b-row align-h="end" no-gutters>
            <b-img class="balloon-image" src="~@/assets/images/balloon-phone.png" alt="Balloon" />
          </b-row>
          <!--<CambiarEquipo @change="mudarEquipamento()"/>-->
        </b-col>
      </b-row>

      <div v-if="showPhoneFeatures">
        <hr class="no-margin" />
        <b-collapse id="phone-details" class="phone-details py-0 mt-2">
          <b-row>
            <ItemPhoneDetails image="camera.svg" alt="Camera icon" :item="phoneDetails.camera" />
            <ItemPhoneDetails image="memory.svg" alt="Memory icon" :item="phoneDetails.ram" />
            <ItemPhoneDetails image="processor.svg" alt="Phone screen size icon" :item="phoneDetails.processor" />
            <ItemPhoneDetails image="screen.svg" alt="Processor icon" :item="phoneDetails.screen" />
          </b-row>
        </b-collapse>
        <b-row class="py-1">
          <b-col>
            <ToggleCollapse id="phone-details" icon-up="arrow-up.svg" icon-down="arrow-down.svg" @click="verSpecs()" />
          </b-col>
        </b-row>
      </div>

      <div v-if="showPhoneReview">
        <hr class="no-margin mx-2" />
        <b-row class="p-2" v-if="currentApplication.advancedPayment">
          <b-col cols="7" class="d-flex align-items-center">
            <p class="font-14 text-grey text-left mb-0">
              <strong>Pago inicial equipo:</strong>
            </p>
          </b-col>
          <b-col cols="5" class="text-right">
            <span class="offer-value-iva font-weight-bold mr-1" v-text="currentApplication.advancedPaymentFmt" />
          </b-col>
        </b-row>
        <b-row class="py-2">
          <b-col cols="8" class="ml-2 pr-0">
            <p class="no-margin text-left text-grey font-12">
              <strong class="onboard-contract-value"> {{ currentApplication.offer.totalFmt }}</strong>
              <span v-if="!currentApplication.isTaxFree"> Con IVA</span> /mes en
              <strong>{{ currentApplication.intendedInstallmentsNumber }} cuotas</strong> - en tu factura
            </p>
          </b-col>
          <b-col cols="3" align-self="start" class="no-padding">
            <ToggleCollapse id="phone-details" icon-up="arrow-up.svg" icon-down="arrow-down.svg" @click="verSpecs()" />
          </b-col>
        </b-row>
        <hr class="no-margin mx-2" />
        <b-collapse id="phone-details" class="phone-details py-0 mt-2 pb-2">
          <b-row>
            <ItemPhoneDetails image="camera.svg" alt="Camera icon" :item="phoneDetails.camera" />
            <ItemPhoneDetails image="memory.svg" alt="Memory icon" :item="phoneDetails.ram" />
            <ItemPhoneDetails image="processor.svg" alt="Phone screen size icon" :item="phoneDetails.processor" />
            <ItemPhoneDetails image="screen.svg" alt="Processor icon" :item="phoneDetails.screen" />
          </b-row>
        </b-collapse>
      </div>
    </div>
  </b-card>
</template>

<script>
import ItemPhoneDetails from './ItemPhoneDetails'
import ToggleCollapse from './buttons/ToggleCollapse'
import ColorCircle from './ColorCircle'
import { gtmDataLayerTrackEvent } from '../helpers/gtmHelper'

export default {
  name: 'CardPhoneDetails',
  components: { ColorCircle, ToggleCollapse, ItemPhoneDetails },
  props: ['phoneDetails', 'showPhoneFeatures', 'showPhoneReview', 'currentApplication', 'step'],
  methods: {
    verSpecs() {
      gtmDataLayerTrackEvent(
        window.dataLayer,
        'trackEvent',
        'movistar money',
        'click::selección cuotas::paso ' + this.step,
        'ver especificaciones del celular',
        this.CryptoJS.SHA1(this.currentApplication.mobile).toString()
      )
    },
    mudarEquipamento() {
      gtmDataLayerTrackEvent(
        window.dataLayer,
        'trackEvent',
        'movistar money',
        'click::selección cuotas::paso ' + this.step,
        'cambiar equipo',
        this.CryptoJS.SHA1(this.currentApplication.mobile).toString()
      )
    },
  },
}
</script>

<style scoped>
.phone-header {
  padding-left: 20px;
}

.phone-title {
  text-align: left;
  font-size: 18px;
  font-weight: bold;
}

.phone-image {
  height: 80px;
}

.balloon-image {
  margin-top: -12px;
  height: 58px;
  width: 49px;
}

.phone-details {
  padding: 20px;
}
</style>
