<template>
  <b-button pill :variant="computedVariant" @click="$emit('click')" :disabled="disabled" :size="size">
    <slot> {{ label }} </slot>
  </b-button>
</template>

<script setup>
import { computed } from 'vue'
const props = defineProps({
  label: {
    type: String,
    default: 'Label',
  },
  variant: {
    type: String,
    default: 'movistar',
  },
  outline: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: 'sm',
  },
})

const computedVariant = computed(() => {
  if (props.disabled) return props.outline ? 'outline-disabled' : 'disabled'
  else return props.outline ? 'outline-' + props.variant : props.variant
})
</script>

<style scoped lang="scss">
.btn {
  padding: 0.75rem 3rem;
  font-size: 20px;
  font-weight: bold;
  &-movistar {
    background-color: #019df4;
    color: white;
    &:focus {
      color: white;
      box-shadow: none;
    }
    &:hover {
      filter: brightness(105%);
      color: white;
    }
  }
  &-movistar-green {
    background-color: #5cb639;
    color: white;
    &:focus {
      color: white;
      box-shadow: none;
    }
    &:hover {
      filter: brightness(105%);
      color: white;
    }
  }
  &-disabled {
    background: #d3d4d3;
    color: var(--movistar-grey);
    pointer-events: none;
    cursor: default;
  }
  &-outline {
    background-color: white;
    &-movistar {
      color: #019df4;
      border: 1px solid #019df4;
      &:hover {
        filter: brightness(105%);
        color: #019df4;
      }
    }
    &-movistar-green {
      color: #5cb639;
      border: 1px solid #5cb639;
      &:hover {
        filter: brightness(105%);
        color: #5cb639;
      }
    }
    &-disabled {
      color: #d3d4d3;
      border: 1px solid #d3d4d3;
      &:hover {
        color: #d3d4d3;
      }
    }
  }
  &-sm {
    font-size: 16px;
    padding: 12px 32px;
  }
}
</style>
