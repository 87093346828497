<template>
  <div class="container-contrato-assistido">
    <h1 class="header">¡Genial, ya casi está!</h1>
    <div class="contract">
      <p>Debes marcar el check box y luego dar clic en el botón “Continuar”</p>
      <ol>
        <li>Leas el contrato.</li>
        <li>Firmes el contrato marcando el ícono</li>
      </ol>

      <b-row align-v="center" class="pl-3 mb-2">
        <b-img
          v-if="termsConditionsBool"
          v-b-modal.modal-terms-and-conditions
          src="@/assets/icons/terms-accept.svg"
          alt="Contract icon"
        />
        <b-img
          v-else
          v-b-modal.modal-terms-and-conditions
          @click="acceptTermsConditions(true)"
          src="@/assets/icons/terms.svg"
          alt="Contract icon"
        />
        <p
          v-b-modal.modal-terms-and-conditions
          @click="acceptTermsConditions(true)"
          class="no-margin onboard-contract-terms pl-2"
        >
          <u>He leído y estoy de acuerdo con el contrato</u>
        </p>
      </b-row>
      <b-row align-v="center" class="pl-3" v-if="currentApplication.guaranteePayment">
        <b-img
          v-if="contratoFianca"
          v-b-modal.modal-contrato-fianca
          src="@/assets/icons/terms-accept.svg"
          alt="Contract icon"
        />
        <b-img
          v-else
          v-b-modal.modal-contrato-fianca
          @click="aceitarContratoFianca(true)"
          src="@/assets/icons/terms.svg"
          alt="Contract icon"
        />
        <p
          v-b-modal.modal-contrato-fianca
          @click="aceitarContratoFianca(true)"
          class="no-margin onboard-contract-terms pl-2"
        >
          <u>Acepto la fianza</u>
        </p>
      </b-row>
    </div>

    <DetalhesTelefoneAssistido
      :phoneDetailsAssisted="phoneDetailtUrl"
      :footerInformationAssisted="selectedOfferAssisted"
    />
    <MVButton
      label="Continuar"
      variant="movistar-green"
      @click.once="goNextPage"
      class="btn-contrato"
      :disabled="desabilitarContinuar"
    />
    <ModalTermsAndConditions
      title="Contrato de crédito"
      :contract="contract"
      subtitle="¡Te enviaremos una copia de este contrato a tu correo electrónico!"
    />
    <ModalContratoFiancaAssistido />

    <StepperVertical :step="step" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import store from '@/store'
import router from '@/router'
import ModalTermsAndConditions from '@/components/modal/ModalTermsAndConditions.vue'
import ModalContratoFiancaAssistido from '@/components/fianca/modal/ModalContratoFiancaAssistido.vue'
import StepperVertical from '@/components/StepperVertical.vue'
import DetalhesTelefoneAssistido from '@/components/DetalhesTelefoneAssistido.vue'
import MVButton from '@/components/movistar/MVButton.vue'

import {
  FETCH_CONTRACT_ASSISTED,
  FETCH_APPLICATION_ASSISTED,
  ACTION_WORKFLOW_STATUS_ASSISTED,
} from '@/store/actions.type'

export default {
  components: {
    ModalTermsAndConditions,
    ModalContratoFiancaAssistido,
    StepperVertical,
    MVButton,
    DetalhesTelefoneAssistido,
  },
  mounted() {
    this.$store.dispatch(FETCH_APPLICATION_ASSISTED)
    store.dispatch(FETCH_CONTRACT_ASSISTED)
  },
  data() {
    return {
      step: 3,
      termsConditionsBool: false,
      contratoFianca: false,
      openInformation: false,
    }
  },
  watch: {
    responseChangeStatusAssisted(newValue) {
      if (newValue) {
        if (newValue.situation === 'waitingClient') router.push({ name: 'OTPAssistido' })
        else router.push({ name: 'CantProcessRequest' })
      }
    },
  },
  methods: {
    acceptTermsConditions(bool) {
      this.termsConditionsBool = bool
      this.$emit('terms')
    },
    aceitarContratoFianca(bool) {
      this.contratoFianca = bool
      this.$emit('terms')
    },
    abrirInformacao() {
      this.openInformation = !this.openInformation
    },
    goNextPage() {
      this.$store.dispatch(ACTION_WORKFLOW_STATUS_ASSISTED, { action: 'createAssistedContract', isPartial: false })
    },
  },
  computed: {
    ...mapGetters({
      currentApplication: 'currentApplication',
      contract: 'contract',
      phoneDetailtUrl: 'phoneDetailtUrl',
      selectedOfferAssisted: 'selectedOfferAssisted',
    }),
    desabilitarContinuar() {
      if (this.currentApplication.guaranteePayment) {
        return !this.termsConditionsBool || !this.contratoFianca
      } else return !this.termsConditionsBool
    },
  },
}
</script>

<style lang="scss" scoped>
.container-contrato-assistido {
  .header {
    font-size: 1.25rem;
    margin-bottom: 2rem;
  }

  .contract {
    max-width: 22rem;
    padding: 1.25rem 1.75rem 1rem 1.75rem;
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    margin-bottom: 2rem;
    ol {
      padding: 0px;
      li {
        list-style-position: inside;
        text-align: left;
        font-size: 0.85rem;
        color: #50535a;
        &::marker {
          color: #50535a;
        }
      }
    }
  }

  .detalhes-telefone {
    margin-bottom: 2rem;
  }

  .btn-contrato {
    width: 100%;
  }

  .stepper {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: -3rem;
  }
}
</style>
