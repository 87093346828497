<template>
  <div class="container-fluid mb-5">
    <div class="text-center mt-4 mb-4">
      <b-img src="@/assets/images/money.png" fluid alt="Movistar money logo" class="movistar-image" />
    </div>
    <PagamentoAntecipadoCard />
    <br />
    <PaymentTypes />
  </div>
</template>
<script>
import PaymentTypes from '@/components/SelfManage/PaymentTypes.vue'
import PagamentoAntecipadoCard from '@/components/PagamentoAntecipado/PagamentoAntecipadoCard.vue'
export default {
  components: { PaymentTypes, PagamentoAntecipadoCard },
}
</script>
