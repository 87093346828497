<template>
  <b-card no-body class="sm-card-payments">
    <b-card-body>
      <b-row align-v="center">
        <b-col>
          <p class="valor-pendiente-de-pago">Valor pendiente de pago</p>
        </b-col>
      </b-row>
      <b-row align-v="center"> </b-row>
      <b-row>
        <b-col class="pl-2 pr-2" v-if="planoPagamento.globalData">
          {{ planoPagamento.globalData.lastDueValueFmt }}
          <br />
          Saldo pendiente
        </b-col>
        <b-col class="pl-2 pr-2">
          <div>
            <b-button class="contorno-botao" variant="outline-primary" @click="downloadExtract()">
              <b-img src="@/assets/icons/seta-baixo.svg" alt="Download icon" />
              Ver mi extracto
            </b-button>
          </div>
        </b-col>
      </b-row>
      <br />
      <br />
      <ApplyPaymentCardMenuItem
        @pagamentoSelecionado="metodoPagamento"
        :pagamento="this.dadosPagamento.tipoPagamento"
        :faturaMovistar="this.propostaMambu.isMovistarBill"
        :ultimaParcela="planoPagamento.globalData.isLastInstallment"
      />

      <div>
        <!-- PAGAR CUOTA -->
        <div v-if="!this.propostaMambu.isMovistarBill">
          <PagarCuota
            v-if="pagarCuotaSelecionado"
            @pagar="prePagarModal"
            :saldoPendente="planoPagamento.globalData.lastDueValue"
            :saldoPendenteFmt="planoPagamento.globalData.lastDueValueFmt"
            :keyMambu="this.propostaMambu.keyMambu"
            :contractNumber="this.propostaMambu.contractNumber"
            :meioPagamento="this.dadosPagamento.meioPagamento"
          />
        </div>
        <!-- PAGAR ADELANTO CUOTA -->
        <div v-if="!this.propostaMambu.isMovistarBill && !planoPagamento.globalData.isLastInstallment">
          <PagarAdelantoCuota
            v-if="pagarAdelantoCuotaSelecionado"
            @pagar="prePagarModal"
            :keyMambu="this.propostaMambu.keyMambu"
            :planoPagamento="this.planoPagamento"
            :meioPagamento="this.dadosPagamento.meioPagamento"
          />
        </div>
        <!-- ABONO CAPITAL  -->
        <PagarAbono
          v-if="pagarAbonoCapitalSelecionado && !planoPagamento.globalData.isLastInstallment"
          @pagar="prePagarModal"
          @pagarAtrasados="metodoPagamento('Pagar cuota')"
          :faturaMovistar="this.propostaMambu.isMovistarBill"
          :keyMambu="this.propostaMambu.keyMambu"
          :planoPagamento="this.planoPagamento"
          :meioPagamento="this.dadosPagamento.meioPagamento"
        />
      </div>

      <b-modal
        id="modalTipoPagamento"
        ref="modalTipoPagamento"
        class="modal-body"
        size="xl"
        centered
        header-border-variant="white"
        header-class="justify-content-center position-relative"
        footer-border-variant="white"
        cancel-title=""
        cancel-variant="hidden"
        hide-footer
        no-close-on-esc
        no-close-on-backdrop
        ok-title=""
        ok-variant="hidden"
      >
        <template #modal-header="{ close }">
          <div class="modal-titulo" align="center">
            <p class="mt-4 mb-0">Selecciona el medio de pago</p>
          </div>
          <b-img src="@/assets/icons/cancel.svg" alt="Spinner" @click="close()" class="position-absolute modal-close" />
        </template>

        <div align="left" class="mb-4">
          <div class="radio-custom ml-5">
            <fieldset>
              <input type="radio" id="Efectivo" value="Efectivo" v-model="dadosPagamento.meioPagamento" />
              <label for="Efectivo" class="radio-label">Efectivo</label>
            </fieldset>
          </div>
          <div class="radio-custom ml-5 mt-2">
            <fieldset>
              <input type="radio" id="Tarjeta-PSE" value="Tarjeta-PSE" v-model="dadosPagamento.meioPagamento" />
              <label for="Tarjeta-PSE" class="radio-label">Tarjeta / PSE</label>
            </fieldset>
          </div>
        </div>
        <div class="pt-2">
          <button class="blue-button continue-button-text" :disabled="!ativarBotaoModal" @click="modalConfirmar">
            <span>Pagar</span>
          </button>
        </div>
      </b-modal>
    </b-card-body>

    <Modal
      v-if="errorEpaycoResponse.data"
      id="modal-payment"
      :subtitle="errorEpaycoResponse.data.message"
      image="many-attempts.png"
    />
  </b-card>
</template>

<script>
import Vue from 'vue'
import LoadScript from 'vue-plugin-load-script'
Vue.use(LoadScript)

import ApplyPaymentCardMenuItem from './ApplyPaymentCardMenuItem'
import { SELF_MANAGE_GET_APPLICATION_EXTRACT, EPAYCO_SALVAR_CHECKOUT_REQUISICAO } from '../../store/actions.type'
import store from '../../store'
import PagarCuota from './ApplyPaymentCard/PagarCuota.vue'
import PagarAdelantoCuota from './ApplyPaymentCard/PagarAdelantoCuota.vue'
import PagarAbono from './ApplyPaymentCard/PagarAbono.vue'
import Modal from '../modal/Modal.vue'

import { mapGetters } from 'vuex'
import { uuid } from 'vue-uuid' // Import uuid

export default {
  name: 'ApplyPaymentCard',
  components: {
    ApplyPaymentCardMenuItem,
    PagarCuota,
    PagarAdelantoCuota,
    PagarAbono,
    Modal,
  },
  props: {
    planoPagamento: {},
    propostaMambu: {},
    saldoPendente: String,
    outroValor: Number,
  },
  data() {
    return {
      ativarBotaoModal: false,
      mensagemTemporaria: 'No puede pagar más que su deuda',
      dadosPagamento: {
        keyMambu: '',
        valorPagar: '',
        tipoPagamento: 'Pagar cuota',
        abono: '',
        descricao: '',
        processamentoBackend: '',
        meioPagamento: 'Tarjeta-PSE',
      },
      epaycoData: {
        encodeKeyMambu: '',
        name: '',
        description: '',
        invoice: '',
        currency: 'COP',
        amount: '',
        tax_base: '',
        tax: '',
        country: 'CO',
        lang: 'ES',
        test: process.env.VUE_APP_EPAYCO_TEST,
        external: false,
        extra1: '',
        extra2: '',
        extra3: '',
        extra5: '',
        method: 'GET',
        response: '',
        confirmation: '',
        name_billing: '',
        address_billing: '',
        type_doc_billing: 'CC',
        number_doc_billing: '',
        mobilephone_billing: '',
        methodsDisable: [],
        meioPagamento: '',
      },

      pagarSaldo: true,
      algumSelecionado: false,
      problemasForm: false,
      pagarCuotaSelecionado: true,
      pagarAdelantoCuotaSelecionado: false,
      pagarAbonoCapitalSelecionado: false,
      meioPAgamentoSelecionado: false,
    }
  },
  watch: {
    planoPagamento: function () {
      if (this.propostaMambu.isMovistarBill) {
        this.metodoPagamento('Abono a capital')
      }
    },
  },
  mounted() {
    Vue.loadScript('/js/epayco/checkout.js')
      .then((ePaycoScript) => {
        ePaycoScript.id = 'epaycoScript'
      })
      .catch(() => {
        console.log('Problemas ao carregar script epayco.')
      })
  },
  methods: {
    modalConfirmar() {
      this.ativarBotaoModal = false
      this.$refs['modalTipoPagamento'].hide()
      this.openCheckout()
    },
    prePagarModal(payload) {
      this.dadosPagamento = payload
      this.ativarBotaoModal = true
      this.$refs['modalTipoPagamento'].show()
    },
    openCheckout() {
      if (this.dadosPagamento.meioPagamento === 'Efectivo') {
        // this.epaycoData.methodsDisable = ['TDC', 'PSE']
        window.handler = window.ePayco.checkout.configure({
          key: process.env.VUE_APP_EPAYCO_KEY_AGREGADOR,
          test: process.env.VUE_APP_EPAYCO_TEST,
        })
      }

      if (this.dadosPagamento.meioPagamento === 'Tarjeta-PSE') {
        // this.epaycoData.methodsDisable = ['SP','CASH','DP']
        window.handler = window.ePayco.checkout.configure({
          key: process.env.VUE_APP_EPAYCO_KEY_GATEWAY,
          test: process.env.VUE_APP_EPAYCO_TEST,
        })
      }

      this.salvarDadosRequisicaoEpayco()
    },
    showModal(id) {
      this.$bvModal.show(id)
    },
    validCheckout() {
      window.ePayco.checkout.open({
        name: this.dadosPagamento.processamentoBackend,
        description: this.dadosPagamento.tipoPagamento,
        invoice: this.epaycoData.invoice,
        currency: this.epaycoData.currency,
        amount: (+this.dadosPagamento.valorPagar).toFixed(2),
        tax_base: this.epaycoData.taxBase,
        tax: this.epaycoData.tax,
        country: this.epaycoData.country,
        lang: this.epaycoData.lang,
        test: this.epaycoData.test,
        external: this.epaycoData.external,
        extra1: this.dadosPagamento.abono,
        extra2: this.dadosPagamento.meioPagamento,
        extra3: this.epaycoData.extra3,
        extra5: this.propostaMambu.contractNumber,
        method: 'GET',
        response: process.env.VUE_APP_FRONT_URL + '/self-manage/payment/' + this.propostaMambu.keyMambu + '/response/',
        confirmation:
          process.env.VUE_APP_API_URL + '/publicdata/epayco/' + this.propostaMambu.keyMambu + '/confirmation/',
        name_billing: this.currentApplication.borrower.name,
        address_billing: this.epaycoData.billingAddress,
        type_doc_billing: 'CC',
        number_doc_billing: this.documentNumber,
        mobilephone_billing: this.currentApplication.mobile,
      })
    },
    salvarDadosRequisicaoEpayco() {
      this.epaycoData.encodeKeyMambu = this.propostaMambu.keyMambu
      this.epaycoData.name = this.dadosPagamento.processamentoBackend
      this.epaycoData.description = this.dadosPagamento.tipoPagamento
      this.epaycoData.invoice = uuid.v4()
      this.epaycoData.amount = (+this.dadosPagamento.valorPagar).toFixed(2)
      this.epaycoData.extra1 = this.dadosPagamento.abono
      this.epaycoData.extra2 = this.dadosPagamento.meioPagamento
      this.epaycoData.extra3 = this.dadosPagamento.descricao
      this.epaycoData.extra5 = this.propostaMambu.contractNumber
      this.epaycoData.method = 'GET'
      this.epaycoData.response =
        process.env.VUE_APP_FRONT_URL + '/self-manage/payment/' + this.propostaMambu.keyMambu + '/response/'
      this.epaycoData.confirmation =
        process.env.VUE_APP_API_URL + '/publicdata/epayco/' + this.propostaMambu.keyMambu + '/confirmation/'
      this.epaycoData.name_billing = this.currentApplication.borrower.name
      this.epaycoData.address_billing = this.epaycoData.billingAddress
      this.epaycoData.type_doc_billing = 'CC'
      this.epaycoData.number_doc_billing = this.documentNumber
      this.epaycoData.mobilephone_billing = this.currentApplication.mobile

      store
        .dispatch(EPAYCO_SALVAR_CHECKOUT_REQUISICAO, this.epaycoData)
        .then((response) => {
          if (response.status == 200) {
            this.validCheckout()
          }
        })
        .catch((e) => this.showModal('modal-payment'))
    },

    downloadExtract() {
      store.dispatch(SELF_MANAGE_GET_APPLICATION_EXTRACT, this.propostaMambu.keyMambu).catch((error) => {
        this.$bvToast.toast(error, {
          title: '¡Ocurrio un error!',
          autoHideDelay: 25000,
          variant: 'danger',
        })
      })
    },

    metodoPagamento(tipoPagamento) {
      this.dadosPagamento.tipoPagamento = tipoPagamento

      if (tipoPagamento === 'Pagar cuota') {
        this.pagarCuotaSelecionado = true
        this.pagarAdelantoCuotaSelecionado = false
        this.pagarAbonoCapitalSelecionado = false
        this.dadosPagamento.abono = ''
      }
      if (tipoPagamento === 'Adelanto de cuota') {
        this.pagarCuotaSelecionado = false
        this.pagarAdelantoCuotaSelecionado = true
        this.pagarAbonoCapitalSelecionado = false
        this.dadosPagamento.abono = ''
      }
      if (tipoPagamento === 'Abono a capital') {
        this.pagarCuotaSelecionado = false
        this.pagarAdelantoCuotaSelecionado = false
        this.pagarAbonoCapitalSelecionado = true
        this.dadosPagamento.abono = 'Diminuir valor parcela'
      }
    },
  },
  computed: {
    ...mapGetters({
      documentNumber: 'documentNumber',
      currentApplication: 'currentApplication',
      errorEpaycoResponse: 'errorEpaycoResponse',
    }),
  },
}
</script>

<style scoped>
.modal-body {
  padding: 0rem !important;
}
.modal-close {
  top: 20px;
  right: 20px;
}
.valor-pendiente-de-pago {
  font-family: Telefonica, sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #50535a;
}

.input-radio {
  margin-left: 28px;
  width: 20px;
  height: 20px;
}

.content-input-radio {
  display: flex;
  justify-content: center;
}

.container-input-radio {
  margin-top: 2rem;
}

.contorno-botao {
  border-radius: 18px;
}
input[type='radio']:checked + label:before,
input[type='checkbox']:checked + label:before {
  background: #019df4;
}
.posicao-campos {
  margin-right: 2.5rem;
}

.label-input-radio {
  margin-left: 8px;
  position: relative;
  top: -5px;
  font-size: 14px;
}
.modal-titulo {
  font-family: Telefonica;
  font-size: 16px;
  color: var(--pine-green);
}
.radio-label {
  font-family: Telefonica;
  font-size: 14px;
  color: var(--pine-green);
}
.blue-button {
  height: 30px;
  width: 50%;
  margin: 0;
  position: relative;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-family: Telefonica;
  font-size: 12px;
}
</style>
