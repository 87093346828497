<template>
  <AssistidoLayout>
    <template #guia>
      <FiancaAssistidoGuia v-if="guaranteePayment" />
      <OfertaAssistidoGuia v-else />
    </template>
    <OfertaAssistidoForm />
    <ModalDinamico
      v-if="this.postApplicationResponse && this.postApplicationResponse.data"
      :image="true"
      :title="this.postApplicationResponse.data.personalizedMessage"
      :subtitle="this.postApplicationResponse.data.personalizedMessageSubTitle"
      :baseboard="'esta alerta se cierra en ' + timerCount + ' segundos '"
      proximoText="Continuar"
      @proximo="proximoModal"
    />
  </AssistidoLayout>
</template>

<script>
import AssistidoLayout from '@/components/assistido/AssistidoLayout.vue'
import ModalDinamico from '@/components/modal/ModalDinamico.vue'
import OfertaAssistidoGuia from '@/components/assistido/OfertaAssistido/OfertaAssistidoGuia.vue'
import FiancaAssistidoGuia from '@/components/assistido/OfertaAssistido/FiancaAssistidoGuia.vue'
import OfertaAssistidoForm from '@/components/assistido/OfertaAssistido/OfertaAssistidoForm.vue'
import { mapGetters } from 'vuex'

export default {
  components: { AssistidoLayout, OfertaAssistidoGuia, FiancaAssistidoGuia, OfertaAssistidoForm, ModalDinamico },
  data() {
    return {
      timerCount: 10,
    }
  },
  mounted() {
    if (
      this.postApplicationResponse &&
      this.postApplicationResponse.data &&
      this.postApplicationResponse.data.personalizedMessage
    ) {
      this.$bvModal.show('modal-confirm')
    }
  },
  watch: {
    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--
          }, 1000)
        } else {
          this.$bvModal.hide('modal-confirm')
        }
      },
      immediate: true,
    },
  },
  methods: {
    proximoModal() {
      this.$bvModal.hide('modal-confirm')
    },
  },
  computed: {
    ...mapGetters({
      postApplicationResponse: 'postApplicationResponse',
      guaranteePayment: 'guaranteePayment',
    }),
  },
}
</script>

<style scoped>
.container-home-assistido {
  height: 100vh;
  width: 100%;
  background-image: url(~@/assets/images/background-assistido.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
}
.movistar-image-login {
  width: 127.8px;
  height: 41.3px;
}

.container-info-assistido {
  display: flex;
  align-self: center;
  justify-content: space-around;
  margin-bottom: 100px;
}

@media (max-width: 1380px) {
  .container-home-assistido {
    background-image: url(~@/assets/images/background-assistido.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 100vh;
    width: 100%;
    overflow: auto;
  }
}
</style>
