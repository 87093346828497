<template>
  <b-modal
    :id="id"
    class="modal"
    cancel-title=""
    cancel-variant="hidden"
    size="sm"
    hide-header
    hide-footer
    ok-title=""
    ok-variant="hidden"
    centered
    header-border-variant="white"
    footer-border-variant="white"
    no-close-on-esc
    no-close-on-backdrop
  >
    <div class="mx-3">
      <b-row align-h="end">
        <b-button v-if="modalType" variant="blank" @click="closeApp">
          <b-img src="@/assets/icons/cancel.svg" alt="Cancel icon" />
        </b-button>
        <b-button v-else variant="blank" @click="closeModal(id)">
          <b-img src="@/assets/icons/cancel.svg" alt="Cancel icon" />
        </b-button>
      </b-row>
      <div class="py-3">
        <p class="text-center title-onboard">
          {{ error }}
        </p>
      </div>
    </div>
    <div>
      <button class="tentar-novamente-acesso-rapido" @click="closeModal(id)">Volver a intentarlo</button>
    </div>

    <div class="footer-modal-acesso-rapido">
      <p>Si presentas problemas escríbenos aquí</p>
      <div class="footer-contato-acesso-rapido">
        <button class="blue-button inverted" @click="clickWhatsapp()">
          <b-img src="@/assets/icons/whatsapp-icon.svg" alt="GPS icon" />
          &ensp;315 2008280
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'ModalAcessoRapidoError',
  props: {
    error: String,
    id: String,
    modalType: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    clickWhatsapp() {
      window.open('https://api.whatsapp.com/send?phone=573152008280', '_blank')
    },
    closeModal(id) {
      this.$bvModal.hide(id)
    },
  },
}
</script>

<style scoped>
.inverted {
  background: #ffffff;
  color: var(--movistar-pine-green);
  border: none;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  width: fit-content;
  height: 36px;
  padding: 0px 10px;
  border-radius: 12px;
}
.tentar-novamente-acesso-rapido {
  width: 249px;
  height: 48px;
  background: #019df4;
  color: #ffffff;
  display: flex;
  align-items: center;
  border: none;
  justify-content: center;
  border-radius: 24px;
  margin: 0 auto;
}

.footer-modal-acesso-rapido {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footer-contato-acesso-rapido {
  display: flex;
  justify-content: space-around;
  width: 15rem;
  align-items: center;
}

.footer-contato-acesso-rapido > nav > p {
  margin-bottom: 0px;
  color: #019df4;
  font-size: 1rem;
}

.footer-contato-acesso-rapido > nav {
  display: flex;
}

.footer-contato-acesso-rapido > nav > img {
  margin-right: 10px;
}

.footer-modal-acesso-rapido > p {
  color: #09202e;
  margin-bottom: 5px;
  font-size: 1rem;
}
</style>
