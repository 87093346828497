<template>
  <b-button class="py-0 px-0 custom-border" block v-b-toggle="id" variant="blank" @click="emitEvent">
    <template v-if="value">
      <span class="offer-value-iva font-weight-bold mr-1"><input hidden />{{ value }}</span>
      <span v-if="!currentApplication.isTaxFree" class="offer-iva mr-1">Con IVA</span>
    </template>
    <span class="when-opened">
      <b-img :src="require(`@/assets/icons/${iconUp}`)" alt="Arrow icon" />
    </span>
    <span class="when-closed">
      <b-img :src="require(`@/assets/icons/${iconDown}`)" alt="Arrow icon" />
    </span>
  </b-button>
</template>

<script>
export default {
  name: 'ToggleCollapse',
  props: {
    id: String,
    value: String,
    totalValue: Number,
    iconUp: String,
    iconDown: String,
    currentApplication: {},
  },
  data() {
    return {
      isClicked: false,
    }
  },
  methods: {
    emitEvent() {
      !this.isClicked ? (this.isClicked = true) : (this.isClicked = false)
      this.isClicked ? this.$emit('click', true) : this.$emit('click', false)
    },
  },
}
</script>

<style scoped>
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}

.custom-border {
  border: none;
}
</style>
