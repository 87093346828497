<template>
  <AssistidoLayout>
    <div>
      <div class="card-veridas-assistido">
        <div class="container-assistido-info">
          <section>
            <h1 class="title-assistido-info">Verificación de identidad</h1>
          </section>
          <section>
            <div class="mb-3">
              <span class="text-bold">Por seguridad,</span> vamos a validar la identidad de tu cliente, al correo
              ingresado le llegará un link para empezar este proceso
            </div>
            <span class="text-bold">Recuérdale:</span>
            <ul class="list-assistido-info-guia">
              <li class="content-assistido-info-guia">
                Le tomaremos una foto a su cédula y a su rostro, por lo que debe tener su documento de indentidad listo
                antes de iniciar la validación
              </li>
              <li class="content-assistido-info-guia">
                El cliente debe darle clic en "Permitir" para acceder a la cámara
              </li>
              <li class="content-assistido-info-guia">
                Recomiéndale no usar acessorios (como gafas o gorros) mientras realice el proceso y ubicarse en un lugar
                con buena iluminación
              </li>
            </ul>
          </section>
        </div>
        <div class="reenviar-link mt-3 mb-3">
          <span class="text-bold">Si el link no llega da click en volver a enviar</span><br />
          <span>(Podrás enviar hasta 5 veces el link)</span><br />
          <span>Puedes ingresar otro correo y enviar el link ahí</span>
          <ButtonContinue text="Volver a enviar" @click="abrirModalEnviarEmail()" class="btn-reenviar" />
        </div>
      </div>
      <b-row class="botoes">
        <b-col class="pr-5" cols="6">
          <ButtonCancel text="Cancelar solicitud" @click="modalCancel()" class="btn-cancelar" />
        </b-col>
        <b-col class="pl-5" cols="6">
          <ButtonContinue :disable="false" text="Continuar" @click="continuar()" class="botao-continuar" />
        </b-col>
      </b-row>
    </div>
    <ModalNovoEmailVeridas
      id="modal-confirmar-novo-email"
      :applicationId="currentApplication?.applicationId"
      @email_enviado="emailEnviado"
    />
    <ModalEmailVeridas
      id="modal-enviar-email-veridas"
      @email_enviado="emailEnviado"
      @novo_email="abrirModalNovoEmail"
      :email="currentApplication?.email"
      :applicationId="currentApplication?.applicationId"
    />
    <ModalCancelarAssistido
      id="modal-cancelar"
      title="Estás a punto de cancelar tu propuesta"
      subtitle="¿Quieres continuar con esta acción?"
      image="congratulations.svg"
    />
    <Modal id="modal-mensagem" :message="mensagemModal">
      <template #icon>
        <img src="@/assets/icons/negado.svg" alt="Error icon" width="43px" />
      </template>
      <template #button>
        <Button class="button-voltar continue-button continue-button-text" @click="$bvModal.hide('modal-mensagem')">
          <img src="@/assets/icons/arrow-white.svg" alt="" class="arrow" width="15" />
          <span>Volver</span>
        </Button>
      </template>
    </Modal>
  </AssistidoLayout>
</template>
<script>
import { mapGetters } from 'vuex'
import AssistidoLayout from '@/components/assistido/AssistidoLayout.vue'
import ButtonCancel from '@/components/buttons/ButtonCancel.vue'
import ButtonContinue from '@/components/buttons/ButtonContinue.vue'
import ModalNovoEmailVeridas from '@/components/modal/ModalNovoEmailVeridas.vue'
import ModalEmailVeridas from '@/components/modal/ModalEmailVeridas.vue'
import ModalCancelarAssistido from '@/components/modal/ModalCancelarAssistido.vue'
import Modal from '@/components/PreValidacao/Modal.vue'
import { ACTION_WORKFLOW_STATUS_ASSISTED, ACTION_VALIDAR_VERIDAS_ASSISTED } from '@/store/actions.type'
import { MUTATION_RESPONSE_CHANGE_STATUS_ASSISTED } from '@/store/mutations.type'

export default {
  components: {
    AssistidoLayout,
    ButtonCancel,
    ButtonContinue,
    ModalNovoEmailVeridas,
    ModalEmailVeridas,
    ModalCancelarAssistido,
    Modal,
  },
  data() {
    return {
      mensagemModal: '',
      resposta: null,
    }
  },
  async mounted() {
    const body = {
      action: 'sendEmailVeridas',
      firstEmail: true,
    }
    this.resposta = await this.$store.dispatch(ACTION_WORKFLOW_STATUS_ASSISTED, body)
  },

  methods: {
    emailEnviado(payload) {
      this.resposta = payload
    },
    exibirModalGenerico(mensagem) {
      this.mensagemModal = mensagem
      this.$bvModal.show('modal-mensagem')
    },
    modalCancel() {
      this.$bvModal.show('modal-cancelar')
    },
    continuar() {
      this.verificarVeridas()
    },
    async verificarVeridas() {
      const validateIdAction = {
        action: 'validateVeridasId',
        validateId: this.resposta?.response?.validation_id,
      }
      const resposta = await this.$store.dispatch(ACTION_VALIDAR_VERIDAS_ASSISTED, validateIdAction)
      if (resposta.situation === 'documents')
        this.exibirModalGenerico('El cliente aún no ha realizado el processo de validación de identidad')
      else this.$store.commit(MUTATION_RESPONSE_CHANGE_STATUS_ASSISTED, resposta)
    },
    abrirModalEnviarEmail() {
      this.$bvModal.show('modal-enviar-email-veridas')
    },
    abrirModalNovoEmail() {
      this.$bvModal.hide('modal-enviar-email-veridas')
      this.$bvModal.show('modal-confirmar-novo-email')
    },
  },
  computed: {
    ...mapGetters({
      currentApplication: 'currentApplication',
    }),
  },
}
</script>
<style scoped lang="scss">
.button-voltar {
  width: 40%;
  background-color: var(--movistar-blue);
  color: var(--white);
  .arrow {
    margin-right: 12px;
    transform: rotate(90deg);
  }
}
.card-veridas-assistido {
  width: 100%;
}
.botoes {
  width: 100vw;
}
.btn-reenviar {
  max-width: 204px;
  height: 49px;
  margin: 1rem auto;
}
.btn-cancelar {
  max-width: 300px;
  height: 49px;
  margin: 1rem auto;
}
.botao-continuar {
  max-width: 382px;
  height: 49px;
  margin: 1rem auto;
  cursor: pointer;
}
.reenviar-link {
  width: 540px;
  height: 100%;
  max-height: 680px;
  margin-left: auto;
  margin-right: auto;
}
.container-assistido-info {
  margin-left: auto;
  margin-right: auto;
  width: 540px;
  height: 100%;
  max-height: 680px;
  border: solid 1px rgba(1, 157, 244, 0.6);
  border-radius: 10px;
  background-color: #f5f5f5;
  padding: 42px 45px 81.4px 27px;
}
.title-assistido-info {
  font-size: 22px;
  margin: 0 10px 20px;
}
.content-assistido-info-guia {
  font-size: 16px;
  width: 420px;
  height: 100%;
  text-align: start;
  margin-bottom: 8px;
}
.list-assistido-info-guia {
  margin-top: 1rem;
  padding-left: 30px;
}

.content-assistido-info-guia::marker {
  color: #019df4;
  font-size: 1.3rem;
}
::v-deep.container-assistido {
  .content {
    .formulario {
      max-width: 0rem !important;
    }
  }
}
</style>
