<template>
  <b-modal
    id="modal-terms-and-conditions"
    cancel-title=""
    cancel-variant="hidden"
    ok-title=""
    ok-variant="hidden"
    centered
    hide-header
    hide-footer
    header-border-variant="none"
  >
    <template #default>
      <div class="sticky-top pb-3">
        <div class="text-right">
          <b-button variant="none" @click="$bvModal.hide('modal-terms-and-conditions')">
            <b-img src="@/assets/icons/cancel.svg" alt="Cancel icon" />
          </b-button>
        </div>
        <p class="modal-title mx-5">{{ title }}</p>
        <p v-if="subtitle" class="font-16 blue-text mb-2 mx-5 text-center">{{ subtitle }}</p>
      </div>

      <div class="text-center">
        <p v-if="terms"><span class="font" v-html="terms"></span></p>
        <p v-if="contract"><span class="font" v-html="contract"></span></p>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'ModalTermsAndConditions',
  props: ['terms', 'title', 'subtitle', 'contract'],
}
</script>
