<template>
  <div class="container-footer" v-if="footerArrayOffers">
    <section class="content-footer">
      <p class="legend-content value-moth" style="margin-right: 10px">Seguro Mensual</p>
      <p class="footer-content value-moth">{{ footerArrayOffers.feesDueFmt }}</p>
    </section>
    <section class="content-footer">
      <p class="legend-content value-vtu">Valor total Unificado (VTU)</p>
      <p class="footer-content value-vtu">{{ footerArrayOffers.taxRateFmt }}</p>
    </section>
    <section class="content-footer">
      <p class="legend-content value-tea">Tasa efectiva anual (TEA)</p>
      <p class="footer-content value-tea">{{ footerArrayOffers.interestCETYearlyFmt }}</p>
    </section>
    <p class="legend-warning">* La tasa varía dependiendo de la política de crédito</p>
    <button class="next-btn" @click="salvarStorage">
      <router-link to="/" class="next-btn" target="_blank">Lo quiero</router-link>
    </button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Cookies from '../../plugins/vue-cookies'
import { gtmDataLayerCustom } from '../../helpers/gtmHelper'

export default {
  props: {
    footerArrayOffers: {},
  },
  methods: {
    salvarStorage() {
      ;<router-link to="/" class="next-btn" target="_blank">
        Lo quiero
      </router-link>
      Cookies.set('oferta_simulador', JSON.stringify(this.footerArrayOffers), 300)
      localStorage.setItem('oferta_simulador', JSON.stringify(this.footerArrayOffers))
      gtmDataLayerCustom(window.dataLayer, {
        event: 'trackEvent',
        eventCategory: 'movistar money web',
        eventAction: 'click:: inicio',
        eventLabel: 'simulador préstamo lo quiero',
      })
    },
  },
  computed: {
    ...mapGetters({
      selectOffer: 'selectOffer',
    }),
  },
}
</script>

<style scoped>
.value-moth {
  text-align: center;
}

.next-btn {
  background: #ec624b;
  width: 288px;
  height: 35px;
  border-radius: 25px;
  border: none;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  font-weight: bold;
}

.container-footer {
  height: 100%;
  max-height: 140px;
  background: white;
  width: 100%;
  position: absolute;
  bottom: 0px;
  left: 0;
  position: fixed;
  border-radius: 10px 10px 0 0;
  padding-bottom: 10px;
}

.footer-content {
  font-family: Telefonica;
  font-size: 10px;
  color: #50535a;
}

.legend-content {
  font-size: 10px;
  color: #50535a;
  font-family: Telefonica;
  text-align: start;
  margin-bottom: 0px;
}

.legend-warning {
  font-size: 10px;
  color: #50535a;
  font-family: Telefonica;
  text-align: start;
  width: 2100px;
  margin-top: 10px;
  margin-left: 17%;
  margin-bottom: 4px;
}

.content-footer {
  display: flex;
  justify-content: space-around;
  position: relative;
  top: 10%;
  height: 1.3rem;
}

@media (max-height: 580px) {
  .container-footer {
    height: 94px;
  }
  .content-footer {
    display: flex;
    justify-content: space-around;
    position: relative;
    top: 7%;
    height: 12px;
  }
  .next-btn {
    background: #ec624b;
    width: 240px;
    height: 25px;
    font-size: 14px;
  }
}
</style>
