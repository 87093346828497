import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import '@/plugins/bootstrap-vue'
import '@/plugins/v-mask'
import '@/plugins/vuelidate'
import '@/plugins/vue-cookies'
import '@/plugins/vue-crypto'
import 'es6-promise/auto'
import '@/assets/styles/app.scss'
import i18n from '@/plugins/i18n'
import '@/plugins/the-mask'

import OtpInput from '@bachdgvn/vue-otp-input'
import Api from '@/plugins/api'

import interceptorsSetup from '@/helpers/interceptors'

interceptorsSetup()
Vue.config.productionTip = false
Vue.component('v-otp-input', OtpInput)

Api.init()

new Vue({
  store,
  router,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
