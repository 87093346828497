<template>
  <div class="sm-epayco">
    <div ref="content" id="comprovante">
      <b-card no-body class="sm-card-epayco">
        <b-card-body v-if="epayco.data">
          <b-row class="text-center" align-v="center">
            <b-col class="px-2">
              <p class="epayco-header mb-4 ml-2 mt-2">Resultado de la transacción</p>
            </b-col>
          </b-row>
          <br />
          <b-row>
            <b-col cols="5">
              <p class="epayco-text">Estado transacción</p>
            </b-col>
            <b-col>
              <p class="epayco-field" :class="epaycoComputedClass">
                {{ estado }}
                <img
                  v-if="estado === 'Transacción exitosa'"
                  src="../../assets/images/correcto.png"
                  alt="Aditional info"
                  class="ml-2"
                />
              </p>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="5"><p class="epayco-text">Descripción</p></b-col>
            <b-col>
              <p class="epayco-field">{{ epayco.data.x_description }}</p>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="5"><p class="epayco-text">Método de pago</p></b-col>
            <b-col>
              <p class="epayco-field">{{ epayco.data.x_type_payment }}</p>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="5"><p class="epayco-text">Fecha y hora</p></b-col>
            <b-col>
              <p class="epayco-field">{{ this.formatDate(epayco.data.x_transaction_date) }}</p>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="5"><p class="epayco-text">Valor</p></b-col>
            <b-col>
              <p class="epayco-field">$ {{ moeda }}</p>
            </b-col>
          </b-row>
          <hr />
          <b-row>
            <b-col cols="5">
              <p class="epayco-text">Empresa</p>
            </b-col>
            <b-col>
              <p class="epayco-field">Movistar Consumer Finance Colombia SAS</p>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="5"><p class="epayco-text">NIT</p></b-col>
            <b-col>
              <p class="epayco-field">9014457955</p>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="5"><p class="epayco-text">Número de recibo</p></b-col>
            <b-col>
              <p class="epayco-field">{{ epayco.data.x_transaction_id }}</p>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="5"><p class="epayco-text">Referencia</p></b-col>
            <b-col>
              <p class="epayco-field">{{ epayco.data.x_id_invoice }}</p>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="5"><p class="epayco-text">IP Origen</p></b-col>
            <b-col>
              <p class="epayco-field">{{ epayco.data.x_customer_ip }}</p>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </div>

    <b-row>
      <b-col>
        <div class="mt-3">
          <button @click="outroPago()" class="blue-button continue-button-text">
            <span>Realizar otro pago</span>
          </button>
        </div>
      </b-col>
      <b-col>
        <div class="mt-3">
          <button @click="finalizar()" class="blue-button continue-button-text inverted">
            <span>Finalizar</span>
          </button>
        </div>
      </b-col>
      <!-- <img src="@/assets/icons/seta-baixo.svg" alt="Download icon" /> -->
    </b-row>
    <ModalAcessoRapidoError id="modal-acesso-rapido" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import store from '../../store'
import {
  EPAYCO_POST_RESPONSE_ACESSO_RAPIDO,
  EPAYCO_MOBILE_POST_RESPONSE_ACESSO_RAPIDO,
  TOGGLE_OVERLAY,
} from '../../store/actions.type'
import ModalAcessoRapidoError from '../../components/modal/ModalGuiaAcessoRapido.vue'
import router from '../../router/index'
import moment from 'moment'

export default {
  name: 'FastPaymentEpaycoCard',
  components: { ModalAcessoRapidoError },
  data() {
    return {
      email: '',
      emailDisable: true,
      epaycoResponse: {
        idCredito: this.$route.params.idCredito,
        refEpayco: this.$route.query.ref_payco,
        mobileData: this.$route.query,
      },
    }
  },
  created() {
    if (this.$route.query.ref_payco) {
      store.dispatch(TOGGLE_OVERLAY, true)
      store.dispatch(EPAYCO_POST_RESPONSE_ACESSO_RAPIDO, this.epaycoResponse)
    } else {
      store.dispatch(TOGGLE_OVERLAY, true)
      store.dispatch(EPAYCO_MOBILE_POST_RESPONSE_ACESSO_RAPIDO, this.epaycoResponse)
    }
  },
  updated() {
    this.$nextTick(function () {
      store.dispatch(TOGGLE_OVERLAY, false)
    })
  },
  methods: {
    finalizar() {
      router.push({ name: 'Login' })
    },
    outroPago() {
      const idCredito = this.epaycoResponse.idCredito
      router.push({ name: 'AcessoRapidoForm' })
    },
    formatDate(date) {
      if (date) {
        return moment(String(date)).format('DD/MM/YYYY hh:mm')
      }
    },
    modalAcessoRapido(error) {
      this.$bvModal.show('modal-acesso-rapido')
    },
  },
  mounted() {
    this.modalAcessoRapido()
  },
  watch: {
    email: {
      handler(value) {
        var reg = new RegExp(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/)
        if (reg.test(value)) {
          this.emailDisable = false
        } else {
          this.emailDisable = true
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      epayco: 'epaycoResponse',
      cedulaNumber: 'cedulaNumber',
    }),
    moeda() {
      // Create our number formatter.
      var formatter = new Intl.NumberFormat('de-DE', {
        minimumFractionDigits: 2,
        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      })
      return formatter.format(this.epayco.data.x_amount)
    },
    epaycoComputedClass() {
      let className = ''
      if (this.epayco.data) {
        if (this.epayco.data.x_cod_transaction_state === 1) {
          className = 'epayco-blue'
        } else if (this.epayco.data.x_cod_transaction_state === 3) {
          className = 'epayco-orange'
        } else {
          className = 'epayco-red'
        }
      }
      return className
    },
    estado() {
      let result = ''
      if (this.epayco.data) {
        if (this.epayco.data.x_cod_transaction_state === 1) {
          result = 'Transacción exitosa'
        } else if (this.epayco.data.x_cod_transaction_state === 2) {
          result = 'Transacción rechazada'
        } else if (this.epayco.data.x_cod_transaction_state === 3) {
          result = 'Transacción pendiente'
        } else {
          result = 'Transacción cancelada'
        }
      }
      return result
    },
  },
}
</script>

<style scoped>
.btn-epayco {
  border: none;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important ;
  background: #ec624b;
}
.form-epayco {
  border-radius: 20px;
  border-top-left-radius: 0px;
  background: #f5f5f5;
  border: none;
}
.input-group {
  border-bottom: none;
}
.sm-epayco {
  background-color: #fff;
  margin: auto auto;
  max-width: 30rem;
}
.sm-card-epayco {
  border-radius: 10px;
  background-color: #fff;
  border: solid 1px #d3d4d3;
  margin: auto auto;
  max-width: 30rem;
}
.epayco-header {
  font-family: Telefonica;
  font-size: 18px;
  font-weight: bold;
  color: #0b2739;
}
.epayco-field {
  font-family: Telefonica;
  font-size: 14px;
  color: #707070;
  text-align: left;
}
.epayco-text {
  font-family: Telefonica;
  font-size: 12px;
  color: #8e8e8e;
  text-align: left;
}
hr {
  border: 0.1px solid #ec624b;
}
.epayco-blue {
  color: #019df4;
}
.epayco-red {
  color: #e63780;
}
.epayco-orange {
  color: #f28d15;
}
.inverted {
  background: #ffffff;
  color: var(--movistar-blue);
  border: 1px solid var(--movistar-blue);
}
.btn-pine-green {
  color: #fff;
}
</style>
