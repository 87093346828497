var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.MVModal,{attrs:{"id":"modal-enviar-email-outro-endereco"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h2',{staticClass:"titulo"},[_vm._v("Ingresa el correo al que quieres enviar el link de pago")])]},proxy:true},{key:"footer",fn:function(){return [_c(_setup.MVButton,{attrs:{"label":"Continuar","variant":"movistar-green","size":"sm","disabled":!_setup.form.aceitoTermosCondicoes || _setup.v$.$error},on:{"click":function($event){return _setup.enviarEmailPagamento(_vm.$bvModal)}}})]},proxy:true}])},[_c('form',{staticClass:"card-form",on:{"submit":function($event){$event.preventDefault();return _vm.validarForm.apply(null, arguments)}}},[_c('div',{staticClass:"email-inputs"},[_c(_setup.InputGroup,{attrs:{"invalid":_setup.v$.email.$error,"messages":{
          'El correo es requerido': _setup.v$.email.$error && !_setup.v$.email.required,
          'El correo no es válido': _setup.v$.email.$error && !_setup.v$.email.email,
        }},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('b-img',{attrs:{"src":require("@/assets/icons/mail-blue.svg")}})]},proxy:true}]),model:{value:(_setup.form.email),callback:function ($$v) {_vm.$set(_setup.form, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.email"}}),_c(_setup.InputGroup,{attrs:{"invalid":_setup.v$.emailConfirmacao.$error,"messages":{
          'El correo es requerido': _setup.v$.emailConfirmacao.$error && _setup.v$.emailConfirmacao.required.$invalid,
          'Los correos no coinciden': _setup.v$.emailConfirmacao.$error && _setup.v$.emailConfirmacao.email.$invalid,
        }},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('b-img',{attrs:{"src":require("@/assets/icons/mail-blue.svg")}})]},proxy:true}]),model:{value:(_setup.form.emailConfirmacao),callback:function ($$v) {_vm.$set(_setup.form, "emailConfirmacao", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.emailConfirmacao"}}),(_setup.v$.emailConfirmacao)?_c('small'):_vm._e()],1),_c('div',{staticClass:"termos-condicoes"},[_c(_setup.InputCheckbox,{model:{value:(_setup.form.aceitoTermosCondicoes),callback:function ($$v) {_vm.$set(_setup.form, "aceitoTermosCondicoes", $$v)},expression:"form.aceitoTermosCondicoes"}}),_c('p',[_vm._v(" Acepto los Términos y Condiciones y la Política de Datos Personales, declaro que los he leído en su totalidad y que estoy de acuerdo con su contenido. ")])],1),_c('div',{staticClass:"alerta"},[_c('p',[_vm._v(" Recuerda que el correo es un dato muy importante para estar en contacto con el cliente, asegúrate de que sea correcto ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }