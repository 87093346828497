<template>
  <MVModal id="modal-confirmar-recebimento-email-fianca">
    <template v-if="!title" #header>
      <h2 class="titulo">¿Recibiste el link en tu correo?</h2>
    </template>
    <p v-if="title" class="titulo-customizado">{{ title }}</p>
    <p class="content">Recuerda que son máximo 3 intentos permitidos para el envío del link</p>
    <template #footer>
      <b-row cols="1" cols-sm="2">
        <b-col>
          <MVButton label="Si" variant="movistar-green" @click="$router.push({ name: redirectPage })" />
        </b-col>
        <b-col>
          <MVButton
            label="No"
            variant="movistar-green"
            outline
            v-b-modal.modal-enviar-email
            @click="$bvModal.hide('modal-confirmar-recebimento-email-fianca')"
          />
        </b-col>
      </b-row>
    </template>
  </MVModal>
</template>
<script setup>
import { gtmDataLayerTrackEvent } from '@/helpers/gtmHelper'

import MVModal from '@/components/movistar/MVModal.vue'
import MVButton from '@/components/movistar/MVButton.vue'

const props = defineProps({
  sendGtm: {
    type: Boolean,
    default: true,
  },
  redirectPage: {
    type: String,
    default: 'Opiniao',
  },
  title: {
    type: String,
  },
})

function openModal(modalContext, modalId) {
  modalContext.hide('modal-confirmar-recebimento-email-fianca')
  modalContext.show(modalId)
  if (props.sendGtm)
    gtmDataLayerTrackEvent(
      window.dataLayer,
      'trackEvent',
      'movistar money',
      'click::thank you page::paso 6',
      'cerrar',
      this.CryptoJS.SHA1(this.currentApplication.mobile).toString()
    )
}
</script>
<style lang="scss" scoped>
.modal-footer {
  .row {
    width: 100%;
  }
  .btn {
    width: 100%;
    margin: 0.25rem 0;
  }
}

.titulo {
  font-size: 1.75rem;
  margin-top: 1rem;
  width: 100%;
  padding: 0 2rem;
}

p.content {
  font-size: 18px;
  padding: 0 1.5rem;
}

p.titulo-customizado {
  font-size: 20px;
  padding: 0 1.5rem;
}
</style>
