<template>
  <div class="container-simulation">
    <div class="content-simulation">
      <h1 class="text-content-simulation">
        ¡Simula tu préstamo! Mueve la barra para elegir el monto <br />
        y a continuación marca la opción de cuotas que prefieras
      </h1>
    </div>

    <b-tooltip :show.sync="show" ref="tooltip" target="tooltip-button-show-event" variant="movistar">
      <h1 class="tooltip-size">Mueve la barra para elegir otro monto</h1>
    </b-tooltip>

    <div class="content-input-simulation">
      <h1 class="value-range-simulation">{{ valueInputOfferSimulationFmt }}</h1>
      <input
        id="tooltip-button-show-event"
        ref="input-range"
        type="range"
        v-bind:min="minValueOfferSimulation"
        v-bind:max="maxValueOfferSimulation"
        v-bind:step="rangeOfferSimulation"
        :value="valueInputOfferSimulation"
        @input="handleChangeInput"
        class="input-range"
      />
    </div>
    <section class="content-range-simulation">
      <p class="value-range">{{ minValueOfferSimulationFmt }}</p>
      <p class="value-range max-value-range">{{ maxValueOfferSimulationFmt }}</p>
    </section>

    <ContainerCuotasOffer v-bind:arrayOffers="lastArrayInOffer" v-if="arrayOffers.length <= 0" />
    <ContainerCuotasOffer v-bind:arrayOffers="arrayOffers" v-if="arrayOffers.length > 0" />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { MUTATION_CHANGE_VALUE_INPUT } from '../../store/mutations.type'
import { BUSCAR_LISTA_OFERTAS, VALIDAR_CAMPANHA } from '../../store/actions.type'
import ContainerCuotasOffer from './ContainerCuotasOfferSimulation.vue'
import Cookies from '../../plugins/vue-cookies'

export default {
  components: { ContainerCuotasOffer },
  data() {
    return {
      arrayOffers: [],
      show: true,
    }
  },
  computed: {
    ...mapGetters({
      valueInputOfferSimulation: 'valueInputOfferSimulation',
      valueInputOfferSimulationFmt: 'valueInputOfferSimulationFmt',
      minValueOfferSimulation: 'minValueOfferSimulation',
      maxValueOfferSimulation: 'maxValueOfferSimulation',
      minValueOfferSimulationFmt: 'minValueOfferSimulationFmt',
      maxValueOfferSimulationFmt: 'maxValueOfferSimulationFmt',
      rangeOfferSimulation: 'rangeOfferSimulation',
      getOffers: 'getOffers',
      lastArrayInOffer: 'lastArrayInOffer',
    }),
  },
  mounted() {
    localStorage.clear()
    Cookies.remove('oferta_simulador')
    this.$store.dispatch(VALIDAR_CAMPANHA, this.$route.query)
  },
  watch: {
    maxValueOfferSimulation(newValue) {
      if (newValue) return this.changeInputProgress(this.$refs['input-range'])
    },
  },
  methods: {
    ...mapMutations([MUTATION_CHANGE_VALUE_INPUT]),
    handleChangeInput(event) {
      this.show = false
      this[MUTATION_CHANGE_VALUE_INPUT](Number(event.target.value))
      this.getOffers.options.filter((option) => {
        if (option.value == this.valueInputOfferSimulation) {
          this.arrayOffers = option.offers.map((offer) => ({ ...offer, isActive: false }))
        }
      })
      this.changeInputProgress(event.target)
    },
    changeInputProgress(element) {
      if (element) {
        element.style.setProperty('--value', element.value == 0 ? this.maxValueOfferSimulation : element.value)
        element.style.setProperty('--min', element.min === '' ? this.minValueOfferSimulation : element.min)
        element.style.setProperty('--max', element.max === '' ? this.maxValueOfferSimulation : element.max)
      }
    },
  },
}
</script>

<style scoped>
.tooltip-size {
  font-size: 11px;
}
.container-simulation {
  margin-top: 1.5rem;
  width: 100%;
}
.content-input-simulation {
  margin-top: 1.5rem;
  width: 100%;
}

.input-range {
  width: 84%;
}

.text-range-simulation {
  color: #b4b4b4;
  font-size: 12px;
}

.filter-value {
  display: none;
}

.content-simulation {
  width: 96%;
}

.text-content-simulation {
  color: #50535a;
  font-size: 12px;
}

.value-range-simulation {
  color: #0b2739;
  font-size: 1rem;
  text-align: start;
  margin-left: 8%;
}

.max-value-range {
  position: relative;
  left: 90px;
}

.value-range {
  color: #50535a;
  font-size: 14px;
}

.content-range-simulation {
  display: flex;
  width: 87%;
  justify-content: space-between;
  padding-left: 8%;
}

::v-deep.b-tooltip-movistar .tooltip-inner {
  background-color: #019df4;
}
::v-deep.bs-tooltip-top .arrow::before {
  border-top-color: #019df4;
}

@media (min-width: 600px) and (max-width: 800px) {
  .max-value-range {
    left: 30px;
  }
}

@media (min-width: 341px) and (max-width: 450px) {
  .container-simulation,
  .content-input-simulation {
    margin-top: 1rem;
  }

  .max-value-range {
    left: 15px;
  }

  .value-range-simulation {
    font-size: 18px;
  }

  .value-range {
    color: #50535a;
    font-size: 11px;
  }
}

@media (max-width: 340px) {
  .container-simulation,
  .content-input-simulation {
    margin-top: 0.3rem;
  }

  .max-value-range {
    left: 15px;
  }

  .value-range-simulation {
    font-size: 18px;
  }

  .value-range {
    color: #50535a;
    font-size: 11px;
  }
}
</style>
