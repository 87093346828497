<template>
  <div class="container-fluid bg-login">
    <b-container class="py-5">
      <b-row class="text-center py-3" align-h="center">
        <b-col cols="12" md="6" class="no-padding">
          <div class="card-wrapper-login">
            <MovistarMoneyLogoLogin />

            <div v-if="linkStatus" class="card-inner">
              <LoginSubtitle text="Crea una nueva contraseña" />
              <SMPasswordForm :error-message="selfManageUpdatePasswordError" @send-form="sendForm" />
            </div>
            <div class="card-inner" v-else>
              <p>Este enlace ha expirado.</p>
              <ButtonContinueLogin class="pb-4" text="Volver" @click="voltar()" />
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import MovistarMoneyLogoLogin from '../../components/SelfManage/MovistarMoneyLogoLogin'
import LoginSubtitle from '../../components/SelfManage/LoginSubtitle'
import store from '../../store'
import {
  SELF_MANAGE_NEW_FORGOTTEN_PASSWORD,
  SELF_MANAGE_VALIDATE_LINK_FORGOTTEN_PASSWORD,
} from '../../store/actions.type'
import SMPasswordForm from '../../components/SelfManage/SMPasswordForm'
import { mapGetters } from 'vuex'
import ButtonContinueLogin from '../../components/buttons/ButtonContinueLogin'
import router from '../../router/index'

export default {
  name: 'ResetPassword',
  components: { SMPasswordForm, LoginSubtitle, MovistarMoneyLogoLogin, ButtonContinueLogin },
  data() {
    return {
      linkStatus: true,
      routeParams: {
        idClient: null,
        idProposal: null,
        idHash: null,
      },
    }
  },
  mounted() {
    this.saveRouteParams()
    store.dispatch(SELF_MANAGE_VALIDATE_LINK_FORGOTTEN_PASSWORD, { routeParams: this.routeParams }).then((response) => {
      console.log(response)
      this.linkStatus = response.validLink
    })
  },
  methods: {
    sendForm(params) {
      store.dispatch(SELF_MANAGE_NEW_FORGOTTEN_PASSWORD, { form: params, routeParams: this.routeParams })
    },
    saveRouteParams() {
      this.routeParams.idClient = this.$route.params.idClient
      this.routeParams.idProposal = this.$route.params.idProposal
      this.routeParams.idHash = this.$route.params.idHash
    },
    voltar() {
      router.push({ name: 'Login' })
    },
  },
  computed: {
    ...mapGetters({
      selfManageUpdatePasswordError: 'selfManageUpdatePasswordError',
    }),
  },
}
</script>

<style scoped>
.card-inner {
  margin: 20px 35px;
}
</style>
