<template>
  <div class="container-bank">
    <HeaderDadosBancarios />
    <InputMoneyBankData />
  </div>
</template>
<script>
import HeaderDadosBancarios from '../components/DadosBancarios/HeaderDadosBancarios.vue'
import InputMoneyBankData from '../components/DadosBancarios/InputMoneyBankData.vue'

export default {
  components: { HeaderDadosBancarios, InputMoneyBankData },
}
</script>
<style scoped>
.container-bank {
  padding: 10px 16px;
}
</style>
