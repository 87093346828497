<template>
  <input ref="inputRef" class="currency-input" type="text" />
</template>

<script>
import { watch, computed } from 'vue'
import { useCurrencyInput } from 'vue-currency-input'

export default {
  props: {
    value: Number,
    precision: {
      type: Number,
      default: 2,
    },
  },
  setup(props) {
    const options = computed(() => ({
      currency: 'COL',
      currencyDisplay: 'hidden',
      precision: props.precision,
      autoDecimalDigits: true,
      hideGroupingSeparatorOnFocus: false,
    }))
    const { inputRef, setValue } = useCurrencyInput(options.value)

    watch(
      () => props.value,
      () => {
        setValue(props.value)
      }
    )
    return { inputRef }
  },
}
</script>

<style lang="scss" scoped>
.currency-input {
  border: none;
  padding: 6px 12px;
}
</style>
