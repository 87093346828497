const experianCollector = {
  collectJsc() {
    // eslint-disable-next-line no-undef
    experian.validate('user_prefs')
    return document.getElementsByName('jsc')[0].value
  },
  collectHdim() {
    return document.getElementsByName('hdim')[0].value
  },
  initiate() {
    // eslint-disable-next-line no-undef
    experian.initiate(null)
  },
}

export default experianCollector
