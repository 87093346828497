<template>
  <div>
    <b-row v-if="step === 1" class="text-center" align-h="center">
      <div class="step"></div>
      <div class="spacer" />
      <div class="step step-active"></div>
      <div class="spacer" />
      <div class="step"></div>
    </b-row>

    <b-row v-else-if="step === 2" class="text-center" align-h="center">
      <div class="step"></div>
      <div class="spacer" />
      <div class="step"></div>
      <div class="spacer" />
      <div class="step step-active"></div>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'StepperLogin',
  props: {
    step: Number,
  },
}
</script>

<style lang="scss" scoped>
.step {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #d3d4d3;
}

.step-active {
  width: 20px;
  height: 10px;
  border-radius: 40px;
  background: var(--movistar-pine-green);
}

.spacer {
  width: 10px;
}
</style>
