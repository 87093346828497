<template>
  <div class="container-banner">
    <b-row class="banner-header">
      <b-col>
        <p class="notification-title text-left">Banner informativo o de nuevas ofertas</p>
      </b-col>
    </b-row>
    <div class="content-banner">
      <b-card class="card-banner" img-alt="Card Image">
        <b-row align-v="center">
          <b-col cols="8">
            <p class="text-left self-manage-banner-text">
              Te traemos una oferta exclusiva para que financies tu equipo con Movistar Money
            </p>
            <button class="button-banner mr-3">
              <span class="font-12">Lo quiero</span>
            </button>
          </b-col>
          <b-col cols="4" align-self="center">
            <b-img src="~@/assets/images/banner-phones.png" alt="Stacked phones" class="img-banner-phone" />
          </b-col>
        </b-row>
      </b-card>
      <b-card class="card-banner card-right" img-alt="Card Image">
        <b-row align-v="center">
          <b-col cols="8">
            <p class="text-left self-manage-banner-text">
              Te traemos una oferta exclusiva para que financies tu equipo con Movistar Money
            </p>
            <button class="button-banner mr-3">
              <span class="font-12">Lo quiero</span>
            </button>
          </b-col>
          <b-col cols="4" align-self="center">
            <b-img src="~@/assets/images/banner-phones.png" alt="Stacked phones" class="img-banner-phone" />
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Banner',
}
</script>

<style scoped>
.container-banner {
  margin-bottom: 2rem;
  display: none;
}

.button-banner {
  width: 150px;
  height: 36px;
}

.img-banner-phone {
  width: 100%;
  height: 100%;
}

.self-manage-banner-text {
  font-size: 1.62rem;
}

.notification-title {
  font-size: 28px;
  font-weight: 300;
  padding: 54px 20px 20px 71px;
}
.content-banner {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 20px;
  flex-wrap: wrap;
}

.card-banner {
  width: 37.5rem;
  height: 13.56rem;
}

@media (max-width: 1280px) {
  .card-banner {
    width: 32rem;
    height: 10rem;
  }

  .img-banner-phone {
    width: 80%;
    height: 80%;
  }

  .self-manage-banner-text {
    font-size: 1.4rem;
  }

  .button-banner {
    width: 130px;
    height: 30px;
  }
}

@media (max-width: 1196px) {
  .card-right {
    margin-top: 10px;
  }
  .card-banner {
    width: 33, 75rem;
    height: 12, 20rem;
  }
  .notification-title {
    font-size: 24px;
  }
}
</style>
