<template>
  <div>
    <div v-if="!editarCota" class="box-informacoes-assistido">
      <b-img src="@/assets/icons/header-assistido.svg" fluid alt="Movistar money logo" class="header-svg-assistido" />

      <section class="title-in-headear-asssistido">
        <p class="title-header-assistido">Precio de equipo con IVA</p>
        <b-img src="@/assets/icons/phone-white.svg" fluid alt="Movistar money logo" class="phone-svg-assistido" />
      </section>

      <section class="header-box-information">
        <div class="alldevices">
          <ul class="no-bullets">
            <li v-for="device in phoneDetailsAssisted.deviceModel" id="devices" class="devices" :key="device">
              {{ device }}
            </li>
          </ul>
          <img @click="showDevices()" id="arrow" src="@/assets/icons/arrow-down.svg" class="arrow-down" />
        </div>
        <p class="valor-monetario">{{ phoneDetailsAssisted.price }}</p>
      </section>

      <div class="container-footer">
        <button class="footer-informacoes-assistido" @click="abrirMaisInformacoes">Simular cuota</button>
      </div>
    </div>

    <div class="container-assisted-phone-details" v-if="editarCota">
      <section class="header-mais-informacoes">
        <div class="alldevices">
          <ul class="no-bullets titulo-mais-informacoes">
            <li v-for="device in phoneDetailsAssisted.deviceModel" id="devices" class="devices" :key="device">
              {{ device }}
            </li>
          </ul>
          <img @click="showDevices()" id="arrow" src="@/assets/icons/arrow-down-white.svg" class="arrow-down" />
        </div>
        <p class="valor-mais-informacoes">{{ phoneDetailsAssisted.price }}</p>
      </section>

      <b-row align-h="center" align-v="center" class="mb-1 mt-1">
        <b-img src="@/assets/icons/money.svg" alt="Money icon" />
        <b-card-text class="choose-quotas">{{ title }}</b-card-text>
      </b-row>

      <b-card-text class="offer-subtitle">{{ subTitle }}</b-card-text>

      <div class="simulador-calculadora">
        <section v-for="button of objectButton" :key="button.value">
          <button
            class="btn-parcel"
            @click="changeValue(button)"
            :class="{ btnVerificado: button.verified, disabledButton: button.disabled }"
            :disabled="button.disabled"
          >
            {{ button.value }}
          </button>
        </section>
      </div>

      <div class="more-information">
        <section class="container-more-information container-total-cueta padding-header">
          <h1 class="content-total-cueta">Total cuota / mes</h1>
          <span class="value-total-cueta"
            >{{ footerOffer.totalFmt }} <span class="legend-con-iva" v-if="footerOffer.taxRate > 0">Con IVA</span></span
          >
        </section>

        <section class="container-more-information">
          <h1 class="legend-value">Cuota capital</h1>
          <span class="content-value legend-value">{{ footerOffer.principalDueFmt }}</span>
        </section>

        <section class="container-more-information">
          <h1 class="legend-value">Intereses</h1>
          <span class="content-value legend-value">{{ footerOffer.interestDueFmt }} </span>
        </section>

        <section class="container-more-information">
          <h1 class="legend-value">IVA intereses</h1>
          <span class="content-value legend-value">{{ footerOffer.taxInterestDueFmt }} </span>
        </section>

        <section class="container-more-information">
          <h1 class="legend-value">Cargo administrativo</h1>
          <span class="content-value legend-value">{{ footerOffer.feesDueFmt }}</span>
        </section>

        <section class="container-more-information">
          <h1 class="legend-value">IVA cargo administrativo</h1>
          <span class="content-value legend-value">{{ footerOffer.taxFeesDueFmt }}</span>
        </section>

        <section class="container-more-information">
          <h1 class="legend-value">Total cuota</h1>
          <span class="content-value legend-value">{{ footerOffer.totalFmt }}</span>
        </section>
      </div>

      <div class="segunda-mais-informacao">
        <section class="container-more-information">
          <h1 class="legend-value">Valor a financiar</h1>
          <span class="content-value legend-value">{{ phoneDetailtUrl.price }}</span>
        </section>

        <section class="container-more-information">
          <h1 class="legend-value">Tasa efectiva anual (TEA)</h1>
          <span class="content-value legend-value">{{ footerOffer.interestCETYearlyFmt }}</span>
        </section>
      </div>

      <section>
        <p class="footer-legenda">*Este es un valor aproximado de la composición de la cuota</p>
      </section>
    </div>
  </div>
</template>

<script>
import store from '../../../store'
import {
  MUTATION_SELECTED_ASSISTED,
  MUTATION_SELECTED_BUTTONS_ASSISTED,
  MUTATION_MAIS_INFORMACOES,
} from '../../../store/mutations.type'
import { mapGetters } from 'vuex'

export default {
  name: 'DetalhesTelefoneAssistido',
  props: {
    title: String,
    subTitle: String,
    offerAssisted: {},
    phoneDetailsAssisted: {},
  },
  data() {
    return {
      editarCota: false,
      allDevices: false,
      footerOffer: {},
      objectButton: [
        {
          value: 3,
          verified: false,
          disabled: true,
        },
        {
          value: 6,
          verified: false,
          disabled: true,
        },
        {
          value: 12,
          verified: false,
          disabled: true,
        },
        {
          value: 18,
          verified: false,
          disabled: true,
        },
        {
          value: 24,
          verified: false,
          disabled: true,
        },
        {
          value: 36,
          verified: false,
          disabled: true,
        },
      ],
    }
  },
  watch: {
    offerAssisted(value) {
      if (value) {
        this.checkButtons()
      }
    },
  },
  methods: {
    abrirMaisInformacoes() {
      this.editarCota = true
      this.checkButtons()
    },
    showDevices() {
      document.getElementById('arrow').className = this.allDevices ? 'arrow-down' : 'arrow-up'
      document
        .querySelectorAll('[id=devices]')
        .forEach((element) => (element.className = this.allDevices ? 'devices' : 'devices-open'))
      this.allDevices = !this.allDevices
    },
    checkButtons() {
      this.objectButton.map((objButton, index) => {
        this.offerAssisted?.map((singleOffer) => {
          if (singleOffer.installment == objButton.value) {
            this.changeValue(this.objectButton[index])
            this.objectButton[index].disabled = false
          }
        })
      })
    },
    changeValue(btnObject) {
      this.objectButton.map((button, index) => {
        if (button.value == btnObject.value) {
          this.objectButton[index].verified = true
          this.offerAssisted?.map((offer) => {
            if (offer.installment == button.value) {
              this.footerOffer = offer
              store.commit(MUTATION_SELECTED_BUTTONS_ASSISTED, this.objectButton)
              store.commit(MUTATION_SELECTED_ASSISTED, this.footerOffer)
            }
          })
        }
        if (button.value !== btnObject.value) {
          this.objectButton[index].verified = false
        }
      })
    },
  },
  computed: {
    ...mapGetters({
      phoneDetailtUrl: 'phoneDetailtUrl',
      maisInformacoes: 'maisInformacoes',
    }),
  },
}
</script>

<style scoped>
.footer-informacoes-assistido {
  width: 308px;
  height: 40px;
  border-radius: 40px;
  background-color: #5cb630;
  border: none;
  color: #ffffff;
  font-weight: bold;
}

.header-box-information {
  display: flex;
  justify-content: space-between;
  word-wrap: break-word;
  padding-bottom: 10px;
  border-bottom: 1px solid #d3d4d3;
  margin-bottom: 1rem;
}
.devices {
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.devices-open {
  width: 140px;
  overflow: auto;
  word-wrap: break-word;
  height: fit-content;
}

ul.no-bullets {
  list-style-type: none;
  text-align: left;
  font-size: 14px;
  margin: 0;
  padding: 0;
  width: 170px;
}
.alldevices {
  display: flex;
  width: 190px;
  border-right: 2px solid #d3d4d3;
  padding-right: 1rem;
}

.phone-svg-assistido {
  color: #ffffff;
  margin-top: 3px;
}

.title-header-assistido {
  color: #ffffff;
  margin-bottom: 0px;
  font-size: 19px;
}

.title-in-headear-asssistido {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
  top: -45px;
}

.header-svg-assistido {
  position: absolute;
  top: -10px;
  left: 22px;
}

.box-informacoes-assistido {
  position: relative;
  width: 340px;
  height: fit-content;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  padding: 37.4px 12.7px 23.6px 12.5px;
  background: #ffffff;
}

.value-total-cueta {
  font-weight: bold;
  font-size: 1rem;
}
.titulo-cifrao-assistido {
  font-size: 16px;
  color: #0b2739;
  font-weight: bold;
  margin-left: 1rem;
  margin-bottom: 0px;
}

.valor-mais-informacoes {
  font-weight: bold;
  color: #fff;
  font-size: 16px;
  margin-bottom: 0px;
}

.header-mais-informacoes {
  background-color: #019df4;
  border-radius: 10px 10px 0 0;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: fit-content;
  position: relative;
  top: -20px;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
}

.valor-monetario {
  font-weight: bold;
}

.legend-value {
  font-size: 14px;
  color: #86888c;
}

.more-information {
  display: flex;
  flex-direction: column;
}

.btn-parcel {
  width: 50px;
  height: 35px;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  border: none;
  text-align: center;
  color: #86888c;
}

.container-more-information {
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
  height: 17px;
}
.container-total-cueta {
  margin-bottom: 7px;
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.simulador-calculadora {
  height: 70px;
  flex-direction: row;
  border-bottom: 1px solid #d3d4d3;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.choose-quotas {
  margin: 0 0 1.6px 9.6px;
  font-weight: bold;
}

.offer-subtitle {
  font-size: 0.86rem;
  color: #019df4;
}

.container-assisted-phone-details {
  box-shadow: 0 3px 6px #00000029;
  border-radius: 10px;
  width: 340px;
  background-color: #fff;
}

.btnVerificado {
  background: #019df4;
  color: #fff;
  font-weight: bold;
}
.disabledButton {
  background-color: #b6b9bf;
  color: #ffffff;
}

.content-total-cueta {
  font-size: 1rem;
  color: #86888c;
  font-weight: bold;
  margin-top: 8px;
}

.segunda-mais-informacao {
  margin-top: 1.5rem;
}

.container-more-information {
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
  height: 17px;
}

.footer-legenda {
  color: #86888c;
  font-size: 12px;
  margin-top: 14px;
}

.legend-con-iva {
  font-size: 14px;
  font-weight: lighter;
}

.titulo-mais-informacoes {
  font-size: 17px;
  color: #fff;
  margin-bottom: 0px;
}
.arrow-blue-assistido {
  position: relative;
  top: 1px;
  cursor: pointer;
}
.padding-header {
  padding: 1rem;
}
img.arrow-down {
  margin-top: 0px;
  width: 17px;
}

img.arrow-up {
  width: 17px;
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  margin-top: 0px;
}
</style>
