var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"header-simulation"},[_c('button',{staticClass:"btn-simulation-box-first",class:{ modificarEstiloBtn: _vm.selecionarPrimeiroBotao },attrs:{"disabled":_vm.selecionarPrimeiroBotao},on:{"click":_vm.modificarEstiloBtn}},[_vm._v(" Simular cuota ")]),_c('button',{staticClass:"btn-simulation-box-second",class:{ modificarEstiloBtn: _vm.selecionarSegundoBotao },attrs:{"disabled":_vm.selecionarSegundoBotao},on:{"click":_vm.modificarEstiloBtn}},[_vm._v(" ¡Guía al cliente! ")])]),_c('div',{staticClass:"container-assistido-info"},[(_vm.selecionarPrimeiroBotao)?_c('div',[_vm._m(0),_vm._m(1),_c('BoxIntroducaoTelefoneAssistido')],1):_vm._e(),(_vm.selecionarSegundoBotao)?_c('div',[_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('section',[_c('h1',{staticClass:"title-assistido-info"},[_vm._v(" Aquí puedes ayudar al cliente a simular las cuotas de su crédito "),_c('span',{staticClass:"text-bold"},[_vm._v("da click en \"Simular cuota\"")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',[_c('ul',{staticClass:"list-assistido-info"},[_c('li',{staticClass:"content-assistido-info"},[_vm._v(" Esta simulación no generará ninguna consulta a fuentes de información o Buró, es solo una referencia aproximada. ")]),_c('li',{staticClass:"content-assistido-info"},[_vm._v(" Comunícale al cliente que el detalle que se muestra en el simulador es un aproximado, este puede variar acorde al perfil de riesgo del cliente mostrando el valor real más adelante. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',[_c('h1',{staticClass:"title-assistido-info"},[_vm._v("¡Para iniciar el crédito guía al cliente!")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',[_c('div',{staticClass:"list-number"},[_c('span',{staticClass:"number-in-list"},[_vm._v("1.")]),_c('p',{staticClass:"list-with-number"},[_c('span',{staticClass:"text-bold"},[_vm._v("Recuérdale al cliente")]),_vm._v(" que iniciará una solicitud de crédito con Movistar Money y que para continuar debe aceptar los términos y condiciones y la política de tratamiento de datos que serán enviados a su correo al continuar este paso. Indícale que en resumen estará aceptando: ")])]),_c('ul',{staticClass:"list-assistido-info-guia"},[_c('li',{staticClass:"content-assistido-info-guia"},[_vm._v("El uso de sus datos para la gestión de este crédito")]),_c('li',{staticClass:"content-assistido-info-guia"},[_vm._v(" Sus datos personales serán tratados de acuerdo con la ley 1581 de 2012 ")]),_c('li',{staticClass:"content-assistido-info-guia"},[_vm._v(" El crédito es financiado por Movistar Money y el equipo es vendido por Movistar Colombia, entidad que recibirá el desembolso del crédito aprobado. ")]),_c('li',{staticClass:"content-assistido-info-guia"},[_vm._v(" El monto del crédito esta sujeto a la evaluación de riesgo y nunca superará el valor del equipo. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',[_c('div',{staticClass:"list-number"},[_c('span',{staticClass:"number-in-list"},[_vm._v("2.")]),_c('p',[_c('span',{staticClass:"text-bold"},[_vm._v("Pregúntale al cliente")]),_vm._v(" claramente que responda “si” o “no”.")])]),_c('section',[_c('ul',{staticClass:"list-assistido-info"},[_c('li',{staticClass:"content-assistido-info-guia"},[_vm._v(" ¿Acepta los Términos y condiciones y el tratamiento de sus datos? ")]),_c('li',{staticClass:"content-assistido-info-guia"},[_vm._v("¿Acepta recibir información comercial?")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',[_c('div',{staticClass:"list-number"},[_c('span',{staticClass:"number-in-list"},[_vm._v("3.")]),_c('p',[_c('span',{staticClass:"text-bold"},[_vm._v("Si el cliente respondió si,")]),_vm._v(" selecciona check de acuerdo con su respuesta y a continuación da clic en la parte derecha de la pantalla \"Iniciar crédito\" ")])])])
}]

export { render, staticRenderFns }