<template>
  <div class="container-introduction-assistido">
    <section class="header-introduction-assistido" v-if="!visualizarFormulario">
      <h1 class="title-introduction-assistido">Aquí puedes iniciar el crédito directamente</h1>
    </section>
    <section class="content-introduction-assistido" v-if="!visualizarFormulario">
      <ul class="list-introduction-assistido">
        <li class="content-list">
          Recuérdale al cliente que si su línea es pospago, recibirá la cuota de su crédito en su factura Movistar y
          podrá pagarla en los canales autorizados por Movistar.
        </li>
        <li class="content-list">
          Si es prepago recibirá una factura aparte de Movistar Money a su correo electrónico y podrá pagar en línea o
          en puntos baloto y Efecty
        </li>
        <li class="content-list">
          Guía a tu cliente paso a paso con el botón <span class="text-bold">"Iniciar"</span>
        </li>
      </ul>
    </section>

    <ButtonContinue
      text="Iniciar"
      class="btn-introduction-assistido"
      @click="abrirFormulario"
      v-if="!visualizarFormulario"
    />

    <div v-if="visualizarFormulario" :class="{ alingContainer: visualizarFormulario }">
      <FormularioAssistido />
    </div>
  </div>
</template>

<script>
import ButtonContinue from '../../buttons/ButtonContinue.vue'
import FormularioAssistido from './FormularioAssistido.vue'
import { mapGetters } from 'vuex'
import { BUSCAR_PARAMETROS_CANAL } from '@/store/actions.type'

export default {
  components: { ButtonContinue, FormularioAssistido },
  data() {
    return {
      visualizarFormulario: false,
    }
  },
  mounted() {
    this.$store.dispatch(BUSCAR_PARAMETROS_CANAL, {
      nomeParametro: 'maintenance',
      canal: 'Assistido',
    })
  },
  methods: {
    abrirFormulario() {
      this.visualizarFormulario = true
    },
  },
  computed: {
    ...mapGetters({
      footerInformationAssisted: 'footerInformationAssisted',
    }),
  },
}
</script>

<style>
.container-introduction-assistido {
  position: relative;
  top: -40px;
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 8rem;
}

.content-introduction-assistido {
  margin-top: 80px;
}

.header-introduction-assistido {
  display: flex;
  justify-content: center;
}

.title-introduction-assistido {
  width: 462px;
  height: 33px;
  text-align: center;
  margin: 0;
  font-size: 24px;
}

.btn-introduction-assistido {
  width: 382px;
  height: 49px;
  margin-top: 60px;
}

.content-list {
  width: 466px;
  font-size: 1.2rem;
  margin-top: 1rem;
  text-align: start;
}

.content-list::marker {
  color: #86888c;
  font-size: 1.2rem;
}

.alingContainer {
  position: relative;
  top: -30px;
}

.text-bold {
  font-weight: bold;
}
</style>
