<template>
  <div class="container-fluid mb-5">
    <b-container>
      <StripesTopImage />

      <MovistarMoneyLogo />

      <Stepper :step="step" />

      <b-row class="text-center" align-h="center">
        <b-col cols="12" md="6" class="no-padding">
          <p class="title-onboard-otp">¿Dónde quieres recibir tu equipo?</p>

          <div class="mt-4">
            <CardAddress
              :address-form-callejero="addressFormCallejero"
              @enableButton="enableButtonMethod"
              @selectAddress="updateAddress"
            />
          </div>

          <div class="mt-5">
            <CardPhoneDetails
              :phone-details="phoneDetails"
              :step="step"
              :show-phone-review="true"
              :current-application="currentApplication"
            />
          </div>
          <div class="mt-5">
            <ButtonContinue text="Continuar" :disable="!enableButton || completeDirection" @click="goNextPage" />
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import StripesTopImage from '@/components/StripesTopImage.vue'
import MovistarMoneyLogo from '@/components/MovistarMoneyLogo.vue'
import Stepper from '@/components/Stepper.vue'
import ButtonContinue from '@/components/buttons/ButtonContinue.vue'
import CardPhoneDetails from '@/components/CardPhoneDetails.vue'
import CardAddress from '@/components/CardAddress.vue'
import { mapGetters } from 'vuex'
import store from '@/store'
import { FETCH_APPLICATION_REDIRECT, GET_ADDRESS_CLIENT, SAVE_CHOOSEN_ADDRESS } from '@/store/actions.type'
import { gtmDataLayerCheckout, gtmDataLayerTrackEvent } from '@/helpers/gtmHelper'

export default {
  name: 'Direction',
  components: { CardAddress, CardPhoneDetails, ButtonContinue, Stepper, MovistarMoneyLogo, StripesTopImage },
  data() {
    return {
      step: 4,
      nextPage: 'Contract',
      enableButton: false,
      sendBody: {
        action: 'confirmDeliveryAddress',
        confirmDeliveryAddress: 'WORK',
      },
    }
  },
  mounted() {
    store.dispatch(FETCH_APPLICATION_REDIRECT, 'Finter')
    store.dispatch(GET_ADDRESS_CLIENT)
    gtmDataLayerCheckout(
      window.dataLayer,
      'checkOutView',
      this.phoneDetails.deviceModel,
      this.phoneDetails.sku,
      this.phoneDetails.price,
      this.phoneDetails.deviceModel,
      'movistar money',
      this.phoneDetails.deviceColor,
      this.step
    )
  },
  methods: {
    goNextPage() {
      if (!this.enableButton) return
      gtmDataLayerTrackEvent(
        window.dataLayer,
        'trackEvent',
        'movistar money',
        'click::dirección de entrega::paso 4',
        'confirmar dirección de entrega',
        this.CryptoJS.SHA1(this.currentApplication.mobile).toString()
      )
      store.dispatch(SAVE_CHOOSEN_ADDRESS, { sendBody: this.sendBody, nextPage: this.nextPage })
    },
    enableButtonMethod(bool) {
      this.enableButton = bool
    },
    updateAddress(address) {
      if (address) {
        this.sendBody.confirmDeliveryAddress = address
      } else {
        this.sendBody.confirmDeliveryAddress = 'WORK'
      }
    },
  },
  computed: {
    ...mapGetters({
      phoneDetails: 'phoneDetails',
      addressFormCallejero: 'addressFormCallejero',
      currentApplication: 'currentApplication',
      completeDirection: 'completeDirection',
    }),
  },
}
</script>
