<template>
  <b-modal
    :id="id"
    cancel-title=""
    cancel-variant="hidden"
    size="xl"
    hide-header
    hide-footer
    ok-title=""
    ok-variant="hidden"
    centered
    header-border-variant="white"
    footer-border-variant="white"
  >
    <div class="mx-3">
      <b-row align-h="end">
        <b-button variant="blank" @click="closeModal(id)">
          <b-img src="@/assets/icons/cancel.svg" alt="Cancel icon" />
        </b-button>
      </b-row>

      <b-img
        class="mb-4 mt-3 modal-image"
        center
        align-h="center"
        src="@/assets/images/solicitud-en-vuelo.png"
        alt="Modal image"
      />

      <p class="text-center subtitle-onboard text-grey-2 mb-4">{{ title }}</p>

      <b-row class="mb-4">
        <b-col class="no-padding">
          <div class="mr-3">
            <b-overlay :show="loading" rounded opacity="0.5" spinner-small spinner-variant="primary">
              <ButtonContinueBlue text="Si" @click="retrieveCart(availableCart)" />
            </b-overlay>
          </div>
        </b-col>
        <b-col class="ml-3 no-padding text-right">
          <div>
            <ButtonContinueBlue text="No" @click="closeModal(id)" />
          </div>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import ButtonContinueBlue from '../buttons/ButtonContinueBlue'
import { MUTATE_APPLICATION_ID } from '../../store/mutations.type'
import store from '../../store'
import { FETCH_APPLICATION_REDIRECT } from '../../store/actions.type'

export default {
  name: 'ModalRetrieveCart',
  components: { ButtonContinueBlue },
  props: {
    id: String,
    title: String,
    subtitle: String,
    availableCart: Object,
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    closeModal(id) {
      this.$bvModal.hide(id)
    },
    retrieveCart(data) {
      this.loading = true
      this.$store.commit(MUTATE_APPLICATION_ID, data.applicationId)
      store.dispatch(FETCH_APPLICATION_REDIRECT)
    },
  },
}
</script>

<style scoped>
.modal-image {
  width: 200px;
}
</style>
