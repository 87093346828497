<template>
  <div class="header">
    <div class="container-infomation">
      <section class="content-tooltip">
        <h1 class="title-header-offer">
          ¿Necesitas un préstamo <br />
          de libre inversión?
        </h1>
        <b-img src="@/assets/icons/cuidado-svg-cinza.svg" alt="Tooltip" class="ml-2 img-money" id="tooltip-target-1" />
        <b-tooltip target="tooltip-target-1" triggers="hover" variant="secondary">
          <p class="tooltip-offer-text">El cálculo de la cuota es un aproximado del monto real a prestar</p>
        </b-tooltip>
      </section>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
.title-header-offer {
  color: #0b2739;
  font-size: 18px;
}
.content-tooltip {
  display: flex;
}

.img-money {
  width: 14px;
  height: 14px;
  position: relative;
  top: 5px;
}

.tooltip-offer-text {
  font-size: 12px;
  color: #0b2739;
  margin: 5px 2px 5px 0px;
}
.img-money-offer,
.img-money-offer {
  margin-bottom: 1rem;
}
</style>
