/* eslint-disable */
export default () => ({
  execute(documento, tipo, Id, deploymentId, buttonId) {
    var initESWAuto = function () {
      embedded_svc.init(
        'https://credisferaorg2.my.salesforce.com',
        'https://movistarmoney.secure.force.com/liveAgentSetupFlow',
        'https://service.force.com',
        Id,
        'N1_Chat_Auto',
        {
          baseLiveAgentContentURL: 'https://c.la4-c1-ia4.salesforceliveagent.com/content',
          deploymentId: deploymentId,
          buttonId: buttonId,
          baseLiveAgentURL: 'https://d.la4-c1-ia4.salesforceliveagent.com/chat',
          eswLiveAgentDevName: 'N1_Chat_Auto',
          isOfflineSupportEnabled: false
        }
      );
    };
    var initESWOnBoarding = function () {
      embedded_svc.init(
        'https://credisferaorg2.my.salesforce.com',
        'https://movistarmoney.secure.force.com/liveAgentSetupFlow',
        'https://service.force.com',
        Id,
        'N1_Chat',
        {
          baseLiveAgentContentURL: 'https://c.la4-c1-ia4.salesforceliveagent.com/content',
          deploymentId: deploymentId,
          buttonId: buttonId,
          baseLiveAgentURL: 'https://d.la4-c1-ia4.salesforceliveagent.com/chat',
          eswLiveAgentDevName: 'N1_Chat',
          isOfflineSupportEnabled: false
        }
      );
    };

    embedded_svc.settings.displayHelpButton = true;
    embedded_svc.settings.language = '';
    embedded_svc.settings.enabledFeatures = ['LiveAgent'];
    embedded_svc.settings.entryFeature = 'LiveAgent';

    embedded_svc.settings.extraPrechatFormDetails = [
      {
        "value": documento,
        "transcriptFields": ["NumeroDocumento__c"],
        "displayToAgent": false
      }
    ];

    if (tipo === 'chatOnBoarding')
      initESWOnBoarding();
    else if (tipo === 'chatAuto')
      initESWAuto();
    else {
      var chatButton = document.querySelector(".embeddedServiceHelpButton");
      if (chatButton)
        document.querySelector(".embeddedServiceHelpButton").style.display = 'none';
    }

  }
})