<template>
  <b-card no-body class="sm-card-credits" v-if="this.planoPagamento.globalData">
    <b-card-body>
      <b-card-text class="sm-credits-card-item" v-if="this.propostaMambu.productName">
        <div v-if="this.propostaMambu.faturaMovistar" align="center">
          <CardMensagem
            :texto="texto"
            :texto2="texto2"
            :linkTexto="linkTexto"
            :abrirApos="2"
            :fecharApos="120"
            :link="link"
          />
        </div>
        <p class="sm-credits-card-title no-margin text-left">
          {{ propostaMambu.origin === 'Finter' ? 'Saldo de Crédito' : 'Saldo total del Crédito' }}
        </p>
        <div class="sm-credits-card-row">
          <p class="sm-credits-card-title">
            {{ nomeProduto }}
          </p>
        </div>
        <div class="sm-credits-card-row">
          <p class="sm-credits-card-item">Numero de producto</p>
          <p class="sm-credits-card-item-value">
            {{ this.propostaMambu.contractNumber }}
          </p>
        </div>
        <div class="sm-credits-card-row">
          <p class="sm-credits-card-item no-margin">Fecha límite de pago</p>
          <p :class="computedClass">
            {{ this.carregando ? '00/00/0000' : this.planoPagamento.globalData.lastDueDate }}
          </p>
        </div>
      </b-card-text>
    </b-card-body>

    <b-card-footer class="sm-credits-card-bottom">
      <div class="sm-credits-card-row">
        <div>
          <p class="sm-credits-card-item-final-value no-margin">
            {{ this.carregando ? '$ 0,00' : this.planoPagamento.globalData.lastDueValueFmt }}
          </p>
          <p class="sm-credits-card-item-message no-margin">Valor a pagar</p>
        </div>
      </div>
    </b-card-footer>
  </b-card>
</template>

<script>
import CardMensagem from '../../components/CardMensagem.vue'

import { mapGetters } from 'vuex'

export default {
  name: 'FastPaymentHeaderCard',
  components: { CardMensagem },
  props: {
    planoPagamento: {},
    propostaMambu: {},
    selecionado: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      linkTexto: 'pagarla en línea aqui',
      link: 'https://movistar.recaudo.epayco.co/?_ga=2.10144117.1301339125.1632781748-264939336.1631815530&tipo_pago=movistar&tipo_servicio=movil',
      texto: 'Ten en cuenta que ya fue generada tu factura actual en Movistar, puedes ',
      texto2: 'o en los puntos físicos autorizados por Movistar.',
    }
  },
  computed: {
    ...mapGetters({
      overlayStatus: 'overlayStatus',
    }),
    computedClass() {
      let className = 'sm-credits-card-item-value no-margin'
      if (!this.carregando && this.planoPagamento.globalData.lastDueDate === 'Inmediato') {
        className = 'sm-credits-card-item-value-red no-margin'
      }
      return className
    },
    nomeProduto() {
      if (this.propostaMambu.origin === 'Finter') return this.propostaMambu.access.deviceModel
      if (this.propostaMambu.origin === 'Assistido') return this.propostaMambu.access.skuName
      if (this.propostaMambu.origin === 'Libre') return this.propostaMambu.productName
      else return null
    },
    carregando() {
      return this.overlayStatus || !this.selecionado
    },
  },
}
</script>

<style scoped>
.sm-credits-card-bottom {
  background-color: var(--movistar-grey-7);
  border: none;
}

.sm-credits-card-title {
  font: 700 12px Telefonica, sans-serif;
  color: var(--movistar-grey-2);
}

.sm-credits-card-item {
  font: 12px Telefonica, sans-serif;
  color: var(--movistar-grey);
}

.sm-credits-card-item-value {
  font: 12px Telefonica, sans-serif;
  color: var(--movistar-grey-2);
}

.sm-credits-card-item-value-red {
  font: 12px Telefonica, sans-serif;
  color: red;
}

.sm-credits-card-row {
  display: flex;
  justify-content: space-between;
}

.sm-credits-card-row-button {
  display: flex;
  align-content: flex-end;
}

.sm-credits-card-item-final-value {
  font: 16px Telefonica, sans-serif;
  color: var(--movistar-pine-green);
  text-align: left;
}

.sm-credits-card-item-message {
  font: 15px Telefonica, sans-serif;
  color: var(--movistar-pine-green);
  text-align: left;
}

.sm-mi-contrato {
  width: 126px;
  height: 35px;
  padding: 2px 10px 7px 12px;
  border-radius: 32px;
  background-color: var(--movistar-blue);
  border: 0;
  margin: 0 0 0 6px;
  text-align: center;
}
</style>
