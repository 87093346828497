<template>
  <div class="container-sm-profile">
    <p class="sm-title mb-4">Mis créditos</p>
    <CreditsHeaderCard :accountInstallmentMambu="accountInstallmentMambu" :accountInstallments="accountInstallments" />
  </div>
</template>

<script>
import CreditsHeaderCard from './CreditsHeaderCard'
import { mapGetters } from 'vuex'

export default {
  name: 'CreditsHeader',
  components: { CreditsHeaderCard },
  computed: {
    ...mapGetters({
      accountInstallmentMambu: 'selfManageApplicationMambu',
      accountInstallments: 'selfManageApplicationPaymentPlan',
    }),
  },
}
</script>
