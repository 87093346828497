<template>
  <div>
    <template v-if="error">
      <vue-bootstrap-typeahead
        class="vbt-border-error"
        ref="typeahead"
        v-model="neighborhood"
        :data="newNeighborhood"
        placeholder="Barrio"
        @hit="onSelect"
        @input="onKeywordChanged"
      />
    </template>
    <template v-else>
      <vue-bootstrap-typeahead
        class="vbt-border"
        ref="typeahead"
        v-model="neighborhood"
        :data="newNeighborhood"
        placeholder="Barrio"
        @hit="onSelect"
        @input="onKeywordChanged"
      />
    </template>
  </div>
</template>

<script>
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'

export default {
  name: 'Autocomplete',
  components: {
    VueBootstrapTypeahead,
  },
  props: {
    neighborhoodList: Array,
    error: Boolean,
    input: String,
  },
  data() {
    return {
      neighborhood: '',
    }
  },
  methods: {
    onSelect() {
      const filtered = this.neighborhoodList.filter(this.filterArray)
      this.$emit('neighborhoodSelect', filtered[0])
    },
    onKeywordChanged(event) {
      this.$emit('neighborhoodTyped', event)
    },
    filterArray(obj) {
      return obj.text === this.neighborhood
    },
  },
  computed: {
    newNeighborhood: function () {
      return this.neighborhoodList.map((neighborhood) => {
        return neighborhood.text
      })
    },
  },
  updated: function () {
    this.$nextTick(function () {
      if (this.input === '') {
        this.$refs.typeahead.$data.inputValue = this.input
      }
    })
  },
}
</script>
