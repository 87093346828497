import Api from '@/plugins/api'
import router from '../router'

import {
  GET_PROPOSAL_ADVANCED_PAYMENT,
  EPAYCO_SALVAR_CHECKOUT_REQUISICAO_PAGAMENTO_ANTECIPADO,
  TOGGLE_OVERLAY,
  DEFINE_ANTICIPO,
  FETCH_PRE_OFFER,
  FETCH_APPLICATION_REDIRECT,
  FETCH_PRE_OFFER_ASSISTED,
  ENVIAR_COMPROVANTE_EPAYCO_EMAIL_ANTECIPO,
  ACTION_WORKFLOW_STATUS_ASSISTED,
  GET_PROPOSAL_ADVANCED_PAYMENT_FIANCA,
  ENVIAR_COMPROVANTE_EPAYCO_EMAIL_FIANCA,
} from '@/store/actions.type'

import {
  EPAYCO_ERROR_RESPONSE,
  EPAYCO_POST_RESPONSE_SUCCESS,
  SET_ADVANCED_PAYMENT,
  SET_ADVANCED_PAYMENT_FMT,
  SET_CHANGED_ADVANCED_PAYMENT,
  SET_FINANCED_AMOUNT,
  SET_FINANCED_AMOUNT_FMT,
  SET_GUARANTEE_PAYMENT,
  SET_GUARANTEE_PAYMENT_FMT,
  SET_CALCULATED_GUARANTEE_PAYMENT_IVA,
  SET_CALCULATED_GUARANTEE_PAYMENT_IVA_FMT,
  SET_GUARANTEE_PAYMENT_TOTAL,
  SET_GUARANTEE_PAYMENT_TOTAL_FMT,
  SET_MAXIMUM_ADVANCED_PAYMENT,
  SET_MAXIMUM_ADVANCED_PAYMENT_FMT,
  SET_MINIMUM_ORDER_VALUE,
  SET_MINIMUM_ORDER_VALUE_FMT,
  SET_REAL_FINANCED_AMOUNT,
  SET_REAL_FINANCED_AMOUNT_FMT,
  SET_ADVANCED_PAYMENT_FIANCA,
} from '@/store/mutations.type'

import store from '@/store'

const state = {
  advancedPayment: '',
  advancedPaymentFmt: '',
  maximumAdvancedPayment: '',
  maximumAdvancedPaymentFmt: '',
  financedAmount: '',
  financedAmountFmt: '',
  realFinancedAmount: '',
  realFinancedAmountFmt: '',
  guaranteePayment: '',
  guaranteePaymentFmt: '',
  calculatedGuaranteePaymentIva: '',
  calculatedGuaranteePaymentIvaFmt: '',
  guaranteePaymentTotal: '',
  guaranteePaymentTotalFmt: '',
  minimumOrderValue: '',
  minimumOrderValueFmt: '',
  changedAdvancedPayment: '',
  advancedPaymentFianca: '',
}

const mutations = {
  [SET_ADVANCED_PAYMENT_FIANCA](state, advancedPaymentFianca) {
    state.advancedPaymentFianca = advancedPaymentFianca
  },
  [SET_ADVANCED_PAYMENT](state, advancedPayment) {
    state.advancedPayment = advancedPayment
  },
  [SET_ADVANCED_PAYMENT_FMT](state, advancedPaymentFmt) {
    state.advancedPaymentFmt = advancedPaymentFmt
  },
  [SET_MAXIMUM_ADVANCED_PAYMENT](state, maximumAdvancedPayment) {
    state.maximumAdvancedPayment = maximumAdvancedPayment
  },
  [SET_MAXIMUM_ADVANCED_PAYMENT_FMT](state, maximumAdvancedPaymentFmt) {
    state.maximumAdvancedPaymentFmt = maximumAdvancedPaymentFmt
  },
  [SET_FINANCED_AMOUNT](state, financedAmount) {
    state.financedAmount = financedAmount
  },
  [SET_FINANCED_AMOUNT_FMT](state, financedAmountFmt) {
    state.financedAmountFmt = financedAmountFmt
  },
  [SET_REAL_FINANCED_AMOUNT](state, realFinancedAmount) {
    state.realFinancedAmount = realFinancedAmount
  },
  [SET_REAL_FINANCED_AMOUNT_FMT](state, realFinancedAmountFmt) {
    state.realFinancedAmountFmt = realFinancedAmountFmt
  },
  [SET_GUARANTEE_PAYMENT](state, guaranteePayment) {
    state.guaranteePayment = guaranteePayment
  },
  [SET_GUARANTEE_PAYMENT_FMT](state, guaranteePaymentFmt) {
    state.guaranteePaymentFmt = guaranteePaymentFmt
  },
  [SET_CALCULATED_GUARANTEE_PAYMENT_IVA](state, calculatedGuaranteePaymentIva) {
    state.calculatedGuaranteePaymentIva = calculatedGuaranteePaymentIva
  },
  [SET_CALCULATED_GUARANTEE_PAYMENT_IVA_FMT](state, calculatedGuaranteePaymentIvaFmt) {
    state.calculatedGuaranteePaymentIvaFmt = calculatedGuaranteePaymentIvaFmt
  },
  [SET_GUARANTEE_PAYMENT_TOTAL](state, guaranteePaymentTotal) {
    state.guaranteePaymentTotal = guaranteePaymentTotal
  },
  [SET_GUARANTEE_PAYMENT_TOTAL_FMT](state, guaranteePaymentTotalFmt) {
    state.guaranteePaymentTotalFmt = guaranteePaymentTotalFmt
  },
  [SET_MINIMUM_ORDER_VALUE](state, minimumOrderValue) {
    state.minimumOrderValue = minimumOrderValue
  },
  [SET_MINIMUM_ORDER_VALUE_FMT](state, minimumOrderValueFmt) {
    state.minimumOrderValueFmt = minimumOrderValueFmt
  },
  [SET_CHANGED_ADVANCED_PAYMENT](state, changedAdvancedPayment) {
    state.changedAdvancedPayment = changedAdvancedPayment
  },
}

const actions = {
  async [FETCH_PRE_OFFER]({ getters, commit, dispatch }, body) {
    dispatch(TOGGLE_OVERLAY, true)
    let situation
    await Api.executeAction(getters.applicationId, { action: 'preOffer' }).then((response) => {
      if (response.data.situation === 'reproved') {
        router.push({
          name: 'MensagemReprovacao',
          params: {
            data: {
              response: response.data.response,
            },
            updateMode: true,
            editionMode: true,
          },
        })
      } else if (response.data.situation === 'preOffer') {
        commit(SET_ADVANCED_PAYMENT, response.data.response.advancedPayment)
        commit(SET_ADVANCED_PAYMENT_FMT, response.data.response.advancedPaymentFmt)
        commit(SET_MAXIMUM_ADVANCED_PAYMENT, response.data.response.maximumAdvancedPayment)
        commit(SET_MAXIMUM_ADVANCED_PAYMENT_FMT, response.data.response.maximumAdvancedPaymentFmt)
        commit(SET_MINIMUM_ORDER_VALUE, response.data.response.minimumOrderValue)
        commit(SET_MINIMUM_ORDER_VALUE_FMT, response.data.response.minimumOrderValueFmt)
        commit(SET_GUARANTEE_PAYMENT, response.data.response.guaranteePayment)
        commit(SET_GUARANTEE_PAYMENT_FMT, response.data.response.guaranteePaymentFmt)
        commit(SET_CALCULATED_GUARANTEE_PAYMENT_IVA, response.data.response.calculatedGuaranteePaymentIva)
        commit(SET_CALCULATED_GUARANTEE_PAYMENT_IVA, response.data.response.calculatedGuaranteePaymentIvaFmt)
      }
      situation = response.data.situation
      dispatch(TOGGLE_OVERLAY, false)
    })
    return situation
  },
  async [FETCH_PRE_OFFER_ASSISTED]({ getters, commit, dispatch }) {
    dispatch(TOGGLE_OVERLAY, true)
    let situation
    await Api.postWorkflowActionAssisted(getters.applicationId, { action: 'preOffer' }).then((response) => {
      if (response.data.situation === 'reproved') {
        router.push({
          name: 'MensagemReprovacao',
          params: { data: { response: response.data.response }, updateMode: true, editionMode: true },
        })
      } else if (response.data.situation === 'preOffer') {
        commit(SET_ADVANCED_PAYMENT, response.data.response.advancedPayment)
        commit(SET_ADVANCED_PAYMENT_FMT, response.data.response.advancedPaymentFmt)
        commit(SET_MAXIMUM_ADVANCED_PAYMENT, response.data.response.maximumAdvancedPayment)
        commit(SET_MAXIMUM_ADVANCED_PAYMENT_FMT, response.data.response.maximumAdvancedPaymentFmt)
        commit(SET_MINIMUM_ORDER_VALUE, response.data.response.minimumOrderValue)
        commit(SET_MINIMUM_ORDER_VALUE_FMT, response.data.response.minimumOrderValueFmt)
        commit(SET_GUARANTEE_PAYMENT, response.data.response.guaranteePayment)
        commit(SET_GUARANTEE_PAYMENT_FMT, response.data.response.guaranteePaymentFmt)
        commit(SET_CALCULATED_GUARANTEE_PAYMENT_IVA, response.data.response.calculatedGuaranteePaymentIva)
        commit(SET_CALCULATED_GUARANTEE_PAYMENT_IVA, response.data.response.calculatedGuaranteePaymentIvaFmt)
      }
      situation = response.data.situation
      dispatch(TOGGLE_OVERLAY, false)
    })
    return situation
  },
  [DEFINE_ANTICIPO]({ getters, dispatch }, body) {
    Api.executeAction(getters.applicationId, body)
      .then((resp) => {
        if (resp.status === 200 || resp.data.situation !== 'preOffer') dispatch(FETCH_APPLICATION_REDIRECT)
      })
      .catch((e) => console.log(e))
  },
  [GET_PROPOSAL_ADVANCED_PAYMENT](context, params) {
    store.dispatch(TOGGLE_OVERLAY, true)
    Api.getPropostaPagamentoAntecipado(params.idProposta, params.hash)
      .then((response) => {
        context.commit(SET_ADVANCED_PAYMENT, response.data)
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        store.dispatch(TOGGLE_OVERLAY, false)
      })
  },
  [EPAYCO_SALVAR_CHECKOUT_REQUISICAO_PAGAMENTO_ANTECIPADO](context, epaycoResponse) {
    return Api.postEpaycoCheckoutRequisicaoPagamentoAntecipado(epaycoResponse)
      .then((response) => {
        context.commit(EPAYCO_POST_RESPONSE_SUCCESS, response.data)
        return response
      })
      .catch((e) => {
        const error = JSON.parse(e.message)
        context.commit(EPAYCO_ERROR_RESPONSE, error)
      })
  },
  [ENVIAR_COMPROVANTE_EPAYCO_EMAIL_ANTECIPO](context, body) {
    return Api.enviarComprovanteEpaycoAntecipado(body)
  },

  //---FIANCA---
  [GET_PROPOSAL_ADVANCED_PAYMENT_FIANCA](context, params) {
    store.dispatch(TOGGLE_OVERLAY, true)
    Api.getPropostaPagamentoFianca(params.idProposta, params.hash)
      .then((response) => {
        context.commit(SET_ADVANCED_PAYMENT_FIANCA, response.data)
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        store.dispatch(TOGGLE_OVERLAY, false)
      })
  },
  [ENVIAR_COMPROVANTE_EPAYCO_EMAIL_FIANCA](context, body) {
    return Api.enviarComprovanteEpaycoFianca(body)
  },
}

const getters = {
  advancedPayment: (state) => state.advancedPayment,
  advancedPaymentFmt: (state) => state.advancedPaymentFmt,
  maximumAdvancedPayment: (state) => state.maximumAdvancedPayment,
  maximumAdvancedPaymentFmt: (state) => state.maximumAdvancedPaymentFmt,
  financedAmount: (state) => state.financedAmount,
  financedAmountFmt: (state) => state.financedAmountFmt,
  realFinancedAmount: (state) => state.realFinancedAmount,
  realFinancedAmountFmt: (state) => state.realFinancedAmountFmt,
  guaranteePayment: (state) => state.guaranteePayment,
  guaranteePaymentFmt: (state) => state.guaranteePaymentFmt,
  calculatedGuaranteePaymentIva: (state) => state.calculatedGuaranteePaymentIva,
  calculatedGuaranteePaymentIvaFmt: (state) => state.calculatedGuaranteePaymentIvaFmt,
  guaranteePaymentTotal: (state) => state.guaranteePaymentTotal,
  guaranteePaymentTotalFmt: (state) => state.guaranteePaymentTotalFmt,
  minimumOrderValue: (state) => state.minimumOrderValue,
  minimumOrderValueFmt: (state) => state.minimumOrderValueFmt,
  changedAdvancedPayment: (state) => state.changedAdvancedPayment,
  advancedPaymentFianca: (state) => state.advancedPaymentFianca,
  tipoPagamento: (state) => state.tipoPagamento,
}

export default {
  state,
  mutations,
  actions,
  getters,
}
