import { render, staticRenderFns } from "./AssistidoLayout.vue?vue&type=template&id=783a3aa2&scoped=true&"
var script = {}
import style0 from "./AssistidoLayout.vue?vue&type=style&index=0&id=783a3aa2&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "783a3aa2",
  null
  
)

export default component.exports