<template>
  <b-card no-body border-variant="white">
    <div class="card-wrapper">
      <p class="ft-14 mt-3">Selecciona que día quieres pagar tu cuota</p>
      <b-row align-h="center" class="mr-1 ml-1">
        <b-col v-for="date of dueDates" :key="date" class="radio-custom radio-local radio-input">
          <b-row>
            <input @change="emityValue()" v-model="ciclo" :value="date" :id="date" :name="date" type="radio" />
            <label :for="date" class="radio-label ft-14"> {{ date }} </label>
          </b-row>
        </b-col>
      </b-row>
      <p class="ft-12 m-3">Tu fecha de vencimiento para el pago de tu cuota será el día seleccionado</p>
    </div>
  </b-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { gtmDataLayerTrackEvent } from '../helpers/gtmHelper'
export default {
  name: 'CardCycle',
  mounted() {
    if (this.currentApplication.origin === 'Libre') this.dueDates = [5, 16, 20]
    else this.dueDates = [5, 16, 23, 26]
    this.defaultChecked()
  },
  data() {
    return {
      dueDates: null,
      ciclo: null,
    }
  },
  methods: {
    emityValue() {
      this.$emit('valueCycle', this.ciclo)
      this.$emit('enableButton', true)
      gtmDataLayerTrackEvent(
        window.dataLayer,
        'trackEvent',
        'movistar money',
        'click::click::completar datos::paso 5',
        this.ciclo,
        this.CryptoJS.SHA1(this.currentApplication.mobile).toString()
      )
    },
    defaultChecked() {
      const paymentCycle = this.currentApplication.paymentCycle
      if (this.dueDates.includes(paymentCycle)) {
        this.ciclo = paymentCycle
        this.$emit('valueCycle', paymentCycle)
        this.$emit('enableButton', true)
        return
      }
      const dayOfMonth = new Date().getDate()
      const dateChecked = this.dueDates.find((date, index) => {
        return index > 0
          ? dayOfMonth >= this.dueDates[index - 1] && dayOfMonth < date
          : dayOfMonth < date || dayOfMonth >= this.dueDates[this.dueDates.length - 1]
      })

      this.ciclo = dateChecked
      this.$emit('valueCycle', dateChecked)
      this.$emit('enableButton', true)
    },
  },
  computed: {
    ...mapGetters({
      currentApplication: 'currentApplication',
    }),
  },
}
</script>

<style scoped>
.ft-12 {
  font-family: Telefonica;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #50535a;
}
.ft-14 {
  font-family: Telefonica;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #313235;
}

.radio-input {
  margin-left: 1.5rem;
}
</style>
