<template>
  <div class="container-payment-types pb-5">
    <div class="card-payments">
      <b-row align-v="center">
        <b-col cols="8" class="pr-2">
          <p class="text-left payment-text no-margin">
            Paga online con <b-img src="~@/assets/images/epayco.png" class="ml-2" alt="Payment" />
          </p>
        </b-col>
        <b-col cols="4" class="payment-custom-col no-padding pl-2">
          <b-img class="img-size payment-custom-col no-padding pl-2" src="~@/assets/images/visa.png" alt="Payment" />
          <b-img class="img-size payment-custom-col no-padding pl-2" src="~@/assets/images/pse.png" alt="Payment" />
        </b-col>
      </b-row>

      <hr />

      <b-row v-if="!antecipo" align-v="center">
        <b-col cols="7" class="pr-2">
          <p class="payment-text no-margin">Paga en puntos físicos</p>
        </b-col>
        <b-col cols="5" class="payment-custom-col no-padding pl-2">
          <b-img class="img-size" src="~@/assets/images/efecty.png" alt="Payment" />
          <b-img class="img-size" src="~@/assets/images/pagaTodo.png" alt="Payment" />
          <b-img class="img-size" src="~@/assets/images/gana.png" alt="Payment" />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PaymentTypesFianca',
  props: {
    antecipo: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>
.container-payment-types {
  background-color: white;
  padding: 0 16px;
}

.card-payments {
  max-width: 30rem;
  margin: auto;
}

.img-size {
  height: 38px;
}

.payment-custom-col {
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
}

.payment-text {
  font: 14px Telefonica, sans-serif;
  color: var(--movistar-grey-2);
  text-align: left;
}
</style>
