<template>
  <div class="container-fluid mb-5">
    <div class="text-center mt-4 mb-4">
      <b-img src="@/assets/images/money.png" fluid alt="Movistar money logo" class="movistar-image" />
    </div>
    <PagamentoFiancaCard />
    <br />
    <PaymentTypesFianca />
  </div>
</template>
<script>
import PaymentTypesFianca from '@/components/SelfManage/PaymentTypesFianca.vue'
import PagamentoFiancaCard from '@/components/PagamentoFianca/PagamentoFiancaCard.vue'
export default {
  components: { PaymentTypesFianca, PagamentoFiancaCard },
}
</script>
