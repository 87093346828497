import api from '../plugins/api'

import {
  FAST_PAYMENT_LOGIN,
  TOGGLE_OVERLAY,
  FAST_PAYMENT_GET_APPLICATION_MAMBU,
  FAST_PAYMENT_GET_APPLICATION_PAYMENT_PLAN,
} from './actions.type'

import {
  SET_FAST_PAYMENT_FORM,
  FAST_PAYMENT_ERROR,
  FAST_PAYMENT_GET_APPLICATION_PAYMENT_PLAN_RESP,
  FAST_PAYMENT_GET_APPLICATION_MAMBU_RESP,
  MUTATION_FAST_PAYMENT_APPLICATION_MAMBU_SELECIONADO,
} from './mutations.type'

import router from '../router'
import store from './index'

const state = {
  cedulaNumber: '',
  errorFastPayment: '',
  fastPaymentApplicationsMambu: {},
  fastPaymentApplicationMambuSelecionado: {},
  fastPaymentApplicationPaymentPlan: {},
}

const mutations = {
  [SET_FAST_PAYMENT_FORM](state, cedulaNumber) {
    state.cedulaNumber = cedulaNumber
  },
  [FAST_PAYMENT_ERROR](state, error) {
    state.errorFastPayment = error
  },
  [FAST_PAYMENT_GET_APPLICATION_MAMBU_RESP](state, response) {
    state.fastPaymentApplicationsMambu = response
  },
  [MUTATION_FAST_PAYMENT_APPLICATION_MAMBU_SELECIONADO](state, response) {
    state.fastPaymentApplicationMambuSelecionado = response
  },
  [FAST_PAYMENT_GET_APPLICATION_PAYMENT_PLAN_RESP](state, response) {
    state.fastPaymentApplicationPaymentPlan = response
  },
}

const actions = {
  [FAST_PAYMENT_LOGIN](context, cedulaNumber) {
    store.dispatch(TOGGLE_OVERLAY, true)
    context.commit(FAST_PAYMENT_ERROR, null)
    api
      .getFastPaymentValidate(cedulaNumber)
      .then((response) => {
        context.commit(SET_FAST_PAYMENT_FORM, cedulaNumber)
        store.dispatch(TOGGLE_OVERLAY, false)
        console.log(response)
        store.dispatch(FAST_PAYMENT_GET_APPLICATION_MAMBU, cedulaNumber)
        //router.push({name: 'PagamentoAcessoRapido'})
      })
      .catch((e) => {
        context.commit(
          FAST_PAYMENT_ERROR,
          ' Lo sentimos, este número de cédula ' + cedulaNumber + ' no genera coincidencia'
        )
        store.dispatch(TOGGLE_OVERLAY, false)
        throw new Error(e)
      })
  },
  [FAST_PAYMENT_GET_APPLICATION_MAMBU](context) {
    api
      .getFastPaymentApplicationsMambu(state.cedulaNumber)
      .then((response) => {
        context.commit(FAST_PAYMENT_GET_APPLICATION_MAMBU_RESP, response.data)
        if (response.data.applications.length > 0) {
          const validApplications = response.data.applications.filter((application) => !application.isMovistarBill)
          if (validApplications.length > 0) {
            router.push({ name: 'PagamentoAcessoRapido' })
            store.dispatch(FAST_PAYMENT_GET_APPLICATION_PAYMENT_PLAN, validApplications[0].keyMambu)
          } else {
            router.push({ name: 'AcessoRapidoForm' })
            context.commit(
              FAST_PAYMENT_ERROR,
              'Eres cliente Movistar Pospago, el pago de tus cuotas los debes realizar a través de la factura de tu servicio móvil, encontrarás el cargo "Financiación equipos Movistar Money"'
            )
          }
        } else {
          router.push({ name: 'AcessoRapidoForm' })
          context.commit(
            FAST_PAYMENT_ERROR,
            ' Lo sentimos, este número de cédula ' + state.cedulaNumber + ' no coincide'
          )
        }
      })
      .catch((e) => {
        console.log(e)
        router.push({ name: 'AcessoRapidoForm' })
      })
  },
  [FAST_PAYMENT_GET_APPLICATION_PAYMENT_PLAN](context, loanAccountId) {
    store.dispatch(TOGGLE_OVERLAY, true)
    api
      .getFastPaymentApplicationPaymentPlan(state.cedulaNumber, loanAccountId)
      .then((response) => {
        context.commit(FAST_PAYMENT_GET_APPLICATION_PAYMENT_PLAN_RESP, response.data)
        store.dispatch(TOGGLE_OVERLAY, false)
      })
      .catch((e) => {
        console.log(e)
        store.dispatch(TOGGLE_OVERLAY, false)
      })
  },
}

const getters = {
  cedulaNumber: (state) => state.cedulaNumber,
  errorFastPayment: (state) => state.errorFastPayment,
  fastPaymentApplicationsMambu: (state) => state.fastPaymentApplicationsMambu,
  fastPaymentApplicationMambuSelecionado: (state) => state.fastPaymentApplicationMambuSelecionado,
  fastPaymentApplicationPaymentPlan: (state) => state.fastPaymentApplicationPaymentPlan,
}

export default {
  state,
  mutations,
  actions,
  getters,
}
