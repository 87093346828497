<template>
  <div class="container-resumen">
    <HeaderResumenContrato />
    <BoxSolicitud />
    <BoxDataBank :edit="true" />
  </div>
</template>

<script>
import HeaderResumenContrato from '../../components/ResumenContato/HeaderResumenContato.vue'
import BoxSolicitud from '../../components/ResumenContato/BoxSolicitud.vue'
import BoxDataBank from '../../components/DadosBancarios/BoxBankData.vue'

export default {
  components: { HeaderResumenContrato, BoxSolicitud, BoxDataBank },
}
</script>

<style scoped>
.container-resumen {
  padding: 15px 10px;
}
</style>
