<template>
  <div>
    <span class="text-info mb-2">{{ textoInfo }}</span>
    <b-row class="mt-1">
      <b-col class="ml-3" align-self="center">
        <b-row class="contact-options ml-5">
          <input
            id="billing-sms"
            class="input-checkbox mr-2"
            :class="{ 'error-validation-input': validationError }"
            type="checkbox"
            v-model="contactPreferences.billingSms"
          />
          Mensaje de texto
        </b-row>
        <b-row class="contact-options mt-1 ml-5">
          <input
            id="billing-email"
            class="input-checkbox mr-2"
            :class="{ 'error-validation-input': validationError }"
            type="checkbox"
            v-model="contactPreferences.billingEmail"
          />
          Correo electrónico
        </b-row>
      </b-col>
      <b-col>
        <b-row class="contact-options">
          <input
            id="billing-whatsapp"
            class="input-checkbox mr-2"
            :class="{ 'error-validation-input': validationError }"
            type="checkbox"
            v-model="contactPreferences.billingWhatsapp"
          />
          Whatsapp
        </b-row>
        <b-row class="contact-options mt-1">
          <input
            id="billing-call"
            class="input-checkbox mr-2"
            :class="{ 'error-validation-input': validationError }"
            type="checkbox"
            v-model="contactPreferences.billingCall"
          />
          Llamada
        </b-row>
      </b-col>
    </b-row>
    <b-row v-if="validationError" align-h="end" class="texto-erro">Debes marcar al menos 1 opción</b-row>
  </div>
</template>

<script>
export default {
  name: 'PreferenciaContato',
  props: {
    textoInfo: String,
  },
  data: function () {
    return {
      contactPreferences: {
        billingSms: true,
        marketingSms: false,
        billingWhatsapp: true,
        marketingWhatsapp: false,
        billingEmail: true,
        marketingEmail: false,
        billingCall: true,
        marketingCall: false,
      },
    }
  },
  watch: {
    contactPreferences: {
      handler: function (newValue) {
        this.$emit('updateContactPreferences', newValue)
      },
      deep: true,
    },
  },

  computed: {
    validationError() {
      let billingContact = Object.entries(this.contactPreferences).filter(([key, value]) => {
        if (key.startsWith('billing') && value) {
          return value
        }
      })
      if (billingContact.length === 0) return true

      return false
    },
  },
}
</script>

<style scoped>
.texto-erro {
  font-size: 13px;
  color: var(--movistar-pink);
}
.error-validation-input {
  outline: 2px solid var(--movistar-pink);
  margin-left: 5%;
  border-radius: 3px;
}
.input-checkbox {
  width: 16.6px;
  height: 16.6px;
  border-radius: 3px;
  background-color: var(--019-df-4);
}
.text-info {
  font-size: 13px;
  color: var(--movistar-blue);
}
.contact-options {
  font-size: 13px;
  color: var(--movistar-grey-2);
}
</style>
