const APPLICATION_ID = 'application-id'
const APPLICATION_STATUS = 'application-status'
const APPLICATION_OFFER = 'application-offer'
const CURRENT_APPLICATION = 'currentApplication'
const PHONE_DETAILS = 'phone-details'
const OFFER = 'offer'
const OFFER_OPTIONS = 'offerOptions'
const OFFER_SELECTED_NUMBER = 'offerSelectedNumber'
const OFFER_SELECTED_ITEM = 'offerSelectedItem'
const POST_APPLICATION_FORM = 'postApplicationForm'
const URL_CALLBACK = 'urlCallback'
const CONTRACT_TERMS = 'contractTerms'
const INSTALLMENT_PAYMENT = 'installmentPayment'
const DECREASE_PAYMENT = 'decreasePayment'
const PHONE_DETAILS_URL_ASSISTED = 'phoneDetails'
const DETAILS_ASSISTED = 'detailsAssistent'
const SELECT_BUTTONS_ASSISTED = 'selectButtonsAssisted'
const PRE_VALIDACAO_DATA_QUERY = 'preValidacaoDataQuery'

function saveLocalStorage(nome, valor) {
  if (valor) {
    window.localStorage.setItem(nome, JSON.stringify(valor))
  }
}

export const Persist = {
  getpreValidacaoDataQuery: function () {
    if (window.localStorage.getItem(PRE_VALIDACAO_DATA_QUERY) !== 'undefined') {
      return JSON.parse(window.localStorage.getItem(PRE_VALIDACAO_DATA_QUERY))
    } else return ''
  },
  savepreValidacaoDataQuery: function (dataQuery) {
    saveLocalStorage(PRE_VALIDACAO_DATA_QUERY, dataQuery)
  },

  getSelectButtonsAssisted: function () {
    const cookieURLCallBack = window.localStorage.getItem(SELECT_BUTTONS_ASSISTED)
    return JSON.parse(cookieURLCallBack && cookieURLCallBack != 'undefined' ? cookieURLCallBack : '{}')
  },

  saveSelectButtonsAssisted: function (selectButtonsAssisted) {
    saveLocalStorage(SELECT_BUTTONS_ASSISTED, selectButtonsAssisted)
  },

  getDetailsAssisted: function () {
    const cookieURLCallBack = window.localStorage.getItem(DETAILS_ASSISTED)
    return JSON.parse(cookieURLCallBack && cookieURLCallBack != 'undefined' ? cookieURLCallBack : '{}')
  },
  saveDetailsAssisted: function (detailsAssistent) {
    saveLocalStorage(DETAILS_ASSISTED, detailsAssistent)
  },
  getPhoneDetailsUrlAssisted: function () {
    const cookieURLCallBack = window.localStorage.getItem(PHONE_DETAILS_URL_ASSISTED)
    return JSON.parse(cookieURLCallBack && cookieURLCallBack != 'undefined' ? cookieURLCallBack : '{}')
  },
  //phoneDetailtUrl
  savePhoneDetailsUrlAssisted: function (phoneDetailsUrlAssisted) {
    saveLocalStorage(PHONE_DETAILS_URL_ASSISTED, phoneDetailsUrlAssisted)
  },
  getApplicationId: function () {
    if (window.localStorage.getItem(APPLICATION_ID)) {
      return JSON.parse(window.localStorage.getItem(APPLICATION_ID))
    } else return ''
  },
  saveApplicationId: function (applicationId) {
    saveLocalStorage(APPLICATION_ID, applicationId)
  },
  getApplicationStatus: function () {
    if (window.localStorage.getItem(APPLICATION_STATUS)) {
      return JSON.parse(window.localStorage.getItem(APPLICATION_STATUS))
    } else return ''
  },
  saveApplicationStatus: function (applicationStatus) {
    saveLocalStorage(APPLICATION_STATUS, applicationStatus)
  },

  getApplicationOffer: function () {
    if (window.localStorage.getItem(APPLICATION_OFFER)) {
      return JSON.parse(window.localStorage.getItem(APPLICATION_OFFER))
    } else return ''
  },
  saveApplicationOffer: function (applicationOffer) {
    saveLocalStorage(APPLICATION_OFFER, applicationOffer)
  },

  getCurrentApplication: function () {
    if (window.localStorage.getItem(CURRENT_APPLICATION)) {
      return JSON.parse(window.localStorage.getItem(CURRENT_APPLICATION))
    } else return ''
  },
  saveCurrentApplication: function (currentApplication) {
    saveLocalStorage(CURRENT_APPLICATION, currentApplication)
  },

  getPhoneDetails: function () {
    if (window.localStorage.getItem(PHONE_DETAILS) !== 'undefined') {
      return JSON.parse(window.localStorage.getItem(PHONE_DETAILS))
    } else return ''
  },
  savePhoneDetails: function (phoneDetails) {
    saveLocalStorage(PHONE_DETAILS, phoneDetails)
  },

  getOffer: function () {
    if (window.localStorage.getItem(OFFER)) {
      return JSON.parse(window.localStorage.getItem(OFFER))
    } else return ''
  },
  saveOffer: function (offer) {
    saveLocalStorage(OFFER, offer)
  },

  getOfferEnabledOptions: function () {
    if (window.localStorage.getItem(OFFER_OPTIONS)) {
      return JSON.parse(window.localStorage.getItem(OFFER_OPTIONS))
    } else
      return [
        { value: 3, disabled: true },
        { value: 6, disabled: true },
        { value: 12, disabled: true },
        { value: 18, disabled: true },
        { value: 24, disabled: true },
        { value: 36, disabled: true },
      ]
  },
  saveOfferEnabledOptions: function (options) {
    window.localStorage.setItem(OFFER_OPTIONS, JSON.stringify(options))
  },

  getOfferSelectedNumber: function () {
    if (window.localStorage.getItem(OFFER_SELECTED_NUMBER)) {
      return JSON.parse(window.localStorage.getItem(OFFER_SELECTED_NUMBER))
    } else return 36
  },
  saveOfferSelectedNumber: function (payment) {
    window.localStorage.setItem(OFFER_SELECTED_NUMBER, JSON.stringify(payment))
  },

  getOfferSelectedItem: function () {
    if (window.localStorage.getItem(OFFER_SELECTED_ITEM)) {
      return JSON.parse(window.localStorage.getItem(OFFER_SELECTED_ITEM))
    } else return ''
  },
  saveOfferSelectedItem: function (item) {
    window.localStorage.setItem(OFFER_SELECTED_ITEM, JSON.stringify(item))
  },

  getPostApplicationForm: function () {
    if (window.localStorage.getItem(POST_APPLICATION_FORM)) {
      return JSON.parse(window.localStorage.getItem(POST_APPLICATION_FORM))
    } else return ''
  },
  savePostApplicationForm: function (item) {
    window.localStorage.setItem(POST_APPLICATION_FORM, JSON.stringify(item))
  },

  getUrlCallback: function () {
    const cookieURLCallBack = window.localStorage.getItem(URL_CALLBACK)
    return JSON.parse(cookieURLCallBack && cookieURLCallBack != 'undefined' ? cookieURLCallBack : '{}')
  },
  saveUrlCallback: function (url) {
    window.localStorage.setItem(URL_CALLBACK, JSON.stringify(url))
  },

  getContractTerms: function () {
    if (window.localStorage.getItem(CONTRACT_TERMS)) {
      return JSON.parse(window.localStorage.getItem(CONTRACT_TERMS))
    } else return false
  },

  saveContractTerms: function (term) {
    window.localStorage.setItem(CONTRACT_TERMS, JSON.stringify(term))
  },

  clearContractTerms: function () {
    window.localStorage.removeItem(CONTRACT_TERMS)
  },

  getInstallmentPayment: function () {
    if (window.localStorage.getItem(INSTALLMENT_PAYMENT)) {
      return JSON.parse(window.localStorage.getItem(INSTALLMENT_PAYMENT))
    } else return ''
  },

  saveInstallmentPayment: function (value) {
    window.localStorage.setItem(INSTALLMENT_PAYMENT, JSON.stringify(value))
  },

  clearInstallmentPayment: function () {
    window.localStorage.removeItem(INSTALLMENT_PAYMENT)
  },

  getDecreasePayment: function () {
    if (window.localStorage.getItem(DECREASE_PAYMENT)) {
      return JSON.parse(window.localStorage.getItem(DECREASE_PAYMENT))
    } else return ''
  },

  saveDecreasePayment: function (value) {
    window.localStorage.setItem(DECREASE_PAYMENT, JSON.stringify(value))
  },

  clearDecreasePayment: function () {
    window.localStorage.removeItem(DECREASE_PAYMENT)
  },
}
