<template>
  <AssistidoLayout>
    <template #guia>
      <ResumoAssistidoAntecipoGuia v-if="currentApplication.advancedPayment" />
      <ResumoAssistidoFiancaGuia v-else-if="currentApplication.guaranteePayment" />
      <ResumoAssistidoGuia v-else />
    </template>
    <FormularioResumoAssistido />
  </AssistidoLayout>
</template>

<script>
import { mapGetters } from 'vuex'

import AssistidoLayout from '@/components/assistido/AssistidoLayout.vue'
import FormularioResumoAssistido from '@/components/assistido/ResumoAssistido/ResumoAssistido.vue'
import ResumoAssistidoAntecipoGuia from '@/components/assistido/ResumoAssistido/ResumoAssistidoAntecipoGuia.vue'
import ResumoAssistidoFiancaGuia from '@/components/assistido/ResumoAssistido/ResumoAssistidoFiancaGuia.vue'
import ResumoAssistidoGuia from '@/components/assistido/ResumoAssistido/ResumoAssistidoGuia.vue'

export default {
  components: {
    AssistidoLayout,
    ResumoAssistidoGuia,
    ResumoAssistidoAntecipoGuia,
    ResumoAssistidoFiancaGuia,
    FormularioResumoAssistido,
  },
  computed: {
    ...mapGetters({
      currentApplication: 'currentApplication',
    }),
  },
}
</script>
