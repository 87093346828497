<template>
  <div class="container-finalizar-assistido">
    <b-row class="py-4 mb-2">
      <b-col>
        <b-img src="@/assets/images/money.png" fluid alt="Movistar money logo" class="movistar-image-login-assistido" />
      </b-col>
    </b-row>

    <div class="container-final-assistindo">
      <b-img src="@/assets/icons/girl-with-phone.svg" fluid alt="Movistar money logo" class="girl-with-phone" />

      <div class="conteudo-final-assistido" v-if="currentApplication.advancedPayment">
        <h1 class="titulo-final-assistido">¡La solicitud de crédito fue aprobada!</h1>
        <h2 class="legenda-final-assistido">
          Recuerda que tu cliente debe pagar el anticipo y solo hasta que lo pague verás pagada la orden en Fullstack
          <br />
          Si el cliente no paga en las próximas {{ validadeEmail }} horas se cancelará la orden automáticamente en 24
          horas
        </h2>
      </div>
      <div class="conteudo-final-assistido" v-else-if="currentApplication.guaranteePayment">
        <h1 class="titulo-final-assistido">¡La solicitud de crédito fue aprobada!</h1>
        <h2 class="legenda-final-assistido">
          Recuerda que el cliente debe pagar el valor de la fianza y
          <b>solo hasta que lo haga verás finalizada la orden en fullstack</b>.
          <br />
          Si el cliente no paga en las próximas {{ validadeEmail }} horas se cancelará la orden automáticamente
        </h2>
      </div>
      <div class="conteudo-final-assistido" v-else>
        <h1 class="titulo-final-assistido">La solicitud de crédito fue aprobada y ha terminado el proceso</h1>
        <h2 class="legenda-final-assistido">
          Puedes continuar con el proceso habitual de entrega de equipo / accesorio
        </h2>
      </div>

      <div class="footer-final-assistido">
        <ButtonContinue text="Finalizar" @click="finalizarAssistido" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { BUSCAR_PARAMETROS } from '@/store/actions.type'

import ButtonContinue from '@/components/buttons/ButtonContinue.vue'

export default {
  name: 'FinalizarAssistido',
  components: { ButtonContinue },
  data() {
    return {
      validadeEmail: null,
    }
  },
  async mounted() {
    this.validadeEmail = await this.$store.dispatch(BUSCAR_PARAMETROS, 'epaycoAdvancedEmailLinkValidity')
  },
  methods: {
    finalizarAssistido() {
      window.location.replace('https://www.movistarmoney.co/')
    },
  },
  computed: {
    ...mapGetters({
      currentApplication: 'currentApplication',
    }),
  },
}
</script>

<style scoped>
.container-finalizar-assistido {
  height: 100vh;
  width: 100%;
  background-image: url(~@/assets/images/background-assistido.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  overflow-x: hidden;
}

.container-info-assistido {
  display: flex;
  align-self: center;
  position: relative;
  justify-content: space-around;
  margin-bottom: 100px;
}

.titulo-final-assistido {
  font-family: Telefonica;
  font-size: 26px;
  font-weight: 300;
  color: #0b2739;
}

.legenda-final-assistido {
  color: #0b2739;
  font-family: Telefonica;
  font-size: 20px;
}

.container-final-assistindo {
  display: flex;
  flex-direction: column;
  margin-top: 5rem;
}

.girl-with-phone {
  width: 250px;
  height: 305px;
  margin: 0 auto;
}

.conteudo-final-assistido {
  margin-top: 2rem;
}

.footer-final-assistido {
  width: 382px;
  height: 49px;
  margin: 3rem auto;
}

@media (max-width: 1380px) {
  .container-home-assistido {
    background-image: url(~@/assets/images/background-assistido.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 100vh;
    width: 100%;
    overflow-y: scroll;
  }
}
</style>
