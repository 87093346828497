<template>
  <div class="container-sm-my-credit">
    <b-row class="title-credits no-margin" align-v="end">
      <b-img left class="image-size" src="@/assets/icons/credits.svg" alt="Paper plane img" />
      <span class="mis-creditos">Mis créditos</span>
    </b-row>
    <b-row class="no-margin border-row">
      <b-col cols="3" class="no-padding">
        <hr class="separator-orange" />
      </b-col>
      <b-col cols="9" class="no-padding">
        <hr class="separator" />
      </b-col>
    </b-row>
    <div v-if="!selfApplicationsLiberadas.length > 0" class="table-my-credits">
      <div class="texto-centro">No hay créditos registrados.</div>
    </div>
    <div class="table-my-credits" v-if="selfApplicationsLiberadas.length > 0">
      <table width="100%" class="table-my-credits" aria-describedby="table-sm-my-credits">
        <tr>
          <th scope="col" class="texto-header">Producto</th>
          <th scope="col" class="texto-header">Número de producto</th>
          <th scope="col" class="texto-header">Seguro de vida</th>
          <th></th>
        </tr>
        <tbody>
          <tr class="list-applications" v-for="(value, index) in selfApplicationsLiberadas" :key="index">
            <td class="credito-de-equipo" nowrap>{{ value.productName }}</td>
            <td class="texto-body" nowrap>{{ value.contractNumber }}</td>
            <td>
              <div v-if="value.insurancePolicy">
                <button class="button-hide-show-password" @click="downloadApoliceSeguro(value)">
                  <img src="@/assets/icons/seta-baixo.svg" alt="Download icon" />
                  <span class="download-text ml-1">Descargar</span>
                </button>
              </div>
            </td>
            <td class="cursor-pointer" nowrap @click="viewCreditDetail(value)">
              <h1 class="see-more-btn">Ver más</h1>
              <b-img right class="img-arrow" src="@/assets/icons/arrow-blue-right.svg" alt="Paper plane img" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { FETCH_APPLICATION, SELF_MANAGE_GET_APPLICATION_APOLICE_SEGURO } from '../../store/actions.type'
import { MUTATE_APPLICATION_ID } from '../../store/mutations.type'

export default {
  name: 'Credits',
  computed: {
    ...mapGetters({
      selfApplicationsLiberadas: 'selfManageApplicationsLiberadas',
    }),
  },
  methods: {
    viewCreditDetail(value) {
      this.$store.commit(MUTATE_APPLICATION_ID, value.applicationId)
      this.$store.dispatch(FETCH_APPLICATION, value.applicationId)
      this.$router.push('/self-manage/credits/' + value.keyMambu)
    },
    downloadApoliceSeguro(value) {
      this.$store.dispatch(SELF_MANAGE_GET_APPLICATION_APOLICE_SEGURO, value.keyMambu)
    },
  },
}
</script>

<style scoped>
.button-hide-show-password {
  background: none;
  border: none;
  padding: 0 1px;
  cursor: pointer;
  display: block;
  align-content: center;
  margin-left: 0;
}
.download-text {
  vertical-align: middle;
  color: var(--movistar-blue);
  text-decoration: underline;
  font-size: 14px;
}

.texto-centro {
  color: #86888c;
  margin-top: 1rem;
}

.container-sm-my-credit {
  background-color: var(--white);
  padding: 20px;
  border: 1px solid #d3d4d3;
  margin: 70px 70px 20px 70px;
  height: 100%;
}

.see-more-btn:hover {
  cursor: pointer;
}

.img-arrow:hover {
  cursor: pointer;
}

.cursor-pointer {
  display: flex;
  align-content: center;
  align-items: center;
  height: 28px;
}

table {
  width: 100%;
  border-spacing: 0 0.5rem;
}

.list-applications {
  border-bottom: 1px solid #d3d4d3;
  align-items: center;
}

.button-information {
  position: absolute;
  right: 0;
}

.table-my-credits {
  position: relative;
  top: -20px;
}

th {
  padding: 1rem 0;
  line-height: 1.5rem;
}

.border-row {
  position: relative;
  top: -55px;
}

.more-information {
  display: flex;
  align-content: center;
  justify-content: flex-end;
  position: relative;
  top: -70px;
}

.img-arrow {
  height: 18px;
  width: 18px;
  margin-left: 5px;
}

.see-more-btn {
  color: #0b2739;
  font-size: 18px;
  margin-bottom: inherit;
}

.separator {
  background-color: var(--movistar-grey-5);
  height: 1px;
}

.separator-orange {
  background-color: var(--movistar-orange);
  height: 2px;
}

.title-credits {
  position: relative;
  top: -50px;
}

.image-size {
  height: 80px;
  border-color: #019df4;
}

img.arrow {
  width: 16px;
  height: 16px;
  margin: 10px 0 20px 4px;
  object-fit: contain;
}

.my-credits-padding {
  padding: 0 15px;
}

.my-credits-title {
  font: 300 14px Telefonica, sans-serif;
  color: var(--movistar-pine-green);
  text-align: left;
}

.mis-creditos {
  margin: 0 26.5px 15px 7.5px;
  font-family: Telefonica, sans-serif;
  font-size: 22px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #0b2739;
}

.texto-header {
  margin: 16px 19px 23px 22px;
  font-family: Telefonica, sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #86888c;
}

.texto-body {
  margin: 10px 15px 20px 0;
  font-family: Telefonica, sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.4px;
  text-align: left;
  color: #50535a;
}

.credito-de-equipo {
  margin: 12px 23px 21px 0;
  font-family: Telefonica, sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.4px;
  text-align: left;
  color: #50535a;
}

@media (max-width: 790px) {
  .container-sm-my-credit {
    background-color: var(--white);
    padding: 10px;
    border: 1px solid #d3d4d3;
    margin: 50px 0px 0px 0px;
    min-height: 200px;
    max-height: 100%;
  }
  .mis-creditos {
    height: 12px;
    font-size: 16px;
    position: relative;
    top: 5px;
    font-family: Telefonica, sans-serif;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    align-items: center;
    color: #0b2739;
  }
  .title-credits {
    position: relative;
    top: -20px;
  }

  .image-size {
    width: 40px;
    height: 40px;
  }
  .border-row {
    position: relative;
    top: -30px;
  }
  .texto-header {
    margin: 10px 15px 18px 17px;
    font-family: Telefonica, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #86888c;
  }
  .credito-de-equipo {
    margin: 8px 18px 17px 0;
    font-family: Telefonica, sans-serif;
    font-size: 10px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.4px;
    text-align: left;
    color: #50535a;
    font-weight: bold;
  }

  .texto-body {
    margin: 10px 15px 20px 0;
    font-family: Telefonica, sans-serif;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.4px;
    text-align: left;
    color: #50535a;
  }

  .see-more-btn {
    font-size: 13px;
    margin-top: 0px;
    line-height: inherit;
    margin-bottom: inherit;
  }

  .cursor-pointer {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    height: 26px;
  }
}
</style>
