<template>
  <div class="container-date-assistido">
    <b-row class="py-4 mb-2">
      <b-col>
        <b-img src="@/assets/images/money.png" fluid alt="Movistar money logo" class="movistar-image-login-assistido" />
      </b-col>
    </b-row>

    <div class="container-info-assistido">
      <EvidenteDataAssistidoGuia />
      <ContainerEvidenteDataAssistido />
    </div>
  </div>
</template>

<script>
import EvidenteDataAssistidoGuia from '../../components/assistido/EvidenteDataAssistido/EvidenteDataAssistidoGuia.vue'
import ContainerEvidenteDataAssistido from '../../components/assistido/EvidenteDataAssistido/ContainerEvidenteDataAssistido.vue'

export default {
  components: { EvidenteDataAssistidoGuia, ContainerEvidenteDataAssistido },
}
</script>

<style scoped>
.container-date-assistido {
  height: 100vh;
  width: 100%;
  background-image: url(~@/assets/images/background-assistido.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  overflow-x: hidden;
}

.container-info-assistido {
  display: flex;
  align-self: center;
  justify-content: space-around;
  margin-bottom: 100px;
}

@media (max-width: 1380px) {
  .container-date-assistido {
    background-image: url(~@/assets/images/background-assistido.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 100vh;
    width: 100%;
    overflow: auto;
  }
}
</style>
