<template>
  <b-card no-body>
    <div>
      <b-row align-h="end" align-v="center" class="align-balloon">
        <b-col cols="9" class="text-left orange-balloon">
          <span class="onboard-finish-balloon-title no-margin"> Orden {{ currentApplication.idOrderAmount }} </span>
        </b-col>
      </b-row>
    </div>
    <div class="mx-4 my-4">
      <b-row align-v="center" class="mb-3">
        <b-col cols="4">
          <b-img class="phone-image" :src="phoneDetails.urlPhoto" alt="Phone" />
        </b-col>
        <b-col cols="8">
          <b-row>
            <p class="onboard-finish-card">{{ phoneDetails.deviceModel }}</p>
          </b-row>
          <b-row align-v="center">
            <span class="onboard-finish-card-item text-pine-green mr-1">{{ phoneDetails.internMemory }}</span>
            <ColorCircle :big="true" :color="phoneDetails.deviceColorHex" />
            <span class="onboard-finish-card-item text-grey">{{ phoneDetails.deviceColor }}</span>
          </b-row>
        </b-col>
      </b-row>
      <div class="detalhes">
        Detalle de tu orden
        <b-button class="ml-2 p-0" variant="blank" v-b-toggle.detalhes>
          <b-img src="@/assets/icons/arrow-down.svg"></b-img>
        </b-button>
      </div>
      <b-collapse id="detalhes" class="detalhes-table">
        <div class="d-flex justify-content-between">
          <p class="key">Valor equipo</p>
          <p class="value" v-text="currentApplication.requestedValueFmt" />
        </div>
        <div class="d-flex justify-content-between">
          <p class="key">Número de cuotas</p>
          <p class="value" v-text="currentApplication.intendedInstallmentsNumber" />
        </div>
        <hr />
        <div class="d-flex justify-content-between">
          <p class="key">Total Cuota / mes</p>
          <p v-if="!currentApplication.isTaxFree" class="value">{{ applicationOffer.totalFmt }} Con IVA</p>
          <p v-if="currentApplication.isTaxFree" class="value">{{ applicationOffer.totalFmt }}</p>
        </div>
        <div class="d-flex justify-content-between align-items-center">
          <p class="key">Mi dirección</p>
          <div>
            <p class="value">{{ addressClient.cityName }}</p>
            <p class="value">{{ addressClient.formattedAddress }}</p>
          </div>
        </div>
        <hr />
      </b-collapse>

      <div v-if="currentApplication.statusGuaranteePayment === 'Pendente'" class="box-pendente-fianca">
        <b-img class="mr-2" src="@/assets/icons/warning-blue.svg" width="16" />
        Pendiente pago fianza
      </div>
      <div
        v-if="currentApplication.guaranteePayment"
        class="d-flex justify-content-between align-items-center detalhes-table"
      >
        <p class="key">Pago único fianza</p>
        <p class="value">{{ currentApplication.guaranteePaymentFmt }}</p>
      </div>
      <div
        v-if="currentApplication.advancedPayment"
        class="d-flex justify-content-between align-items-center detalhes-table"
      >
        <p class="key">Cuota inicial</p>
        <p class="value">{{ currentApplication.advancedPaymentFmt }}</p>
      </div>
      <hr />
      <b-row class="mb-4" align-v="center">
        <b-col cols="3" class="no-padding">
          <b-img src="@/assets/images/mail.png" alt="Phone" />
        </b-col>
        <b-col cols="9" class="text-left font-16 text-pine-green no-padding">
          <p class="onboard-finish-card-item no-margin" v-text="mensagemEmail" />
        </b-col>
      </b-row>

      <div class="mt-5">
        <MVButton
          v-if="currentApplication.advancedPayment"
          label="Volver a enviar link de pago"
          outline
          variant="movistar-green"
          class="btn-pagar"
          v-b-modal.modal-recado-email-antecipo
        />
        <MVButton
          v-else-if="currentApplication.statusGuaranteePayment === 'Pendente'"
          label="Pagar fianza"
          variant="movistar-green"
          class="btn-pagar"
          v-b-modal.modal-link-enviado
        />
        <router-link
          v-if="!currentApplication.guaranteePayment || currentApplication.statusGuaranteePayment !== 'Pendente'"
          to="/opinion"
        >
          <MVButton label="Cerrar" variant="movistar-green" class="btn-pagar mt-3" />
        </router-link>
      </div>
    </div>
    <ModalRecadoEmailAnticipo id="modal-recado-email-antecipo" @continuar="abrirModalEnviarEmail" />
    <ModalEnviarEmailAnticipo
      id="modal-enviar-email-antecipo"
      @continuar="reenviarEmailAnticipo"
      @novo_email="abrirModalConfirmarNovoEmail"
      :email="currentApplication.email"
      :applicationId="currentApplication.applicationId"
    />
    <ModalConfirmarNovoEmailAnticipo
      id="modal-confirmar-novo-email-antecipo"
      @continuar="reenviarEmailAnticipo"
      :applicationId="currentApplication.applicationId"
    />

    <ModalLinkEnviadoFianca />
    <ModalEnviarEmailFianca />
    <ModalEnviarEmailOutroEnderecoFianca />
    <ModalConfirmarRecebimentoEmailFianca />
  </b-card>
</template>

<script>
import MVButton from '@/components/movistar/MVButton.vue'
import ColorCircle from '@/components/ColorCircle.vue'

import ModalLinkEnviadoFianca from '@/components/fianca/modal/ModalLinkEnviado.vue'
import ModalEnviarEmailFianca from '@/components/fianca/modal/ModalEnviarEmail.vue'
import ModalEnviarEmailOutroEnderecoFianca from '@/components/fianca/modal/ModalEnviarEmailOutroEndereco.vue'
import ModalConfirmarRecebimentoEmailFianca from '@/components/fianca/modal/ModalConfirmarRecebimentoEmail.vue'

import ModalRecadoEmailAnticipo from '@/components/modal/antecipo/ModalRecadoEmailAnticipo.vue'
import ModalEnviarEmailAnticipo from '@/components/modal/antecipo/ModalEnviarEmail.vue'
import ModalConfirmarNovoEmailAnticipo from '@/components/modal/antecipo/ModalConfirmarNovoEmail.vue'

import { mapGetters } from 'vuex'
import { gtmDataLayerTrackEvent } from '@/helpers/gtmHelper'

export default {
  name: 'CardFinal',
  components: {
    ColorCircle,
    MVButton,
    ModalRecadoEmailAnticipo,
    ModalConfirmarNovoEmailAnticipo,
    ModalEnviarEmailAnticipo,
    ModalLinkEnviadoFianca,
    ModalEnviarEmailFianca,
    ModalEnviarEmailOutroEnderecoFianca,
    ModalConfirmarRecebimentoEmailFianca,
  },
  data() {
    return {
      contract: {
        action: 'createContract',
        isPartial: false,
      },
    }
  },
  methods: {
    openModal(modalId) {
      this.$bvModal.show(modalId)
      gtmDataLayerTrackEvent(
        window.dataLayer,
        'trackEvent',
        'movistar money',
        'click::thank you page::paso 6',
        'cerrar',
        this.CryptoJS.SHA1(this.currentApplication.mobile).toString()
      )
    },
    abrirModalEnviarEmail() {
      this.$bvModal.hide('modal-recado-email-antecipo')
      this.$bvModal.show('modal-enviar-email-antecipo')
    },
    abrirModalConfirmarNovoEmail() {
      this.$bvModal.hide('modal-enviar-email-antecipo')
      this.$bvModal.show('modal-confirmar-novo-email-antecipo')
    },
    reenviarEmailAnticipo() {},
  },
  computed: {
    ...mapGetters({
      phoneDetails: 'phoneDetails',
      currentApplication: 'currentApplication',
      applicationOffer: 'applicationOffer',
      addressClient: 'addressClient',
    }),
    mensagemEmail() {
      if (this.currentApplication.advancedPayment) {
        return 'Enviamos el link de pago de tu cuota inicial del equipo a tu correo, si no recibiste el link puedes reenviar dando clic en el botón "Volver a enviar el link de pago"'
      } else if (this.currentApplication.guaranteePayment) {
        return 'Enviamos el link de pago de la fianza a tu correo'
      } else {
        return 'Recibirás la información de tu solicitud de crédito en tu correo electrónico'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.card {
  border: 1px solid #ec624b;
}

.phone-image {
  height: 80px;
}

.orange-balloon {
  color: #ffffff;
  background-color: #ec624b;
  border-radius: 25px 25px 0 25px;
  height: 3em;
  line-height: 3em;
  vertical-align: middle;
  margin-top: -1.5em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.align-balloon {
  padding-right: 14px;
}

.box-pendente-fianca {
  display: inline-block;
  border: 1px solid #ec624b;
  padding: 2px 6px;
  margin: 1rem 0;
}

.detalhes {
  font-weight: bold;
  text-align: left;
  &-table {
    .key {
      color: var(--movistar-grey);
      font-size: 14px;
      margin: 0;
    }
    .value {
      color: var(--movistar-grey-2);
      font-size: 16px;
      margin: 0;
    }
  }
}

.btn-pagar {
  width: 100%;
}
</style>
