<template>
  <div class="btn-continue">
    <button v-if="disable" class="continue-button continue-button-text disable" :disabled="disable" @click="emitClick">
      <span>{{ text }}</span>
    </button>
    <button
      v-else-if="inverted"
      class="continue-button continue-button-text"
      :disabled="disable"
      :class="origin === 'Libre' ? 'inverted-orange' : 'inverted'"
      @click="emitClick"
    >
      <span>{{ text }}</span>
    </button>
    <button
      v-else
      class="continue-button continue-button-text"
      :disabled="disable"
      @click="emitClick"
      :class="{ isSelectOffer: origin === 'Libre' }"
    >
      <span>{{ text }}</span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'ButtonContinue',
  props: {
    disable: {
      type: Boolean,
      default: false,
    },
    inverted: Boolean,
    text: String,
    origin: {
      type: String,
      default: 'Finter',
    },
  },
  methods: {
    emitClick() {
      this.$emit('click')
    },
  },
}
</script>

<style scoped>
.isSelectOffer {
  background: #ec624b;
}
.inverted-orange {
  background: #ffffff;
  color: var(--movistar-orange);
  border: 1px solid var(--movistar-orange);
}

.disable {
  background: #d3d4d3;
  color: var(--movistar-grey);
  pointer-events: none;
  cursor: default;
}

.inverted {
  background: #ffffff;
  color: var(--movistar-green);
  border: 1px solid var(--movistar-green);
}

p {
  color: #ffffff;
  padding: 12px;
}
</style>
