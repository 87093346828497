import router from '../router'
import store from '../store'
import { FETCH_APPLICATION_REDIRECT, TOGGLE_OVERLAY } from '../store/actions.type'

export function errorHandler(error) {
  store.dispatch(TOGGLE_OVERLAY, false)

  if (error) {
    if (error.status === 504) {
      store.dispatch(TOGGLE_OVERLAY, true)
      setTimeout(() => {
        store.dispatch(TOGGLE_OVERLAY, false)
        store.dispatch(FETCH_APPLICATION_REDIRECT)
      }, 10000)
    } else if (error.status === 500) {
      router.push({ name: 'ServiceUnavailable' })
    } else if (error.status === 412 || error.status === 424) {
      router.push({ name: 'CantProcessRequest' })
    } else if (error.status === 409) {
      if (error.data.errorCode === 'EmAndamento' || error.data.errorCode === 'Liberada') {
        router.push({ name: 'LoanInProgress' })
        throw new Error(JSON.stringify(error))
      } else {
        router.push({ name: 'ExpiredEmail' })
      }
    } else {
      throw new Error(JSON.stringify(error))
    }
  }
}

export function selfManageErrorHandler(error) {
  if (error.status === 500) {
    router.push({ name: 'ServiceUnavailable' })
    store.dispatch(TOGGLE_OVERLAY, false)
  } else if (error.status === 424) {
    router.push({ name: 'ManyAttempts' })
    throw new Error(error.data.message)
  } else if (error.status === 409) {
    throw new Error(error.data.message)
  } else if (error.status === 404) {
    throw new Error(error.data.message)
  } else {
    throw new Error(JSON.stringify(error.data))
  }
}

export function sendFormHandler(response) {
  if (response.status === 200) {
    let status
    if (response.data.status) status = response.data.status.toLowerCase()

    if (status === 'reproved' || status === 'infolaftfailure') {
      router.push({ name: 'CantProcessRequest' })
      store.dispatch(TOGGLE_OVERLAY, false)
    } else {
      router.push({ name: 'OTP' })
      store.dispatch(TOGGLE_OVERLAY, false)
    }
  } else if (response.status === 500 || response.status === 503) {
    router.push({ name: 'ServiceUnavailable' })
    store.dispatch(TOGGLE_OVERLAY, false)
  } else if (response.data.errorCode === 'Reprovada') {
    router.push({ name: 'CantProcessRequest' })
    store.dispatch(TOGGLE_OVERLAY, false)
  } else if (response.data.errorCode === 'EmAndamento') {
    router.push({ name: 'LoanInProgress' })
    store.dispatch(TOGGLE_OVERLAY, false)
  } else {
    router.push({ name: 'LoanInProgress' })
    store.dispatch(TOGGLE_OVERLAY, false)
  }
}

export function validationException(error) {
  let mensagem = error.data.message
  let respostaJson
  if (error.status === 409) {
    let mensagemFormatada = mensagem.replace(/\\/g, '')
    try {
      respostaJson = JSON.parse(mensagemFormatada)
    } catch (errorCatch) {
      errorHandler(error)
    }

    if (respostaJson.detail === 'Validation exception') {
      store.dispatch(TOGGLE_OVERLAY, false)
      throw new Error(mensagemFormatada)
    } else errorHandler(error)
  } else errorHandler(error)
}
