<template>
  <b-card class="card-cupo" border-variant="light">
    <div class="d-flex justify-content-between">
      <h4>Ofertas</h4>
    </div>
    <div class="divider mb-3"></div>
    <b-form>
      <FloatingInputText
        v-model="value.sku"
        id="sku"
        label="Código de referencia SKU"
        class="mb-4 mt-5"
        mask="XXXXXXXXXXXXXXXXXXXXXXXXX"
        error-message="El campo es obligatorio"
        icon="teclado-de-discagem.png"
      />
      <FloatingInputCurrency
        v-model="value.intendedValue"
        id="valor"
        :max-length="10"
        label="Valor equipo"
        class="mb-4"
        :precision="0"
        error-message="El campo es obligatorio"
        icon="movil-credisfera.svg"
      />

      <div class="iva d-flex justify-content-between">
        <div>
          <p class="blue">¿El equipo tiene IVA?</p>
          <p>Elige una opción</p>
        </div>
        <b-form-radio-group
          id="rado-iva"
          button-variant="outlined-movistar"
          v-model="value.iva"
          :options="options"
          buttons
        />
      </div>
    </b-form>
  </b-card>
</template>

<script setup>
import FloatingInputText from '@/components/inputs/FloatingInputText.vue'
import FloatingInputCurrency from '@/components/inputs/FloatingInputCurrency.vue'

defineProps({
  value: {
    type: Object,
    default: () => ({}),
  },
})

const options = [
  { text: 'Si', value: 'Si' },
  { text: 'No', value: 'No' },
]
</script>

<style scoped lang="scss">
.card-cupo {
  max-width: 41.25rem;
  margin: 0;
  background-color: #f5f5f5;
  border-radius: 10px;

  .card-body {
    padding: 27px 37px;
    h4 {
      font-size: 25px;
      margin-bottom: 0;
      font-weight: bold;
    }

    .divider {
      width: 100%;
      height: 0px;
      margin-top: 1rem;
      border-bottom: solid 0.5px #707070;
    }

    .iva {
      p {
        margin-bottom: 0;
        font-size: 20px;
        text-align: left;
        color: #86888c;
        &.blue {
          color: #019df4;
        }
      }

      ::v-deep .btn-outlined-movistar {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 1.5rem;
        width: 5rem;
        background-color: #ffffff;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        border-radius: 10px;
        &:last-child {
          margin-right: 0;
        }
        &.active {
          border: 1px solid #019df4;
        }
      }
    }
  }
}
</style>
