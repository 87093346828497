<template>
  <div class="container-fluid mb-5">
    <div class="text-center mt-4 mb-4">
      <b-img src="@/assets/images/money.png" fluid alt="Movistar money logo" class="movistar-image" />
    </div>
    <EpaycoCard />
    <br />
    <PaymentTypes />
  </div>
</template>
<script>
import PaymentTypes from '../../components/SelfManage/PaymentTypes.vue'
import EpaycoCard from '../../components/AcessoRapido/FastPaymentEpaycoCard.vue'
export default {
  name: 'AcessoRapidoEpaycoResponse',
  components: { PaymentTypes, EpaycoCard },
}
</script>
