<template>
  <b-card no-body class="sm-card-payments">
    <b-card-body>
      <b-row class="text-center" align-v="center">
        <b-col class="px-2">
          <p class="sm-credits-payments-card-value mb-2">Características del crédito</p>
        </b-col>
      </b-row>

      <hr />
      <b-collapse id="payments">
        <b-row align-v="center" v-if="propostaMambu">
          <b-col class="pr-1">
            <p class="sm-finance-card-title text-left">Capital concedido</p>
          </b-col>
          <b-col class="pl-1">
            <p class="sm-finance-card-title text-right">{{ propostaMambu.requestedValueFmt }}</p>
          </b-col>
        </b-row>

        <b-row align-v="center">
          <b-col class="pr-1">
            <p class="sm-finance-card-title text-left">Número de cuotas</p>
          </b-col>
          <b-col class="pl-1">
            <p class="sm-finance-card-title text-right">{{ propostaMambu.intendedInstallmentsNumber }}</p>
          </b-col>
        </b-row>

        <b-row align-v="center" v-if="propostaMambu.offer">
          <b-col class="pr-1">
            <p class="sm-finance-card-title text-left">Tasa anual</p>
          </b-col>
          <b-col class="pl-1">
            <p class="sm-finance-card-title text-right">
              <b-img
                src="@/assets/icons/aditional-info.svg"
                alt="Aditional info"
                class="ml-2"
                v-b-tooltip.hover.topleft="{ title: `Efectiva Anual` }"
              />
              {{ propostaMambu.currentInterestRateFmt }} EA
            </p>
          </b-col>
        </b-row>

        <b-row align-v="center" v-if="propostaMambu.offer && propostaMambu.offer.taxRate > 0">
          <b-col class="pr-1">
            <p class="sm-finance-card-title text-left">IVA Intereses</p>
          </b-col>
          <b-col class="pl-1">
            <p class="sm-finance-card-title text-right">19% IVA</p>
          </b-col>
        </b-row>

        <b-row align-v="center" v-if="propostaMambu.offer">
          <b-col class="pr-1">
            <p class="sm-finance-card-title text-left">Interés mensual</p>
          </b-col>
          <b-col class="pl-1">
            <p class="sm-finance-card-title text-right">
              <b-img
                src="@/assets/icons/aditional-info.svg"
                alt="Aditional info"
                class="ml-2"
                v-b-tooltip.hover.topleft="{ title: `Nominal Mensual` }"
              />
              {{ propostaMambu.currentInterestMonthlyRateFmt }} NM
            </p>
          </b-col>
        </b-row>

        <hr />
        <b-row align-v="center" v-if="propostaMambu.guaranteePayment > 0">
          <b-col class="pr-1">
            <p class="sm-finance-card-title text-left">
              <b-img src="@/assets/icons/check-circle.svg" alt="Aditional info" class="ml-2" /> &ensp; Garantía Fianza*
            </p>
          </b-col>
          <b-col class="pl-1">
            <p class="sm-finance-card-title text-right">
              <b-img
                src="@/assets/icons/aditional-info.svg"
                alt="Aditional info"
                class="ml-2"
                v-b-tooltip.hover.topleft="{
                  title: `Este pago lo realizaste en el proceso de solicitud de tu crédito`,
                }"
              />
              {{ propostaMambu.guaranteePaymentFmt }}
            </p>
          </b-col>
        </b-row>
        <b-row v-if="propostaMambu.guaranteePayment > 0">
          <b-col cols="12">
            <p class="sm-finance-card-title text-left">
              *Este ítem es una garantía del crédito que adquiriste, es decir, esta entidad te facilitó el acceso a
              nuestro crédito. El fondo de garantías de Antioquia avala y respalda tu cupo de crédito.
            </p>
            <p class="sm-finance-card-title text-left mb-0"><b>Para más información:</b></p>
            <p class="sm-finance-card-title text-left mb-0">Línea de atención gratuita</p>
            <p class="sm-finance-card-title text-left mb-0">018000423882</p>
            <p class="sm-finance-card-title text-left">Email info@fga.com.co</p>
          </b-col>
        </b-row>
      </b-collapse>

      <ToggleCollapse id="payments" icon-up="arrow-up-grey-sm.svg" icon-down="arrow-down-grey-sm.svg" />
    </b-card-body>
  </b-card>
</template>

<script>
import ToggleCollapse from '../buttons/ToggleCollapse'

export default {
  name: 'CreditsPaymentsCard',
  components: { ToggleCollapse },
  props: {
    totalDueValue: String,
    totalPaidValue: String,
    propostaMambu: {},
    planoPagamento: {
      globalData: {
        lastDueDate: '',
        totalCapitalDueValueFmt: '',
        totalCapitalPaidValue: '',
      },
    },
  },
}
</script>

<style scoped>
.sm-credits-payments-card-value {
  font: 18px Telefonica, sans-serif;
  color: var(--movistar-grey-6);
}
</style>
