var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{attrs:{"id":_vm.id,"header-border-variant":"white","footer-border-variant":"white","hide-footer":""},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('b-button',{attrs:{"variant":"blank"},on:{"click":_vm.fecharModal}},[_c('IconeFechar')],1)]},proxy:true}])},[_c('p',{staticClass:"text-center title-onboard"},[_vm._v("Ingresa el correo al que quieres enviar el link")]),_c('form',{staticClass:"card-form",on:{"submit":function($event){$event.preventDefault();return _vm.validarForm.apply(null, arguments)}}},[_c('div',{staticClass:"email-inputs"},[_c('InputGroup',{attrs:{"invalid":_vm.$v.form.email.$error,"messages":{
          'El correo es requerido': _vm.$v.form.email.$error && !_vm.$v.form.email.required,
          'El correo no es válido': _vm.$v.form.email.$error && !_vm.$v.form.email.email,
        }},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('IconeEmail')]},proxy:true}]),model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.email"}}),_c('label',[_vm._v("Confirma el correo")]),_c('InputGroup',{attrs:{"invalid":_vm.$v.form.emailConfirmacao.$error,"messages":{
          'El correo es requerido': _vm.$v.form.emailConfirmacao.$error && !_vm.$v.form.emailConfirmacao.required,
          'Los correos no coinciden': _vm.$v.form.emailConfirmacao.$error && !_vm.$v.form.emailConfirmacao.email,
        }},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('IconeEmail')]},proxy:true}]),model:{value:(_vm.form.emailConfirmacao),callback:function ($$v) {_vm.$set(_vm.form, "emailConfirmacao", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.emailConfirmacao"}}),(_vm.$v.form.emailConfirmacao)?_c('small'):_vm._e()],1),(_vm.limite)?_c('p',{staticClass:"error"},[_vm._v("Has superado el número de envíos permitidos")]):_vm._e(),_c('div',{staticClass:"termos-condicoes"},[_c('InputCheckbox',{model:{value:(_vm.form.aceitoTermosCondicoes),callback:function ($$v) {_vm.$set(_vm.form, "aceitoTermosCondicoes", $$v)},expression:"form.aceitoTermosCondicoes"}}),_c('p',[_vm._v(" Acepto los Términos y Condiciones y la Política de Datos Personales, declaro que los he leído en su totalidad y que estoy de acuredo con su contenido. ")])],1),_c('div',{staticClass:"alerta"},[_c('p',[_vm._v(" Recuerda que el correo es un dato muy importante para estar en contacto con el cliente, asegúrate de que sea correcto ")])]),_c('ButtonContinue',{attrs:{"text":"Continuar","disable":_vm.$v.form.aceitoTermosCondicoes.$invalid || _vm.limite}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }