<template>
  <b-card no-body class="sm-card-white">
    <b-card-body class="pb-3">
      <b-row>
        <b-col class="title-l"> Préstamo </b-col>
        <b-col class="title-r">{{ currentApplication.requestedValueFmt }}</b-col>
      </b-row>
      <b-row>
        <b-col>
          <img src="@/assets/icons/cloud-blue-larg.svg" alt="heade-image" class="img-cloud-blue" />
          <p class="img-msg-sign">
            Paga tu cuota antes del <b>{{ dataCamputed }}</b> cada mes
          </p>
        </b-col>
      </b-row>
      <hr class="hr-orange" />
      <b-row>
        <b-col class="txt-l">Número de cuotas </b-col>
        <b-col class="txt-r">
          {{ currentApplication.installmentsNumber }}
          <img
            src="@/assets/icons/arrow-blue.svg"
            alt="heade-image"
            class="arrow-blue ml-2 mt-1"
            :class="{ 'arrow-blue-up': viewMoreInfo }"
            @click="viewMoreInfo = !viewMoreInfo"
          />
        </b-col>
      </b-row>
      <b-row class="mt-1" v-if="viewMoreInfo">
        <b-col class="txt-l">Seguro Mensual </b-col>
        <b-col class="txt-r">{{ currentApplication.offer.feesDueFmt }}</b-col>
      </b-row>
      <b-row class="mt-1" v-if="viewMoreInfo">
        <b-col class="txt-l">Tasa efectiva anual (TEA)</b-col>
        <b-col class="txt-r">{{ currentApplication.offer.interestCETYearlyFmt }}</b-col>
      </b-row>
      <hr class="hr-gray" />

      <b-row class="mt-4">
        <b-col>
          <b-img src="~@/assets/images/email.png" alt="Email" />
        </b-col>
        <b-col cols="8" class="txt-email">
          Recibirás la información de tu solicitud de crédito y extracto en tu correo electrónico
        </b-col>
      </b-row>
      <b-row class="txt-info mt-3">
        <b-col>
          <a href="https://www.movistarmoney.co/">Ingresa a tu cuenta </a>desde nuestra página www.movistarmoney.co,
          para gestionar tus pagos y ver el detalle de tu crédito.
        </b-col>
      </b-row>
      <ModalFinishLibre />
      <ModalFinishOpinion newUrlCallback="https://www.movistarmoney.co/" />
      <ButtonContinue
        class="mt-4"
        text="Cerrar"
        origin="Libre"
        @click="openModal('modal-finish')"
        :disable="desabilitarBotao"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import store from '@/store'
import { DESTROY_TOKEN } from '@/store/actions.type'
import ButtonContinue from '@/components/buttons/ButtonContinue.vue'
import { mapGetters } from 'vuex'
import { gtmDataLayerTrackEvent, gtmDataLayerCustom } from '@/helpers/gtmHelper'
import ModalFinishLibre from '@/components/modal/ModalFinishLibre.vue'
import ModalFinishOpinion from '@/components/modal/ModalFinishOpinion.vue'

export default {
  name: 'ResumoLibreCard',
  components: { ButtonContinue, ModalFinishOpinion, ModalFinishLibre },
  data() {
    return {
      desabilitarBotao: false,
      viewMoreInfo: false,
    }
  },
  mounted() {
    gtmDataLayerCustom(window.dataLayer, {
      event: 'trackTransaction',
      compra: {
        id: this.currentApplication.applicationId,
        revenue: this.currentApplication.offer.id,
      },
      productos: [
        {
          name: this.currentApplication.requestedValueFmt,
          id: this.currentApplication.installmentsNumber,
          price: this.currentApplication.offer.feesDueFmt,
          brand: 'prestamo libre inversión',
          category: 'movistar money',
          variant: '0',
          quantity: '1',
          coupon: '__CUPON__',
        },
      ],
      userIdMoney: this.CryptoJS.SHA1(this.currentApplication.mobile).toString(),
    })
  },
  methods: {
    openModal(modalId) {
      this.desabilitarBotao = true
      this.$bvModal.show(modalId)
      gtmDataLayerTrackEvent(
        window.dataLayer,
        'trackEvent',
        'movistar money',
        'click::thank you page::paso 6',
        'cerrar',
        this.CryptoJS.SHA1(this.currentApplication.mobile).toString()
      )
    },
  },
  beforeDestroy() {
    if (this.$route.path.includes('on-boarding')) {
      store.dispatch(DESTROY_TOKEN)
    }
  },
  computed: {
    dataCamputed() {
      if (this.currentApplication.paymentCycle === 5) return '0' + this.currentApplication.paymentCycle
      else return this.currentApplication.paymentCycle
    },
    ...mapGetters({
      currentApplication: 'currentApplication',
    }),
  },
}
</script>

<style scoped>
a {
  color: black;
  font-weight: bold;
  text-decoration: underline;
}
a:hover {
  color: black;
}
.img-cloud-blue {
  position: absolute;
  right: -0.4rem;
  top: -4rem;
}

.img-msg-sign {
  position: absolute;
  right: 0.3rem;
  top: -3.5rem;
  font-size: 14px;
  color: #fff;
}
.sm-card-white {
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  /* border: solid 3px #a13ea1; */
  margin: 0 auto;
  max-width: 30rem;
}
.hr-orange {
  border: 0.01px solid #ec624b;
}
.hr-gray {
  border: 0.1px solid #d3d4d3;
}
.title-l {
  font-family: Telefonica;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--pine-green);
}
.title-r {
  font-family: Telefonica;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: var(--pine-green);
}
.txt-l {
  font-family: Telefonica;
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #86888c;
}
.txt-r {
  font-family: Telefonica;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #50535a;
}
.txt-email {
  padding: 0;
  font-family: Telefonica;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #313235;
}
.txt-info {
  font-family: Telefonica;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--pine-green);
}
.arrow-blue {
  position: relative;
  top: -3px;
  margin-left: 10px;
}
.arrow-blue-up {
  transform: rotate(180deg);
}
</style>
