import { API_URL } from './config'
import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { errorHandler } from '../helpers/apiHandler'

const apiCaptcha = {
  init() {
    Vue.use(VueAxios, axios)
    Vue.axios.defaults.baseURL = API_URL
  },

  validacaoCaptcha(captchaToken) {
    return Vue.axios.get(`user/captcha/${captchaToken}`).catch((error) => {
      errorHandler(error.response)
    })
  },
}

export default apiCaptcha
