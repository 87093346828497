import Vue from 'vue'

const ApiBuscador = {
  postEnviarEmailValidacao(request) {
    return Vue.axios.post('publicdata/application/prevalidator/email', request)
  },
  postPreValidadorRequestBasic(request) {
    return Vue.axios.post('publicdata/application/prevalidator/basic', request)
  },
  postPreValidadorRequestFull(request) {
    return Vue.axios.post('publicdata/application/prevalidator/full', request)
  },
  validarEmail(hash) {
    return Vue.axios.post(`publicdata/application/prevalidator/validate-email/${hash}`)
  },
  enviarNovoPin(request) {
    return Vue.axios.post('publicdata/application/prevalidator/new-pin', request)
  },
  validarPin(request) {
    return Vue.axios.post('publicdata/application/prevalidator/validate-pin', request)
  },
}

export default ApiBuscador
