<template>
  <div class="detalhes-telefone">
    <section class="header">
      <div class="alldevices">
        <ul class="no-bullets">
          <li v-for="device in phoneDetailsAssisted.deviceModel" id="devices" class="devices" :key="device">
            {{ device }}
          </li>
        </ul>
        <img @click="showDevices()" id="arrow" src="@/assets/icons/arrow-down.svg" class="arrow-down" />
      </div>
      <p class="valor">{{ phoneDetailsAssisted.price }}</p>
    </section>

    <div class="body">
      <section v-if="currentApplication.advancedPayment" class="container-more-information-top container-total-cueta">
        <h1 class="legend-value text-bold mb-0">Pago inicial equipo:</h1>
        <span class="value-total-cueta" v-text="currentApplication.advancedPaymentFmt" />
      </section>
      <section v-if="currentApplication.guaranteePayment" class="container-more-information-top container-total-cueta">
        <div class="d-flex align-items-center">
          <h1 class="legend-value text-bold mb-0 mr-2">Pago único fianza</h1>
          <b-button class="p-0" variant="blank" v-b-toggle.fianca-details>
            <b-img src="@/assets/icons/arrow-down.svg"></b-img>
          </b-button>
        </div>
        <div class="d-flex align-items-center">
          <b-img
            class="mr-2"
            src="@/assets/icons/warning-blue.svg"
            alt="Info fianca"
            width="18"
            v-b-tooltip.hover.topleft="{
              title: `Valor total de la fianza`,
            }"
          />
          <span class="value-total-cueta" v-text="currentApplication.guaranteePaymentFmt" />
        </div>
      </section>
      <b-collapse id="fianca-details" class="fianca-details">
        <section class="container-more-information">
          <h1 class="legend-value">Fianza</h1>
          <span class="content-value legend-value" v-text="guaranteePaymentFmt" />
        </section>
        <section class="container-more-information">
          <h1 class="legend-value">IVA fianza</h1>
          <span class="content-value legend-value" v-text="calculatedGuaranteePaymentIvaFmt" />
        </section>
      </b-collapse>
      <section class="container-more-information-top container-total-cueta">
        <div class="d-flex align-items-center">
          <h1 class="legend-value text-bold mb-0 mr-2">Total cuota / mes</h1>
        </div>
        <div class="d-flex align-items-center">
          <span class="value-total-cueta" v-text="footerInformationAssisted.totalFmt" />
          <span class="ml-1" v-if="footerInformationAssisted.taxRate > 0">Con IVA</span>
          <b-button class="ml-2 p-0" variant="blank" v-b-toggle.factura-details>
            <b-img src="@/assets/icons/arrow-down.svg"></b-img>
          </b-button>
        </div>
      </section>
      <b-collapse id="factura-details" class="factura-details">
        <section class="container-more-information">
          <h1 class="legend-value">Cuota capital</h1>
          <span class="content-value legend-value">{{ footerInformationAssisted.principalDueFmt }}</span>
        </section>

        <section class="container-more-information">
          <h1 class="legend-value">Intereses</h1>
          <span class="content-value legend-value">{{ footerInformationAssisted.interestDueFmt }} </span>
        </section>

        <section class="container-more-information">
          <h1 class="legend-value">IVA intereses</h1>
          <span class="content-value legend-value">{{ footerInformationAssisted.taxInterestDueFmt }} </span>
        </section>

        <section class="container-more-information">
          <h1 class="legend-value">Cargo administrativo</h1>
          <span class="content-value legend-value">{{ footerInformationAssisted.feesDueFmt }}</span>
        </section>

        <section class="container-more-information">
          <h1 class="legend-value">IVA cargo administrativo</h1>
          <span class="content-value legend-value">{{ footerInformationAssisted.taxFeesDueFmt }}</span>
        </section>

        <section class="container-more-information mb-3">
          <h1 class="legend-value">Total cuota</h1>
          <span class="content-value legend-value">{{ footerInformationAssisted.totalFmt }}</span>
        </section>

        <section class="container-more-information">
          <h1 class="legend-value text-bold">Valor a financiar</h1>
          <span class="content-value legend-value text-bold">{{ currentApplication.requestedValueFmt }}</span>
        </section>

        <section class="container-more-information">
          <h1 class="legend-value">Tasa efectiva anual (TEA)</h1>
          <span class="content-value legend-value">{{ footerInformationAssisted.interestCETYearlyFmt }}</span>
        </section>

        <section class="container-more-information">
          <h1 class="legend-value">Tasa nominal mensual</h1>
          <span class="content-value legend-value">{{ footerInformationAssisted.interestCETFmt }}</span>
        </section>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'DetalhesTelefoneAssistido',
  props: {
    footerInformationAssisted: {},
    phoneDetailsAssisted: {},
  },
  data() {
    return {
      moreInformation: false,
      allDevices: false,
    }
  },
  methods: {
    moreInformationArrow() {
      this.moreInformation = !this.moreInformation
    },
    showDevices() {
      document.getElementById('arrow').className = this.allDevices ? 'arrow-down' : 'arrow-up'
      document
        .querySelectorAll('[id=devices]')
        .forEach((element) => (element.className = this.allDevices ? 'devices' : 'devices-open'))
      this.allDevices = !this.allDevices
    },
  },
  computed: {
    ...mapGetters({
      currentApplication: 'currentApplication',
      guaranteePaymentFmt: 'guaranteePaymentFmt',
      calculatedGuaranteePaymentIvaFmt: 'calculatedGuaranteePaymentIvaFmt',
    }),
  },
}
</script>

<style lang="scss" scoped>
.detalhes-telefone {
  position: relative;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px 10px 0 0;
    padding: 0.5rem 1rem;
    background-color: var(--movistar-blue);
    color: var(--white);
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    .alldevices {
      display: flex;
      border-right: 1px solid #d3d4d3;
      padding-right: 1rem;
      margin-right: 10px;
    }
    .valor {
      font-weight: bold;
      font-size: 16px;
      margin-bottom: 0px;
    }
  }

  .body {
    padding: 1rem 0;
  }
}

.legend-text-gray {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  margin-left: 10px;
  font-size: 13.2px;
  flex-direction: row;
  color: #86888c;
}

.legend-text-gray-bold {
  font-weight: bold;
  color: #0b2739;
  margin: 0 5px;
  display: inline;
}

.value-total-cueta {
  font-weight: bold;
  font-size: 1rem;
}

.legend-value {
  font-size: 14px;
  color: #86888c;
}

.container-more-information-top {
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
}

.container-more-information {
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
  height: 17px;
}
.container-total-cueta {
  margin-bottom: 7px;
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.devices {
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.devices-open {
  width: 150px;
  overflow: auto;
  word-wrap: break-word;
  height: fit-content;
}

ul.no-bullets {
  list-style-type: none;
  text-align: left;
  font-size: 14px;
  margin: 0;
  padding: 0;
  width: 170px;
}
</style>
