<template>
  <div>
    <div>
      <b-modal id="modal-info-inicio" centered header-border-variant="white" footer-border-variant="white" hide-footer>
        <template #modal-header>
          <b-button align-self="end" variant="blank" @click="fecharModal('modal-info-inicio')">
            <b-img src="@/assets/icons/cancel.svg" alt="Spinner" />
          </b-button>
        </template>
        <p class="my-4 txt-modal">Te recomendamos realizar este paso desde tu celular</p>
        <div><b-img class="imagem-centro" src="@/assets/images/veridas-info.svg" alt="Spinner" /></div>
      </b-modal>
      <Modal id="modal-mensagem" :message="mensagemModal">
        <template #icon>
          <img src="@/assets/icons/negado.svg" alt="Error icon" width="43px" />
        </template>
        <template #button>
          <Button class="button-voltar continue-button continue-button-text" @click="fecharModalInvalido()">
            <span>Cerrar</span>
          </Button>
        </template>
      </Modal>
    </div>
    <iframe
      title="veridas assistido"
      style="display: block; border: none; height: 100vh; width: 100vw"
      id="XpressID-iframe"
      allow="camera; microphone; geolocation;"
      :src="url"
      allowfullscreen
    >
    </iframe>
  </div>
</template>

<script>
import {
  VERIDAS_ASSISTIDO_VALIDAR_URL,
  VERIDAS_ASSISTIDO_INVALIDAR_URL,
  BUSCAR_PARAMETROS,
  TOGGLE_OVERLAY,
} from '@/store/actions.type'
import Modal from '@/components/PreValidacao/Modal.vue'

export default {
  name: 'VeridasCliente',
  components: { Modal },
  watch: {
    veridasAccessToken: {
      handler() {
        this.updateUrl()
      },
    },
  },
  data() {
    return {
      modalInfo: true,
      validacaoLink: null,
      url: '',
      veridasUrl: '',
      mensagemModal: null,
    }
  },
  created() {
    this.$store.dispatch(TOGGLE_OVERLAY, true)
    window.addEventListener('message', this.receiveMessage, false)
  },

  async mounted() {
    this.validacaoLink = await this.$store.dispatch(VERIDAS_ASSISTIDO_VALIDAR_URL, this.$route.params)
    if (this.validacaoLink.urlValida) {
      this.$bvModal.show('modal-info-inicio')
      this.veridasUrl = await this.$store.dispatch(BUSCAR_PARAMETROS, 'veridasXpressIdUrl')
      this.url = this.veridasUrl.replace('%s', this.$route.params.tokenVeridas)
    } else {
      this.mensagemModal = this.validacaoLink.erro.data.message
      this.$bvModal.show('modal-mensagem')
      this.$store.dispatch(TOGGLE_OVERLAY, false)
    }
  },
  methods: {
    fecharModalInvalido() {
      this.$bvModal.hide('modal-mensagem')
      window.close()
    },
    fecharModal(value) {
      this.$bvModal.hide(value)
      this.modalInfo = false
    },
    receiveMessage(event) {
      if (event.data.code === 'ProcessEvents') this.$store.dispatch(TOGGLE_OVERLAY, false)
      if (event.origin.includes('xpressid') && event.data.code == 'ProcessCompleted') {
        this.$store.dispatch(VERIDAS_ASSISTIDO_INVALIDAR_URL, this.$route.params)
      }
    },
  },
}
</script>
<style lang="scss">
.button-voltar {
  width: 40%;
  background-color: var(--movistar-blue);
  color: var(--white);
  .arrow {
    margin-right: 12px;
    transform: rotate(90deg);
  }
}
.modal-content {
  border-radius: 10px !important;
  background-color: #fff !important;
}
.txt-modal {
  font-family: Telefonica;
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}
.imagem-centro {
  align-items: center;
}
.modal-body {
  text-align: center !important;
}
.modal-header {
  flex-direction: row-reverse;
}
</style>
