import Vue from 'vue'
import Router from 'vue-router'

// onboard flow
import OTP from '@/views/OTP.vue'
import Anticipo from '@/views/Anticipo.vue'
import Offer from '@/views/Offer.vue'
import Form from '@/views/Form.vue'
import Identity from '@/views/Identity.vue'
import Veridas from '@/views/Veridas.vue'
import Evidente from '@/views/Evidente.vue'
import Direction from '@/views/Direction.vue'
import DirectionLibre from '@/views/DirectionLibre.vue'
import Contract from '@/views/Contract.vue'
import ContratoLibre from '@/views/ContratoLibre.vue'
import Resumo from '@/views/Resumo.vue'
import Opiniao from '@/views/Opiniao.vue'
// onboard errors
import LoanInProgress from '@/views/error/LoanInProgress.vue'
import ManyAttempts from '@/views/error/ManyAttempts.vue'
import ManyAttemptsResendLink from '@/views/error/ManyAttemptsResendLink.vue'
import ManyAttemptsResendLinkFianca from '@/views/error/ManyAttemptsResendLinkFianca.vue'
import NotFoundResendLink from '@/views/error/NotFoundResendLink.vue'
import CantProcessRequest from '@/views/error/CantProcessRequest.vue'
import ServiceUnavailable from '@/views/error/ServiceUnavailable.vue'
import ExceededSessionTime from '@/views/error/ExceededSessionTime.vue'
import MensagemReprovacao from '@/views/error/MensagemReprovacao.vue'
import ExpiredEmail from '@/views/error/ExpiredEmail.vue'

// self-manage

import Login from '@/views/SelfManage/Login.vue'
import LoginOTP from '@/views/SelfManage/LoginOTP.vue'
import LoginCreatePassword from '@/views/SelfManage/LoginCreatePassword.vue'
import LoginPassword from '@/views/SelfManage/LoginPassword.vue'
import ResetPassword from '@/views/SelfManage/ResetPassword.vue'
import Home from '@/views/SelfManage/Home.vue'
import MyCredits from '@/views/SelfManage/MyCredits.vue'
import Payment from '@/views/SelfManage/Payment.vue'
import EpaycoResponse from '@/views/SelfManage/EpaycoResponse.vue'

// Offer Simulation
import HomeOfferSimulation from '@/views/OfferSimultaion/HomeOfferSimulation.vue'
import DadosBancarios from '@/views/BankData.vue'
import CounterOfferSimulation from '@/views/OfferSimultaion/CounterOfferSimulation.vue'
import ResumenContrato from '@/views/OfferSimultaion/ResumenContrato.vue'
import ResumoLibre from '@/views/ResumoLibre.vue'

//Assistido
import HomeAssistido from '@/views/Assistido/HomeAssistido.vue'
import AnticipoAssistido from '@/views/Assistido/AnticipoAssistido.vue'
import OfertaAssistido from '@/views/Assistido/OfertaAssistido.vue'
import EvidenteDataAssistido from '@/views/Assistido/EvidenteDataAssistido.vue'
import EvidenteValidarAssistido from '@/views/Assistido/EvidenteValidarAssistido.vue'
import ContratoAssistido from '@/views/Assistido/ContratoAssistido.vue'
import OTPAssistido from '@/views/Assistido/OTPAssistido.vue'
import ResumoAssistido from '@/views/Assistido/ResumoAssistido.vue'
import FinalAssistido from '@/views/Assistido/FinalAssistido.vue'
import SimuladorAssistido from '@/views/SimuladorAssistido.vue'
import VeridasCliente from '@/views/Assistido/VeridasCliente.vue'
import VeridasAssistido from '@/views/Assistido/VeridasAssistido.vue'

// Pagamento rápido
import AcessoRapidoForm from '@/views/AcessoRapido/AcessoRapidoForm.vue'
import PagamentoAcessoRapido from '@/views/AcessoRapido/PagamentoAcessoRapido.vue'
import AcessoRapidoEpaycoResponse from '@/views/AcessoRapido/AcessoRapidoEpaycoResponse.vue'

// Pagamento antecipado
import PagamentoAntecipado from '@/views/PagamentoAntecipado/PagamentoAntecipado.vue'
import PagamentoAntecipadoResponse from '@/views/PagamentoAntecipado/PagamentoAntecipadoResponse.vue'
import ReenviarLinkPagamento from '@/views/PagamentoAntecipado/ReenviarLinkPagamento.vue'
import ReenviarLinkPagamentoAgradecimento from '@/views/PagamentoAntecipado/ReenviarLinkPagamentoAgradecimento.vue'

import PagamentoFianca from '@/views/PagamentoFianca/PagamentoFianca.vue'
import PagamentoFiancaResponse from '@/views/PagamentoFianca/PagamentoFiancaResponse.vue'

// Pre Validação
import PreValidacao from '@/views/PreValidacao/PreValidacao.vue'
import PreValidacaoOTP from '@/views/PreValidacao/PreValidacaoOTP.vue'
import PreValidacaoResultado from '@/views/PreValidacao/PreValidacaoResultado.vue'
import PreValidacaoResultadoAssistido from '@/views/PreValidacao/Assistido/PreValidacaoResultado.vue'
import PreValidacaoValidarEmail from '@/views/PreValidacao/PreValidacaoValidarEmail.vue'
import PreValidacaoReprovacao from '@/views/PreValidacao/PreValidacaoReprovacao.vue'

//Fianca
import ReenvioLinkFianca from '@/views/fianca/ReenvioLinkFianca.vue'
import ReenviarLinkFiancaAgradecimento from '@/views/fianca/ReenviarLinkFiancaAgradecimento.vue'

import { jwt } from '@/helpers/jwt.service'

import UUID from 'vue-uuid'
Vue.use(UUID)

import VueGtm from '@gtm-support/vue2-gtm'

import LoadScript from 'vue-plugin-load-script'
Vue.use(LoadScript)

Vue.config.productionTip = false

Vue.use(Router)

Vue.use(VueGtm, {
  id: process.env.VUE_APP_TAGMANAGER, // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
  /*queryParams: {
    // Add URL query string when loading gtm.js with GTM ID (required when using custom environments)
    gtm_auth: 'AB7cDEf3GHIjkl-MnOP8qr',
    gtm_preview: 'env-4',
    gtm_cookies_win: 'x'
  },*/
  defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
  compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
  nonce: '2946c8f19d', // Will add `nonce` to the script tag
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: false, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: null, //Router, // Pass the router instance to automatically sync with router (optional)
  ignoredViews: null, //['homepage'], // Don't trigger events for specified router names (optional)
  trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
})

const ifAuthenticated = (to, from, next) => {
  if (jwt.getIsAuthenticated()) {
    next()
    return
  } else next('/')
}

const selfManageIfAuthenticated = (to, from, next) => {
  if (jwt.getIsAuthenticated()) {
    next()
    return
  } else next('/login')
}

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      alias: '/form',
      name: 'Form',
      component: Form,
    },
    {
      path: '/on-boarding/otp',
      name: 'OTP',
      component: OTP,
    },
    {
      path: '/home-offer-simulation',
      name: 'HomeOfferSimulation',
      component: HomeOfferSimulation,
    },
    {
      path: '/pre-validation',
      name: 'PreValidacao',
      component: PreValidacao,
    },
    {
      path: '/pre-validation/otp',
      name: 'PreValidacaoOTP',
      component: PreValidacaoOTP,
    },
    {
      path: '/pre-validation/result',
      name: 'PreValidacaoResultado',
      component: PreValidacaoResultado,
    },
    {
      path: '/pre-validation/assisted/result',
      name: 'PreValidacaoResultadoAssistido',
      component: PreValidacaoResultadoAssistido,
    },
    {
      path: '/pre-validation/reproved-reasons',
      name: 'PreValidacaoReprovacao',
      component: PreValidacaoReprovacao,
    },
    {
      path: '/pre-validation/validate-email/:hash',
      name: 'PreValidacaoValidarEmail',
      component: PreValidacaoValidarEmail,
    },
    {
      path: '/bank-data',
      name: 'DadosBancarios',
      component: DadosBancarios,
      beforeEnter: ifAuthenticated,
      alias: ['/self-manage/bank-data', '/on-boarding/bank-data'],
    },
    {
      path: '/counter-offer-simulation',
      name: 'CounterOfferSimulation',
      component: CounterOfferSimulation,
      beforeEnter: ifAuthenticated,
      alias: ['/self-manage/counter-offer-simulation', '/on-boarding/counter-offer-simulation'],
    },
    {
      path: '/offer-equipo',
      name: 'OfferFinter',
      component: Offer,
      beforeEnter: ifAuthenticated,
      alias: ['/self-manage/offer-equipo', '/on-boarding/offer-equipo'],
    },
    {
      path: '/pre-offer',
      name: 'Anticipo',
      component: Anticipo,
      beforeEnter: ifAuthenticated,
      alias: ['/self-manage/pre-offer', '/on-boarding/pre-offer'],
    },
    {
      path: '/identity',
      name: 'Identity',
      component: Identity,
      beforeEnter: ifAuthenticated,
      alias: ['/self-manage/identity', '/on-boarding/identity'],
    },
    {
      path: '/veridas',
      name: 'Veridas',
      component: Veridas,
      beforeEnter: ifAuthenticated,
      alias: ['/self-manage/veridas', '/on-boarding/veridas'],
    },
    {
      path: '/evidente',
      name: 'Evidente',
      component: Evidente,
      beforeEnter: ifAuthenticated,
      alias: ['/self-manage/evidente', '/on-boarding/evidente'],
    },
    {
      path: '/direction',
      name: 'Direction',
      component: Direction,
      beforeEnter: ifAuthenticated,
      alias: ['/self-manage/direction', '/on-boarding/direction'],
    },
    {
      path: '/directionLibre',
      name: 'DirectionLibre',
      component: DirectionLibre,
      beforeEnter: ifAuthenticated,
      alias: ['/self-manage/directionLibre', '/on-boarding/directionLibre'],
    },
    {
      path: '/contract',
      name: 'Contract',
      component: Contract,
      beforeEnter: ifAuthenticated,
      alias: ['/self-manage/contract', '/on-boarding/contract'],
    },
    {
      path: '/contractLibre',
      name: 'ContratoLibre',
      component: ContratoLibre,
      beforeEnter: ifAuthenticated,
      alias: ['/self-manage/contractLibre', '/on-boarding/contractLibre'],
    },
    {
      path: '/detailsLibre',
      name: 'ResumoLibre',
      component: ResumoLibre,
      beforeEnter: ifAuthenticated,
      alias: ['/self-manage/detailsLibre', '/on-boarding/detailsLibre'],
    },
    {
      path: '/details',
      name: 'Details',
      component: Resumo,
      beforeEnter: ifAuthenticated,
      alias: ['/self-manage/details', '/on-boarding/details'],
    },
    {
      path: '/opinion',
      name: 'Opiniao',
      component: Opiniao,
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/resumen-contato',
      name: 'ResumenContrato',
      component: ResumenContrato,
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
    },
    {
      path: '/login-otp',
      name: 'LoginOTP',
      component: LoginOTP,
    },
    {
      path: '/create-password',
      name: 'LoginCreatePassword',
      beforeEnter: selfManageIfAuthenticated,
      component: LoginCreatePassword,
    },
    {
      path: '/reset-forgotten-password/:idClient/:idProposal/:idHash',
      name: 'ResetPassword',
      component: ResetPassword,
    },
    {
      path: '/login-password',
      name: 'LoginPassword',
      component: LoginPassword,
    },
    {
      path: '/self-manage',
      name: 'Home',
      component: Home,
      beforeEnter: selfManageIfAuthenticated,
    },
    {
      path: '/self-manage/credits/:idCredito',
      name: 'MyCredits',
      component: MyCredits,
      beforeEnter: selfManageIfAuthenticated,
    },
    {
      path: '/self-manage/payment/:idCredito',
      name: 'Payment',
      component: Payment,
      beforeEnter: selfManageIfAuthenticated,
    },
    {
      path: '/self-manage/payment/:idCredito/response/*',
      name: 'EpaycoResponse',
      component: EpaycoResponse,
      beforeEnter: selfManageIfAuthenticated,
    },
    {
      path: '/loan-progress',
      name: 'LoanInProgress',
      component: LoanInProgress,
    },
    {
      path: '/many-attempts',
      name: 'ManyAttempts',
      component: ManyAttempts,
    },
    {
      path: '/resend-link/many-attempts',
      name: 'ManyAttemptsResendLink',
      component: ManyAttemptsResendLink,
    },
    {
      path: '/process-request',
      name: 'CantProcessRequest',
      component: CantProcessRequest,
      alias: ['/self-manage/process-request', '/on-boarding/process-request'],
    },
    {
      path: '/expired-email',
      name: 'ExpiredEmail',
      component: ExpiredEmail,
      alias: ['/self-manage/expired-email', '/on-boarding/expired-email'],
    },
    {
      path: '/unavailable',
      name: 'ServiceUnavailable',
      component: ServiceUnavailable,
    },
    {
      path: '/exceeded-session-time',
      name: 'ExceededSessionTime',
      component: ExceededSessionTime,
    },
    {
      path: '/assisted/home',
      name: 'HomeAssistido',
      component: HomeAssistido,
    },
    {
      path: '/assisted/pre-offer',
      name: 'PreOfertaAssistido',
      component: AnticipoAssistido,
    },
    {
      path: '/assisted/offer',
      name: 'OfertaAssistido',
      component: OfertaAssistido,
    },
    {
      path: '/veridas/assisted/:idProposta/:idCliente/:hashEmail/:tokenVeridas',
      name: 'VeridasCliente',
      component: VeridasCliente,
    },
    {
      path: '/assisted/veridas',
      name: 'VeridasAssistido',
      component: VeridasAssistido,
    },
    {
      path: '/assisted/evidente-date',
      name: 'EvidenteDataAssistido',
      component: EvidenteDataAssistido,
    },
    {
      path: '/assisted/evidente-validate',
      name: 'EvidenteValidarAssistido',
      component: EvidenteValidarAssistido,
    },
    {
      path: '/assisted/contract',
      name: 'ContratoAssistido',
      component: ContratoAssistido,
    },
    {
      path: '/assisted/otp',
      name: 'OTPAssistido',
      component: OTPAssistido,
    },
    {
      path: '/assisted/resume',
      name: 'ResumoAssistido',
      component: ResumoAssistido,
    },
    {
      path: '/assisted/end',
      name: 'FinalAssistido',
      component: FinalAssistido,
    },
    {
      path: '/assisted/simulator',
      name: 'SimuladorAssistido',
      component: SimuladorAssistido,
    },
    {
      path: '/fast-payment',
      name: 'AcessoRapidoForm',
      component: AcessoRapidoForm,
    },
    {
      path: '/fast-payment/payment/:idCredito/response/*',
      name: 'AcessoRapidoEpaycoResponse',
      component: AcessoRapidoEpaycoResponse,
    },
    {
      path: '/fast-payment/payment',
      name: 'PagamentoAcessoRapido',
      component: PagamentoAcessoRapido,
    },
    {
      path: '/pagofianzamovistarco',
      name: 'ReenvioLinkFianca',
      component: ReenvioLinkFianca,
    },
    {
      path: '/pagofianzamovistarco/many-attempts',
      name: 'ManyAttemptsResendLinkFianca',
      component: ManyAttemptsResendLinkFianca,
    },
    {
      path: '/pagofianzamovistarco/not-found',
      name: 'NotFoundResendLink',
      component: NotFoundResendLink,
    },
    {
      path: '/pagofianzamovistarco/thank-you',
      name: 'ReenviarLinkFiancaAgradecimento',
      component: ReenviarLinkFiancaAgradecimento,
    },
    {
      path: '/advanced-payment/:idProposta/response',
      name: 'PagamentoAntecipadoResponse',
      component: PagamentoAntecipadoResponse,
    },
    {
      path: '/advanced-payment/:idProposta/:hash',
      name: 'PagamentoAntecipado',
      component: PagamentoAntecipado,
    },
    {
      path: '/guarantee-payment/:idProposta/:hash',
      name: 'PagamentoFianca',
      component: PagamentoFianca,
    },
    {
      path: '/guarantee-payment-response/:idProposta/response',
      name: 'PagamentoFiancaResponse',
      component: PagamentoFiancaResponse,
    },
    {
      path: '/pagoinicialequipomovistarco',
      name: 'ReenviarLinkPagamento',
      component: ReenviarLinkPagamento,
    },
    {
      path: '/pagoinicialequipomovistarco/thank-you',
      name: 'ReenviarLinkPagamentoAgradecimento',
      component: ReenviarLinkPagamentoAgradecimento,
    },
    {
      path: '/error-response',
      name: 'MensagemReprovacao',
      component: MensagemReprovacao,
      meta: { auth: true, updateMode: true },
      props: true,
    },
    {
      path: '/:catchAll(.*)',
      component: Login,
    },
  ],
})
