import Vue from 'vue'
import Vuex from 'vuex'

import base from '@/store/base.module'
import auth from '@/store/auth.module'
import evidente from '@/store/evidente.module'
import address from '@/store/address.module'
import offer from '@/store/offer.module'
import selfManage from '@/store/self-manage.module'
import epaycoModule from '@/store/epayco-module'
import fastPaymentModule from '@/store/fast-payment.module'
import offerSimulationModule from '@/store/offer-simulation.module'
import anticipoModule from '@/store/anticipo.module'
import prevalidacaoModule from '@/store/prevalidacao.module'
import parametrosModule from '@/store/parametros.module'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    base,
    auth,
    evidente,
    address,
    offer,
    selfManage,
    epaycoModule,
    fastPaymentModule,
    offerSimulationModule,
    anticipoModule,
    prevalidacaoModule,
    parametrosModule,
  },
})
