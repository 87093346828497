<template>
  <div class="bg-sm">
    <SMNavbar />
    <ApplyPaymentHeader />
    <ApplyPayment />
    <PaymentTypes />
    <hr class="no-margin" />
    <Footer />
  </div>
</template>

<script>
import SMNavbar from '../../components/SelfManage/SMNavbar'
import ApplyPaymentHeader from '../../components/SelfManage/ApplyPaymentHeader'
import ApplyPayment from '../../components/SelfManage/ApplyPayment'
import Footer from '../../components/SelfManage/Footer'
import store from '../../store'
import { SELF_MANAGE_GET_APPLICATION_MAMBU, SELF_MANAGE_GET_APPLICATION_PAYMENT_PLAN } from '../../store/actions.type'
import PaymentTypes from '../../components/SelfManage/PaymentTypes'

export default {
  name: 'Payment',
  components: {
    PaymentTypes,
    ApplyPayment,
    Footer,
    ApplyPaymentHeader,
    SMNavbar,
  },
  mounted() {
    store.dispatch(SELF_MANAGE_GET_APPLICATION_MAMBU, this.$route.params.idCredito)
    store.dispatch(SELF_MANAGE_GET_APPLICATION_PAYMENT_PLAN, this.$route.params.idCredito)
  },
}
</script>
