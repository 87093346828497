<template>
  <div class="body-box-bank" v-bind:class="[viewMoreInfo ? 'openBox' : 'closeBox']">
    <div class="container-bank-data">
      <div class="header-text-title">
        <b-row>
          <b-col>
            <h1 class="text-title">
              Te llamaremos para confirmar tu desembolso<span v-if="diaPagamento"
                >, recuerda que tu fecha de pago será el {{ diaPagamento }} de cada mes</span
              >
            </h1>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <img src="@/assets/icons/cloud-blue.svg" alt="heade-image" class="img-cloud-blue" />
            <img src="@/assets/icons/dollar-sign.svg" alt="heade-image" class="img-dollar-sign" />
          </b-col>
        </b-row>
      </div>
      <div class="container-bank-info" v-if="currentApplication">
        <h1 class="content-value-bank">
          Préstamo:
          <span class="value-interent">{{ currentApplication.requestedValueFmt }}</span>
        </h1>
        <h2 class="content-installment">- {{ currentApplication.installmentsNumber }} cuotas</h2>
        <img
          src="@/assets/icons/arrow-blue.svg"
          alt="heade-image"
          class="arrow-blue"
          :class="{ 'arrow-blue-up': viewMoreInfo }"
          @click="viewInformation"
        />
      </div>
    </div>

    <div v-if="viewMoreInfo" class="more-information">
      <section class="container-more-information">
        <h1 class="legend-value">Cuota capital</h1>
        <span class="content-value legend-value">{{ currentApplication.offer.principalDueFmt }}</span>
      </section>
      <section class="container-more-information">
        <h1 class="legend-value">Intereses</h1>
        <span class="content-value legend-value">{{ currentApplication.offer.totalInterestDueFmt }}</span>
      </section>
      <section class="container-more-information">
        <h1 class="legend-value">Seguro</h1>
        <span class="content-value legend-value">{{ currentApplication.offer.feesDueFmt }}</span>
      </section>
      <section class="container-more-information">
        <h1 class="legend-value">Total cuota</h1>
        <span class="content-value legend-value">{{ currentApplication.offer.totalFmt }}</span>
      </section>

      <p class="footer-more-information">*Este es un valor aproximado de la composición de la cuota</p>
    </div>

    <section class="show-info-bank" v-if="edit && currentApplication.bankData" v-bind:class="{ borderViewMore: edit }">
      <h1 class="title-info-bank">
        Banco {{ currentApplication.bankData.bankName }} - {{ currentApplication.bankData.accountType }}
      </h1>
      <div class="edit-info-bank">
        <h2 class="title-edit-bank">Cuenta: {{ currentApplication.bankData.accountNumber }}</h2>
        <button class="btn-edit-info-bank" @click="editarDadosBancarios" :disabled="contractTerms === true">
          Editar
        </button>
      </div>
      <!-- <h1 class="title-info-bank">{{currentApplication.bankData.accountType}}</h1> -->
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { CHANGE_STATUS } from '../../store/actions.type'
import store from '../../store'
import { gtmDataLayerTrackEvent } from '../../helpers/gtmHelper'

export default {
  name: 'BoxBankData',
  data() {
    return {
      viewMoreInfo: false,
    }
  },
  props: {
    edit: Boolean,
    diaPagamento: Number,
  },
  computed: {
    ...mapGetters({
      currentApplication: 'currentApplication',
      contractTerms: 'contractTerms',
    }),
  },
  methods: {
    viewInformation() {
      this.viewMoreInfo = !this.viewMoreInfo
      gtmDataLayerTrackEvent(
        window.dataLayer,
        'trackEvent',
        'movistar money',
        'click::verificación de contrato::paso 5',
        'ver información de crédito',
        this.CryptoJS.SHA1(this.currentApplication.mobile).toString()
      )
    },
    editarDadosBancarios() {
      if (this.contractTerms === true) return
      store.dispatch(CHANGE_STATUS, { status: 'BANK', origin: this.currentApplication.origin })
      gtmDataLayerTrackEvent(
        window.dataLayer,
        'trackEvent',
        'movistar money',
        'click::verificación de contrato::paso 5',
        'editar',
        this.CryptoJS.SHA1(this.currentApplication.mobile).toString()
      )
    },
  },
}
</script>

<style scoped>
.title-info-bank {
  font-size: 14px;
  text-align: left;
  color: #313235;
  margin-left: 9px;
  margin-bottom: 0;
  margin-top: 8px;
}

.borderViewMore {
  border-top: 1px solid #d3d4d3;
}

.title-edit-bank {
  font-size: 11.8px;
  text-align: left;
  color: #313235;
  margin-left: 9px;
  margin-bottom: 0;
}

.edit-info-bank {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn-edit-info-bank {
  width: 56px;
  height: 24px;
  background: #019df4;
  color: #ffffff;
  font-size: 12px;
  border-radius: 12px;
  border: none;
  margin: 5px;
}

.btn-edit-info-bank:disabled {
  background-color: rgba(83, 80, 80, 0.5);
  cursor: default;
}

.container-more-information {
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
  height: 17px;
}

.legend-value {
  font-size: 12px;
  color: #86888c;
}

.footer-more-information {
  font-size: 12px;
  text-align: center;
  color: #86888c;
}

.moreInformation {
  display: flex;
  flex-direction: row;
}

.container-bank-info {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
}

.arrow-blue {
  position: relative;
  top: -3px;
  margin-left: 10px;
}

.arrow-blue-up {
  transform: rotate(180deg);
}

.content-value-bank,
.content-installment {
  font-size: 14px;
  color: #50535a;
  margin-right: 2px;
}

.value-interent {
  font-size: 16px;
  font-weight: bold;
  color: #0b2739;
}

.body-box-bank {
  border-radius: 10px;
  width: 96%;
  height: 100%;
  border: solid 1px #d3d4d3;
  margin: 0 auto;
  margin-top: 2.5rem;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  padding: 5px;
}
.header-text-title {
  border-bottom: 1px solid #d3d4d3;
}

.img-cloud-blue {
  position: absolute;
  right: 0.5rem;
  top: -4.5rem;
}

.img-dollar-sign {
  position: absolute;
  right: 1rem;
  top: -4rem;
}

.text-title {
  color: var(--azure);
  font-size: 12px;
  position: relative;
  top: 5px;
  padding-top: 14px;
}

.openBox {
  animation: animationOpenBox 0.3s forwards;
}

.closeBox {
  animation: animationCloseBox 0.5s forwards;
}

@keyframes animationOpenBox {
  from {
    height: 87px;
  }
  to {
    height: 100%;
  }
}

@keyframes animationCloseBox {
  from {
    height: 100%;
  }
  to {
    height: 87px;
  }
}
</style>
