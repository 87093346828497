<template>
  <div class="container-sm-credits">
    <ApplyPaymentCard
      :propostaMambu="propostaMambu"
      :planoPagamento="planoPagamento"
      :saldoPendente="planoPagamento.lastDueValueFmt"
    />
  </div>
</template>

<script>
import ApplyPaymentCard from './ApplyPaymentCard'
import { mapGetters } from 'vuex'

export default {
  name: 'ApplyPayment',
  components: { ApplyPaymentCard },
  computed: {
    ...mapGetters({
      propostaMambu: 'selfManageApplicationMambu',
      planoPagamento: 'selfManageApplicationPaymentPlan',
    }),
  },
}
</script>
