export const AUTH_REQUEST = 'authRequest'
export const EPAYCO_ERROR_RESPONSE = 'epaycoErrorResponse'
export const MUTATION_SELECT_OFFER = 'mutationSelectOffer'
export const AUTH_SUCCESS = 'authSuccess'
export const AUTH_SUCCESS_AUTO_GESTAO = 'authSuccessAutoGestao'
export const AUTH_SUCCESS_ACEITE_CLIENTE = 'authSuccessClienteAceite'
export const AUTH_ERROR = 'authError'
export const FORM_ERROR_ARRAY = 'formErrorArray'
export const MUTATE_AUTH_ERROR = 'mutateAuthError'
export const MUTATION_LISTAR_OFERTAS = 'mutationListarOfertas'
export const MUTATION_SELECTED_BUTTONS_ASSISTED = 'mutationSelectButtonsAssisted'
export const REFRESH_TOKEN_REQUEST = 'refreshTokenRequest'
export const REFRESH_TOKEN_SUCCESS = 'refreshTokenSuccess'
export const REFRESH_TOKEN_ERROR = 'refreshTokenError'
export const MUTATION_SELECTED_ASSISTED = 'mutationSelectedAssisted'
export const MUTATION_SIMULADOR_ASSISTIDO = 'mutationSimuladorAssistido'
export const REFRESH_TOKEN = 'refreshToken'
export const DESTROY_TOKEN_MUTATION = 'destroyToken'
export const SET_TERMS_CONDITIONS = 'termsAndConditions'
export const MUTATION_OFFER_ASSISTED = 'mutationOfferAssisted'
export const CHANGE_CHANNEL_ORIGIN = 'changeChannelOrigin'
export const SET_CONTRACT = 'contract'
export const PHONE_DETAILS_ULR = 'phoneDetailsUrl'
export const MUTATION_RESPONSE_CHANGE_STATUS_ASSISTED = 'mutationResponseChangeStatusAssisted'
export const MUTATION_MAIS_INFORMACOES = 'mutationMaisInformacoes'
export const MUTATION_CONTRA_OFERTA = 'mutationContraOferta'
export const SALVAR_DADOS_BANCARIOS = 'salvarBankData'
export const MUTATION_SET_ARRAY_RATE = 'mutationSetArrayRate'
export const SET_URL_CALLBACK = 'urlCallback'
export const GET_APPLICATION_SUCCESS = 'getApplicationSuccess'
export const GET_OFFER_SUCCESS = 'getOfferSuccess'
export const SET_OFFER_OPTIONS = 'setOfferOptions'
export const MUTATION_COUNTER_OFFER = 'mutationCounterOffer'
export const MUTATION_BANKS = 'mutationBanks'
export const MUTATE_BIGGEST_OFFER_SELECTED = 'mutateBiggestOfferSelected'
export const MUTATE_OFFER_SELECTED_NUMBER = 'mutateOfferSelectedNumber'
export const MUTATE_OFFER_SELECTED_ITEM = 'mutateOfferSelectedItem'
export const FLAG_DISABLE_OFFER = 'flagDisableOffer'
export const POST_APPLICATION_SUCCESS = 'postApplicationSuccess'
export const POST_APPLICATION_ERROR = 'postApplicationError'
export const SET_POST_APPLICATION_FORM = 'savePostApplicationForm'
export const SET_CANCEL_PROPOSAL = 'cancelProposal'
export const MUTATION_CHANGE_VALUE_INPUT = 'mutationChangeValeuInput'
export const SET_EVIDENTE_QUESTIONS = 'evidenteQuestions'
export const SET_ADDRESS_DEPARTMENTS = 'addressDepartments'
export const MUTATE_CITIES_AND_NEIGHBORHOOD = 'mutateCitiesAndNeighborhood'
export const SET_ADDRESS_CITIES = 'addressCities'
export const SET_ADDRESS_NEIGHBORHOOD = 'addressNeighborhood'
export const SET_ADDRESS_FORM = 'addressForm'
export const SET_ADDRESS_CLIENT = 'addressClient'
export const SET_ADDRESS_CLIENT_FINAL = 'addressClientFinal'
export const SET_OVERLAY = 'overlay'
export const NEW_PIN_ERROR = 'newPinError'
export const SET_MY_IP = 'myIP'
export const DEFINIR_MINHA_LOCALIZACAO = 'minhaLocalizacao'
export const CONTRACT_TERMS = 'contractTerms'
export const CONTRACT_FIANCA = 'contractFianza'
export const MUTATION_CHANGE_AGAINST_INPUT_OFFER = 'mutationChangeAgainstInputOffer'
export const MUTATION_VERIDAS_TOKEN = 'mutationVeridasToken'

//SELF MANAGE MUTATIONS
export const DEFINIR_MOBILE_DISPONIVEIS = 'definirMobileDisponiveis'
export const SET_ONBOARD_LOGIN = 'onboardLogin'
export const SELF_MANAGE_ERROR = 'selfManageError'
export const SET_ONBOARD_LOGIN_FORM = 'onboardLoginForm'
export const SELF_MANAGE_UPDATE_PASSWORD_RESP = 'selfManageUpdatePasswordResp'
export const SELF_MANAGE_UPDATE_PASSWORD_ERROR = 'selfManageUpdatePasswordError'
export const SELF_MANAGE_RESET_PASSWORD_RESP = 'selfManageResetPasswordResp'
export const SELF_MANAGE_GET_BORROWER_RESP = 'selfManageGetBorrowerResp'
export const SELF_MANAGE_UPDATE_CONTACT_RESP = 'selfManageUpdateContactResp'
export const SELF_MANAGE_UPDATE_EMAIL_RESP = 'selfManageUpdateEmailResp'
export const MUTATE_APPLICATION_EMAIL = 'mutateApplicationEmail'
export const MUTATE_APPLICATION_ID = 'mutateApplicationId'
export const MUTATE_BORROWER_PHONE = 'mutateApplicationPhone'
export const SELF_MANAGE_GET_APPLICATIONS_RESP = 'selfManageApplicationsSuccess'
export const SELF_MANAGE_GET_APPLICATIONS_RETRIEVE_CART = 'selfManageApplicationsRetrieveCart'
export const SELF_MANAGE_GET_APPLICATIONS = 'selfManageGetApplications'
export const SELF_MANAGE_GET_APPLICATIONS_LIBERADAS_RESP = 'selfManageGetApplicationsLiberadasSuccess'
export const SELF_MANAGE_GET_APPLICATION_PAYMENT_PLAN_RESP = 'selfManageGetApplicationPaymentPlanSuccess'
export const SELF_MANAGE_GET_APPLICATION_MAMBU_RESP = 'selfManageGetApplicationMambuSuccess'
export const SELF_MANAGE_GET_CLAIMS_RESP = 'selfManageClaimsSuccess'
export const SELF_MANAGE_POST_UPDATE_CASE_STATUS_RESP = 'selfManageUpdateCaseStatusSuccess'
export const SELF_MANAGE_MUTATE_LAST_DUE_VALUE = 'selfManageMutateLastDueValue'
export const SELF_MANAGE_MUTATE_BALANCE_VALUE = 'selfManageMutateBalanceValue'
export const SELF_MANAGE_MUTATE_CONTACT_PREFERENCES = 'selfManageMutateContactPreferences'
//SELF MANAGE MUTATIONS END

//EPAYCO MUTATIONS
export const EPAYCO_POST_RESPONSE_SUCCESS = 'epaycoPostResponseSuccess'
export const FIANCA_POST_RESPONSE_SUCCESS = 'fiancaPostResponseSuccess'
export const FIANCA_ERROR_RESPONSE = 'fiancaErrorResponse'
//EPAYCO MUTATIONS END

//FAST PAYMENT ACTIONS
export const SET_FAST_PAYMENT_FORM = 'setFastPaymentForm'
export const FAST_PAYMENT_ERROR = 'fastPaymentError'
export const FAST_PAYMENT_GET_APPLICATION_PAYMENT_PLAN_RESP = 'fastPaymentGetApplicationPaymentPlanSuccess'
export const FAST_PAYMENT_GET_APPLICATION_MAMBU_RESP = 'fastPaymentGetApplicationMambuSuccess'
export const MUTATION_FAST_PAYMENT_APPLICATION_MAMBU_SELECIONADO = 'fastPaymentSelecionarApplicationMambu'
//FAST PAYMENT ACTIONS END

// ADVANCED_PAYMENT_MUTATIONS
export const SET_ADVANCED_PAYMENT = 'definirPagamentoAvancado'
export const SET_ADVANCED_PAYMENT_FMT = 'definirPagamentoAvancadoFormatado'
export const SET_MAXIMUM_ADVANCED_PAYMENT = 'definirPagamentoAvancadoMaximo'
export const SET_MAXIMUM_ADVANCED_PAYMENT_FMT = 'definirPagamentoAvancadoMaximoFormatado'
export const SET_FINANCED_AMOUNT = 'definirValorFinanciado'
export const SET_FINANCED_AMOUNT_FMT = 'definirValorFinanciadoFormatado'
export const SET_REAL_FINANCED_AMOUNT = 'definirValorFinancialReal'
export const SET_REAL_FINANCED_AMOUNT_FMT = 'definirValorFinancialRealFormatado'
export const SET_MINIMUM_ORDER_VALUE = 'definirValorOrdemMinimo'
export const SET_MINIMUM_ORDER_VALUE_FMT = 'definirValorOrdemMinimoFormatado'
export const SET_CHANGED_ADVANCED_PAYMENT = 'definirValorPagamentoAvancadoAlterado'
export const SET_GUARANTEE_PAYMENT = 'definirValorPagamentoGarantia'
export const SET_GUARANTEE_PAYMENT_FMT = 'definirValorPagamentoGarantiaFormatado'
export const SET_CALCULATED_GUARANTEE_PAYMENT_IVA = 'definirValorCalculadoPagamentoGarantiaImposto'
export const SET_CALCULATED_GUARANTEE_PAYMENT_IVA_FMT = 'definirValorCalculadoPagamentoGarantiaImpostoFormatado'
export const SET_GUARANTEE_PAYMENT_TOTAL = 'definirValorPagamentoGarantiaTotal'
export const SET_GUARANTEE_PAYMENT_TOTAL_FMT = 'definirValorPagamentoGarantiaTotalFormatado'
export const SET_ADVANCED_PAYMENT_FIANCA = 'definirPagamentoAvancadoFianca'
// ADVANCED_PAYMENT_MUTATIONS END

// PRE_VALIDACAO
export const SET_HASH_VALIDACAO_EMAIL = 'setHashValidacaoEmail'
export const SET_HASH_VALIDACAO_OTP = 'setHashValidacaoOTP'
export const SET_BUSCADOR_BASIC_DATA = 'setBuscadorBasicData'
export const SET_BUSCADOR_BASIC_DATA_REQUEST = 'setBuscadorBasicDataRequest'
export const SET_BUSCADOR_FULL_DATA = 'setBuscadorFullData'
export const SET_BUSCADOR_FULL_OPTIONS = 'setBuscadorFullOptions'
export const SET_BUSCADOR_PARCELA_SELECIONADA = 'setBuscadorParcelaSelecionada'
export const SET_BUSCADOR_REPROVED_MESSAGES = 'setBuscadorReprovedMessages'
export const SET_QUERY_DATA = 'setQueryData'

// PARAMETROS_MUTATIONS
export const SET_PARAMETROS = 'definirParametros'
export const SET_PARAMETROS_CANAL = 'definirParametrosCanal'
