<template>
  <div>
    <section class="container-header">
      <img src="@/assets/images/icon-money-1.png" alt="heade-image" class="img-money-offer" />
      <Stepper :step="step" class="step-counter-offer" />
      <div class="container-welcome">
        <p class="welcome-bank">
          ¡Grandioso!, <br />
          tu cupo de crédito se aprobó
        </p>
        <p class="where-bank">¿Dónde quieres recibir tu dinero?</p>
      </div>
    </section>

    <section>
      <p class="instruction-bank">Para que el desembolso sea efectivo debes ser el titular de la cuenta</p>
    </section>
  </div>
</template>

<script>
import Stepper from '../Stepper'

export default {
  components: { Stepper },
  data() {
    return {
      step: 4,
      status: 'DOWNGRADE',
    }
  },
}
</script>

<style scoped>
.container-welcome {
  position: relative;
  top: -20px;
}

.img-money-offer {
  margin-bottom: 1rem;
}

.welcome-bank {
  font-size: 20px;
  color: #0b2739;
  font-family: Telefonica;
  font-weight: 300;
}

.where-bank,
.welcome-bank,
.instruction-bank {
  margin-bottom: 5px;
}

.where-bank {
  font-size: 14px;
  color: #50535a;
}

.instruction-bank {
  font-size: 10px;
  color: #50535a;
}
</style>
