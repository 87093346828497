import Api from '../plugins/api'
import { FETCH_EVIDENTE_QUESTIONS, SEND_EVIDENTE_QUESTIONS, TOGGLE_OVERLAY } from './actions.type'
import { SET_EVIDENTE_QUESTIONS } from './mutations.type'
import store from './index'
import router from '../router'

const state = {
  evidenteQuestions: '',
}

const mutations = {
  [SET_EVIDENTE_QUESTIONS](state, params) {
    state.evidenteQuestions = params
  },
}

const actions = {
  [FETCH_EVIDENTE_QUESTIONS](context, form) {
    return Api.executeAction(context.getters.applicationId, form)
      .then((response) => {
        const situation = response.data.situation.toLowerCase()
        if (situation === 'infolaftfailure') router.push({ name: 'CantProcessRequest' })
        else if (situation === 'reproved') {
          if (response.data.response)
            router.push({
              name: 'MensagemReprovacao',
              params: {
                data: {
                  response: response.data.response,
                },
                updateMode: true,
                editionMode: true,
              },
            })
          else router.push({ name: 'CantProcessRequest' })
        } else context.commit(SET_EVIDENTE_QUESTIONS, response.data)

        store.dispatch(TOGGLE_OVERLAY, false)
      })
      .catch((e) => console.log(e))
  },
  [SEND_EVIDENTE_QUESTIONS](context, form) {
    return Api.executeAction(context.getters.applicationId, form)
      .then((response) => {
        return response.data
      })
      .catch((e) => console.log(e))
  },
}

const getters = {
  evidenteQuestions: (state) => state.evidenteQuestions,
}

export default {
  state,
  mutations,
  actions,
  getters,
}
