<template>
  <div class="input-checkbox" @click="$emit('input', !value)">
    <img v-if="value" src="@/assets/icons/checkbox.svg" alt="" />
    <img v-else src="@/assets/icons/checkbox-unmarked.svg" alt="" />
    <p v-if="label" class="label" v-text="label" />
  </div>
</template>
<script>
export default {
  props: {
    value: Boolean,
    label: String,
  },
  watch: {
    value() {
      this.$emit('input', this.value)
    },
  },
}
</script>
<style scoped lang="scss">
.input-checkbox {
  cursor: pointer;
  display: flex;
  align-items: center;
  .label {
    margin-left: 0.75rem;
    margin-bottom: 0;
  }
}
</style>
