<template>
  <div>
    <button class="continue-button continue-button-text" @click="emitClick" :disabled="desabilitar">
      <span>{{ text }}</span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'ButtonContinueBlue',
  props: {
    text: String,
    desabilitar: Boolean,
  },
  methods: {
    emitClick() {
      this.$emit('click')
    },
  },
}
</script>

<style scoped>
.continue-button {
  height: 36px;
  background-color: var(--movistar-blue);
}
.continue-button:disabled {
  height: 36px;
  background-color: rgba(83, 80, 80, 0.5);
  cursor: default;
}
.continue-button-text {
  color: var(--white);
}
</style>
