<template>
  <div>
    <div v-if="big" class="big-circle mx-2" :style="{ background: color }" />
    <div v-else-if="color === null" class="circle mx-2" :style="{ background: '#000000' }" />
    <div v-else class="circle mx-2" :style="{ background: color }" />
  </div>
</template>

<script>
export default {
  name: 'ColorCircle',
  props: {
    big: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: '#000000',
    },
  },
}
</script>

<style scoped>
.circle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.big-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
</style>
