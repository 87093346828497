<template>
  <PreValidacaoLayout
    subtitle="Llena los siguientes datos y validaremos la información:"
    description="Requerimos autorización de datos personales para realizar la consulta"
  >
    <b-card border-variant="light">
      <template #header>
        <div class="card-title">
          <h4 class="title">Cupo</h4>
          <span class="tip">Obligatorio*</span>
        </div>
        <div class="divider"></div>
      </template>
      <b-form>
        <FloatingInputText
          icon="id-card.svg"
          v-model="form.documentNumber"
          id="documento"
          label="Documento de identidad *"
          class="mb-4"
          :mask="['# ## ###', '# ### ###', '# ### ### ###', '## ### ####']"
          :error="v$.documentNumber.$error"
          error-message="Este número de cédula debe tener entre 6 a 14 dígitos"
        />
        <FloatingInputText
          icon="teclado-de-discagem.png"
          v-model="form.mobile"
          id="numero"
          label="Línea *"
          class="mb-4"
          mask="### ### ## ##"
          :error="v$.mobile.$error"
          error-message="Este no es un número Movistar válido"
        />
        <FloatingInputText
          icon="mail.svg"
          v-model="form.email"
          id="email"
          label="Correo electrónico *"
          :error="v$.email.$error"
          error-message="El correo es inválido"
        />
      </b-form>
    </b-card>

    <InputCheckbox
      v-model="form.acceptTermsConditions"
      label="Acepto los Términos y Condiciones y la Política de Datos Personales, declaro que los he leído en su totalidad."
    />
    <div class="button-content">
      <Button class="button-continuar" @click="enviarEmailValidacao($bvModal)" :disabled="isButtonDisabled">
        <span>Continuar</span>
        <img src="@/assets/icons/arrow-white.svg" class="arrow" width="15" />
      </Button>
    </div>

    <Modal id="modal-tec" message="Enviamos los TyC al correo ingresado, dar clic en acepto para poder continuar">
      <template #icon>
        <img src="@/assets/icons/check-circle.svg" alt="Check icon" width="43px" />
      </template>
      <template #button>
        <Button class="button-continuar" @click="proximaTela($bvModal)">
          <span>Continuar</span>
          <img src="@/assets/icons/arrow-white.svg" class="arrow" width="15" />
        </Button>
      </template>
    </Modal>
    <Modal id="modal-tec-expirado" message="El enlace de verificación ha expirado">
      <template #icon>
        <img src="@/assets/icons/negado.svg" alt="Error icon" width="43px" />
      </template>
      <template #button>
        <a href="https://www.movistarmoney.co">
          <Button class="button-voltar">
            <img src="@/assets/icons/arrow-white.svg" class="arrow" width="15" />
            <span>Volver</span>
          </Button>
        </a>
      </template>
    </Modal>
    <Modal id="modal-tec-negado" message="Pendiente aceptar TyC enviados al correo">
      <template #icon>
        <img src="@/assets/icons/negado.svg" alt="Error icon" width="43px" />
      </template>
      <template #button>
        <Button class="button-voltar" @click="voltarModal($bvModal)">
          <img src="@/assets/icons/arrow-white.svg" class="arrow" width="15" />
          <span>Volver</span>
        </Button>
      </template>
    </Modal>
  </PreValidacaoLayout>
</template>

<script setup>
import { reactive, computed, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import { useVuelidate } from '@vuelidate/core'
import { required, minLength, maxLength, email } from '@vuelidate/validators'
import store from '@/store'
import { ENVIAR_EMAIL_VALIDACAO, PRE_VALIDADOR_REQUEST_BASIC } from '@/store/actions.type'
import { SET_BUSCADOR_BASIC_DATA_REQUEST, SET_QUERY_DATA } from '@/store/mutations.type'
import PreValidacaoLayout from '@/components/PreValidacao/PreValidacaoLayout.vue'
import Modal from '@/components/PreValidacao/Modal.vue'
import Button from '@/components/PreValidacao/Button.vue'
import FloatingInputText from '@/components/inputs/FloatingInputText.vue'
import InputCheckbox from '@/components/inputs/InputCheckbox.vue'
import CryptoJS from 'crypto-js'

const route = useRoute()

const formRules = {
  documentNumber: { required, min: minLength(8), max: maxLength(19) },
  mobile: { required, min: minLength(13) },
  email: { required, email, max: maxLength(100) },
  acceptTermsConditions: { required },
}
const form = reactive({
  documentNumber: '',
  mobile: '',
  email: '',
  acceptTermsConditions: false,
})

onMounted(() => {
  const queryData = decodeURIComponent(route.query.data)
  store.commit(SET_QUERY_DATA, queryData)
})

const requestData = computed(() => {
  const queryData = decodeURIComponent(route.query.data)
  let decryptedObject = JSON.parse(
    CryptoJS.AES.decrypt(queryData, process.env.VUE_APP_SECRET_SITE).toString(CryptoJS.enc.Utf8)
  )
  return {
    uuid: decryptedObject.id,
    requestCode: decryptedObject.userCode,
    requestName: decryptedObject.name,
    channel: decryptedObject.channel,
  }
})

const v$ = useVuelidate(formRules, form)

const isButtonDisabled = computed(() => {
  return !form.documentNumber || !form.mobile || !form.email || !form.acceptTermsConditions || v$.value.$error
})

async function enviarEmailValidacao(modalContext) {
  v$.value.$touch()
  if (v$.value.$error) return
  await store.dispatch(ENVIAR_EMAIL_VALIDACAO, {
    documentNumber: form.documentNumber.replace(/ /g, ''),
    mobile: form.mobile.replace(/ /g, ''),
    email: form.email,
    ...requestData.value,
  })
  modalContext.show('modal-tec')
}

function voltarModal(modalContext) {
  modalContext.hide('modal-tec-negado')
  modalContext.show('modal-tec')
}

const router = useRouter()

function proximaTela(modalContext) {
  modalContext.hide('modal-tec')
  store.commit(SET_BUSCADOR_BASIC_DATA_REQUEST, {
    identityCardNumber: form.documentNumber.replace(/ /g, ''),
    mobile: form.mobile.replace(/ /g, ''),
    email: form.email,
    hashEmail: store.getters.hashValidacaoEmail,
    origin: CryptoJS.AES.encrypt(requestData.value.channel, process.env.VUE_APP_SECRET_SITE).toString(),
    uuid: CryptoJS.AES.encrypt(requestData.value.uuid, process.env.VUE_APP_SECRET_SITE).toString(),
  })

  if (form.mobile.startsWith('3')) {
    router.push({ name: 'PreValidacaoOTP', query: route.query })
  } else {
    store.dispatch(PRE_VALIDADOR_REQUEST_BASIC).catch((errorCode) => {
      if (errorCode === 'EXPIRED_VERIFICATION_URL') {
        modalContext.show('modal-tec-expirado')
      } else if (errorCode === 'PENDING_TERMS_AND_CONDITIONS') {
        modalContext.show('modal-tec-negado')
      }
    })
  }
}
</script>

<style scoped lang="scss">
.card {
  max-width: 41.25rem;
  margin: 0 auto;
  background-color: #f5f5f5;
  border-radius: 10px;
  .card-header {
    background: none;
    border-bottom: none;
    padding: 27px 37px;
    .card-title {
      display: flex;
      justify-content: space-between;
      .title {
        font-size: 25px;
        margin-bottom: 0;
        font-weight: bold;
      }
      .tip {
        font-size: 20px;
      }
    }

    .divider {
      width: 100%;
      height: 0px;
      margin-top: 1rem;
      border-bottom: solid 0.5px #707070;
    }
  }

  .card-body {
    padding: 27px 37px;
  }
}

::v-deep .input-checkbox {
  max-width: 41.25rem;
  margin: 2rem auto 0 auto;
  .label {
    font-size: 1.25rem;
    color: #019df4;
    text-decoration: underline;
    text-align: left;
  }
  img {
    width: 30px;
    height: 30px;
  }
}

::v-deep .button-enviar .continue-button {
  width: 10rem;
  margin-top: 2rem;
}

.button-content {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
}

.button-continuar {
  .arrow {
    margin-left: 12px;
    transform: rotate(270deg);
  }
}

.button-voltar {
  .arrow {
    margin-right: 12px;
    transform: rotate(90deg);
  }
}
</style>
