<template>
  <div class="pb-2">
    <hr />
    <b-row align-h="center" align-v="center">
      <b-col cols="1" class="py-0">
        <b-img src="@/assets/icons/clock.svg" alt="Clock icon" />
      </b-col>
      <b-col cols="10" class="text-left px-0 pl-3">
        <b-row align-v="center">
          <span class="font-16 text-grey pl-3">Fecha y horario de entrega del equipo</span>
        </b-row>
      </b-col>
    </b-row>
    <b-row v-if="deliveryError === '0'" align-h="center" align-v="start" class="mt-2">
      <b-col cols="1" class="py-0"></b-col>
      <b-col cols="10" class="text-left px-0 pl-3">
        <b-row align-v="center" class="pl-3">
          <span class="font-16">{{ deliveryDateFormatted }}</span>
        </b-row>
        <b-row align-v="center" class="pl-3">
          <span v-if="deliveryPeriod === 'AM'" class="font-16">Jornada AM</span>
          <span v-else-if="deliveryPeriod === 'PM'" class="font-16">Jornada PM</span>
          <span v-else class="font-16"></span>
        </b-row>
      </b-col>
      <span class="text-grey mt-2">Fecha válida si aceptas el contrato hoy</span>
    </b-row>

    <b-row v-else align-h="center" align-v="start" class="mt-2">
      <b-col cols="1" class="py-0"></b-col>
      <b-col cols="10" class="text-left px-0 pl-3">
        <b-row align-v="center" class="pl-3">
          <span class="font-16"> Movistar te confirmará vía correo electrónico la fecha de entrega de tu equipo </span>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { formatDate } from '../helpers/common'

export default {
  name: 'CardAddressDeliveryDate',
  props: ['deliveryPeriod', 'deliveryDate', 'deliveryError'],
  data() {
    return {}
  },
  created() {
    this.deliveryDateFormatted = formatDate(this.deliveryDate)
  },
  computed: {
    deliveryDateFormatted: {
      get() {
        return formatDate(this.deliveryDate)
      },
      set(newName) {
        return newName
      },
    },
  },
}
</script>
