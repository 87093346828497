<template>
  <b-modal
    id="modal-confirm"
    cancel-title=""
    cancel-variant="hidden"
    size="xl"
    hide-header
    hide-footer
    ok-title=""
    ok-variant="hidden"
    centered
    header-border-variant="white"
    footer-border-variant="white"
  >
    <div class="text-right">
      <b-button variant="none" @click="$bvModal.hide('modal-confirm')">
        <b-img src="@/assets/icons/cancel.svg" alt="Cancel icon" />
      </b-button>
    </div>
    <div class="my-3">
      <div v-if="image" class="text-center">
        <b-img class="happy" src="~@/assets/images/wow.png" />
      </div>
      <p v-if="title" class="text-center subtitle-onboard title">{{ title }}!</p>
      <p v-if="subtitle" class="subtitle">{{ subtitle }}</p>
      <p v-if="baseboard" class="text-center subtitle-onboard baseboard">{{ baseboard }}</p>
      <b-row>
        <b-col v-if="confirmText">
          <div class="mt-3">
            <button class="blue-button continue-button-text" @click="confirm">
              <span>{{ confirmText }}</span>
            </button>
          </div>
        </b-col>
        <b-col v-if="cancelText">
          <div class="mt-3">
            <button class="blue-button continue-button-text inverted" @click="cancel">
              <span>{{ cancelText }}</span>
            </button>
          </div>
        </b-col>
        <button v-if="proximoText" class="pine-green-button continue-button-text proximo" @click="proximo">
          <span>{{ proximoText }}</span>
        </button>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'ModalDinamico',
  props: {
    image: Boolean,
    title: String,
    subtitle: String,
    baseboard: String,
    confirmText: String,
    cancelText: String,
    proximoText: String,
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    confirm() {
      this.$emit('confirm')
    },
    proximo() {
      this.$emit('proximo')
    },
  },
}
</script>

<style scoped>
.inverted {
  background: #ffffff;
  color: var(--movistar-blue);
  border: 1px solid var(--movistar-blue);
}
.baseboard {
  color: red !important;
  font-size: 15px !important;
  margin-top: 20px;
}
.happy {
  width: 383px;
  height: 287px;
  margin: 0 93px 9px 94px;
}
.title {
  width: 522px;
  height: 60px;
  margin: auto;
  margin-bottom: 29px;
  font-family: Telefonica;
  font-size: 24px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--0-b-2739);
}

.subtitle {
  width: 570px;
  height: 70px;
  margin: auto;
  margin-top: 29px;
  font-family: Telefonica;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: center;
  color: var(--0-b-2739);
}

.proximo {
  width: 161px;
  height: 43px;
  margin: auto;
  margin-top: 22px;
  border-radius: 21.5px;
  background-color: #5cb615;
}
</style>
