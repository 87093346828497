<template>
  <b-modal :id="id" header-border-variant="white" footer-border-variant="white">
    <template #modal-header>
      <h1 class="text-center title-onboard">¡Importante!</h1>
      <b-button variant="blank" @click="$bvModal.hide(id)">
        <IconeFechar />
      </b-button>
    </template>

    <p class="description" v-if="assistido">
      Por capacidad de pago de tu cliente el valor del pago inicial ha incrementado a: <b>{{ advancedPaymentFmt }}</b>
    </p>
    <p class="description" v-else>
      Por tu capacidad de endeudamiento el valor del pago inicial ha incrementado a: <b>{{ advancedPaymentFmt }}</b>
    </p>

    <p class="description blue" v-if="assistido">
      Si tú cliente está de acuerdo avanza dando clic en "continuar" de lo contrario finaliza el proceso
    </p>
    <p class="description blue" v-else>
      Si estás de acuerdo avanza dando clic en “continuar” de lo contrario finaliza el proceso
    </p>

    <template #modal-footer>
      <b-row cols="1">
        <b-col>
          <ButtonContinue text="Continuar" class="btn-continue" @click="$emit('continuar')" />
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>
<script>
import { mapGetters } from 'vuex'

import ButtonContinue from '@/components/buttons/ButtonContinue.vue'
import IconeFechar from '@/components/icones/IconeFechar.vue'

export default {
  components: {
    ButtonContinue,
    IconeFechar,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    assistido: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({ advancedPaymentFmt: 'advancedPaymentFmt' }),
  },
}
</script>
<style lang="scss" scoped>
::v-deep .modal-header {
  padding-bottom: 0px;
  justify-content: center;
  position: relative;
  h1 {
    margin-top: 0.5rem;
    margin-bottom: 0;
  }

  .btn-blank {
    position: absolute;
    right: 1rem;
    top: 1rem;
  }
}

::v-deep .modal-body {
  .description {
    margin: 0rem 1rem 1rem 1rem;
    text-align: center;
    &.blue {
      color: #019df4;
    }
  }

  ol {
    text-align: left;
    padding: 0px;
    margin: 2rem 1.5rem 0rem 1.5rem;
    li {
      padding-left: 0.4rem;
      margin-bottom: 1rem;
      &:last-child {
        margin-bottom: 0;
      }
      &::marker {
        font-weight: bold;
      }
    }
  }
}

::v-deep .modal-footer {
  justify-content: space-around !important;
  .row {
    width: 100%;
    .col {
      display: flex;
      justify-content: center;
      .btn-continue {
        width: 10rem;
        margin-bottom: 1rem;
      }
    }
  }
}
</style>
