'
<template>
  <div class="container-fluid mb-5">
    <b-container>
      <StripesTopImage />

      <MovistarMoneyLogo />

      <Stepper :step="step" />

      <b-row class="text-center" align-h="center">
        <b-col cols="12" md="6" class="no-padding">
          <div class="container-view text-center mx-3">
            <b-row align-h="center">
              <p class="onboard-identity-title mx-2">
                Por tu seguridad, vamos a validar tu identidad mediante biometria
              </p>
            </b-row>

            <b-row v-for="item in identityItems" align-v="center" :key="item.title" class="pb-3">
              <b-col cols="1" class="no-padding"></b-col>
              <b-col cols="2" class="no-padding">
                <b-img :src="require(`@/assets/images/${item.image}`)" :alt="item.imageAlt" />
              </b-col>
              <b-col cols="1" class="no-padding"></b-col>
              <b-col cols="8" class="text-left no-padding">
                <span class="onboard-identity-items">{{ item.title }}</span>
              </b-col>
            </b-row>
            <b-row align-h="center" class="pb-4 mx-1">
              <p class="onboard-identity-items no-margin">
                Tratamos tu información conforme a nuestra Política de Tratamiento de Datos Personales.
              </p>
            </b-row>
            <b-row align-h="center" class="pb-2">
              <b-img src="@/assets/icons/phone-rotate.gif" alt="Rotate Phone" />
            </b-row>
            <b-row align-h="center" class="mx-4">
              <p class="offer-subtitle blue-text mb-1">
                ¡Por favor alista tu documento de identidad antes de iniciar la validación!
              </p>
              <p class="offer-subtitle blue-text">Necesitaremos acceso a tu cámara</p>
            </b-row>
            <b-row>
              <b-col>
                <div class="mt-5">
                  <ButtonContinue text="Rechazar" :inverted="true" @click="showModal" />
                </div>
              </b-col>
              <b-col>
                <div class="mt-5">
                  <ButtonContinue text="Permitir" @click="goNextPage" />
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>

      <ModalRechazar
        title="No podemos validar tu identidad sin acceso a tu cámara"
        subtitle="¿Deseas permitir el acceso a tu cámara?"
        @rechazar="closeModal"
        @nextPage="goNextPage"
      />
    </b-container>
  </div>
</template>

<script>
import ButtonContinue from '../components/buttons/ButtonContinue'
import StripesTopImage from '../components/StripesTopImage'
import MovistarMoneyLogo from '../components/MovistarMoneyLogo'
import Stepper from '../components/Stepper'
import store from '../store'
import { FETCH_APPLICATION_REDIRECT, REDIRECT_USER, TOGGLE_OVERLAY } from '../store/actions.type'
import ModalRechazar from '../components/modal/ModalRechazar'
import { gtmDataLayerCheckout, gtmDataLayerTrackEvent } from '../helpers/gtmHelper'
import { mapGetters } from 'vuex'

export default {
  name: 'Identity',
  components: {
    ModalRechazar,
    Stepper,
    MovistarMoneyLogo,
    StripesTopImage,
    ButtonContinue,
  },
  data() {
    return {
      step: 3,
      nextPage: 'veridas',
      identityItems: [
        {
          title: 'Le tomaremos una foto a tu cédula',
          image: 'id-photo.png',
          imageAlt: 'Id Photo',
        },
        {
          title: 'Tomaremos una foto de tu rostro',
          image: 'selfie.png',
          imageAlt: 'Selfie',
        },
      ],
    }
  },
  mounted() {
    store.dispatch(FETCH_APPLICATION_REDIRECT)
  },
  methods: {
    goNextPage() {
      store.dispatch(REDIRECT_USER, this.nextPage)
      gtmDataLayerTrackEvent(
        window.dataLayer,
        'trackEvent',
        'movistar money',
        'click::identidad biometria::paso 3',
        'permitir identidad biometria',
        this.CryptoJS.SHA1(this.currentApplication.mobile).toString()
      )
    },
    showModal() {
      this.$bvModal.show('modal-rechazar')
      gtmDataLayerTrackEvent(
        window.dataLayer,
        'trackEvent',
        'movistar money',
        'click::identidad biometria::paso 3',
        'rechazar identidad biometria',
        this.CryptoJS.SHA1(this.currentApplication.mobile).toString()
      )
    },
    closeModal() {
      this.$bvModal.hide('modal-rechazar')
      gtmDataLayerTrackEvent(
        window.dataLayer,
        'trackEvent',
        'movistar money',
        'click::identidad biometria::paso 3',
        'rechazar identidad biometria',
        this.CryptoJS.SHA1(this.currentApplication.mobile).toString()
      )
    },
  },
  computed: {
    ...mapGetters({
      currentApplication: 'currentApplication',
    }),
  },
}
</script>
'
