<template>
  <div class="container-fluid mb-5">
    <b-container>
      <StripesTopImage />

      <MovistarMoneyLogo />

      <Stepper :step="step" />

      <b-row class="text-center" align-h="center">
        <b-col cols="12" md="6" class="no-padding">
          <b-row>
            <b-col>
              <p class="title-onboard-otp mb-3 mx-4">
                Hemos enviado un código a la línea {{ form.mobile }}, lo recibirás en unos segundos
              </p>
              <p class="subtitle-onboard mb-5">Ingresa aquí el código</p>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-row align-h="center">
                <b-img src="../assets/icons/lock.svg" alt="Lock icon" />
                <v-otp-input
                  ref="otpInput"
                  input-classes="otp-input"
                  separator=""
                  :num-inputs="4"
                  :should-auto-focus="true"
                  :is-input-num="true"
                  @on-change="handleOnChange"
                  @on-complete="handleOnComplete"
                />
              </b-row>

              <b-row class="mt-2 text-left" align-v="center" align-h="center">
                <b-col class="otp-error">
                  <p v-if="authErrorPin === 'TTL_EXPIRED'" class="font-12 no-margin text-pink">
                    Tu tiempo ha caducado, solicita un nuevo código.
                  </p>
                  <p v-if="authErrorPin === 'WRONG_PIN'" class="font-12 no-margin text-pink">Código Incorrecto.</p>
                  <p v-if="authErrorPin === 'NO_MORE_PIN_ATTEMPTS'" class="font-12 no-margin text-pink">
                    Has superado el límite de reintentos. Solicita un nuevo código.
                  </p>
                  <p v-if="authErrorPin === 'Token no es válido.'" class="font-12 no-margin text-pink">
                    Código Incorrecto.
                  </p>
                </b-col>
              </b-row>
              <b-row class="mb-5" align-v="end">
                <b-col class="no-padding">
                  <p class="font-12 no-margin">
                    Válido por <span class="blue-text">{{ countdown }}</span> segundos
                  </p>
                </b-col>
                <b-col class="no-padding">
                  <b-button
                    variant="link"
                    class="font-12 no-margin resend-code no-padding"
                    @click="newPin"
                    :disabled="disableSendCode"
                  >
                    <span>Enviar un código nuevo</span>
                  </b-button>
                </b-col>
              </b-row>
              <div class="mt-5">
                <ButtonContinue
                  text="Continuar"
                  :disable="disableButton"
                  @click="goNextPage"
                  :origin="this.channelOrigin"
                />
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
    <ModalDinamico
      v-if="this.postApplicationResponse && this.postApplicationResponse.data"
      :image="true"
      :title="this.postApplicationResponse.data.personalizedMessage"
      :subtitle="this.postApplicationResponse.data.personalizedMessageSubTitle"
      :baseboard="'esta alerta se cierra en ' + timerCount + ' segundos '"
      proximoText="Continuar"
      @proximo="proximoModal"
    />
  </div>
</template>
<script>
import ModalDinamico from '../components/modal/ModalDinamico.vue'
import StripesTopImage from '../components/StripesTopImage'
import MovistarMoneyLogo from '../components/MovistarMoneyLogo'
import Stepper from '../components/Stepper'
import store from '../store'
import ButtonContinue from '../components/buttons/ButtonContinue'
import { mapGetters } from 'vuex'
import { LOGIN_OTP, NEW_PIN, RESET_AUTH_ERROR, TOGGLE_OVERLAY } from '../store/actions.type'
import { gtmDataLayerTrackEvent } from '../helpers/gtmHelper'

export default {
  name: 'OTP',
  components: { ButtonContinue, Stepper, MovistarMoneyLogo, StripesTopImage, ModalDinamico },
  data() {
    return {
      step: 1,
      disableSendCode: true,
      countdown: 80,
      timerCount: 10,
      disableButton: true,
      userAuth: {
        grant_type: 'password',
        username: '',
        password: '',
      },
    }
  },
  mounted() {
    if (
      this.postApplicationResponse &&
      this.postApplicationResponse.data &&
      this.postApplicationResponse.data.personalizedMessage
    ) {
      this.$bvModal.show('modal-confirm')
    }
  },
  watch: {
    countdown: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.countdown--
          }, 1000)
        }
        if (value === 0) {
          this.disableSendCode = false
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--
          }, 1000)
        } else {
          this.$bvModal.hide('modal-confirm')
        }
      },
      immediate: true,
    },
  },
  methods: {
    proximoModal() {
      this.$bvModal.hide('modal-confirm')
    },
    handleOnComplete(value) {
      this.disableButton = false
      this.handleOnChange(value)
    },
    handleOnChange(value, disabled) {
      if (disabled) return
      if (value.length < 4) {
        this.disableButton = true
      }
      this.userAuth.password = value
    },
    newPin() {
      //TODO unblock input
      store.dispatch(TOGGLE_OVERLAY, true)
      store.dispatch(NEW_PIN, 'Form')
      this.disableSendCode = true
      this.countdown = 80
      store.dispatch(RESET_AUTH_ERROR)
      store.dispatch(TOGGLE_OVERLAY, false)

      gtmDataLayerTrackEvent(
        window.dataLayer,
        'trackEvent',
        'movistar money',
        'click::financiamiento::paso 1',
        'enviar codigo nuevo',
        this.CryptoJS.SHA1(this.form.mobile).toString()
      )
    },
    goNextPage() {
      //console.log('goNextPage')
      if (this.disableButton) return
      this.userAuth.username = this.form.identityCardNumber
      store.dispatch(TOGGLE_OVERLAY, true)
      store.dispatch(RESET_AUTH_ERROR)
      store.dispatch(LOGIN_OTP, this.userAuth).then(() => {
        this.disableButton = true
        this.$refs.otpInput.clearInput()
      })

      gtmDataLayerTrackEvent(
        window.dataLayer,
        'trackEvent',
        'movistar money',
        'click::financiamiento::paso 1',
        'envío de código',
        this.CryptoJS.SHA1(this.form.mobile).toString()
      )
    },
  },
  computed: {
    ...mapGetters({
      authStatus: 'authStatus',
      authErrorPin: 'authErrorPin',
      form: 'postApplicationForm',
      isAuthenticated: 'isAuthenticated',
      currentApplication: 'currentApplication',
      channelOrigin: 'channelOrigin',
      postApplicationResponse: 'postApplicationResponse',
    }),
  },
}
</script>

<style scoped>
.input-container {
  max-width: 350px;
  margin: 0 auto;
}

.form-control {
  background: #fff;
  border: 0;
  border-bottom: 1px solid #b6b7b7;
  margin: 20px;
  width: 30px;
}
</style>
