import { format } from 'date-fns'
import es from 'date-fns/locale/es'

export function queryString(param) {
  return Object.keys(param)
    .map((key) => `${key}=${encodeURIComponent(param[key])}`)
    .join('&')
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function emailFormatter(value) {
  if (!value) return value
  let parts = value.split('@')
  parts[1] = parts[1].toLowerCase()
  return parts.join('@')
}

export function formatDate(deliveryDate) {
  const time = deliveryDate.split('-')
  const y = parseInt(time[0])
  let d = parseInt(time[1]) - 1
  const m = parseInt(time[2])
  const newDate = format(new Date(y, d, m), "eeee dd 'de' MMMM", { locale: es })
  return newDate.charAt(0).toUpperCase() + newDate.slice(1)
}

export function isSelectedDeliveryAddress(item) {
  return item.selectedDeliveryAddress
}

export function isNotSelectedDeliveryAddress(item) {
  return item.selectedDeliveryAddress !== true
}
