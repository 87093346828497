<template>
  <b-modal
    :id="id"
    class="modal"
    cancel-title=""
    cancel-variant="hidden"
    size="sm"
    hide-header
    hide-footer
    ok-title=""
    ok-variant="hidden"
    centered
    header-border-variant="white"
    footer-border-variant="white"
    no-close-on-esc
    no-close-on-backdrop
  >
    <div class="mx-3">
      <b-row align-h="end">
        <b-button v-if="modalType" variant="blank" @click="closeApp">
          <b-img src="@/assets/icons/cancel.svg" alt="Cancel icon" />
        </b-button>
        <b-button v-else variant="blank" @click="closeModal(id)">
          <b-img src="@/assets/icons/cancel.svg" alt="Cancel icon" />
        </b-button>
      </b-row>
      <div>
        <b-img
          class="pt-4"
          center
          align-h="center"
          :src="require(`@/assets/images/img-guia-acesso-rapido.png`)"
          alt="Modal image"
        />
      </div>

      <div class="py-3">
        <p class="text-center title-onboard">
          Para consultar más información de tu crédito, ver pagos aplicados, nuevo saldo, descargar tu factura y más.
        </p>
      </div>
    </div>
    <div>
      <button class="tentar-novamente-acesso-rapido" @click="Home(id)">¡Accede a tu cuenta aquí!</button>
    </div>
  </b-modal>
</template>

<script>
import router from '../../router/index'
export default {
  name: 'ModalAcessoRapidoError',
  props: {
    cedulaNumber: String,
    id: String,
    modalType: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    closeModal(id) {
      this.$bvModal.hide(id)
    },
    Home(id) {
      router.push({ name: 'Home' })
    },
  },
}
</script>
<style scoped>
.tentar-novamente-acesso-rapido {
  width: 249px;
  height: 30px;
  background: #019df4;
  color: #ffffff;
  display: flex;
  align-items: center;
  border: none;
  justify-content: center;
  border-radius: 1rem;
  margin: 0 auto;
}

.footer-modal-acesso-rapido {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footer-contato-acesso-rapido {
  display: flex;
  justify-content: space-around;
  width: 15rem;
  align-items: center;
}

.footer-contato-acesso-rapido > nav > p {
  margin-bottom: 0px;
  color: #019df4;
  font-size: 1rem;
}

.footer-contato-acesso-rapido > nav {
  display: flex;
}

.footer-contato-acesso-rapido > nav > img {
  margin-right: 10px;
}

.footer-modal-acesso-rapido > p {
  color: #09202e;
  margin-bottom: 5px;
  font-size: 1rem;
}
</style>
