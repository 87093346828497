import apiAuth from '../autenticacao/api.auth'
import { jwt } from '../helpers/jwt.service'

import {
  AUTH_REQUEST,
  AUTH_SUCCESS,
  AUTH_ERROR,
  REFRESH_TOKEN,
  REFRESH_TOKEN_REQUEST,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_ERROR,
  DESTROY_TOKEN_MUTATION,
  MUTATE_AUTH_ERROR,
  CHANGE_CHANNEL_ORIGIN,
  AUTH_SUCCESS_AUTO_GESTAO,
} from './mutations.type'
import { DESTROY_TOKEN, TOGGLE_OVERLAY, RESET_AUTH_ERROR, LOGIN_OTP, VALIDAR_OTP_CLIENTE } from './actions.type'
import store from './index'
import router from '../router'
import { FETCH_APPLICATION_REDIRECT } from '../store/actions.type'

const state = {
  authStatus: '',
  refreshTokenStatus: '',
  authError: '',
  authErrorPin: '',
  isAuthenticated: jwt.getIsAuthenticated(),
  accessToken: jwt.getToken(),
  refreshToken: jwt.getRefreshToken(),
  documentNumber: jwt.getDocumentNumber(),
  channelOrigin: 'Finter',
}

const mutations = {
  [AUTH_REQUEST]: (state) => {
    state.authStatus = 'loading'
  },
  [CHANGE_CHANNEL_ORIGIN]: (state, value) => {
    state.channelOrigin = value
  },
  [AUTH_SUCCESS](state, response) {
    jwt.saveJwt(response)
    state.authStatus = 'success'
    setTimeout(function () {
      store.dispatch(FETCH_APPLICATION_REDIRECT)
    }, 2000)
  },
  [AUTH_SUCCESS_AUTO_GESTAO](state, response) {
    jwt.saveJwt(response)
    state.authStatus = 'success'
  },
  [AUTH_ERROR](state, error) {
    jwt.destroyJWT()
    state.authStatus = 'error'
    state.accessToken = ''
    state.refreshToken = ''
    state.authErrorPin = error.pinError
    state.authError = error
  },
  [MUTATE_AUTH_ERROR](state, authError) {
    state.authError = authError
    state.authErrorPin = authError
  },
  [REFRESH_TOKEN_REQUEST]: (state) => {
    state.refreshTokenStatus = 'loading'
  },
  [REFRESH_TOKEN_SUCCESS](state) {
    state.refreshTokenStatus = 'success'
  },
  [REFRESH_TOKEN_ERROR](state) {
    state.refreshTokenStatus = 'error'
    jwt.destroyJWT()
  },
  [DESTROY_TOKEN_MUTATION]() {
    jwt.destroyJWT()
  },
}

const actions = {
  [LOGIN_OTP](context, userAuth) {
    context.commit(AUTH_REQUEST)
    apiAuth
      .postOauthToken(userAuth)
      .then((response) => {
        context.commit(AUTH_SUCCESS, response.data)
      })
      .catch((e) => {
        const parsedError = JSON.parse(e.message)
        switch (parsedError.data.message) {
          case 'Token no es válido.':
            context.commit(AUTH_ERROR, {
              pinError: 'Token no es válido.',
            })
            store.dispatch(TOGGLE_OVERLAY, false)
            return
        }
        const messageParsed = JSON.parse(parsedError.data.message)

        context.commit(AUTH_ERROR, messageParsed)
        switch (messageParsed.pinError) {
          case 'TTL_EXPIRED':
          case 'WRONG_PIN':
            store.dispatch(TOGGLE_OVERLAY, false)
            break
          case 'NO_MORE_PIN_ATTEMPTS':
            //TODO block input, user cant type more
            store.dispatch(TOGGLE_OVERLAY, false)
            break
          case 'Usuario no es válido.':
            router.push({ name: 'Form' })
            store.dispatch(TOGGLE_OVERLAY, false)
            break
          case 'Too many requests':
            router.push({ name: 'CantProcessRequest' })
            store.dispatch(TOGGLE_OVERLAY, false)
            break
          default:
            store.dispatch(TOGGLE_OVERLAY, false)
        }
      })
  },

  async [VALIDAR_OTP_CLIENTE](context, userAuth) {
    context.commit(AUTH_REQUEST)

    let parsedError = null
    let messageParsed = null

    await apiAuth.postOauthToken(userAuth).catch((e) => {
      parsedError = JSON.parse(e.message)
      switch (parsedError.data.message) {
        case 'Token no es válido.':
          context.commit(AUTH_ERROR, {
            pinError: 'Token no es válido.',
          })
          store.dispatch(TOGGLE_OVERLAY, false)
          return
      }
      messageParsed = JSON.parse(parsedError.data.message)

      context.commit(AUTH_ERROR, messageParsed)
      switch (messageParsed.pinError) {
        case 'TTL_EXPIRED':
        case 'WRONG_PIN':
          store.dispatch(TOGGLE_OVERLAY, false)
          break
        case 'NO_MORE_PIN_ATTEMPTS':
          //TODO block input, user cant type more
          store.dispatch(TOGGLE_OVERLAY, false)
          break
        case 'Usuario no es válido.':
          router.push({ name: 'Form' })
          store.dispatch(TOGGLE_OVERLAY, false)
          break
        case 'Too many requests':
          router.push({ name: 'CantProcessRequest' })
          store.dispatch(TOGGLE_OVERLAY, false)
          break
        default:
          store.dispatch(TOGGLE_OVERLAY, false)
      }
    })

    if (parsedError || messageParsed) return Promise.reject('Error al validar OTP')
  },

  [REFRESH_TOKEN](context) {
    context.commit(REFRESH_TOKEN_REQUEST)
    const body = { refresh_token: context.getters.refreshToken, grant_type: 'refresh_token' }
    return apiAuth
      .postOauthToken(body)
      .then((response) => {
        context.commit(AUTH_SUCCESS, response.data)
        context.commit(REFRESH_TOKEN_SUCCESS, response.data)
      })
      .catch((e) => {
        context.commit(REFRESH_TOKEN_ERROR)
        router.push({ name: 'ExceededSessionTime' })
        store.dispatch(TOGGLE_OVERLAY, false)
      })
  },
  [DESTROY_TOKEN](context) {
    context.commit(DESTROY_TOKEN_MUTATION)
  },
  [RESET_AUTH_ERROR](context) {
    context.commit(MUTATE_AUTH_ERROR, '')
  },
}

const getters = {
  authStatus: (state) => state.authStatus,
  authError: (state) => state.authError,
  authErrorPin: (state) => state.authErrorPin,
  isAuthenticated: (state) => state.isAuthenticated,
  channelOrigin: (state) => state.channelOrigin,
  accessToken: (state) => state.accessToken,
  refreshToken: (state) => state.refreshToken,
  refreshTokenStatus: (state) => state.refreshTokenStatus,
  documentNumber: (state) => state.documentNumber,
}

export default {
  state,
  mutations,
  actions,
  getters,
}
