<template>
  <b-modal
    :id="id"
    class="modal"
    cancel-title=""
    cancel-variant="hidden"
    size="sm"
    hide-header
    hide-footer
    ok-title=""
    ok-variant="hidden"
    centered
    header-border-variant="white"
    footer-border-variant="white"
    no-close-on-esc
    no-close-on-backdrop
  >
    <div class="mx-3">
      <b-row align-h="end">
        <b-button v-if="modalType" variant="blank" @click="closeApp">
          <b-img src="@/assets/icons/cancel.svg" alt="Cancel icon" />
        </b-button>
        <b-button v-else variant="blank" @click="closeModal(id)">
          <b-img src="@/assets/icons/cancel.svg" alt="Cancel icon" />
        </b-button>
      </b-row>
      <div v-if="title" class="py-5">
        <p class="text-center title-onboard">{{ title }}</p>
        <p class="text-center subtitle-onboard mx-4">{{ subtitle }}</p>
        <b-img class="pt-4" center align-h="center" :src="require(`@/assets/images/${image}`)" alt="Modal image" />
      </div>
      <div v-else class="py-5">
        <p class="text-center subtitle-onboard mx-2">{{ subtitle }}</p>
        <b-img class="pt-5 pb-3" center align-h="center" :src="require(`@/assets/images/${image}`)" alt="Modal image" />
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    id: String,
    title: String,
    subtitle: String,
    image: String,
    modalType: {
      type: Boolean,
      default: false,
    },
    function: { type: Function },
  },
  methods: {
    closeModal(id) {
      this.$bvModal.hide(id)
    },
    closeApp() {
      this.$router.push({ name: 'Form' })
      this.function()
    },
  },
}
</script>
