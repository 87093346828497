<template>
  <div>
    <b-row class="py-4 mb-2">
      <b-col>
        <b-img src="@/assets/images/money.png" fluid alt="Movistar money logo" class="movistar-image-login" />
      </b-col>
    </b-row>
    <p class="login-title blue-text mb-4 pb-2">Ingresa a tu cuenta Movistar Money</p>
  </div>
</template>

<script>
export default {
  name: 'MovistarMoneyLogoLogin',
}
</script>
