<template>
  <b-card border-variant="light">
    <div class="card-title">
      <h4 class="title">Estado de cliente</h4>
      <span class="tip">
        {{ $t(`situation.${responseData.situation}`) }}
        <img class="ml-3" src="@/assets/icons/check-circle-white.svg" alt="Check icon" width="26px" />
      </span>
    </div>
  </b-card>
</template>

<script setup>
import { computed } from 'vue'
import Button from '@/components/PreValidacao/Button.vue'
import store from '@/store'

const responseData = computed(() => store.getters.buscadorBasicData)
</script>

<style scoped lang="scss">
.card {
  max-width: 41.25rem;
  margin: 0 0;
  background-color: #019df4;
  border-radius: 10px;

  .card-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
    color: white;
    .title {
      font-size: 25px;
      margin-bottom: 0;
      font-weight: bold;
    }
    .tip {
      font-size: 25px;
    }
  }

  .card-body {
    padding: 23px 41px;
  }
}
</style>
