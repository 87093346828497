import Api from '../plugins/api'
import apiAuth from '../autenticacao/api.auth'
import { datadogRum } from '@datadog/browser-rum'
import {
  FETCH_APPLICATION,
  FETCH_APPLICATION_REDIRECT,
  FETCH_TERMS_CONDITIONS,
  NEW_PIN,
  SEND_FORM,
  CHANGE_STATUS,
  TOGGLE_OVERLAY,
  FETCH_CONTRACT,
  FETCH_CONTRACT_ASSISTED,
  CREATE_CONTRACT,
  CRIAR_CONTRATO_LIBRE,
  FETCH_URL_CALLBACK,
  FETCH_MY_IP,
  BUSCAR_MINHA_LOCALIZACAO,
  CHANGE_DEVICE,
  REDIRECT_USER,
  SAVE_CONTRACT_TERMS,
  NEW_PIN_SELF_MANAGE,
  SEND_LOG_FRONT,
  BUSCAR_CONTRA_OFERTA,
  SALVAR_DADOS_BANCARIOS,
  SALVAR_CONTRA_OFERTA,
  ACTION_FETCH_OFFER_ASSISTED,
  ACTION_WORKFLOW_STATUS_ASSISTED,
  FETCH_APPLICATION_ASSISTED,
  BUSCAR_BANCOS,
  ACTION_LISTAR_OFERTAS,
  ACTION_POST_REQUEST_VALUE,
  VERIDAS_CRIAR_TOKEN,
  VERIDAS_VALIDAR_ID,
  CONFIRMAR_ACEITE_CLIENTE_ASSISTED,
  SAVE_CONTRACT_FIANCA,
  FETCH_PRE_OFFER_ASSISTED,
  BUSCAR_PARAMETROS_CANAL,
  VERIDAS_ASSISTIDO_VALIDAR_URL,
  ACTION_VALIDAR_VERIDAS_ASSISTED,
  VERIDAS_ASSISTIDO_INVALIDAR_URL,
} from './actions.type'
import {
  GET_APPLICATION_SUCCESS,
  POST_APPLICATION_SUCCESS,
  SET_POST_APPLICATION_FORM,
  SET_TERMS_CONDITIONS,
  POST_APPLICATION_ERROR,
  FORM_ERROR_ARRAY,
  SET_OVERLAY,
  SET_CONTRACT,
  NEW_PIN_ERROR,
  SET_URL_CALLBACK,
  SET_MY_IP,
  DEFINIR_MINHA_LOCALIZACAO,
  CONTRACT_TERMS,
  MUTATE_APPLICATION_EMAIL,
  MUTATE_APPLICATION_ID,
  MUTATION_SET_ARRAY_RATE,
  MUTATION_SELECT_OFFER,
  MUTATION_COUNTER_OFFER,
  MUTATION_CHANGE_AGAINST_INPUT_OFFER,
  MUTATION_CONTRA_OFERTA,
  MUTATION_MAIS_INFORMACOES,
  MUTATION_OFFER_ASSISTED,
  MUTATION_SELECTED_ASSISTED,
  MUTATION_RESPONSE_CHANGE_STATUS_ASSISTED,
  MUTATION_BANKS,
  PHONE_DETAILS_ULR,
  MUTATION_LISTAR_OFERTAS,
  MUTATION_SELECTED_BUTTONS_ASSISTED,
  MUTATION_SIMULADOR_ASSISTIDO,
  MUTATION_VERIDAS_TOKEN,
  SET_ADVANCED_PAYMENT,
  SET_ADVANCED_PAYMENT_FMT,
  SET_FINANCED_AMOUNT,
  SET_FINANCED_AMOUNT_FMT,
  SET_CHANGED_ADVANCED_PAYMENT,
  CONTRACT_FIANCA,
  SET_REAL_FINANCED_AMOUNT,
  SET_REAL_FINANCED_AMOUNT_FMT,
  SET_GUARANTEE_PAYMENT,
  SET_GUARANTEE_PAYMENT_FMT,
  SET_CALCULATED_GUARANTEE_PAYMENT_IVA,
  SET_CALCULATED_GUARANTEE_PAYMENT_IVA_FMT,
  SET_GUARANTEE_PAYMENT_TOTAL,
  SET_GUARANTEE_PAYMENT_TOTAL_FMT,
} from './mutations.type'

import { Persist } from '../helpers/persist.service'
import { sendFormHandler } from '../helpers/apiHandler'
import router from '../router'
import store from './index'

const state = {
  termsAndConditions: '',
  contract: '',
  postApplicationResponse: '',
  postApplicationError: '',
  pin: '',
  overlay: false,
  newPinError: '',
  ip: '',
  localizacao: {},
  arrayRate: [],
  applicationEmail: '',
  urlCallback: Persist.getUrlCallback(),
  postApplicationForm: Persist.getPostApplicationForm(),
  currentApplication: Persist.getCurrentApplication(),
  applicationStatus: Persist.getApplicationStatus(),
  applicationId: Persist.getApplicationId(),
  applicationOffer: Persist.getApplicationOffer(),
  phoneDetails: Persist.getPhoneDetails(),
  contractTerms: Persist.getContractTerms(),
  contractFianca: false,
  formErrorArray: [],
  counterOfferId: '',
  counterOfferData: {},
  banks: [],
  arrayOption: [],
  minAgainstOffer: '',
  maxAgainstOffer: '',
  valueAgainstOffer: 0,
  intendedInstallments: -1,
  valueAgainsOfferFmt: 'Aguardando valor',
  lastArrayAgainsOffer: [],
  formContraOferta: {},
  moneyResumo: {},
  lastOffer: [],
  maisInformacoes: false,
  offerAssisted: {},
  footerInformationAssisted: {},
  responseChangeStatusAssisted: {},
  phoneDetailtUrl: Persist.getPhoneDetailsUrlAssisted(),
  listarOfertasAssistido: {},
  selectedOfferAssisted: Persist.getDetailsAssisted(),
  selectButtonsAssisted: Persist.getSelectButtonsAssisted(),
  responseSimuladorAssistido: [],
  responseSimuladorAssistidoOferta: [],
  veridasAccessToken: '',
}

const mutations = {
  [MUTATION_SIMULADOR_ASSISTIDO](state, response) {
    state.responseSimuladorAssistido = response
    state.responseSimuladorAssistidoOferta = response.data.calculator
  },
  [MUTATION_SELECTED_BUTTONS_ASSISTED](state, payload) {
    Persist.saveSelectButtonsAssisted(payload)
    state.selectButtonsAssisted = payload
  },
  [MUTATION_SELECTED_ASSISTED](state, payload) {
    Persist.saveDetailsAssisted(payload)
    state.selectedOfferAssisted = payload
  },
  [MUTATION_LISTAR_OFERTAS](state, response) {
    switch (response.situation) {
      case 'reproved':
        router.push({
          name: 'MensagemReprovacao',
          params: { data: response, updateMode: true, editionMode: true },
        })
        break
    }
    state.listarOfertasAssistido = response.response.offers
  },
  [PHONE_DETAILS_ULR](state, payload) {
    state.phoneDetailtUrl = payload
    Persist.savePhoneDetailsUrlAssisted(payload)
  },
  [MUTATION_RESPONSE_CHANGE_STATUS_ASSISTED](state, response) {
    state.responseChangeStatusAssisted = response
    switch (response.situation) {
      case 'infolaftFailure':
      case 'reproved':
        router.push({
          name: 'MensagemReprovacao',
          params: { data: response, updateMode: true, editionMode: true },
        })
        break
      case 'offer':
        router.push({ name: 'OfertaAssistido' })
        break
      case 'documents':
        router.push({ name: 'VeridasAssistido' }).catch((err) => {})
        break
      case 'contract':
        router.push({ name: 'ContratoAssistido' })
        break
      case 'evidente':
        router.push({ name: 'EvidenteDataAssistido' })
        break
      case 'canceled':
        window.location.replace('https://www.movistarmoney.co/')
        break
      case 'waitingClient':
        router.push({ name: 'OTPAssistido' })
        break
    }
  },
  [MUTATION_MAIS_INFORMACOES](state, payload) {
    state.maisInformacoes = payload
  },
  [MUTATION_VERIDAS_TOKEN](state, response) {
    state.veridasAccessToken = response.response.access_token
  },
  [MUTATION_COUNTER_OFFER](state, response) {
    state.counterOfferData = response
    state.minAgainstOffer = response.response.calculator.minimumValue
    state.maxAgainstOffer = response.response.calculator.maximumValue
    state.arrayOption = response.response.calculator.options

    const selectedOffer = state.arrayOption.find((option) => {
      return option.value === state.currentApplication.intendedRequestedValue
    })

    state.intendedInstallments = selectedOffer ? state.currentApplication.intendedInstallmentsNumber : null
    state.valueAgainstOffer = selectedOffer
      ? state.currentApplication.intendedRequestedValue
      : response.response.calculator.maximumValue
    state.lastArrayAgainsOffer =
      response.response.calculator.options[response.response.calculator.options.length - 1].offers
    state.lastArrayAgainsOffer[state.lastArrayAgainsOffer.length - 1].isActive = true
    state.lastOffer = state.lastArrayAgainsOffer[state.lastArrayAgainsOffer.length - 1]
  },
  [MUTATION_BANKS](state, response) {
    state.banks = response
  },
  [SET_TERMS_CONDITIONS](state, response) {
    state.termsAndConditions = response
  },
  [MUTATION_SET_ARRAY_RATE](state, payload) {
    state.arrayRate.shift()
    state.arrayRate.push(JSON.parse(JSON.stringify(payload)))
  },
  [SET_CONTRACT](state, response) {
    state.contract = response
  },
  [GET_APPLICATION_SUCCESS](state, response) {
    if (response.status === 'FalhaInfolaft' || response.status === 'Reprovada')
      router.push({ name: 'CantProcessRequest' })

    state.currentApplication = response
    state.applicationStatus = response.status
    state.applicationId = response.applicationId

    state.applicationEmail = response.email

    if (response.offer) state.applicationOffer = response.offer

    if (response.access) {
      state.phoneDetails = {
        phoneNumber: response.access.phoneNumber,
        price: response.access.price,
        shippingCost: response.access.shippingCost,
        ...response.access.equipments[0],
      }
    }

    if (response.urlReturn) Persist.saveUrlCallback(response.urlReturn)

    Persist.saveCurrentApplication(response)
    Persist.saveApplicationStatus(response.status)
    Persist.saveApplicationId(response.applicationId)
    Persist.saveApplicationOffer(response.offer)
    Persist.savePhoneDetails(response.access)
  },
  [POST_APPLICATION_SUCCESS](state, response) {
    state.postApplicationResponse = response
    state.applicationId = response.data.applicationId
    Persist.saveApplicationId(response.data.applicationId)
  },
  [SET_POST_APPLICATION_FORM](state, form) {
    state.postApplicationForm = form
    Persist.savePostApplicationForm(form)
  },
  [MUTATION_CHANGE_AGAINST_INPUT_OFFER](state, value) {
    state.valueAgainstOffer = value
    state.arrayOption.filter((option) => {
      if (option.value == value) {
        state.valueAgainsOfferFmt = option.valueFmt
      }
    })
  },
  [POST_APPLICATION_ERROR](state, response) {
    state.postApplicationError = response.data.message
  },
  [FORM_ERROR_ARRAY](state, fields) {
    state.formErrorArray = fields
  },
  [SET_OVERLAY](state, bool) {
    state.overlay = bool
  },
  [NEW_PIN_ERROR](state, error) {
    state.newPinError = error
  },
  [SET_URL_CALLBACK](state, urlCallback) {
    state.urlCallback = urlCallback
    Persist.saveUrlCallback(urlCallback)
  },
  [SET_MY_IP](state, response) {
    state.ip = response
  },
  [DEFINIR_MINHA_LOCALIZACAO](state, response) {
    state.localizacao = response.location
  },
  [CONTRACT_TERMS](state) {
    state.contractTerms = true
    Persist.saveContractTerms(true)
  },
  [CONTRACT_FIANCA](state) {
    state.contractFianca = true
  },
  [MUTATE_APPLICATION_EMAIL](state, email) {
    state.applicationEmail = email
  },
  [MUTATION_CONTRA_OFERTA](state, payload) {
    state.formContraOferta = payload
  },
  [MUTATE_APPLICATION_ID](state, id) {
    state.applicationId = id
  },
  [MUTATION_OFFER_ASSISTED](state, responseOfferAssisted) {
    state.offerAssisted = responseOfferAssisted
  },
  [MUTATION_SELECT_OFFER](state, payload) {
    if (payload) {
      state.selectOffer = {
        intendedInstallmentsNumber: payload.installment,
        origin: 'Libre',
        intendedValue: payload.intendedValue,
        id_access: payload.accessId,
      }
    } else {
      state.selectOffer = {
        origin: 'Finter',
      }
    }
  },
}
const actions = {
  [ACTION_POST_REQUEST_VALUE](context, payload) {
    store.dispatch(TOGGLE_OVERLAY, true)

    Api.postSimuladorAssistido(payload)
      .then((response) => {
        context.commit(MUTATION_SIMULADOR_ASSISTIDO, response)
      })
      .catch((error) => {
        context.commit(MUTATION_SIMULADOR_ASSISTIDO, error.response)
      })
    store.dispatch(TOGGLE_OVERLAY, false)
  },

  async [ACTION_LISTAR_OFERTAS](context, payload) {
    if (state.applicationId) {
      store.dispatch(TOGGLE_OVERLAY, true)
      await Api.postWorkflowActionAssisted(state.applicationId, payload)
        .then((response) => {
          context.commit(SET_ADVANCED_PAYMENT, response.data.response.advancedPayment)
          context.commit(SET_ADVANCED_PAYMENT_FMT, response.data.response.advancedPaymentFmt)
          context.commit(SET_FINANCED_AMOUNT, response.data.response.financedAmount)
          context.commit(SET_FINANCED_AMOUNT_FMT, response.data.response.financedAmountFmt)
          context.commit(SET_CHANGED_ADVANCED_PAYMENT, response.data.response.changedAdvancedPayment)
          context.commit(SET_REAL_FINANCED_AMOUNT, response.data.response.realFinancedAmount)
          context.commit(SET_REAL_FINANCED_AMOUNT_FMT, response.data.response.realFinancedAmountFmt)
          context.commit(SET_GUARANTEE_PAYMENT, response.data.response.guaranteePayment)
          context.commit(SET_GUARANTEE_PAYMENT_FMT, response.data.response.guaranteePaymentFmt)
          context.commit(SET_CALCULATED_GUARANTEE_PAYMENT_IVA, response.data.response.calculatedGuaranteePaymentIva)
          context.commit(
            SET_CALCULATED_GUARANTEE_PAYMENT_IVA_FMT,
            response.data.response.calculatedGuaranteePaymentIvaFmt
          )
          context.commit(SET_GUARANTEE_PAYMENT_TOTAL, response.data.response.guaranteePaymentTotal)
          context.commit(SET_GUARANTEE_PAYMENT_TOTAL_FMT, response.data.response.guaranteePaymentTotalFmt)
          context.commit(MUTATION_LISTAR_OFERTAS, response.data)
          store.dispatch(TOGGLE_OVERLAY, false)
        })
        .catch((e) => {
          router.push({ name: 'CantProcessRequest' })
          console.log(e)
          store.dispatch(TOGGLE_OVERLAY, false)
        })
    }
  },
  [ACTION_VALIDAR_VERIDAS_ASSISTED](context, payload) {
    store.dispatch(TOGGLE_OVERLAY, true)
    return Api.postWorkflowActionAssisted(state.applicationId, payload)
      .then((response) => {
        store.dispatch(TOGGLE_OVERLAY, false)
        return response.data
      })
      .catch((e) => {
        console.log(e)
        store.dispatch(TOGGLE_OVERLAY, false)
      })
  },
  [ACTION_WORKFLOW_STATUS_ASSISTED](context, payload) {
    store.dispatch(TOGGLE_OVERLAY, true)
    return Api.postWorkflowActionAssisted(state.applicationId, payload)
      .then((response) => {
        context.commit(MUTATION_RESPONSE_CHANGE_STATUS_ASSISTED, response.data)
        store.dispatch(TOGGLE_OVERLAY, false)
        return response.data
      })
      .catch((e) => {
        console.log(e)
        store.dispatch(TOGGLE_OVERLAY, false)
      })
  },
  [ACTION_FETCH_OFFER_ASSISTED](context, accessId) {
    store.dispatch(TOGGLE_OVERLAY, true)
    Api.getOfferAssisted(accessId)
      .then((response) => {
        context.commit(MUTATION_OFFER_ASSISTED, response.data.calculator)
        store.dispatch(TOGGLE_OVERLAY, false)
      })
      .catch((e) => {
        console.log(e)
        store.dispatch(TOGGLE_OVERLAY, false)
      })
  },
  async [VERIDAS_ASSISTIDO_VALIDAR_URL](context, params) {
    let retorno = {
      urlValida: true,
      erro: null,
    }
    await Api.validarEmailVeridas(params.idProposta, params.hashEmail)
      .then((response) => {
        retorno.urlValida = true
      })
      .catch((e) => {
        retorno.urlValida = false
        retorno.erro = e.response
      })
    return retorno
  },
  async [VERIDAS_ASSISTIDO_INVALIDAR_URL](context, params) {
    await Api.invalidarEmailVeridas(params.idProposta, params.hashEmail)
  },
  [VERIDAS_CRIAR_TOKEN](context) {
    store.dispatch(TOGGLE_OVERLAY, true)
    Api.getVeridasToken(state.applicationId)
      .then((response) => {
        context.commit(MUTATION_VERIDAS_TOKEN, response.data)
        store.dispatch(TOGGLE_OVERLAY, false)
      })
      .catch((e) => {
        console.log(e)
        router.push({ name: 'CantProcessRequest' })
        store.dispatch(TOGGLE_OVERLAY, false)
      })
  },
  [VERIDAS_VALIDAR_ID](context, validationId) {
    store.dispatch(TOGGLE_OVERLAY, true)
    Api.getVeridasValidarId(state.applicationId, validationId)
      .then((response) => {
        if (response.data.situation == 'reproved') {
          router.push({
            name: 'MensagemReprovacao',
            params: { data: response.data, updateMode: true, editionMode: true },
          })
        } else context.dispatch(FETCH_APPLICATION_REDIRECT)
      })
      .catch((e) => {
        router.push({ name: 'CantProcessRequest' })
        console.log(e)
      })
    store.dispatch(TOGGLE_OVERLAY, false)
  },
  [BUSCAR_CONTRA_OFERTA](context) {
    store.dispatch(TOGGLE_OVERLAY, true)
    Api.executeAction(state.applicationId, { action: 'listOfferLibre' })
      .then((response) => {
        if (response.data.situation === 'reproved') {
          router.push({
            name: 'MensagemReprovacao',
            params: { data: response.data, updateMode: true, editionMode: true },
          })
          store.dispatch(TOGGLE_OVERLAY, false)
        } else {
          context.commit(MUTATION_COUNTER_OFFER, response.data)
          store.dispatch(TOGGLE_OVERLAY, false)
        }
      })
      .catch((e) => {
        console.log(e)
        //router.push({name: "CantProcessRequest"})
        store.dispatch(TOGGLE_OVERLAY, false)
      })
  },
  [BUSCAR_BANCOS](context) {
    store.dispatch(TOGGLE_OVERLAY, true)
    Api.getBanks()
      .then((response) => {
        context.commit(MUTATION_BANKS, response.data)
        store.dispatch(TOGGLE_OVERLAY, false)
      })
      .catch((e) => {
        console.log(e), store.dispatch(TOGGLE_OVERLAY, false)
      })
  },
  [SALVAR_DADOS_BANCARIOS](context, payload) {
    Api.executeAction(state.applicationId, payload)
      .then((response) => {
        if (response.data.situation == 'bankData') {
          Api.validarDadosBancarios(state.applicationId).then((response) => {
            if (response.data.situation == 'reproved') {
              router.push({
                name: 'MensagemReprovacao',
                params: { data: response.data, updateMode: true, editionMode: true },
              })
            } else context.dispatch(FETCH_APPLICATION_REDIRECT)
          })
        }
      })
      .catch((e) => {
        console.log(e), store.dispatch(TOGGLE_OVERLAY, false)
      })
      .finally(() => {
        store.dispatch(TOGGLE_OVERLAY, false)
      })
  },
  [SALVAR_CONTRA_OFERTA](context) {
    Api.executeAction(state.applicationId, state.formContraOferta).then((response) => {
      if (response.data.situation == 'reproved') {
        router.push({
          name: 'MensagemReprovacao',
          params: { data: response.data, updateMode: true, editionMode: true },
        })
      } else context.dispatch(FETCH_APPLICATION_REDIRECT)
    })
  },
  [FETCH_TERMS_CONDITIONS](context, origin) {
    Api.getTermsAndConditions(origin)
      .then((response) => {
        context.commit(SET_TERMS_CONDITIONS, response.data)
      })
      .catch((e) => console.log(e))
  },
  [FETCH_CONTRACT](context) {
    Api.getContract(state.applicationId)
      .then((response) => {
        context.commit(SET_CONTRACT, response.data)
      })
      .catch((e) => console.log(e))
  },
  [FETCH_CONTRACT_ASSISTED](context) {
    Api.getContractAssisted(state.applicationId)
      .then((response) => {
        context.commit(SET_CONTRACT, response.data)
      })
      .catch((e) => console.log(e))
  },
  [FETCH_APPLICATION_REDIRECT](context) {
    Api.getApplication(state.applicationId)
      .then((response) => {
        context.commit(GET_APPLICATION_SUCCESS, response.data)
        context.dispatch(REDIRECT_USER, response.data.status)
        store.dispatch(TOGGLE_OVERLAY, false)
      })
      .catch((e) => console.log(e))
  },
  [FETCH_APPLICATION](context) {
    Api.getApplication(state.applicationId)
      .then((response) => {
        context.commit(GET_APPLICATION_SUCCESS, response.data)
      })
      .catch((e) => console.log(e))
  },
  [FETCH_APPLICATION_ASSISTED](context) {
    if (state.applicationId) {
      Api.getApplicationAssisted(state.applicationId)
        .then((response) => {
          context.commit(GET_APPLICATION_SUCCESS, response.data)
        })
        .catch((e) => console.log(e))
    } else {
      router.push({ name: 'ExceededSessionTime' })
    }
  },
  async [SEND_FORM]({ commit, dispatch, getters }, form) {
    store.dispatch(TOGGLE_OVERLAY, true)
    await Api.postApplication(form, getters.ip)
      .then(async (response) => {
        if (response.data.situation == 'reproved') {
          router.push({
            name: 'MensagemReprovacao',
            params: { data: { response: response.data }, updateMode: true, editionMode: true },
          })
          return
        }
        commit(SET_POST_APPLICATION_FORM, form)
        commit(POST_APPLICATION_SUCCESS, response.data)
        if (process.env.VUE_APP_DD_ENV === 'prod') {
          datadogRum.setUser({
            id: response.data.data.applicationId,
          })
        }

        if (form.origin !== 'Assistido') {
          sendFormHandler(response)
        } else {
          const situation = await dispatch(FETCH_PRE_OFFER_ASSISTED)
          if (situation === 'preOffer') router.push({ name: 'PreOfertaAssistido' })
          else if (situation === 'offer') router.push({ name: 'OfertaAssistido' })
          else if (response.data.situation == 'reproved') {
            router.push({
              path: '/error-assisted-request',
              name: 'ErroAssistidoRequest',
              params: { data: { response: response.data }, updateMode: true, editionMode: true },
            })
          }
        }
      })
      .catch((e) => {
        const error = JSON.parse(e.message)
        if (error.detail == 'Validation exception') {
          commit(FORM_ERROR_ARRAY, error.fields)
          setTimeout(() => {
            commit(FORM_ERROR_ARRAY, [])
          }, 8000)
        } else {
          commit(POST_APPLICATION_ERROR, error)
          sendFormHandler(error)
        }
      })
      .finally(() => {
        store.dispatch(TOGGLE_OVERLAY, false)
      })
  },
  [NEW_PIN](context, backupRoute) {
    if (!context.getters.applicationId) router.push({ name: backupRoute })

    store.dispatch(TOGGLE_OVERLAY, true)
    apiAuth
      .newPin(context.getters.applicationId)
      .then((response) => {
        store.dispatch(TOGGLE_OVERLAY, false)
      })
      .catch((e) => {
        store.dispatch(TOGGLE_OVERLAY, false)
        const error = JSON.parse(e.message)
        context.commit(NEW_PIN_ERROR, error.data)
        if (error.data.errorCode === '424') {
          router.push({ name: 'ManyAttempts' })
        }
      })
  },

  [NEW_PIN_SELF_MANAGE](context, payload) {
    if (!payload.hash) router.push({ name: payload.backupRoute })
    store.dispatch(TOGGLE_OVERLAY, true)
    apiAuth
      .newPinSelfManage(payload.hash)
      .then((response) => {
        store.dispatch(TOGGLE_OVERLAY, false)
      })
      .catch((e) => {
        store.dispatch(TOGGLE_OVERLAY, false)
        const error = JSON.parse(e.message)
        context.commit(NEW_PIN_ERROR, error.data)
        if (error.data.errorCode === '424') {
          router.push({ name: 'ManyAttempts' })
        }
      })
  },
  [CHANGE_STATUS](context, payload) {
    store.dispatch(TOGGLE_OVERLAY, true)
    Api.changeStatus(context.getters.applicationId, payload.status)
      .then(() => {
        store.dispatch(TOGGLE_OVERLAY, false)
        context.dispatch(FETCH_APPLICATION_REDIRECT, payload.origin)
      })
      .catch((e) => console.log(e))
  },
  [TOGGLE_OVERLAY](context, bool) {
    context.commit(SET_OVERLAY, bool)
  },
  [CREATE_CONTRACT](context, body) {
    Api.executeAction(context.getters.applicationId, body)
      .then((response) => {
        if (response.status === 200) {
          let situation
          if (response.data.situation) situation = response.data.situation.toLowerCase()

          if (situation === 'reproved') {
            if (response.data.response)
              router.push({
                name: 'MensagemReprovacao',
                params: {
                  data: {
                    response: response.data.response,
                  },
                  updateMode: true,
                  editionMode: true,
                },
              })
            else router.push({ name: 'CantProcessRequest' })
            store.dispatch(TOGGLE_OVERLAY, false)
          } else if (
            situation === 'deviceunavailable' ||
            situation === 'refusedpaymentorder' ||
            situation === 'inconsistentprice' ||
            situation === 'infolaftfailure'
          ) {
            router.push({ name: 'CantProcessRequest' })
            store.dispatch(TOGGLE_OVERLAY, false)
          } else {
            setTimeout(() => {
              router.push({ name: 'Details' })
              store.dispatch(TOGGLE_OVERLAY, false)
            }, 5000)
          }
        }
      })
      .catch((e) => console.log(e))
  },
  [CRIAR_CONTRATO_LIBRE](context, body) {
    Api.executeAction(context.getters.applicationId, body)
      .then((response) => {
        if (response.status === 200) {
          let situation
          if (response.data.situation) situation = response.data.situation.toLowerCase()

          if (situation === 'reproved' || situation === 'infolaftfailure') {
            router.push({ name: 'CantProcessRequest' })
            store.dispatch(TOGGLE_OVERLAY, false)
          } else {
            setTimeout(() => {
              store.dispatch(FETCH_APPLICATION_REDIRECT, 'Libre')
              store.dispatch(TOGGLE_OVERLAY, false)
            }, 5000)
          }
        }
      })
      .catch((e) => console.log(e))
  },

  [FETCH_URL_CALLBACK](context, urlCallback) {
    context.commit(SET_URL_CALLBACK, urlCallback)
  },
  [FETCH_MY_IP](context) {
    Api.getMyIP()
      .then((response) => {
        context.commit(SET_MY_IP, response)
      })
      .catch((e) => console.log(e))
  },
  async [BUSCAR_MINHA_LOCALIZACAO](context, { canal }) {
    const geoLocalizacaoAtiva = await store.dispatch(BUSCAR_PARAMETROS_CANAL, {
      nomeParametro: 'activeCepsRules',
      canal,
    })
    if (geoLocalizacaoAtiva == 'true') {
      Api.getMinhaLocalizacao()
        .then((response) => {
          context.commit(DEFINIR_MINHA_LOCALIZACAO, response)
        })
        .catch((e) => console.log(e))
    }
  },
  [CHANGE_DEVICE](context, body) {
    Api.executeAction(context.getters.applicationId, body)
      .then((response) => {
        if (context.getters.urlCallback) {
          window.location.replace(context.getters.urlCallback)
        } else {
          window.location.replace('https://www.movistarmoney.co/')
        }
      })
      .catch((e) => console.log(e))
  },
  [SAVE_CONTRACT_TERMS](context) {
    context.commit(CONTRACT_TERMS)
  },
  [SAVE_CONTRACT_FIANCA](context) {
    context.commit(CONTRACT_FIANCA)
  },
  [SEND_LOG_FRONT](context, body) {
    Api.sendError(context, body)
  },
  [CONFIRMAR_ACEITE_CLIENTE_ASSISTED]({ dispatch }) {
    dispatch(ACTION_WORKFLOW_STATUS_ASSISTED, { action: 'confirmClientAccept', isPartial: false })
    router.push({ name: 'ResumoAssistido' })
  },
}

const getters = {
  arrayCuotas: (state) => state.arrayCuotas,
  termsAndConditions: (state) => state.termsAndConditions,
  contract: (state) => state.contract,
  postApplicationResponse: (state) => state.postApplicationResponse,
  postApplicationForm: (state) => state.postApplicationForm,
  postApplicationError: (state) => state.postApplicationError,
  applicationStatus: (state) => state.applicationStatus,
  applicationId: (state) => state.applicationId,
  phoneDetails: (state) => state.phoneDetails,
  overlayStatus: (state) => state.overlay,
  newPinError: (state) => state.newPinError,
  currentApplication: (state) => state.currentApplication,
  applicationOffer: (state) => state.applicationOffer,
  urlCallback: (state) => state.urlCallback,
  ip: (state) => state.ip,
  localizacao: (state) => state.localizacao,
  contractTerms: (state) => state.contractTerms,
  contractFianca: (state) => state.contractFianca,
  applicationEmail: (state) => state.applicationEmail,
  formErrorArray: (state) => state.formErrorArray,
  selectOffer: (state) => state.selectOffer,
  counterOfferData: (state) => state.counterOfferData,
  arrayOption: (state) => state.counterOfferData.response.calculator.options,
  minAgainstOffer: (state) => state.minAgainstOffer,
  maxAgainstOffer: (state) => state.maxAgainstOffer,
  valueAgainstOffer: (state) => state.valueAgainstOffer,
  valueAgainsOfferFmt: (state) => state.valueAgainsOfferFmt,
  lastArrayAgainsOffer: (state) => state.lastArrayAgainsOffer,
  moneyResumo: (state) => state.moneyResumo,
  lastOffer: (state) => state.lastOffer,
  maisInformacoes: (state) => state.maisInformacoes,
  completeDirection: (state) => state.completeDirection,
  offerAssisted: (state) => state.offerAssisted,
  footerInformationAssisted: (state) => state.footerInformationAssisted,
  intendedInstallments: (state) => state.intendedInstallments,
  responseChangeStatusAssisted: (state) => state.responseChangeStatusAssisted,
  banks: (state) => state.banks,
  phoneDetailtUrl: (state) => state.phoneDetailtUrl,
  listarOfertasAssistido: (state) => state.listarOfertasAssistido,
  selectedOfferAssisted: (state) => state.selectedOfferAssisted,
  selectButtonsAssisted: (state) => state.selectButtonsAssisted,
  responseSimuladorAssistidoOferta: (state) => state.responseSimuladorAssistidoOferta,
  responseSimuladorAssistido: (state) => state.responseSimuladorAssistido,
  veridasAccessToken: (state) => state.veridasAccessToken,
}

export default {
  state,
  mutations,
  actions,
  getters,
}
