<template>
  <div>
    <money v-model="price" v-bind="money" class="form-input input-lg" hidden></money>
    {{ price }}
  </div>
</template>

<script>
import { Money } from 'v-money'

export default {
  name: 'V-Money',
  components: { Money },
  props: {
    value: Number,
  },
  created() {
    this.price = this.value
  },
  data() {
    return {
      price: 0,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: '$',
        suffix: '',
        precision: 0,
        masked: true,
      },
    }
  },
}
</script>
