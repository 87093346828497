<template>
  <div class="anticipo-assistido-form">
    <div class="d-flex justify-content-center align-items-center">
      <div class="d-flex flex-column align-items-center">
        <div align-h="center" align-v="center" class="header-titulo-anticipo">
          <img src="@/assets/icons/money.svg" alt="Money icon" class="mr-2" />
          <h1>Pago inicial del equipo</h1>
        </div>
        <b-card>
          <template #header>
            <span v-text="phoneDetailtUrl.deviceModel[0]" />
            <div class="divisor-vertical"></div>
            <span class="value" v-text="phoneDetailtUrl.price" />
          </template>

          <label for="valorAntecipado" class="form-label">Valor a pagar para financiar el restante</label>
          <b-input-group class="input" :class="{ 'input-error': !valorValido }">
            <b-input-group-prepend class="d-flex justify-content-center align-items-center">
              <img src="@/assets/icons/cifrao.svg" alt="Dollar icon" height="18" />
            </b-input-group-prepend>
            <CurrencyInput v-model="valorAntecipado" :precision="0" />
          </b-input-group>
          <b-form-text class="input-help" :class="{ 'input-error': !valorValido }">
            <span v-if="valorAntecipado > maximumAdvancedPayment">
              No puedes anticipar más de {{ maximumAdvancedPaymentFmt }}<br />
            </span>
            <span v-if="advancedPayment > valorAntecipado">
              No puedes anticipar menos de {{ advancedPaymentFmt }}<br />
            </span>
            <span v-if="advancedPayment > 0 && valorValido">El valor mínimo es de {{ advancedPaymentFmt }}<br /></span>
            <span v-if="currentApplication.intendedRequestedValue - valorAntecipado < minimumOrderValue">
              El valor de la orden es menor de {{ minimumOrderValueFmt }}
            </span>
          </b-form-text>

          <!-- <label class="form-label mt-4" for="valorAntecipado">Selecciona el medio de pago</label>
          <div class="d-flex radio-custom">
            <fieldset class="mr-4">
              <input type="radio" id="tarjeta" value="Tarjeta-PSE" v-model="paymentType" />
              <label for="tarjeta" class="radio-label">Tarjeta / PSE</label>
            </fieldset>
            <fieldset>
              <input type="radio" id="efectivo" value="Efectivo" v-model="paymentType" />
              <label for="efectivo" class="radio-label">Efectivo (puntos físicos)</label>
            </fieldset>
          </div> -->
        </b-card>
        <ButtonContinue class="btn-continue" text="Continuar" :disable="!valorValido" @click="verificarAntecipo" />
      </div>
    </div>
    <StepperVertical :step="step" />
    <ModalRecadoClienteAnticipo id="modal-recado-anticipo" @continuar="proximaPagina" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { ACTION_WORKFLOW_STATUS_ASSISTED, FETCH_APPLICATION_ASSISTED } from '@/store/actions.type'

import StepperVertical from '@/components/StepperVertical.vue'
import ButtonContinue from '@/components/buttons/ButtonContinue.vue'
import CurrencyInput from '@/components/inputs/CurrencyInput.vue'
import ModalRecadoClienteAnticipo from '@/components/modal/antecipo/ModalRecadoClienteAnticipo.vue'

export default {
  components: {
    StepperVertical,
    ButtonContinue,
    ModalRecadoClienteAnticipo,
    CurrencyInput,
  },
  data() {
    return {
      step: 1,
      valorAntecipado: 0,
      paymentType: 'Tarjeta-PSE',
    }
  },
  created() {
    this.$store.dispatch(FETCH_APPLICATION_ASSISTED)
  },
  mounted() {
    if (this.advancedPayment > 0) this.valorAntecipado = this.advancedPayment
  },
  methods: {
    verificarAntecipo() {
      if (this.valorAntecipado > 0) this.$bvModal.show('modal-recado-anticipo')
      else this.proximaPagina()
    },
    proximaPagina() {
      this.$store.dispatch(ACTION_WORKFLOW_STATUS_ASSISTED, {
        action: 'setPreOffer',
        advancedPayment: this.valorAntecipado || 0,
        paymentType: this.paymentType,
      })
    },
  },
  computed: {
    ...mapGetters({
      currentApplication: 'currentApplication',
      phoneDetailtUrl: 'phoneDetailtUrl',
      advancedPayment: 'advancedPayment',
      advancedPaymentFmt: 'advancedPaymentFmt',
      maximumAdvancedPayment: 'maximumAdvancedPayment',
      maximumAdvancedPaymentFmt: 'maximumAdvancedPaymentFmt',
      minimumOrderValue: 'minimumOrderValue',
      minimumOrderValueFmt: 'minimumOrderValueFmt',
    }),
    valorValido() {
      return this.valorAntecipado >= this.advancedPayment && this.valorAntecipado <= this.anticipoMaximo
    },
    anticipoMaximo() {
      return this.currentApplication.intendedRequestedValue - this.maximumAdvancedPayment < this.minimumOrderValue
        ? this.currentApplication.intendedRequestedValue - this.minimumOrderValue
        : this.maximumAdvancedPayment
    },
  },
}
</script>

<style scoped lang="scss">
.anticipo-assistido-form {
  position: relative;
  width: 100%;
  .header-titulo-anticipo {
    margin: 0 auto 1.5rem auto;
    display: flex;
    justify-content: center;
    align-items: center;
    h1 {
      font-size: 20px;
      margin: 0;
    }
  }
  .card {
    text-align: left;
    border: none;
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    margin-bottom: 4rem;
    width: 340px;
    .card-header {
      background-color: #019df4;
      border: none;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      border-radius: 10px 10px 0 0;
      color: white;
      display: flex;
      justify-content: space-between;
      .value {
        font-weight: bold;
      }
      .divisor-vertical {
        border: solid 1px #d0d0d0;
      }
    }
    .card-body {
      padding-top: 2rem;
      .form-label {
        color: #86888c;
        font-weight: bold;
        font-size: 16px;
      }
      .input-help {
        color: #86888c;
        font-size: 14px;
        &.input-error {
          color: #e63780 !important;
        }
      }
      .radio-label {
        color: #50535a;
        font-size: 14px;
      }
      .input-group.input-error {
        border-bottom: 1px solid #e63780;
      }
    }
  }
  .btn-continue {
    width: 100%;
  }
  .stepper {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: -3rem;
  }
}
</style>
