<template>
  <div>
    <div class="container-assistido-info">
      <section>
        <h1 class="title-assistido-info">Para validar la identidad de tu cliente</h1>
      </section>
      <section>
        <div class="list-number">
          <span class="number-in-list">1.</span>
          <p class="list-with-number">Pídele que te indique su fecha de expedición de la cédula</p>
        </div>
        <div class="list-number">
          <span class="number-in-list">2.</span>
          <p class="list-with-number">Dar clic en “Confirmar”</p>
        </div>
        <div class="list-number">
          <span class="number-in-list">3.</span>
          <p class="list-with-number">Aparecerán las preguntas y debes marcar la opción que el cliente responda.</p>
        </div>
        <div class="list-number">
          <span class="number-in-list">4.</span>
          <p class="list-with-number">Para generar la validación da clic en “Confirmar”</p>
        </div>
        <div class="list-number">
          <span class="number-in-list">5.</span>
          <p class="list-with-number">Si la validación no es correcta podrás hacer un segundo intento</p>
        </div>
        <div class="list-number">
          <span class="number-in-list">6.</span>
          <p class="list-with-number">
            Si después de dos intentos no se logra la validación del cliente, el cliente quedará bloqueado por 24 horas.
            Coméntale que las respuestas no han sido exitosas y que lo estaremos contactando pasadas las 24 horas.
          </p>
        </div>
        <div class="list-number">
          <span class="number-in-list">7.</span>
          <p class="list-with-number">El máximo de intentos al mes es 4</p>
        </div>
      </section>
    </div>
    <div class="box-cancelar">
      <ButtonCancel text="Cancelar solicitud" @click="modalCancell" />
    </div>

    <ModalCancelarAssistido
      id="modal-basic"
      title="Estás a punto de cancelar tu propuesta"
      subtitle="¿Quieres continuar con esta acción?"
      image="congratulations.svg"
    />
  </div>
</template>

<script>
import ButtonCancel from '../../buttons/ButtonCancel.vue'
import ModalCancelarAssistido from '../../modal/ModalCancelarAssistido'

export default {
  components: { ButtonCancel, ModalCancelarAssistido },
  methods: {
    showModal(modalId) {
      this.$bvModal.show(modalId)
    },
    modalCancell() {
      this.showModal('modal-basic')
    },
  },
}
</script>

<style scoped>
.box-cancelar {
  display: flex;
  align-items: flex-start;
}

.header-simulation {
  display: flex;
  width: 509px;
}

.container-assistido-info {
  width: 540px;
  height: 650px;
  border: solid 1px rgba(1, 157, 244, 0.6);
  border-radius: 10px;
  background-color: #f5f5f5;
  padding: 42px 45px 81.4px 27px;
}

.list-assistido-info-guia {
  margin-top: 1rem;
  padding-left: 30px;
}

.btn-simulation-box-first,
.btn-simulation-box-second {
  border: none;
  height: 40px;
  font-size: 16px;
  color: #50535a;
}

.title-assistido-info {
  font-size: 22px;
  margin: 0 10px 20px;
}

.content-assistido-info {
  font-size: 16px;
  width: 413px;
  height: 100%;
  text-align: start;
}

.content-assistido-info::marker {
  color: #019df4;
  font-size: 1rem;
}

.content-assistido-info-guia {
  font-size: 16px;
  width: 420px;
  height: 100%;
  text-align: start;
  margin-bottom: 8px;
}

.content-assistido-info-guia::marker {
  color: #019df4;
  font-size: 1.3rem;
}

.modificarEstiloBtn {
  background: #019df4;
  color: #ffffff;
}

.list-number {
  display: flex;
}

.number-in-list {
  margin-right: 14px;
  font-weight: bold;
}

.list-with-number {
  width: 430px;

  font-size: 15px;
  text-align: start;
  margin-bottom: 1rem;
}

.teste {
  font-weight: bold;
}

.box-informacoes-assistido {
  margin: 5rem auto;
}

@media (max-width: 1380px) {
  .box-cancelar {
    margin-left: 8rem;
    display: flex;
    align-items: flex-start;
  }

  .container-assistido-info {
    margin-left: 8rem;
    width: 540px;
    height: 100%;
    max-height: 680px;
    border: solid 1px rgba(1, 157, 244, 0.6);
    border-radius: 10px;
    background-color: #f5f5f5;
    padding: 42px 45px 81.4px 27px;
  }
}
</style>
