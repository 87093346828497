<template>
  <div>
    <div class="container-footer" v-if="footerArrayOffers">
      <div class="container-couter-offer">
        <h1 class="content-counter-offer">*Te llamaremos para confirmar tu desembolso</h1>
      </div>
      <section class="content-footer">
        <p class="legend-content value-month">Seguro Mensual</p>
        <p class="footer-content value-month">{{ footerArrayOffers.feesDueFmt }}</p>
      </section>
      <!--<section class="content-footer">
        <p class="legend-content display-none" >Valor total Unificado (VTU)</p>
        <p class="footer-content display-none">{{footerArrayOffers.taxRateFmt}}</p>
      </section>-->
      <section class="content-footer">
        <p class="legend-content value-tea">Tasa efectiva anual (TEA)</p>
        <p class="footer-content value-tea">{{ footerArrayOffers.interestCETYearlyFmt }}</p>
      </section>
      <ButtonContinue
        text="Lo quiero"
        :origin="this.currentApplication.origin"
        @click="selecionarContraOferta"
        class="next-btn"
      />
    </div>

    <Modal
      id="modal-correo"
      title="Para continuar debes validar tu correo electrónico, ingresa a tu bandeja de entrada y valida el link que te enviamos"
      image="check-email.png"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import store from '../../../store'
import { SALVAR_CONTRA_OFERTA, FETCH_APPLICATION, TOGGLE_OVERLAY } from '../../../store/actions.type'
import { MUTATION_CONTRA_OFERTA } from '../../../store/mutations.type'
import ButtonContinue from '../../../components/buttons/ButtonContinue.vue'
import Modal from '../../modal/Modal.vue'
import { gtmDataLayerCustom, gtmDataLayerTrackEvent } from '../../../helpers/gtmHelper'

export default {
  components: { ButtonContinue, Modal },
  props: {
    footerArrayOffers: {},
    payloadContraOferta: {},
  },
  data() {
    return {
      contraOferta: this.payloadContraOferta,
    }
  },
  methods: {
    ...mapMutations([MUTATION_CONTRA_OFERTA]),
    selecionarContraOferta() {
      if (this.footerArrayOffers) {
        this.$store.dispatch(FETCH_APPLICATION, '')
        if (this.currentApplication.emailStatus !== 'ACCEPT') {
          this.showModal('modal-correo')
          return null
        }

        this.contraOferta = {
          action: 'offerLibre',
          installments: String(this.footerArrayOffers.installment),
          requestedValue: this.footerArrayOffers.intendedValue,
        }
        store.dispatch(TOGGLE_OVERLAY, true)
        this[MUTATION_CONTRA_OFERTA](this.contraOferta)
        store.dispatch(SALVAR_CONTRA_OFERTA, this.contraOferta)

        gtmDataLayerCustom(window.dataLayer, {
          event: 'trackaddtocart',
          eventChannel: store.getters.channelOrigin,
          entorno: process.env.VUE_APP_TAGMANAGER_ENTORNO,
          productos: [
            {
              name: this.footerArrayOffers.Offer,
              id: this.footerArrayOffers.installment,
              price: this.footerArrayOffers.totalFmt,
              brand: 'prestamo libre inversión',
              category: 'movistar money',
              variant: '0',
              list: 'movistar money',
              position: '2',
              quantity: '1',
            },
          ],
        })
        gtmDataLayerTrackEvent(
          window.dataLayer,
          'trackEvent',
          'movistar money',
          'click::selección cuotas::paso 2',
          'confirmar solicitud prestamo',
          this.CryptoJS.SHA1(this.currentApplication.mobile).toString()
        )
      }
    },
    showModal(id) {
      this.$bvModal.show(id)
    },
  },

  computed: {
    ...mapGetters({
      currentApplication: 'currentApplication',
      counterOfferData: 'counterOfferData',
      lastOffer: 'lastOffer',
      intendedInstallments: 'intendedInstallments',
    }),
  },
}
</script>

<style scoped>
.btn-offer {
  background: #fd7e14;
}

.content-counter-offer {
  font-family: Telefonica;
  font-size: 12px;
  color: #86888c;
}

.display-none {
  display: none;
}

.value-tea,
.value-month,
.value-vtu {
  position: relative;
}

.next-btn {
  background: #ec624b;
  width: 270px;
  height: 40px;
  border-radius: 25px;
  border: none;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1.3rem auto;
  font-weight: bold;
}

.container-footer {
  height: 100px;
  width: 100%;
  margin-top: 10px;
}

.footer-content {
  font-family: Telefonica;
  font-size: 10px;
  color: #50535a;
}

.legend-content {
  font-size: 10px;
  color: #50535a;
  font-family: Telefonica;
  text-align: start;
  width: 120px;
}

.content-footer {
  display: flex;
  justify-content: space-around;
  position: relative;
  top: 10%;
  height: 1.3rem;
}

@media (max-height: 580px) {
  .container-footer {
    height: 74px;
  }
  .content-footer {
    display: flex;
    justify-content: space-around;
    position: relative;
    top: 7%;
    height: 12px;
  }
  .next-btn {
    background: #ec624b;
    width: 240px;
    height: 25px;
    font-size: 14px;
  }
}

@media (min-width: 310px) and (max-width: 330px) {
  .container-footer {
    position: relative;
    padding-top: 20px;
    height: 100px;
    width: 100%;
  }
}
</style>
