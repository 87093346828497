<template>
  <b-modal
    id="modal-finish-libre"
    cancel-title=""
    cancel-variant="hidden"
    hide-header
    hide-footer
    ok-title=""
    ok-variant="hidden"
    centered
    header-border-variant="white"
    footer-border-variant="white"
  >
    <div class="py-2 mx-4">
      <p class="text-center font-16">
        Recuerda que te llegará a tu correo
        {{ currentApplication.borrower.email }} una factura de Movistar Money el
        {{ currentApplication.paymentCycle }} de cada mes
      </p>

      <img src="@/assets/icons/mail-fatura.svg" alt="heade-image" class="imagem" />

      <b-row cols="1" cols-sm="2">
        <b-col class="mt-4">
          <ButtonContinue text="¿Como pagar mi cuota?" @click="goRedirect()" :origin="this.currentApplication.origin" />
        </b-col>
        <b-col class="mt-4">
          <ButtonContinue
            text="Ir a mi cuenta"
            @click="goHome()"
            :origin="this.currentApplication.origin"
            :inverted="true"
          />
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import ButtonContinue from '@/components/buttons/ButtonContinue'
import { mapGetters } from 'vuex'
import router from '@/router/index'

export default {
  name: 'ModalFinishLibre',
  components: { ButtonContinue },

  methods: {
    goRedirect() {
      location.href = 'https://www.facebook.com/Movistarmoney/videos/477153444158028/'
    },
    goHome() {
      if (this.$route.path.includes('on-boarding')) {
        router.push({ name: 'Login' })
      } else {
        router.push({ name: 'Home' })
      }
    },
    closeModal(id) {
      this.$bvModal.hide(id)
    },
  },
  computed: {
    ...mapGetters({
      urlCallback: 'urlCallback',
      currentApplication: 'currentApplication',
    }),
  },
}
</script>

<style scoped>
.imagem {
  display: flex;
  margin-left: auto;
  margin-right: auto;
}
</style>
