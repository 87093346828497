<template>
  <div class="container-fluid bg-login">
    <b-container class="py-5">
      <b-row class="text-center py-3" align-h="center">
        <b-col cols="12" md="6" class="no-padding">
          <div class="card-wrapper-login">
            <MovistarMoneyLogoLogin />

            <div class="card-inner">
              <LoginSubtitle v-if="!mobileDisponiveis.data" text="Ingresa tus datos personales" />

              <b-form @submit.prevent="submit">
                <b-form-group v-if="!mobileDisponiveis.data">
                  <b-input-group :class="{ formInputError: $v.form.identityCardNumber.$error }">
                    <b-input-group-prepend>
                      <b-img src="@/assets/icons/id-card.svg" alt="Phone icon" />
                    </b-input-group-prepend>
                    <input
                      type="tel"
                      class="v-mask pb-2 form-input-item"
                      placeholder="Número de cédula"
                      v-mask="'# ### ### ### ## ##'"
                      v-model="form.identityCardNumber"
                      ref="identityCardNumber"
                    />
                  </b-input-group>
                  <p v-if="$v.form.identityCardNumber.$error" class="form-input-error">
                    Este número de cédula no es válido
                  </p>
                </b-form-group>

                <LoginSubtitle
                  class="text-center"
                  v-if="mobileDisponiveis.data"
                  text="Selecciona el número de celular con el que hiciste tu solicitud de crédito"
                />

                <b-form-group v-if="mobileDisponiveis.data">
                  <b-row align-h="center" v-for="mobile in mobileDisponiveis.data.mobile" :key="mobile">
                    <input
                      v-model="form.mobileLastDigits"
                      type="radio"
                      :id="mobile"
                      :name="mobile"
                      :value="mobile"
                      v-on:click="handleOnChange"
                    />
                    &ensp;&ensp;*** *** {{ mobile }}
                  </b-row>
                </b-form-group>
                <p v-if="selfManageError" class="mt-2 font-12 no-margin text-pink text-left">
                  {{ selfManageError }}
                </p>

                <div class="py-4 mt-4">
                  <ButtonContinueLogin text="Ingresar" :disable="disableButton" />
                </div>
              </b-form>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { maxLength, minLength, required } from 'vuelidate/lib/validators'
import MovistarMoneyLogoLogin from '../../components/SelfManage/MovistarMoneyLogoLogin'
import ButtonContinueLogin from '../../components/buttons/ButtonContinueLogin'
import LoginSubtitle from '../../components/SelfManage/LoginSubtitle'
import store from '../../store'
import {
  DESTROY_TOKEN,
  SELF_MANAGE_LOGIN,
  REMOVER_MOBILE_DISPONIVEIS,
  MOBILE_DISPONIVEIS,
} from '../../store/actions.type'
import { mapGetters } from 'vuex'

export default {
  name: 'Login',
  components: { LoginSubtitle, ButtonContinueLogin, MovistarMoneyLogoLogin },
  data() {
    return {
      disableButton: false,
      queryId: '',
      form: {
        identityCardNumber: '',
        mobileLastDigits: '0000',
      },
    }
  },
  validations: {
    form: {
      identityCardNumber: { required, min: minLength(8), max: maxLength(19) },
    },
  },
  mounted() {
    store.dispatch(DESTROY_TOKEN)
    store.dispatch(REMOVER_MOBILE_DISPONIVEIS)
    this.setFormId()
    this.$refs.identityCardNumber.focus()
    localStorage.clear()
  },
  watch: {
    selfManageError: {
      handler(value) {
        if (value != '') this.disableButton = false
      },
    },
  },
  methods: {
    submit() {
      this.$v.form.$touch()
      if (this.$v.form.$error) return
      store.dispatch(DESTROY_TOKEN)
      const newForm = this.newForm()
      this.disableButton = true
      if (!this.mobileDisponiveis.data) store.dispatch(MOBILE_DISPONIVEIS, newForm)
      else store.dispatch(SELF_MANAGE_LOGIN, newForm)
    },
    newForm() {
      let newForm = Object.assign({}, this.form)
      newForm.identityCardNumber = newForm.identityCardNumber.replace(/ /g, '')
      return newForm
    },
    handleOnChange() {
      this.disableButton = false
    },
    setFormId() {
      this.queryId = this.$route.query.id
    },
  },
  computed: {
    ...mapGetters({
      selfManageError: 'selfManageError',
      mobileDisponiveis: 'mobileDisponiveis',
    }),
  },
}
</script>

<style scoped>
.card-inner {
  margin: 20px 35px;
}
</style>
